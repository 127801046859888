import Guid from "common/values/guid/guid";
import EntityName from "legal-entities/values/entity-name/entity-name";

/** Entity member user information */
export default class UserEntityInfo {
    public readonly entityId: Guid;
    public readonly entityName: EntityName;
    public readonly isAdmin: boolean;
    public readonly isManager: boolean;
    public readonly isOfficer: boolean;
    public readonly entityVendorFlag?: boolean;
    public readonly entityClientFlag?: boolean;

    /** Returns the entity id. */
    public get id(): Guid {
        return this.entityId;
    }

    constructor(values: any) {
        if (!values.entityId) throw new InvalidUserEntityInfoError({ entityId: 'entityId is required' });
        if (!values.entityName) throw new InvalidUserEntityInfoError({ entityName: 'entityName is required' });
        if (values.isAdmin === undefined) throw new InvalidUserEntityInfoError({ isAdmin: 'isAdmin is required' });
        if (values.isOfficer === undefined) throw new InvalidUserEntityInfoError({ isOfficer: 'isOfficer is required' });
        if (values.isManager === undefined) throw new InvalidUserEntityInfoError({ isManager: 'isManager is required' });


        this.entityId = new Guid(values.entityId.valueOf());
        this.entityName = new EntityName(values.entityName);
        this.isAdmin = values.isAdmin;
        this.isManager = values.isManager;
        this.isOfficer = values.isOfficer;
        this.entityVendorFlag = values.entityVendorFlag;
        this.entityClientFlag = values.entityClientFlag;
    }

    public static fromObject(obj: any): UserEntityInfo {
        return new UserEntityInfo(obj);
    }
    public toJSON(): any {
        return {
            entityId: this.entityId.toJSON(),
            entityName: this.entityName.toJSON(),
            isAdmin: this.isAdmin,
            isManager: this.isManager,
            isOfficer: this.isOfficer,
            entityVendorFlag: this.entityVendorFlag,
            entityClientFlag: this.entityClientFlag
        };
    }
}

export class InvalidUserEntityInfoError extends Error {
    issues: any;

    constructor(issues: any) {
        super(`Invalid user entity info.`);
        this.issues = issues;
    }
}
