import { MutableRefObject } from "react";

import { Icon } from "@mui/material";
import MaterialTable, {
  Column,
  MaterialTableProps,
} from "@material-table/core";

import Guid from "common/values/guid/guid";

import { PageTab } from "app/routes/proposals/tabs";
import Proposal from "work/entities/proposal/proposal";


export function getToolbarTableColumns(
  activeTab: PageTab,
  tableRef: MutableRefObject<
    (MaterialTable<Proposal> & MaterialTableProps<Proposal>) | undefined
  >,
  userId?: Guid
): Column<Proposal>[] {
  return [
    {
      field: "status",
      title: "Status",
      width: 125,
    },
    {
      field: "name",
      title: "Name",
      width: 150,
    },
    {
      field: "description",
      title: "Description",
      width: 250,
    },
    {
      field: "creator",
      title: "Creator",
    },
    {
      field: "negotiable",
      title: "Negotiable",
      type: "boolean",
    },
    {
      field: "team",
      title: "Team",
      emptyValue: <Icon>remove</Icon>,
    },
    {
      field: "feeSchedule",
      title: "Fee Schedule",
      emptyValue: <Icon>remove</Icon>,
    },
    {
      field: "responseDueBy",
      title: "Response Due",
      emptyValue: <Icon>remove</Icon>,
    },
    {
      field: "startDate",
      title: "Start Date",
      emptyValue: <Icon>remove</Icon>,
    },
    {
      field: "endDate",
      title: "End Date",
      emptyValue: <Icon>remove</Icon>,
    },
    {
      title: "Actions",
      align: "right",
      filtering: false,
      draggable: false,
      sorting: false,
      grouping: false,
      disableClick: true,
      hiddenByColumnsButton: true,
      removable: false,
      width: "fit-content",
    },
  ];
}
