import { Link, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useConfirmDialog } from "app/providers/confirm-dialog";
import { useDialog } from "app/providers/dialog";
import Guid from "common/values/guid/guid";
import ViewIndividualProfile from "marketplace/values/individual-profile/view/view-individual-profile";
import UserInfo from "work/values/user-info/user-info";

const StyledLink = styled(Link)(({ theme }) => ({
  alignItems: 'center',
  color: '#000',
  cursor: 'pointer',
  display: 'flex',
  marginBottom: theme.spacing(1),
  overflow: 'hidden',
  textDecoration: 'underline',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
}));
const SecondaryText = styled(Typography)(({ theme }) => ({
  color: 'rgba(0, 0, 0, 0.54)',
  display: 'block',
  lineHeight: '1.1em'
}));

type CreatorLinkProps = {
  creator: UserInfo;
};

export default function CreatorLink(props: Readonly<CreatorLinkProps>) {
  const { creator } = props;

  const { openDialog, closeDialog } = useDialog();
  const confirm = useConfirmDialog();

  function openProfileDialog(individualId: Guid) {
    openDialog({
      component: (
        <ViewIndividualProfile 
          individualId={individualId} 
          confirmDialog={confirm}
        />
      ),
      contentSxProps: {
        display: "flex",
      },
      MuiProps: {
        fullWidth: true,
        maxWidth: "lg",
      },
      titleStyle: {
        position: "absolute",
        right: 0,
        top: 0,
      },
      onClose: closeDialog,
    });
  }

  return (
    <>
      <StyledLink
        onClick={() => openProfileDialog(creator.individualId)}>
        {`${creator.name.firstName} ${creator.name.lastName}`}
      </StyledLink>
      {/* <SecondaryText variant='caption'>
        {moment(proposal.createdDate).format('MM/DD/YY')}
      </SecondaryText>
      <SecondaryText variant='caption'>
        {moment(proposal.createdDate).format('hh:mm A')}
      </SecondaryText> */}
    </>
  );
}
