import { ConfirmDialogType, useConfirmDialog } from "app/providers/confirm-dialog";

type UseConfirmDialogProps = {
  onConfirmDialog: (confirmDialog: ConfirmDialogType) => void;
  onError?: (error: any) => void;
}

export const UseConfirmDialog = (props: UseConfirmDialogProps) => {
  try {
    const confirmDialog = useConfirmDialog();
    props.onConfirmDialog(confirmDialog);
  } catch (error) {
    props.onError?.(error);
  }
  return null;
}
