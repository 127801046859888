import { Moment } from "moment";
import Guid from "common/values/guid/guid";
import FeeScheduleTag from "marketplace/values/fee-schedule-tag/fee-schedule-tag";
import EntityRepresentative from "work/entities/entity-representative/entity-representative";
import FeeScheduleCategory from "work/values/fee-schedule-category/fee-schedule-category";

export default class FeeScheduleTemplate {
  id?: Guid;
  createdDate?: Moment;
  creator?: EntityRepresentative;
  name: string;
  tags: FeeScheduleTag[] = [];
  categories: FeeScheduleCategory[] = [];

  archivedDate: Moment | null;
  isExpanded: boolean | undefined;

  constructor(name: string, id?: Guid, tags?: FeeScheduleTag[], categories?: FeeScheduleCategory[], archivedDate?: Moment) {
    this.name = name;
    this.id = id;
    this.tags = tags ?? [];
    this.categories = categories ?? [];
    this.archivedDate = archivedDate ?? null;
  }

  public isUsing(categories: FeeScheduleCategory[]) {
    return categories.some((category) => {
      return this.categories.some((thisCategory) => category.id.isEqualTo(thisCategory.id));
    });
  }
}
