import {
  Column
} from "@material-table/core";
import {
  Archive as ArchiveIcon,
  Delete as DeleteIcon
} from "@mui/icons-material";
import {
  Icon,
  Link,
  ListItemIcon,
  ListItemText,
  MenuItem
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { PageTab } from "app/routes/teams/tabs";
import RowActionMenu from "common/components/table/row-action-menu";
import { ActionsCellContainer } from "common/styles";
import Guid from "common/values/guid/guid";
import Company from "marketplace/entities/company/company";
import Individual from "marketplace/entities/individual/individual";
import TeamTemplate from "work/entities/team-template/team-template";

const TeamColumnLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline",
  },
}));
const StyledIcon = styled(Icon)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  alignContent: "center"
}));

export function getTableColumns(
  activeTab: PageTab,
  onEditTeamTemplate: (id: Guid) => void,
  onDeleteTeamTemplate: (id: Guid) => void,
  onViewCompanyProfile: (company: Company) => void,
  onViewIndividualProfile: (individual: Individual) => void
): Column<TeamTemplate>[] {
  return [
    {
      field: "name",
      title: "Template Name",
      emptyValue: <StyledIcon>remove</StyledIcon>,
      hidden: activeTab !== PageTab.Templates,
      render: (rowData) => (
        <TeamColumnLink onClick={() => {
          if (!rowData.id) return;
          onEditTeamTemplate(rowData.id);
        }}>
          {rowData.name}
        </TeamColumnLink>
      )
    },
    {
      field: "leader.company.profile.name",
      title: "Company",
      emptyValue: <StyledIcon>remove</StyledIcon>,
      hidden: activeTab !== PageTab.Templates,
      render: (rowData) => (
        <TeamColumnLink onClick={() => {
          if (!rowData.leader.company) return;
          onViewCompanyProfile(rowData.leader.company);
        }}>
          {rowData.leader.company?.profile?.name}
        </TeamColumnLink>
      )
    },
    {
      field: "description",
      title: "Description",
      emptyValue: <StyledIcon>remove</StyledIcon>,
      hidden: activeTab === PageTab.Templates,
    },
    {
      field: "leader",
      title: "Leader",
      emptyValue: <StyledIcon>remove</StyledIcon>,
      render: (rowData) => (
        <TeamColumnLink onClick={() => onViewIndividualProfile(rowData.leader)}>
          {rowData.leader.getFullName()}
        </TeamColumnLink>
      )
    },
    {
      field: "members",
      title: "Members",
      emptyValue: <StyledIcon>remove</StyledIcon>,
      render: (rowData) => {
        if (rowData.members.length === 0) {
          return <StyledIcon>remove</StyledIcon>;
        }
        return (
          <ul>
            {rowData.members.map((member: Individual) => (
              <li key={member.id.valueOf()}>
                <TeamColumnLink onClick={() => onViewIndividualProfile(member)}>
                  {member.getFullName()}
                </TeamColumnLink>
              </li>
            ))}
          </ul>
        )
      },
    },
    {
      title: "Marketplace Profile",
      emptyValue: <StyledIcon>remove</StyledIcon>,
      hidden:
        sessionStorage.getItem("accountType") !== "Vendor" ||
        activeTab !== PageTab.Templates,
    },
    {
      field: "created",
      title: "Created",
      emptyValue: <StyledIcon>remove</StyledIcon>,
      type: "datetime",
      render: (rowData) => rowData.created?.format("MM/DD/YY hh:mm A"),
    },
    {
      title: "Actions",
      align: "right",
      filtering: false,
      draggable: false,
      sorting: false,
      grouping: false,
      disableClick: true,
      hiddenByColumnsButton: true,
      removable: false,
      width: "fit-content",
      render: (rowData) => renderActionsColumn(
        rowData,
        onDeleteTeamTemplate
      )
    }
  ];
}

function renderActionsColumn(
  rowData: TeamTemplate,
  onDeleteTeamTemplate: (id: Guid) => void
): React.ReactNode {
  if (!rowData.id) return <Icon>remove</Icon>;

  return (
    <ActionsCellContainer>
      <RowActionMenu
        menuItems={
          getMenuItems(
            rowData, 
            () => { 
              if(!rowData.id) return;
              onDeleteTeamTemplate(rowData.id);
            }
          )
        }
      />
    </ActionsCellContainer>
  );
}

function getMenuItems(
  rowData: TeamTemplate,
  onDeleteTeamTemplate: () => void
): React.ReactNode[] {
  return [
    <MenuItem key="archive">
      <ListItemIcon>
        <ArchiveIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>Archive</ListItemText>
    </MenuItem>,
    <MenuItem key="delete" onClick={onDeleteTeamTemplate}>
      <ListItemIcon>
        <DeleteIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>Delete</ListItemText>
    </MenuItem>
  ];
}
