import MaterialTable, { MaterialTableProps, MTableToolbar } from "@material-table/core";
import { FilterList as FilterListIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { CommunicationTableData, getTableColumns } from "app/routes/communications/table/columns";
import ActiveFiltersList from "common/components/table/active-filters-list";
import { FilterButtons, FilterChip, TableToolbarContainer } from "common/styles";
import { BoxType } from "notifications/values/box-type/box-type";
import { useSearchParams } from "react-router-dom";
import Session from "users/session/session";

const NavContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    marginBottom: 0
  },
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '-5px'
}));

export type TableToolbarComponentProps = {
  tableRef: React.MutableRefObject<(MaterialTable<CommunicationTableData> & MaterialTableProps<CommunicationTableData>) | undefined>;
  activeTab: BoxType;
  tabs: React.ReactNode;
  activeFilters: any[];
  selectedRows: CommunicationTableData[];
  actionRequired: boolean;
  isMediumDisplaySize: boolean;
  clearFilters: () => void;
  setActionRequired: (value: boolean) => void;
  session: Readonly<Session>;
}

export default function TableToolbarComponent(props: Readonly<TableToolbarComponentProps>) {
  const {
    tableRef,
    activeTab,
    tabs,
    activeFilters,
    selectedRows,
    actionRequired,
    clearFilters,
    setActionRequired,
    session
  } = props;

  const [, setSearchParams] = useSearchParams();

  return (
    <TableToolbarContainer rowsselected={selectedRows.length > 0 ? true : undefined}>
      <MTableToolbar {...props} />
      <NavContainer>
        {tabs}
        <FilterButtons>
          {activeTab !== BoxType.All && (
            <FilterChip
              icon={<FilterListIcon />}
              label="Action Required"
              color="primary"
              variant={actionRequired ? 'filled' : 'outlined'}
              onClick={() => {
                setActionRequired(!actionRequired);
                setSearchParams((prevParams) => {
                  const params = new URLSearchParams(prevParams);
                  if (!actionRequired) {
                    params.set('actionRequired', 'true');
                  } else {
                    params.delete('actionRequired');
                  }
                  return params;
                });
              }}
            />
          )}
        </FilterButtons>
      </NavContainer>
      {activeFilters.length > 0 && (
        <ActiveFiltersList
          tableRef={tableRef}
          columns={getTableColumns(activeTab, selectedRows, session)}
          activeFilters={activeFilters}
          onClear={clearFilters} />
      )}
    </TableToolbarContainer>
  )
}
