import { MoreVert as MoreVertIcon } from "@mui/icons-material";
import { IconButton, Menu, Tooltip } from "@mui/material";
import { ReactNode, useState } from "react";

interface RowActionMenuProps {
  menuItems: Array<ReactNode>;
};

export default function RowActionMenu(props: Readonly<RowActionMenuProps>) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  const handleActionMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchor(event.currentTarget);
    setMenuOpen(true);
  };

  return (
    <span>
      <Tooltip title="Show Options">
        <span>
          <IconButton
            sx={{ color: 'black' }}
            onClick={handleActionMenu}
            size="medium">
            <MoreVertIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Menu
        open={menuOpen}
        anchorEl={menuAnchor}
        onClose={() => setMenuOpen(false)}
        onClick={() => setMenuOpen(false)}>
        {props.menuItems}
      </Menu>
    </span>
  );
}
