import FeeScheduleBillingCode from "marketplace/values/fee-schedule-billing-code/fee-schedule-billing-code";
import FeeScheduleCategoryDescription from "marketplace/values/fee-schedule-category-description/fee-schedule-category-description";
import FeeScheduleCategoryName from "marketplace/values/fee-schedule-category-name/fee-schedule-category-name";
import Fee from "marketplace/values/fee/fee";

export default class FeeScheduleCategory {
  name: FeeScheduleCategoryName;
  description?: FeeScheduleCategoryDescription;
  fee: Fee;
  billingCode: FeeScheduleBillingCode;

  constructor(
    name: FeeScheduleCategoryName,
    description: FeeScheduleCategoryDescription | undefined,
    fee: Fee,
    billingCode: FeeScheduleBillingCode
  ) {
    this.name = name;
    this.description = description;
    this.fee = fee;
    this.billingCode = billingCode;
  }

  static areSame(original: FeeScheduleCategory, updated: FeeScheduleCategory): boolean {
    if (original.name.valueOf() !== updated.name.valueOf()) {
      return false;
    }
    if (original.description?.valueOf() !== updated.description?.valueOf()) {
      return false;
    }
    if (!Fee.areSame(original.fee, updated.fee)) {
      return false;
    }
    if (original.billingCode.valueOf() !== updated.billingCode.valueOf()) {
      return false;
    }
    return true;
  }
}
