import WorkDocument from "work/values/work-document/work-document";

export default class ReplaceableDocumentAPIRequest {
    documentId?: string;
    replacesId?: string;

    constructor(document?: WorkDocument) {
        this.documentId = document?.id.valueOf();
        this.replacesId = document?.replacesId?.valueOf();
    }

    public get payload(): object {
        return {
            documentId: this.documentId,
            replacesId: this.replacesId
        };
    }
}
