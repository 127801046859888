import Guid from "common/values/guid/guid";
import EntityVendorRepresentative from "work/entities/entity-vendor-representative/entity-vendor-representative";

export default class Team {
    leader: EntityVendorRepresentative;
    memberUserIds: Guid[];
    marketplaceId?: Guid;

    constructor(leader: EntityVendorRepresentative, memberUserIds: Guid[], marketplaceId?: Guid) {
        this.leader = leader;
        this.memberUserIds = memberUserIds;
        this.marketplaceId = marketplaceId;
    }

    clone(): Team {
        return new Team(
            this.leader.clone(), 
            this.memberUserIds, 
            this.marketplaceId
        );
    }

    toJSON() : any {
        return {
            leader: {
                entityId: this.leader.entityId.valueOf(),
                userId: this.leader.userId.valueOf()
            },
            memberUserIds: this.memberUserIds.map(id => id.valueOf()),
            marketplaceId: this.marketplaceId?.valueOf()
        }
    }
}