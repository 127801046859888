import Individual from 'marketplace/entities/individual/individual';
import Company from 'marketplace/entities/company/company';
import BillingRateInfo from 'marketplace/values/billing-rate-info/billing-rate-info';
import CompanyProfile from 'marketplace/values/company-profile/company-profile';
import IndividualProfile from 'marketplace/values/individual-profile/individual-profile';
import InsuranceInfo from 'marketplace/values/insurance-info/insurance-info';
import MarketplaceResponseAPIResponse from 'marketplace/api/response-contracts/marketplace-response-api-response';
import EmailAddress from 'common/values/email-address/email-address';
import Guid from 'common/values/guid/guid';
import PhoneNumber from 'common/values/phone-number/phone-number';
import RegistrationNumber from 'marketplace/values/registration-number/registration-number';
import TechnicalBackgroundInfo from 'marketplace/values/technical-background-info/technical-background-info';

export default class MarketplaceIndividualInfoAPIResponse extends MarketplaceResponseAPIResponse {
    entityId?: string;
    companyId?: string;
    companyEntityId?: string;
    companyName?: string;
    firstName?: string;
    lastName?: string;
    description?: string;
    location?: string;
    phoneNumber?: string;
    school?: string;
    degree?: string;
    avatar?: string;
    emailAddress?: string;
    resume?: string;
    professionalRegistration?: string;
    technicalBackground?: string;
    numberOfApplicationsDraftedProsecuted?: number;
    numberOfAllowedApplications?: number;
    barAdmissionYear?: number;
    isOnCall?: boolean;
    insuredAmount?: number;
    billingRate?: string;
    billingUnit?: string;
    potentialDiversityCandidate?: boolean;
    collaborates?: boolean;
    additionalInfo?: string;
    links?: string[];
    professionalPublications?: string[];
    sampleApplications?: string[];
    fluentLanguages?: string[];
    technicalLanguages?: string[];
    regionsRegistered?: string[];
    regionsLicensed?: string[];
    categories?: string[];
    userId?: string;
    isVendorRep?: boolean;
    isClientRep?: boolean;

    deserialize(): Individual {
        if (!this.id) throw new Error("Individual Id is required");
        if (!this.userId) throw new Error("User Id is required");
        if (!this.firstName) throw new Error("First name is required");
        if (!this.lastName) throw new Error("Last name is required");

        const individualProfile = IndividualProfile.fromPartial({
            userId: new Guid(this.userId),
            firstName: this.firstName,
            lastName: this.lastName,
            description: this.description,
            location: this.location,
            phoneNumber: new PhoneNumber(this.phoneNumber),
            school: this.school,
            degree: this.degree,
            avatarId: this.avatar ? new Guid(this.avatar) : undefined,
            email: this.emailAddress ? new EmailAddress(this.emailAddress) : undefined,
            resumeId: this.resume ? new Guid(this.resume) : undefined,
            professionalRegistration: this.professionalRegistration ? new RegistrationNumber(this.professionalRegistration) : undefined,
            technicalBackground: this.technicalBackground ? new TechnicalBackgroundInfo(this.technicalBackground) : undefined,
            numberOfApplicationsDraftedProsecuted: this.numberOfApplicationsDraftedProsecuted,
            numberOfAllowedApplications: this.numberOfAllowedApplications,
            barAdmissionYear: this.barAdmissionYear,
            isOnCall: this.isOnCall,
            insured: this.insuredAmount ? new InsuranceInfo(this.insuredAmount) : undefined,
            billingRate: this.billingRate ? new BillingRateInfo(this.billingRate, this.billingUnit ?? "") : undefined,
            potentialDiversityCandidate: this.potentialDiversityCandidate,
            collaborates: this.collaborates,
            additionalInfo: this.additionalInfo,
            links: this.links ?? [],
            professionalPublications: this.professionalPublications ?? [],
            sampleApplications: this.sampleApplications ?? [],
            fluentLanguages: this.fluentLanguages,
            technicalLanguages: this.technicalLanguages,
            administrativeRegions: this.regionsRegistered,
            regionsRegistered: this.regionsRegistered,
            regionsLicensed: this.regionsLicensed,
            categories: this.categories
        });

        const entityId = this.companyEntityId ?? this.entityId;

        const company: Company | undefined = this.companyId && entityId && this.companyName ? new Company(
            new Guid(this.companyId),
            new Guid(entityId),
            new CompanyProfile(this.companyName)
        ) : undefined;

        return new Individual(new Guid(this.id), new Guid(this.userId), individualProfile, company);
    }
}
