import UserOpenEntityAgreementAPIResponse from "users/api/response-contracts/user-open-entity-agreement-api-response";
import UserNetworkInfoAPIResponse from "users/entities/user-network-connection/api/response-contracts/user-network-connection-profile-api-response";
import NetworkInvitationInfoAPIResponse from "users/entities/user-network-invitation/api/response-contracts/network-invitation-info-api-response";
import UserBookmarkInfoAPIResponse from "users/values/user-bookmark-info/api/response-contracts/user-bookmark-info-api-response";
import UserEntityInfoAPIResponse from "users/values/user-entity-info/api/response-contracts/user-entity-info-api-response";
import Guid from "common/values/guid/guid";
import Name from "common/values/name/name";
import EmailAddress from "common/values/email-address/email-address";
import UserInformation, { IUserInformation } from "users/values/user-information/user-information";

export default class UserInformationAPIResponse {
  id?: string;
  isVendor?: boolean;
  isClient?: boolean;
  viewingAsVendor?: boolean;
  isVendorRepresentative?: boolean;
  isClientRepresentative?: boolean;
  emailAddress?: string;
  firstName?: string;
  lastName?: string;
  timestamp?: Date | string;
  legalEntities?: UserEntityInfoAPIResponse[];
  openEntityAgreements?: UserOpenEntityAgreementAPIResponse[];
  hasOpenEntityAgreements?: boolean;
  individualId?: string;
  networkConnections?: UserNetworkInfoAPIResponse[];
  networkInvitations?: NetworkInvitationInfoAPIResponse[];
  bookmarkedIndividuals?: UserBookmarkInfoAPIResponse[];
  bookmarkedCompanies?: UserBookmarkInfoAPIResponse[];
  bookmarkedTeams?: UserBookmarkInfoAPIResponse[];

  deserialize(): UserInformation {
    if (!this.id) throw new Error("Id is required, was not returned by the API");
    if (this.isVendor === undefined) throw new Error("isVendor is required, was not returned by the API");
    if (this.isClient === undefined) throw new Error("isClient is required, was not returned by the API");
    if (!this.firstName) throw new Error("First name is required, was not returned by the API");
    if (!this.lastName) throw new Error("Last name is required, was not returned by the API");
    if (!this.emailAddress) throw new Error("Email address is required, was not returned by the API");
    if (!this.timestamp) throw new Error("Timestamp is required, was not returned by the API");

    const deserializedLegalEntities = this.legalEntities?.map(legalEntity => {
      const entity = Object.assign(new UserEntityInfoAPIResponse(), legalEntity);
      return entity.deserialize()
    }) ?? [];

    const deserializedNetworkConnections = this.networkConnections?.map(connection => {
      const entity = Object.assign(new UserNetworkInfoAPIResponse(), connection);
      return entity.deserialize()
    }) ?? [];

    const deserializedNetworkInvitations = this.networkInvitations?.map(invitation => {
      const entity = Object.assign(new NetworkInvitationInfoAPIResponse(), invitation);
      return entity.deserialize()
    }) ?? [];

    const deserializedOpenEntityAgreements = this.openEntityAgreements?.map(agreement => {
      const entity = Object.assign(new UserOpenEntityAgreementAPIResponse(), agreement);
      return entity.deserialize()
    }) ?? [];

    const deserializedBookmarkedIndividuals = this.bookmarkedIndividuals?.map(bookmark => {
      const entity = Object.assign(new UserBookmarkInfoAPIResponse(), bookmark);
      return entity.deserialize()
    }) ?? [];

    const deserializedBookmarkedCompanies = this.bookmarkedCompanies?.map(bookmark => {
      const entity = Object.assign(new UserBookmarkInfoAPIResponse(), bookmark);
      return entity.deserialize()
    }) ?? [];

    const deserializedBookmarkedTeams = this.bookmarkedTeams?.map(bookmark => {
      const entity = Object.assign(new UserBookmarkInfoAPIResponse(), bookmark);
      return entity.deserialize()
    }) ?? [];

    const info: IUserInformation = {
      id : new Guid(this.id),
      isVendor : Boolean(this.isVendor),
      isClient : Boolean(this.isClient),
      viewingAsVendor : Boolean(
        this.viewingAsVendor == null ? (this.isVendor && !this.isClient) : this.viewingAsVendor
      ),
      isVendorRepresentative : Boolean(this.isVendorRepresentative ?? false),
      isClientRepresentative : Boolean(this.isClientRepresentative ?? false),
      fullName : new Name(this.firstName, this.lastName),
      emailAddress : new EmailAddress(this.emailAddress),
      timestamp : this.timestamp,
      hasOpenEntityAgreements : this.hasOpenEntityAgreements ?? false,
      legalEntities : deserializedLegalEntities,
      openEntityAgreements : deserializedOpenEntityAgreements,
      individualId : this.individualId ?
        new Guid(this.individualId) : undefined,
      networkConnections : deserializedNetworkConnections,
      networkInvitations : deserializedNetworkInvitations,
      bookmarkedIndividuals : deserializedBookmarkedIndividuals,
      bookmarkedCompanies : deserializedBookmarkedCompanies,
      bookmarkedTeams : deserializedBookmarkedTeams
    }
    return new UserInformation(info);
  };
}
