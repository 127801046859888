import { Link } from "@mui/material";
import Proposal from "work/entities/proposal/proposal";
import { useState } from "react";
import RedlinePopover from "work/entities/proposal/redlining/view/proposal-redline-popover";
import { styled } from "@mui/material/styles";

const StyledLink = styled(Link, { shouldForwardProp: prop => prop !== 'revised' })<{ revised: boolean }>(({ revised, theme }) => ({
  color: 'primary',
  cursor: 'pointer',
  fontSize: '0.95em',
  fontWeight: 500,
  textDecoration: revised ? 'underline dotted red' : 'underline'
}));

type NameLinkProps = {
  proposal: Proposal;
  onProposalClicked: (proposal: Proposal) => void;
};

export default function NameLink(props: Readonly<NameLinkProps>) {
  const { proposal, onProposalClicked } = props;
  
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLElement | null>(null);

  return (
    <>
      <StyledLink 
        revised={
          proposal?.supersededById !== null
        }
        onMouseEnter={(event) => setPopoverAnchor(event.currentTarget)}
        onMouseLeave={(_event) => setPopoverAnchor(null)}
        onClick={() => onProposalClicked(proposal)}>
        {proposal.name.valueOf()}
      </StyledLink>
      <RedlinePopover
        columnName='name'
        proposal={proposal}
        open={Boolean(popoverAnchor)}
        anchorEl={popoverAnchor}
      />
    </>
  )
}
