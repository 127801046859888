import Guid from "common/values/guid/guid";
import { Moment } from "moment";

export default class UserBookmarkInfo {
  public readonly id: Guid;
  public readonly entityId: Guid;
  public readonly createdDate: Date | string | Moment;

  constructor(id: Guid, entityId: Guid, createdDate: Moment | Date) {
    this.id = id;
    this.entityId = entityId;
    this.createdDate = createdDate;
  }

  static fromObject(bookmark: any) {
    const { id, entityId, createdDate } = bookmark;

    if(!createdDate)
      throw new InvalidUserBookmarkError({ createdDate: 'createdDate is required' });

    const bookmarkId = Guid.fromObject(id);
    if(!bookmarkId)
      throw new InvalidUserBookmarkError({ id: 'bookmark id has invalid Guid format' });

    const bookmarkEntityId = Guid.fromObject(entityId);
    if(!bookmarkEntityId)
      throw new InvalidUserBookmarkError({ entityId: 'entityId has invalid Guid format' });
    
    return new UserBookmarkInfo(bookmarkId, bookmarkEntityId, createdDate);
  }

  public toJSON() {
    return {
      id: this.id.toString(),
      entityId: this.entityId.toString(),
      createdDate: this.createdDate.toString()
    };
  }
}

export class InvalidUserBookmarkError extends Error {
  issues: any;

  constructor(issues: any) {
    super(`Invalid user bookmark info.`);
    this.issues = issues;
  }
}
