import Name from "common/values/name/name";
import Password from "common/values/password/password";

export default class AcceptEntityUserInvitationAPIRequest {
    password: string;
    firstName?: string;
    lastName?: string;
    isClient?: boolean;
    isVendor?: boolean;

    constructor(password: Password, name: Name, isClient?: boolean, isVendor?: boolean) {
        this.password = password.valueOf();
        this.firstName = name.firstName;
        this.lastName = name.lastName;
        this.isClient = isClient;
        this.isVendor = isVendor;
    }
}
