// import IconButton from "@mui/material/IconButton";
// import { Close as CloseIcon } from "@mui/icons-material";

interface RowActionButtonsProps {
  rowData: any;
};

export default function RowActionButtons(props: Readonly<RowActionButtonsProps>) {
  // TODO: Put the actions that are valid for the type of notification
  // ex. for a proposal hire, cancel, decline, accept, etc.
  return null;
  // return (
  //   <IconButton><CloseIcon /></IconButton>
  // )
}
