import axios, {
  AxiosHeaders,
  CanceledError
} from "axios";
import AttorneyHubAPIService from "common/services/api/attorney-hub-api-service";
import Guid from "common/values/guid/guid";
import { AdministratorInvitationNotFoundError } from "legal-entities/entities/administrator-invitation/administrator-invitation";
import EntityAgreementAPIResponse from "legal-entities/entities/entity-agreement/api/response-contracts/entity-agreement-api-response";
import EntityAgreement from "legal-entities/entities/entity-agreement/entity-agreement";
import OpenEntityOfficerInvitationAPIResponse from "legal-entities/entities/entity-officer-invitation/api/response-contracts/open-entity-officer-invitation-api-response";
import EntityOfficerInvitation, {
  EntityOfficerInvitationNotFoundError,
  UnauthorizedEntityOfficerInvitationAccessError
} from "legal-entities/entities/entity-officer-invitation/entity-officer-invitation";
import AcceptEntityUserInvitationAPIRequest from "legal-entities/entities/user-entity-member-invitation/api/request-contracts/accept-entity-user-invitation-api-request";
import { InvalidRegistrationInfoError } from "legal-entities/values/registration-info";
import InviteEntityOfficerAPIRequest from "users/entities/super-user/api/request-contracts/invite-entity-officer-api-request";
import AuthInfoAPIResponse from "users/entities/user/api/response-contracts/auth-info-api-response";
import { AuthResponse } from "users/entities/user/api/auth-email-api-service";
import Session from "users/session/session";


export default class EntityOfficerInvitationAPIService {

  private headers: AxiosHeaders = new AxiosHeaders();

  constructor(session: Readonly<Session>) {
    //some calls in this service will be made by users without valid session auth tokens
    if (session.authToken?.value) {
      this.headers.set("Authorization", `Bearer ${session.authToken.value}`);
    }
  }

  async inviteEntityOfficer(invitation: EntityOfficerInvitation):
    Promise<EntityOfficerInvitation> {
    const url = new URL(`/super-user/invite-entity-officer`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.post(
        url.toString(),
        new InviteEntityOfficerAPIRequest(invitation),
        {
          headers: this.headers.concat({ 'Content-Type': 'application/json' })
        }
      );
      const returnedId: Guid = new Guid(response.data);
      invitation.id = returnedId;
      return invitation;
    } catch (error: any) {
      if (error instanceof CanceledError)
        throw error;
      throw new EntityOfficerInvitationServiceError("inviteEntityOfficer", error, invitation);
    }
  }

  async getAllOpenOfficerInvitations(abortController: AbortController): Promise<EntityOfficerInvitation[]> {
    const url = new URL(`/legal-entities/officer-invitations`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.get(
        url.toString(),
        {
          headers: this.headers,
          signal: abortController?.signal
        }
      );
      const invitations: EntityOfficerInvitation[] = [];
      response.data.invitations.forEach((element: OpenEntityOfficerInvitationAPIResponse) => {
        invitations.push(Object.assign(new OpenEntityOfficerInvitationAPIResponse(), element).deserialize());
      });
      return invitations;
    } catch (error: any) {
      if (error instanceof CanceledError)
        throw error;
      throw new EntityOfficerInvitationServiceError("getAllOpenOfficerInvitations", error);
    }
  }

  async getOfficerInvitationById(id: Guid, abortController: AbortController): Promise<EntityOfficerInvitation> {
    const url = new URL(`/legal-entities/officer-invitations/${id.valueOf()}`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.get(
        url.toString(),
        {
          headers: this.headers,
          signal: abortController?.signal
        }
      );
      return Object.assign(new OpenEntityOfficerInvitationAPIResponse(), response.data).deserialize();
    } catch (error: any) {
      if (error instanceof CanceledError)
        throw error;
      if (error.response.status === 404) {
        throw new EntityOfficerInvitationNotFoundError(id);
      }
      throw new EntityOfficerInvitationServiceError("getOfficerInvitationById", error);
    }
  }

  async acceptOfficerInvitation(invitation: EntityOfficerInvitation, session: Session): Promise<EntityAgreement> {
    if (!invitation.id)
      throw new Error("Invitation ID is required to accept an invitation.");
    if (!invitation.registrationInfo?.password)
      throw new Error("Password is required to accept an invitation.");

    const url = new URL(`/legal-entities/officer-invitations/${invitation.id.valueOf()}/accept`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.post(
        url.toString(),
        new AcceptEntityUserInvitationAPIRequest(
          invitation.registrationInfo.password,
          invitation.registrationInfo.name
        ),
        {
          headers: this.headers.concat({ 'Content-Type': 'application/json' }),
        }
      );
      const authInfo: AuthInfoAPIResponse = Object.assign(new AuthInfoAPIResponse(), response.data.authInfo);
      const authResponse: AuthResponse = authInfo.deserialize();
      session.initializeFromAuthResponse(authResponse);
      const responseData = Object.assign(new EntityAgreementAPIResponse(), response.data.entityAgreement);
      return responseData.deserialize();
    } catch (error: any) {
      if (error.response?.status === 400) {
        throw new InvalidRegistrationInfoError(error.response.data);
      }
      if (error.response?.status === 404) {
        throw new AdministratorInvitationNotFoundError(invitation.id);
      }
      throw new EntityOfficerInvitationServiceError("acceptOfficerInvitation", error);
    }
  }

  async cancelEntityOfficerInvitation(invitation: EntityOfficerInvitation): Promise<void> {
    if (!invitation.id)
      throw new Error("Cannot cancel an invitation that has not been saved.");
    const url = new URL(`/legal-entities/officer-invitations/${invitation.id.valueOf()}/cancel`, AttorneyHubAPIService.apiBaseUrl);
    try {
      await axios.post(
        url.toString(),
        {},
        {
          headers: this.headers
        }
      );
    } catch (error: any) {
      if (error.response?.status === 404) {
        throw new EntityOfficerInvitationNotFoundError(invitation.id);
      }
      if (error.response?.status === 403) {
        throw new UnauthorizedEntityOfficerInvitationAccessError();
      }
      throw new EntityOfficerInvitationServiceError("cancelEntityOfficerInvitation", error, invitation);
    }
  }
}

export class EntityOfficerInvitationServiceError extends Error {
  invitation?: EntityOfficerInvitation;
  method: string;
  error: any;
  constructor(method: string, error: any, invitation?: EntityOfficerInvitation) {
    super(`Error inviting entity officer: ${error.message}`);
    this.method = method;
    this.error = error;
    this.invitation = invitation;
  }
}