import { Autocomplete, Avatar, CircularProgress, createFilterOptions, FormControl, ListItem, ListItemAvatar, ListItemText, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import Guid from "common/values/guid/guid";
import IndividualAPIService from "marketplace/entities/individual/api/individual-api-service";
import IndividualSummary from "marketplace/values/individual-summary/individual-summary";
import { useEffect, useState } from "react";
import { useSession } from "users/session/session-context";

const Container = styled(FormControl)(({ theme }) => ({
  maxWidth: '100%',
  minWidth: '33%'
}));

type CompanyProfileContactPersonInputProps = {
  className?: string;
  required?: boolean;
  contactId?: Guid;
  onChange: (value: IndividualSummary | null) => void;
};

export default function CompanyProfileContactPersonInput(props: Readonly<CompanyProfileContactPersonInputProps>) {
  const { className, required, contactId, onChange } = props;

  const [coworkers, setCoworkers] = useState<IndividualSummary[]>([]);
  const [contactPerson, setContactPerson] = useState<IndividualSummary | null>(null);
  const [loadingCoworkers, setLoadingCoworkers] = useState<boolean>(false);

  const session = useSession();

  useEffect(() => {
    populateCoworkers();
  }, []);

  async function populateCoworkers(): Promise<void> {
    try {
      setLoadingCoworkers(true);

      const entities = session.entities;
      const service = new IndividualAPIService(session);
      const returnedCoworkers = await service.getIndividualsFromEntityId(entities[0]?.entityId, new AbortController());

      if (returnedCoworkers.length === 0) return;

      const contactPerson = returnedCoworkers.find(c => c.id.isEqualTo(contactId));
      
      setContactPerson(contactPerson ?? null);
      setCoworkers(returnedCoworkers);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCoworkers(false);
    }
  }

  function getOptionLabel(option: unknown) {
    if (!option) return 'None';
    const coworker = option as IndividualSummary;
    return `${coworker.firstName} ${coworker.lastName}`;
  }

  function renderOption(props: React.HTMLAttributes<HTMLLIElement>, option: unknown) {
    if (!option) return null;
    const coworker = option as IndividualSummary;
    return (
      <ListItem {...props}>
        <ListItemAvatar>
          <Avatar>
            {`${coworker.firstName.slice(0, 1)}${coworker.lastName.slice(0, 1)}`}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={`${coworker.firstName} ${coworker.lastName}`}
          secondary={coworker.emailAddress ? coworker.emailAddress.toString() : ''}
        />
      </ListItem>
    )
  }

  const filterOptions = createFilterOptions({
    stringify: (option: unknown) => {
      if (!option) return '';
      const coworker = option as IndividualSummary;
      return `${coworker.firstName} ${coworker.lastName} ${coworker.emailAddress}`;
    }
  });

  return (
    <Container className={className}>
      <Autocomplete
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        onChange={async (_event, value, _reason) => {
          if (!value) return;
          const coworker: IndividualSummary = value as IndividualSummary;
          setContactPerson(coworker);
          onChange(coworker);
        }}
        value={contactPerson}
        options={coworkers}
        isOptionEqualToValue={(option: unknown, value: unknown) => {
          if (!option || !value) return false;
          const coworker = option as IndividualSummary;
          const selectedCoworker = value as IndividualSummary;
          return coworker.id.isEqualTo(selectedCoworker.id);
        }}
        loading={loadingCoworkers}
        filterOptions={filterOptions}
        noOptionsText="No matching coworkers found"
        renderInput={(params) => (
          <TextField
            {...params}
            margin="none"
            variant="outlined"
            required={required}
            label="Contact Person"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loadingCoworkers ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
      />
    </Container>
  );
}
