import { Column } from "@material-table/core";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Icon, ListItemText, Tooltip } from "@mui/material";
import Link from "@mui/material/Link";
import { styled } from "@mui/material/styles";
import { ActionsCellContainer } from "common/styles";
import AdministratorInvitation from "legal-entities/entities/administrator-invitation/administrator-invitation";
import { InvitationStatus } from "legal-entities/values/invitation-status";
import { EntityAdministrationTableData } from "../..";

const NameCellContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center'
}));
const NameText = styled(ListItemText)(({ theme }) => ({
  marginLeft: theme.spacing(1)
}));

export function getTableColumns(
  cancelInvite: (invitation?: AdministratorInvitation) => Promise<void>
): Column<EntityAdministrationTableData>[] {
  return [
    {
      field: 'name',
      title: 'Name',
      render: rowData => renderNameColumn(rowData as AdministratorInvitation)
    },
    {
      field: 'emailAddress',
      title: 'Email',
      render: rowData => renderEmailColumn(rowData as AdministratorInvitation)
    },
    {
      field: 'status',
      title: 'Status',
      render: rowData => renderStatusColumn(rowData as AdministratorInvitation)
    },
    {
      title: 'Actions',
      align: 'right',
      filtering: false,
      draggable: false,
      sorting: false,
      grouping: false,
      disableClick: true,
      hiddenByColumnsButton: true,
      removable: false,
      width: 'fit-content',
      render: (rowData) => renderActionsColumn(
        rowData,
        cancelInvite
      )
    }
  ];
}

function renderNameColumn(rowData: AdministratorInvitation): React.ReactNode {
  return (
    <NameCellContainer>
      <NameText
        primary={rowData.registrationInfo.name.toString()}
        secondary={rowData.entity.name}
      />
    </NameCellContainer >
  );
}

function renderEmailColumn(rowData: AdministratorInvitation): React.ReactNode {
  const emailAddress = rowData.registrationInfo.email.toString();
  return <Link href={`mailto:${emailAddress}`}>{emailAddress}</Link>;
}

function renderStatusColumn(rowData: AdministratorInvitation): React.ReactNode {
  return rowData.status.toString();
}

function renderActionsColumn(
  rowData: EntityAdministrationTableData,
  cancelInvite: (invitation?: AdministratorInvitation) => Promise<void>
): React.ReactNode {
  const invitation = rowData as AdministratorInvitation;

  return (
    <ActionsCellContainer>
      {invitation.status !== InvitationStatus.Open && (
        <Icon>remove</Icon>
      )}
      {invitation.status === InvitationStatus.Open && (
        <Tooltip title="Cancel Invitation">
          <span>
            <Button
              size="small"
              startIcon={<CloseIcon />}
              onClick={() => cancelInvite(invitation)}>
              Cancel
            </Button>
          </span>
        </Tooltip>
      )}
    </ActionsCellContainer>
  );
}
