import { Column } from "@material-table/core";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Icon, Link, ListItemText } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ActionsCellContainer } from "common/styles";
import Guid from "common/values/guid/guid";
import IndividualAvatar from "marketplace/view/individual-avatar";
import moment from "moment";
import NetworkInvitationInfo from "users/entities/user-network-invitation/network-invitation-info";
import { NetworkTableData } from "../..";
import Session from "users/session/session";

const NameCellContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center'
}));
const NameText = styled(ListItemText)(({ theme }) => ({
  marginLeft: theme.spacing(1)
}));
const ContactLink = styled(Link)(({ theme }) => ({
  cursor: 'pointer'
}));

export function getTableColumns(
  handleViewProfile: (individualId?: Guid) => void,
  acceptInvite: (id: Guid) => Promise<void>,
  rejectInvite: (id: Guid) => Promise<void>,
  cancelInvite: (id: Guid) => Promise<void>,
  session: Session
): Column<NetworkTableData>[] {
  return [
    {
      field: 'inviter',
      title: 'Type',
      lookup: { false: 'Invites To Review', true: 'Invites You Sent' },
      defaultGroupOrder: 0
    },
    {
      field: 'name',
      title: 'Name',
      render: rowData => renderNameColumn(
        rowData as NetworkInvitationInfo,
        handleViewProfile,
        session
      )
    },
    {
      field: 'createdDate',
      title: 'Sent',
      type: 'datetime',
      render: rowData => moment((rowData as NetworkInvitationInfo).created).format('L')
    },
    {
      field: 'message',
      title: 'Message',
      render: rowData => {
        const invitation = rowData as NetworkInvitationInfo;
        if (invitation.message === '') return <Icon>remove</Icon>;
        return invitation.message;
      }
    },
    {
      field: 'status',
      title: 'Status'
    },
    {
      title: 'Actions',
      align: 'right',
      filtering: false,
      draggable: false,
      sorting: false,
      grouping: false,
      disableClick: true,
      hiddenByColumnsButton: true,
      removable: false,
      width: 'fit-content',
      render: (rowData) => renderActionsColumn(
        rowData, 
        acceptInvite, 
        rejectInvite, 
        cancelInvite
      )
    }
  ];
}

function renderNameColumn(
  rowData: NetworkInvitationInfo,
  handleViewProfile: (individualId?: Guid) => void,
  session: Session
): React.ReactNode {
  const contactLink = (
    <ContactLink onClick={() => handleViewProfile(rowData.individualId)}>
      {rowData.user.fullName.toString()}
    </ContactLink>
  );

  return (
    <NameCellContainer>
        <IndividualAvatar 
        avatarId={rowData.avatarId} 
        individualId={rowData.individualId} 
        session={session}
        /> 
      <NameText
        primary={contactLink}
      />
    </NameCellContainer >
  );
}

function renderActionsColumn(
  rowData: NetworkTableData,
  acceptInvite: (id: Guid) => void,
  rejectInvite: (id: Guid) => void,
  cancelInvite: (id: Guid) => void
): React.ReactNode {
  const invitation = rowData as NetworkInvitationInfo;
  const isInviter = invitation.inviter;

  return (
    <ActionsCellContainer>
      {isInviter && (
        <Button
          size="small"
          startIcon={<CloseIcon />}
          onClick={() => cancelInvite(invitation.id)}>
          Cancel
        </Button>
      )}
      {!isInviter && (
        <>
          <Button
            size="small"
            startIcon={<CheckIcon />}
            onClick={() => acceptInvite(invitation.id)}>
            Accept
          </Button>
          <Button
            size="small"
            startIcon={<CloseIcon />}
            onClick={() => rejectInvite(invitation.id)}>
            Reject
          </Button>
        </>
      )}
    </ActionsCellContainer>
  );
}
