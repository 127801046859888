import MaterialTable, { MaterialTableProps } from "@material-table/core";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { Theme, useMediaQuery } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import {
  ConfirmResponse,
  useConfirmDialog
} from "app/providers/confirm-dialog";
import { useDialog } from "app/providers/dialog";
import {
  getTableActions, TableActionsComponent
} from "app/routes/fee-schedules/table/actions";
import { getTableColumns } from "app/routes/fee-schedules/table/columns";
import loadData from "app/routes/fee-schedules/table/data-loader";
import { NoRowsPlaceholder } from "app/routes/fee-schedules/table/no-rows-placeholder";
import TableToolbarComponent from "app/routes/fee-schedules/table/toolbar";
import { PageTab } from "app/routes/fee-schedules/tabs";
import TableContainerComponent from "common/components/table/container";
import TableTitle from "common/components/table/title";
import { PageContainer, TableTheme } from "common/styles";
import Guid from "common/values/guid/guid";
import _ from "lodash";
import { enqueueSnackbar } from "notistack";
import React, { useEffect } from "react";
import { Params, useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { useSession } from "users/session/session-context";
import FeeScheduleTemplateAPIService from "work/entities/fee-schedule-template/api/fee-schedule-template-api-service";
import FeeScheduleTemplate from "work/entities/fee-schedule-template/fee-schedule-template";
import CreateTemplateFeeScheduleDialog from "work/entities/fee-schedule-template/view/create-template-fee-schedule-dialog";

type FeeSchedulesProps = {};

export default function FeeSchedules(props: Readonly<FeeSchedulesProps>) {
  const tableRef = React.useRef<MaterialTable<FeeScheduleTemplate> & MaterialTableProps<FeeScheduleTemplate>>();
  const isDirtyRef = React.useRef<boolean>(false);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [activeTab, setActiveTab] = React.useState<PageTab>(PageTab.Templates);
  const [tableBodyHeight, setTableBodyHeight] = React.useState(0);
  const [feeScheduleTemplates, setFeeScheduleTemplates] = React.useState<FeeScheduleTemplate[]>([]);

  const routeParams = useLoaderData() as Params<string>;
  const location = useLocation();
  const navigate = useNavigate();
  const session = useSession();
  const { openDialog, closeDialog, closeAllDialogs } = useDialog();
  const confirm = useConfirmDialog();
  const isMediumDisplaySize = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const tabs = (
    <Tabs
      value={activeTab}
      indicatorColor="primary"
      textColor="primary"
      onChange={(_event, newValue) => navigate(`/fee-schedules/${newValue}`)}
    >
      <Tab value={PageTab.Templates} label="Templates" />
      <Tab value={PageTab.Mine} label="Active" />
      <Tab value={PageTab.Archived} label="Archived" />
    </Tabs>
  );

  // Close any open dialogs when the URL changes
  useEffect(() => {
    closeAllDialogs();
  }, [location]);

  // Handle account type change
  useEffect(() => {
    tableRef?.current?.onQueryChange({});
  }, [session.accountType]);

  // Handle URL changes
  useEffect(() => {
    if (routeParams.tab) {
      setActiveTab(routeParams.tab as PageTab);
    } else {
      navigate(`/fee-schedules/${PageTab.Templates}`);
    }

    if (routeParams.action === 'create') {
      beginCreateFeeScheduleTemplate();
      return;
    }

    if (routeParams.tab === PageTab.Templates && routeParams.id) {
      handleEditFeeScheduleTemplate(new Guid(routeParams.id));
    }
  }, [routeParams]);

  // Handle component mount
  useEffect(() => {
    function computeTableHeight() {
      const tableHead = document.querySelector("thead.MuiTableHead-root");
      const tableFoot = document.querySelector("tfoot.MuiTableFooter-root");
      const siteFooter = document.querySelector("footer#siteFooter");

      const tableOffsetTop = tableHead?.getBoundingClientRect().top;
      const tableFootHeight = tableFoot?.getBoundingClientRect().height;
      const siteFooterHeight = siteFooter?.getBoundingClientRect().height;

      if (!tableOffsetTop || !tableFootHeight || !siteFooterHeight) return;

      setTableBodyHeight(
        Math.floor(
          window.innerHeight -
            tableOffsetTop -
            tableFootHeight -
            siteFooterHeight
        )
      );
    }

    const debouncedResize = _.debounce(() => computeTableHeight(), 250);
    debouncedResize();
    window.addEventListener("resize", debouncedResize);

    return () => {
      window.removeEventListener("resize", debouncedResize);
    };
  }, []);

  function handleTemplatesLoaded(templates: FeeScheduleTemplate[]) {
    setFeeScheduleTemplates(templates);
  }

  function beginCreateFeeScheduleTemplate() {
    const parentPath = new URL(".", window.location.origin + window.location.pathname);

    openDialog(
      {
        title: 'Create Template Fee Schedule',
        MuiProps: { maxWidth: 'xl' },
        component: (
          <CreateTemplateFeeScheduleDialog
            entityId={session.entities[0]?.entityId}
            onDirty={() => { isDirtyRef.current = true }}
            onSave={() => {
              closeDialog();
              navigate(parentPath.pathname);
              tableRef?.current?.onQueryChange({});
            }}
          />
        ),
        onClose: closeDialog
      },
      () => navigate(parentPath.pathname),
      () => isDirtyRef.current
    );
  }

  function handleEditFeeScheduleTemplate(id: Guid) {
    const template = feeScheduleTemplates.find(template => template.id?.isEqualTo(id));
    
    if (!template) {
      enqueueSnackbar('Fee schedule template not found', { variant: 'error' });
      return;
    }

    const parentPath = new URL(".", window.location.origin + window.location.pathname);

    openDialog(
      {
        title: 'Edit Template Fee Schedule',
        MuiProps: { fullWidth: isMediumDisplaySize, maxWidth: 'xl' },
        component: (
          <CreateTemplateFeeScheduleDialog
            entityId={session.entities[0].entityId}
            feeSchedule={template}
            onDirty={() => { isDirtyRef.current = true }}
          />
        ),
      },
      () => navigate(parentPath.pathname),
      () => isDirtyRef.current
    );
  }

  /**
   * Archive or Unarchive the specified fee schedule template
   * @param id fee schedule id to archive / unarchive
   * @param archive if true, archive, else unarchive
   */
  async function handleArchiveFeeScheduleTemplate(id: Guid | undefined, archive: boolean): Promise<void> {
    if (!id) {
      Promise.reject(new Error("No fee schedule template id provided"));
      return;
    }

    const action = archive ? "Archive" : "Unarchive";

    try {
      const response = await confirm({
        title: action,
        message: `${action} fee schedule template?`,
        okButtonText: action,
        cancelButtonText: "Cancel",
      });

      if (response === ConfirmResponse.Cancel) 
        return;

      const feeScheduleService = new FeeScheduleTemplateAPIService(session);

      if (archive)
        await feeScheduleService.archiveFeeScheduleTemplate(id);
      else 
        await feeScheduleService.unarchiveFeeScheduleTemplate(id);
      enqueueSnackbar(`${action} fee schedule`, { variant: "success" });
      tableRef?.current?.onQueryChange(); // Force table data refresh
    } catch (error) {
      console.error(error);
      enqueueSnackbar(`Failed to ${action} fee schedule. Please try again`, {
        variant: "error",
      });
    }
  }

  /**
   * Deletes the specified fee schedule template
   * @param id The fee schedule template id
   */
  async function handleDeleteFeeScheduleTemplate(id: Guid | undefined): Promise<void> {
    if (!id) {
      Promise.reject(new Error("No fee schedule template id provided"));
      return;
    }

    try {
      const response = await confirm({
        title: "Delete?",
        message: `Delete fee schedule template?`,
        okButtonText: "Delete",
        cancelButtonText: "Cancel",
      });
      
      if (response === ConfirmResponse.Cancel) 
        return;

      const feeScheduleService = new FeeScheduleTemplateAPIService(session);
      await feeScheduleService.deleteFeeScheduleTemplate(id);
      enqueueSnackbar("Deleted fee schedule", { variant: "success" });
      tableRef?.current?.onQueryChange(); // Force table data refresh
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to delete fee schedule. Please try again", {
        variant: "error",
      });
    }
  }

  return (
    <PageContainer>
      <MaterialTable
        key={activeTab}
        tableRef={tableRef}
        title={
          <TableTitle title="Fee Schedule" icon={<AccountBalanceIcon />} />
        }
        columns={
          getTableColumns(
            activeTab,
            (id) => navigate(`/fee-schedules/templates/edit/${id}`),
            handleDeleteFeeScheduleTemplate,
            handleArchiveFeeScheduleTemplate
          )
        }
        data={(query) => loadData(query, activeTab, setLoading, handleTemplatesLoaded)}
        actions={getTableActions({ 
          tableRef: tableRef, 
          session: session, 
          isMediumDisplaySize: isMediumDisplaySize,
          columns: [],
          data: []
        })}
        isLoading={loading}
        options={{
          debounceInterval: 250,
          emptyRowsWhenPaging: false,
          filtering: true,
          filterRowStyle: TableTheme.filterRow,
          grouping: true,
          minBodyHeight: tableBodyHeight,
          maxBodyHeight: tableBodyHeight,
          pageSize: 10,
          pageSizeOptions: [10, 25, 50],
          paginationType: isMediumDisplaySize ? "normal" : "stepped",
          showFirstLastPageButtons: !isMediumDisplaySize,
        }}
        components={{
          Container: (props) => TableContainerComponent({ ...props }),
          Actions: (props) =>
            TableActionsComponent({
              tableRef,
              session,
              isMediumDisplaySize,
              ...props,
            }),
          Toolbar: (props) =>
            TableToolbarComponent({
              tableRef,
              activeTab,
              tabs,
              isMediumDisplaySize,
              ...props,
            }),
        }}
        localization={{
          body: {
            emptyDataSourceMessage: (
              <NoRowsPlaceholder tableBodyHeight={tableBodyHeight} />
            ),
          },
        }}
      />
    </PageContainer>
  );
}
