
import BillingRateInfo from "marketplace/values/billing-rate-info/billing-rate-info";
import IndividualProfile from "marketplace/values/individual-profile/individual-profile";
import InsuranceInfo from "marketplace/values/insurance-info/insurance-info";
import MarketplaceIndividualInfoAPIResponse from "marketplace/values/individual-profile/api/response-contracts/marketplace-individual-info-api-response";
import EmailAddress from "common/values/email-address/email-address";
import Guid from "common/values/guid/guid";
import PhoneNumber from "common/values/phone-number/phone-number";
import TechnicalBackgroundInfo from "marketplace/values/technical-background-info/technical-background-info";
import ProfessionalRegistration from "marketplace/values/registration-number/registration-number";
export default class MarketplaceLeaderInfoAPIResponse extends MarketplaceIndividualInfoAPIResponse {
    entityName?: string;

    deserializeToProfile(): IndividualProfile {
        if (!this.userId) throw new Error("userId is required");
        if (!this.firstName) throw new Error("firstName is required");
        if (!this.lastName) throw new Error("lastName is required");

        return IndividualProfile.fromPartial({
            userId: new Guid(this.userId),
            entityId: this.entityId ? new Guid(this.entityId) : undefined,
            entityName: this.entityName,
            firstName: this.firstName,
            lastName: this.lastName,
            description: this.description,
            location: this.location,
            phoneNumber: this.phoneNumber ? new PhoneNumber(this.phoneNumber) : undefined,
            avatarId: this.avatar ? new Guid(this.avatar) : undefined,
            email: this.emailAddress ? new EmailAddress(this.emailAddress) : undefined,
            school: this.school,
            resumeId: this.resume ? new Guid(this.resume) : undefined,
            professionalRegistration: this.professionalRegistration ? new ProfessionalRegistration(this.professionalRegistration) : undefined,
            technicalBackground: this.technicalBackground ? new TechnicalBackgroundInfo(this.technicalBackground): undefined,
            numberOfApplicationsDraftedProsecuted: this.numberOfApplicationsDraftedProsecuted,
            numberOfAllowedApplications: this.numberOfAllowedApplications,
            barAdmissionYear: this.barAdmissionYear,
            isOnCall: this.isOnCall,
            insured: this.insuredAmount ? new InsuranceInfo(this.insuredAmount) : undefined,
            billingRate: this.billingRate && this.billingUnit ? new BillingRateInfo(this.billingRate, this.billingUnit) : undefined,
            potentialDiversityCandidate: this.potentialDiversityCandidate,
            collaborates: this.collaborates,
            additionalInfo: this.additionalInfo,
            categories: this.categories,
            links: this.links,
            professionalPublications: this.professionalPublications,
            sampleApplications: this.sampleApplications,
            fluentLanguages: this.fluentLanguages,
            technicalLanguages: this.technicalLanguages,
            regionsRegistered: this.regionsRegistered,
            regionsLicensed: this.regionsLicensed
        });
    }
}
