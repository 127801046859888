import axios, { AxiosHeaders, CanceledError } from "axios";
import AttorneyHubAPIService from "common/services/api/attorney-hub-api-service";
import { AccountType } from "common/values/account-type/account-type";
import Guid from "common/values/guid/guid";
import Session from "users/session/session";
import UpdateWorkTeamTemplateAPIRequest from "work/entities/team-template/api/request-contracts/update-work-team-template-api-request";
import WorkTeamTemplateAPIRequest from "work/entities/team-template/api/request-contracts/work-team-template-api-request";
import WorkTeamTemplateAPIResponse from "work/entities/team-template/api/response-contracts/work-team-template-api-response";
import TeamTemplate from "work/entities/team-template/team-template";
import TeamName from "work/values/team-name/team-name";
import Team from "work/values/team/team";


export default class TeamTemplateAPIService {

  private authHeaders: AxiosHeaders = new AxiosHeaders();

  private authHeadersWithJson(): AxiosHeaders {
    return this.authHeaders.concat({ 'Content-Type': 'application/json' });
  }

  constructor(session: Readonly<Session>) {
    if (!session.authToken?.value)
      throw new Error("Session must have an authToken to create a TeamTemplateAPIService");
    this.authHeaders.set("Authorization", `Bearer ${session.authToken.value}`);
  }

  async getTeamTemplates(
    entityId: Guid,
    creatorType: AccountType = AccountType.Client,
    abortController?: AbortController
  ): Promise<TeamTemplate[]> {
    const url = new URL(
      `/work/template-teams?entityId=${entityId.valueOf()}&creatorType=${creatorType.toLowerCase()}`,
      AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.get(url.toString(), {
        headers: this.authHeaders,
        signal: abortController?.signal
      });
      const teamTemplates: TeamTemplate[] = [];
      response.data.forEach((teamData: any) => {
        const teamResponse = Object.assign(
          new WorkTeamTemplateAPIResponse(),
          teamData
        );
        teamTemplates.push(teamResponse.deserialize());
      });
      return teamTemplates;
    } catch (error: any) {
      if(error instanceof CanceledError)
        throw error;
      throw new TeamTemplateAPIServiceError("getTeamTemplates", error);
    }
  }

  async createTeamTemplate(
    entityId: Guid,
    team: Team,
    name: TeamName,
    creatorType: AccountType
  ): Promise<TeamTemplate> {
    const url = new URL("/work/template-teams", AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.post(
        url.toString(),
        new WorkTeamTemplateAPIRequest(entityId, team, name, creatorType),
        {
          headers: this.authHeadersWithJson()
        });
      const responseData = Object.assign(
        new WorkTeamTemplateAPIResponse(),
        response.data
      );
      return responseData.deserialize();
    } catch (error: any) {
      throw new TeamTemplateAPIServiceError("createTeamTemplate", error);
    }
  }

  async updateTeamTemplate(
    originalTemplate: TeamTemplate,
    updatedTeam: Team,
    updatedTeamName: TeamName
  ): Promise<TeamTemplate> {
    try {
      const url = new URL(
        `/work/template-teams/${originalTemplate.id?.valueOf()}`,
        AttorneyHubAPIService.apiBaseUrl);
      const response = await axios.patch(
        url.toString(),
        new UpdateWorkTeamTemplateAPIRequest(originalTemplate, updatedTeam, updatedTeamName).payload,
        {
          headers: this.authHeadersWithJson()
        });
      const result = Object.assign(
        new WorkTeamTemplateAPIResponse(),
        response.data
      );
      return result.deserialize();
    } catch (error: any) {
      throw new TeamTemplateAPIServiceError("updateTeamTemplate", error);
    }
  }

  async deleteTeamTemplate(id: Guid): Promise<void> {
    try {
      const url = new URL(`/work/template-teams/${id.valueOf()}`, AttorneyHubAPIService.apiBaseUrl);
      await axios.delete(url.toString(), { headers: this.authHeaders });
    } catch (error: any) {
      throw new TeamTemplateAPIServiceError("deleteTeamTemplate", error);
    }
  }

  async registerTeamTemplateToMarketplace(
    teamId: Guid | undefined
  ): Promise<TeamTemplate> {
    try {
      const url = new URL(
        `/work/template-teams/${teamId?.valueOf()}/register-to-marketplace`,
        AttorneyHubAPIService.apiBaseUrl);
      const response = await axios.post(url.toString(), {}, {
        headers: this.authHeaders
      });
      const responseData = Object.assign(
        new WorkTeamTemplateAPIResponse(),
        response.data
      );
      return responseData.deserialize();
    } catch (error: any) {
      throw new TeamTemplateAPIServiceError("registerTeamTemplateToMarketplace", error);
    }
  }
}

export class TeamTemplateAPIServiceError extends Error {
  constructor(public method: string, public error: any) {
    super(`Error in TeamTemplateAPIService.${method}: ${error}`);
    this.name = "TeamTemplateAPIServiceError";
  }
}