import RegistrationNumber from 'marketplace/values/registration-number/registration-number';
import TechnicalBackgroundInfo from 'marketplace/values/technical-background-info/technical-background-info';
import InsuranceInfo from 'marketplace/values/insurance-info/insurance-info';
import BillingRateInfo from 'marketplace/values/billing-rate-info/billing-rate-info';
import EmailAddress from 'common/values/email-address/email-address';
import Guid from 'common/values/guid/guid';
import PhoneNumber from 'common/values/phone-number/phone-number';


export default class IndividualProfile {
    userId?: Guid;
    entityId?: Guid;
    entityName?: string;
    firstName: string;
    lastName: string;
    description?: string;
    location?: string;
    phoneNumber?: PhoneNumber;
    avatarId?: Guid;
    email?: EmailAddress;
    school?: string;
    degree?: string;
    resumeId?: Guid;
    professionalRegistration?: RegistrationNumber;
    technicalBackground?: TechnicalBackgroundInfo;
    numberOfApplicationsDraftedProsecuted?: number;
    numberOfAllowedApplications?: number;
    barAdmissionYear?: number;
    isOnCall: boolean = false;
    insured?: InsuranceInfo;
    billingRate?: BillingRateInfo;
    potentialDiversityCandidate: boolean = false;
    collaborates: boolean = false;
    additionalInfo?: string;
    categories: string[] = [];
    links: string[] = [];
    professionalPublications: string[] = [];
    sampleApplications: string[] = [];
    fluentLanguages: string[] = [];
    technicalLanguages: string[] = [];
    administrativeRegions: string[] = [];
    regionsRegistered: string[] = [];
    regionsLicensed: string[] = [];

    constructor(userId: Guid | undefined, firstName: string, lastName: string) {
        this.userId = userId;
        this.firstName = firstName;
        this.lastName = lastName;
    }

    clone(): IndividualProfile {
        const newProfile = new IndividualProfile(this.userId, this.firstName, this.lastName);
        return Object.assign(newProfile, this);
    }

    static fromPartial(values: Partial<IndividualProfile>): IndividualProfile {
        if (!values?.userId) throw new Error("User Id is required");
        if (!values?.firstName) throw new Error("First Name is required");
        if (!values?.lastName) throw new Error("Last Name is required");

        const newProfile = new IndividualProfile(values?.userId, values?.firstName, values?.lastName);
        return Object.assign(newProfile, values);
    }

    public static fromObject(object: any) {
        if (!object) return;
        
        const {
            entityId,
            entityName,
            firstName,
            lastName,
            description,
            location,
            phoneNumber,
            avatarId,
            email,
            school,
            degree,
            resumeId,
            professionalRegistration,
            technicalBackground,
            numberOfApplicationsDraftedProsecuted,
            numberOfAllowedApplications,
            barAdmissionYear,
            isOnCall,
            insured,
            billingRate,
            potentialDiversityCandidate,
            collaborates,
            additionalInfo,
            categories,
            links,
            professionalPublications,
            sampleApplications,
            fluentLanguages,
            technicalLanguages,
            administrativeRegions,
            regionsRegistered,
            regionsLicensed,
        } = object;

        const userId = Guid.fromObject(object.userId);

        if (!userId) throw new Error("User Id is required");

        const profile = new IndividualProfile(
            userId,
            firstName,
            lastName
        );

        profile.additionalInfo = additionalInfo;
        profile.administrativeRegions = administrativeRegions;
        profile.avatarId = Guid.fromObject(avatarId);
        profile.barAdmissionYear = barAdmissionYear;
        profile.billingRate = BillingRateInfo.fromObject(billingRate);
        profile.categories = categories;
        profile.collaborates = collaborates;
        profile.description = description;
        profile.email = email ? EmailAddress.fromObject(email) : email;
        profile.entityId = Guid.fromObject(entityId);
        profile.entityName = entityName;
        profile.fluentLanguages = fluentLanguages;
        profile.insured = InsuranceInfo.fromObject(insured);
        profile.isOnCall = isOnCall;
        profile.links = links;
        profile.location = location;
        profile.numberOfAllowedApplications = numberOfAllowedApplications;
        profile.numberOfApplicationsDraftedProsecuted = numberOfApplicationsDraftedProsecuted;
        profile.phoneNumber = PhoneNumber.fromObject(phoneNumber);
        profile.potentialDiversityCandidate = potentialDiversityCandidate;
        profile.professionalPublications = professionalPublications;
        profile.professionalRegistration = RegistrationNumber.fromObject(professionalRegistration);
        profile.regionsLicensed = regionsLicensed;
        profile.regionsRegistered = regionsRegistered;
        profile.resumeId = Guid.fromObject(resumeId);
        profile.sampleApplications = sampleApplications;
        profile.school = school;
        profile.degree = degree;
        profile.technicalBackground = TechnicalBackgroundInfo.fromObject(technicalBackground);
        profile.technicalLanguages = technicalLanguages;
       
        return profile;
    }

    toJSON(): any {
        return {
            userId: this.userId?.toJSON(),
            entityId: this.entityId?.toJSON(),
            entityName: this.entityName,
            firstName: this.firstName,
            lastName: this.lastName,
            description: this.description,
            location: this.location,
            phoneNumber: this.phoneNumber?.toJSON(),
            avatarId: this.avatarId?.toJSON(),
            email: this.email?.toJSON(),
            school: this.school,
            degree: this.degree,
            resumeId: this.resumeId?.toJSON(),
            professionalRegistration: this.professionalRegistration?.toJSON(),
            technicalBackground: this.technicalBackground?.toJSON(),
            numberOfApplicationsDraftedProsecuted: this.numberOfApplicationsDraftedProsecuted,
            numberOfAllowedApplications: this.numberOfAllowedApplications,
            barAdmissionYear: this.barAdmissionYear,
            isOnCall: this.isOnCall,
            insured: this.insured?.toJSON(),
            billingRate: this.billingRate?.toJSON(),
            potentialDiversityCandidate: this.potentialDiversityCandidate,
            collaborates: this.collaborates,
            additionalInfo: this.additionalInfo,
            categories: this.categories,
            links: this.links,
            professionalPublications: this.professionalPublications,
            sampleApplications: this.sampleApplications,
            fluentLanguages: this.fluentLanguages,
            technicalLanguages: this.technicalLanguages,
            administrativeRegions: this.administrativeRegions,
            regionsRegistered: this.regionsRegistered,
            regionsLicensed: this.regionsLicensed,
        };
    }

    getFullName() : string { return `${this.firstName} ${this.lastName}`;}
}