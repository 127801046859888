import DetailedWorkClientRepresentativeAPIResponse from "work/entities/entity-client-representative/api/response-contracts/detailed-work-client-representative-api-response";
import DetailedWorkTeamAPIResponse from "work/values/team/api/response-contracts/detailed-work-team-api-response";
import WorkProposalAPIResponse, { InvalidProposalAPIResponse } from "work/entities/proposal/api/response-contracts/work-proposal-api-response";
import Proposal, { IProposalAPIService, ProposalDetails } from "work/entities/proposal/proposal";
import Guid from "common/values/guid/guid";
import ProposalRedline from "work/entities/proposal/redlining/proposal-redline";

export default class DetailedWorkProposalAPIResponse extends WorkProposalAPIResponse {
  declare client?: DetailedWorkClientRepresentativeAPIResponse;
  declare supersedes?: WorkProposalAPIResponse;
  declare supersededById?: string;
  declare redlining?: string;
  declare team?: DetailedWorkTeamAPIResponse;

  deserialize(apiService: IProposalAPIService): Proposal {
    const undetailedProposal = super.deserialize(
      apiService,
      this.team ? Object.assign(new DetailedWorkTeamAPIResponse(), this.team).deserialize() : undefined
    );
    if (!this.client) {
      throw new InvalidProposalAPIResponse('Client not returned by api.');
    }
    const client = Object.assign(new DetailedWorkClientRepresentativeAPIResponse(), this.client);
    const clientDetails = client.deserialize();
    if (!clientDetails.company?.entityId) throw new InvalidProposalAPIResponse('Client entity id not returned by api.');

    const team = this.team ? Object.assign(new DetailedWorkTeamAPIResponse(), this.team) : undefined;
    const teamDetails = team ? team.deserializeDetailed() : undefined;

    const details: ProposalDetails = {
      team: teamDetails,
      client: clientDetails,
      redlining: this.redlining ? ProposalRedline.fromJSON(this.redlining) : undefined,
      supersedes: this.supersedes ? Object.assign(new DetailedWorkProposalAPIResponse(), this.supersedes).deserialize(apiService) : undefined,
      supersededById: this.supersededById ? new Guid(this.supersededById) : undefined
    }

    const detailedProposal = new Proposal(apiService, undetailedProposal.spec, undetailedProposal.metaInfo, details, true);

    return detailedProposal;
  }
}
