import Text from "common/values/text/text";

export default class FeeScheduleCategoryName extends Text {
    constructor(value: string) {
        super(value);

    }

    public isEqualTo(other: Text | null): boolean {
        if(!(other instanceof FeeScheduleCategoryName)) return false;
        return super.isEqualTo(other);
    }
    
    public override clone(): FeeScheduleCategoryName {
        return new FeeScheduleCategoryName(this.valueOf());
    }

    public static get Prototype(): FeeScheduleCategoryName {
        return new FeeScheduleCategoryName('Prototype');
    }

    public override fromObject(obj: any): Text | null {
        return new FeeScheduleCategoryName(obj);
    }
    public toJSON(): any {
        return this.valueOf();
    }
}