import { Query, QueryResult } from "@material-table/core";

import Session from "users/session/session";
import TeamTemplateAPIService from "work/entities/team-template/api/team-template-service";
import TeamTemplate from "work/entities/team-template/team-template";

import { PageTab } from "app/routes/teams/tabs";


export default async function loadData(
  query: Query<TeamTemplate>,
  activeTab: PageTab,
  session: Readonly<Session>,
  setLoading: (isLoading: boolean) => void,
  onDataLoaded?: (data: TeamTemplate[]) => void
): Promise<QueryResult<TeamTemplate>> {
  const emptyResponse = {
    data: [],
    page: 0,
    totalCount: 0,
  } as QueryResult<TeamTemplate>;
  
  try {
    setLoading(true);

    let teams: TeamTemplate[] = [];

    if (session.hasEntity) {
      const teamTemplateService = new TeamTemplateAPIService(session);
      teams = await teamTemplateService.getTeamTemplates(
        session.entities[0].entityId, 
        session.accountType
      );
    }

    onDataLoaded?.(teams);

    return {
      data: teams,
      page: query.page,
      totalCount: teams.length
    }
  } catch (error) {
    console.error(error);
    return emptyResponse;
  } finally {
    setLoading(false);
  }
}
