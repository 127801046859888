export default class EntityName extends String {
    constructor(data: string) {
        EntityName.validate(data);
        super(data);
    }

    static validate(data: string) {
        if (!data) {
            throw new InvalidEntityNameError("must have a value.");
        }
    }

    public isEqualTo(other: EntityName) : boolean {
        return this.valueOf() === other.valueOf();
    }

    public toString() : string {
        return this.valueOf();
    }
    public static fromObject(data: any): EntityName {
        if(!data) throw new InvalidEntityNameError("Entity name must have a value.");
        return new EntityName(data);
    }
    public toJSON() {
        return this.valueOf();
    }
}

export class InvalidEntityNameError extends Error { };
