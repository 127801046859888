import {
  Box, Link,
  List,
  ListItem,
  ListItemAvatar, ListItemText,
  ListSubheader, Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useConfirmDialog } from "app/providers/confirm-dialog";
import { useDialog } from "app/providers/dialog";
import Guid from "common/values/guid/guid";
import ViewIndividualProfile from "marketplace/values/individual-profile/view/view-individual-profile";
import IndividualAvatar from "marketplace/view/individual-avatar";
import { useSession } from "users/session/session-context";
import { TeamMember } from "work/values/team/team-member";

const MainContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));
const TeamContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
const TeamList = styled(List)(({ theme }) => ({
  margin: 0,
  marginTop: 0,
  minWidth: theme.spacing(40),
  paddingBottom: theme.spacing(3),
  "&:first-of-type": {
    marginRight: theme.spacing(3)
  },
  [theme.breakpoints.down("lg")]: {
    "&:first-of-type": {
      marginRight: 0
    },
  },
}));
const TeamListSubheader = styled(ListSubheader)(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.black,
  display: "flex",
  fontSize: "1.4em",
  justifyContent: "space-between",
  padding: 0,
  "& > button": {
    height: "fit-content",
    width: "fit-content",
  },
}));
const NoRowsPlaceholder = styled(Typography)(({ theme }) => ({
  fontSize: "1.3em",
  paddingTop: theme.spacing(1),
}));
const TeamMemberLink = styled(Link)(({ theme }) => ({
  cursor: "pointer",
}));

type ViewTeamProps = {
  className?: string;
  leader?: TeamMember;
  members?: TeamMember[];
};

export default function ViewTeam(props: Readonly<ViewTeamProps>) {
  const {
    className,
    leader,
    members
  } = props;

  const confirm = useConfirmDialog();
  const { openDialog } = useDialog();
  const session = useSession();

  /**
   * Opens the user profile dialog for the given individual
   *
   * @param individualId The id of the individual to open the dialog for
   */
  function openUserProfileDialog(individualId: Guid) {
    openDialog({
      component: (
        <ViewIndividualProfile
          individualId={individualId}
          confirmDialog={confirm}
        />),
      titleStyle: {
        position: "absolute",
        right: 0,
        top: 0,
      },
      contentSxProps: {
        display: "flex",
        overflowX: "hidden",
      },
      MuiProps: {
        maxWidth: "lg",
        fullWidth: true,
      },
    });
  }

  /**
   * Renders a single team member's info as a list item
   *
   * @param member The member's info to render
   */
  function renderMember(member: TeamMember) {
    return (
      <ListItem key={member.userId?.toString() ?? Guid.generate().valueOf()} disableGutters>
        <ListItemAvatar>
          <IndividualAvatar
            avatarId={member.avatarId}
            individualId={member.individualId}
            session={session}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography>
              <TeamMemberLink
                onClick={() => openUserProfileDialog(member.individualId)}
              >
                {member.firstName} {member.lastName}
              </TeamMemberLink>
            </Typography>
          }
        ></ListItemText>
      </ListItem>
    );
  }

  return (
    <MainContainer className={className}>
      <TeamContainer>
        <TeamList>
          <TeamListSubheader>
            <span>Team Leader</span>
          </TeamListSubheader>
          {!leader && (
            <ListItem disableGutters>
              <NoRowsPlaceholder>None</NoRowsPlaceholder>
            </ListItem>
          )}
          {leader && renderMember(leader)}
        </TeamList>
        <TeamList>
          <TeamListSubheader>
            <span>Team Members</span>
          </TeamListSubheader>
          {(!members || members.length < 1) && (
            <ListItem disableGutters>
              <NoRowsPlaceholder>None</NoRowsPlaceholder>
            </ListItem>
          )}
          {members?.map((member: TeamMember) => renderMember(member))}
        </TeamList>
      </TeamContainer>
    </MainContainer>
  );
}
