import { Icon, Link } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import Proposal from "work/entities/proposal/proposal";
import { useState } from "react";
import RedlinePopover from "work/entities/proposal/redlining/view/proposal-redline-popover";
import { styled } from "@mui/material/styles";

const StyledLink = styled(Link, { shouldForwardProp: prop => prop !== 'revised' })<{ revised: boolean }>(({ revised, theme }) => ({
  color: "#000",
  cursor: revised ? "pointer" : "default",
  textDecoration: revised ? "underline dotted red" : "none",
}));

type DateLinkProps = {
  proposal: Proposal;
  field: keyof Proposal;
}

export default function DateLink(props: Readonly<DateLinkProps>) {
  const { proposal, field } = props;

  const revisedProposal = proposal?.supersedes;
  const date = proposal[field];
  const revisedDate = revisedProposal?.[field];

  const [popoverAnchor, setPopoverAnchor] = useState<HTMLElement | null>(null);

  return (
    <>
      <StyledLink
        revised={
          proposal?.supersedes !== null && !_.isEqual(date, revisedDate)
        }
        onMouseEnter={(event) => setPopoverAnchor(event.currentTarget)}
        onMouseLeave={(_event) => setPopoverAnchor(null)}
      >
        {moment.isMoment(date) && date.isValid() ? (
          date.format("M/D/YY")
        ) : (
          <Icon>remove</Icon>
        )}
      </StyledLink>
      <RedlinePopover
        columnName={field}
        proposal={proposal}
        open={Boolean(popoverAnchor)}
        anchorEl={popoverAnchor}
      />
    </>
  );
}
