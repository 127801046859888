import axios, {
  AxiosHeaders,
  CanceledError
} from "axios";
import AttorneyHubAPIService from "common/services/api/attorney-hub-api-service";
import Guid from "common/values/guid/guid";
import UpdateEntityAgreementAPIRequest from "legal-entities/entities/entity-agreement/api/request-contracts/update-entity-agreement-api-request";
import EntityAgreement, { EntityAgreementNotFoundError, UnauthorizedAgreementAccessError } from "legal-entities/entities/entity-agreement/entity-agreement";
import AuthInfoAPIResponse from "users/entities/user/api/response-contracts/auth-info-api-response";
import { AuthResponse } from "users/entities/user/api/auth-email-api-service";
import Session from "users/session/session";
import EntityAgreementAPIResponse from "./response-contracts/entity-agreement-api-response";

export default class EntityAgreementAPIService {

  private headers: AxiosHeaders = new AxiosHeaders();

  constructor(session: Readonly<Session>) {
    if (!session.authToken) throw new Error("Cannot create EntityAgreementAPIService without session.");
    this.headers.set("Authorization", `Bearer ${session.authToken.value}`);
  }

  async getEntityAgreementById(id: Guid, abortController: AbortController): Promise<EntityAgreement> {
    const url = new URL(`/legal-entities/entity-agreements/${id.valueOf()}`, AttorneyHubAPIService.apiBaseUrl);
    try{
      const response = await axios.get(
        url.toString(),
        {
          headers: this.headers,
          signal: abortController?.signal
        }
      );
      const agreement: EntityAgreementAPIResponse = Object.assign(
        new EntityAgreementAPIResponse(),
        response.data
      );
      return agreement.deserialize();
    } catch (error: any) {
      if (error?.response?.status === 404) 
        throw new EntityAgreementNotFoundError(id); 
      if(error instanceof CanceledError)
        throw error;    
      throw new EntityAgreementAPIServiceError("getEntityAgreementById", error);
    }
  }

  async getEntityAgreementTerms(agreement: EntityAgreement, abortController: AbortController): Promise<Blob> {
    const url = new URL(`/legal-entities/agreements/${agreement.id.valueOf()}/terms`, AttorneyHubAPIService.apiBaseUrl);
    try{
      const response = await axios.get(
        url.toString(),
        {
          headers: this.headers,
          responseType: "blob",
          signal: abortController?.signal
        }
      );
      return response.data;
    } catch (error: any) {
      if (error?.response?.status === 403) 
        throw new UnauthorizedAgreementAccessError(agreement.id);
      if (error?.response?.status === 404) 
        throw new EntityAgreementNotFoundError(agreement.id);
      if(error instanceof CanceledError)
        throw error;  
      throw new EntityAgreementAPIServiceError("getEntityAgreementTerms", error, agreement);
    }
  }

  async updateEntityAgreement(oldAgreement: EntityAgreement, updatedAgreement: EntityAgreement) : Promise<EntityAgreement> {
    const url = new URL(`/legal-entities/entity-agreements/${oldAgreement.id.valueOf()}`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.patch(
        url.toString(),
        new UpdateEntityAgreementAPIRequest(oldAgreement, updatedAgreement).payload,
        {
          headers: this.headers.concat({ 'Content-Type': 'application/json' })
        }
      );
      const responseData: EntityAgreementAPIResponse = Object.assign(
        new EntityAgreementAPIResponse(),
        response.data
      );
      return responseData.deserialize();
    } catch (error: any) {
      if (error instanceof CanceledError)
        throw error;
      if (error.response.status === 404)
        throw new EntityAgreementNotFoundError(oldAgreement.id);
      throw new EntityAgreementAPIServiceError("updateEntityAgreement", error, oldAgreement);
    }
  }

  async acceptEntityAgreement(entityAgreement: EntityAgreement, isClient: boolean = true, isVendor: boolean = false) : Promise<AuthResponse> {
    const url = new URL(`/legal-entities/entity-agreements/${entityAgreement.id.valueOf()}/accept`, AttorneyHubAPIService.apiBaseUrl);
    try{
      const apiResponse = await axios.post(
        url.toString(),
        {
          isClient: isClient,
          isVendor: isVendor
        },
        {
          headers: this.headers.concat({ 'Content-Type': 'application/json' })
        }
      );
      const responseData: AuthInfoAPIResponse = Object.assign(
        new AuthInfoAPIResponse(),
        apiResponse.data
      );
      return responseData.deserialize();
    } catch (error: any) {
      if (error.response.status === 404)
        throw new EntityAgreementNotFoundError(entityAgreement.id);
      throw new EntityAgreementAPIServiceError("acceptEntityAgreement", error, entityAgreement);
    }
  }
}

export class EntityAgreementAPIServiceError extends Error {
  methodName: string;
  error: any;
  agreement?: EntityAgreement;
  constructor(methodName: string, error: any, agreement?: EntityAgreement) {
    super(`Error in EntityAgreementAPIService.${methodName}: ${error.message}`);
    this.methodName = methodName;
    this.error = error;
    this.agreement = agreement;
  }
}