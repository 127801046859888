import _ from "lodash";
import IndividualProfile from "marketplace/values/individual-profile/individual-profile";

export default class UpdateMarketplaceIndividualProfileAPIRequest {
  payload: { op: string; path: string; value: any }[] | null = null;
  constructor(
    originalProfile: IndividualProfile,
    updatedProfile: IndividualProfile
  ) {
    this.payload = [];
    if (!_.isEqual(updatedProfile.categories, originalProfile.categories))
      this.payload.push({
        op: "replace",
        path: "/categories",
        value: updatedProfile.categories,
      });
    if (updatedProfile.firstName !== originalProfile.firstName)
      this.payload.push({
        op: "replace",
        path: "/firstName",
        value: updatedProfile.firstName,
      });
    if (updatedProfile.lastName !== originalProfile.lastName)
      this.payload.push({
        op: "replace",
        path: "/lastName",
        value: updatedProfile.lastName,
      });
    if (
      updatedProfile.professionalRegistration !==
      originalProfile.professionalRegistration
    )
      this.payload.push({
        op: "replace",
        path: "/professionalRegistration",
        value: updatedProfile.professionalRegistration,
      });
    if (
      !_.isEqual(
        updatedProfile.regionsRegistered,
        originalProfile.regionsRegistered
      )
    )
      this.payload.push({
        op: "replace",
        path: "/regionsRegistered",
        value: updatedProfile.regionsRegistered,
      });
    if (updatedProfile.description !== originalProfile.description)
      this.payload.push({
        op: "replace",
        path: "/description",
        value: updatedProfile.description,
      });
    if (!updatedProfile.email?.isEqualTo(originalProfile.email))
      this.payload.push({
        op: "replace",
        path: "/email",
        value: updatedProfile.email?.valueOf() ?? '',
      });
    if (updatedProfile.phoneNumber !== originalProfile.phoneNumber)
      this.payload.push({
        op: "replace",
        path: "/phoneNumber",
        value: updatedProfile.phoneNumber,
      });
    if (updatedProfile.isOnCall !== originalProfile.isOnCall)
      this.payload.push({
        op: "replace",
        path: "/isOnCall",
        value: updatedProfile.isOnCall,
      });
    if (
      updatedProfile.numberOfApplicationsDraftedProsecuted !==
      originalProfile.numberOfApplicationsDraftedProsecuted
    )
      this.payload.push({
        op: "replace",
        path: "/numberOfApplicationsDraftedProsecuted",
        value: updatedProfile.numberOfApplicationsDraftedProsecuted,
      });
    if (
      updatedProfile.technicalBackground !==
      originalProfile.technicalBackground
    )
      this.payload.push({
        op: "replace",
        path: "/technicalBackground",
        value: updatedProfile.technicalBackground,
      });
    if (updatedProfile.school !== originalProfile.school)
      this.payload.push({
        op: "replace",
        path: "/school",
        value: updatedProfile.school,
      });
    if(updatedProfile.degree !== originalProfile.degree)
      this.payload.push({
        op: "replace",
        path: "/degree",
        value: updatedProfile.degree,
      });
    if (
      updatedProfile.numberOfAllowedApplications !==
      originalProfile.numberOfAllowedApplications
    )
      this.payload.push({
        op: "replace",
        path: "/numberOfAllowedApplications",
        value: updatedProfile.numberOfAllowedApplications,
      });
    if (updatedProfile.barAdmissionYear !== originalProfile.barAdmissionYear)
      this.payload.push({
        op: "replace",
        path: "/barAdmissionYear",
        value: updatedProfile.barAdmissionYear,
      });
    if (
      updatedProfile.potentialDiversityCandidate !==
      originalProfile.potentialDiversityCandidate
    )
      this.payload.push({
        op: "replace",
        path: "/potentialDiversityCandidate",
        value: updatedProfile.potentialDiversityCandidate,
      });
    if (updatedProfile.collaborates !== originalProfile.collaborates)
      this.payload.push({
        op: "replace",
        path: "/collaborates",
        value: updatedProfile.collaborates,
      });
    if (
      !_.isEqual(
        updatedProfile.fluentLanguages,
        originalProfile.fluentLanguages
      )
    )
      this.payload.push({
        op: "replace",
        path: "/fluentLanguages",
        value: updatedProfile.fluentLanguages,
      });
    if (
      !_.isEqual(
        updatedProfile.technicalLanguages,
        originalProfile.technicalLanguages
      )
    )
      this.payload.push({
        op: "replace",
        path: "/technicalLanguages",
        value: updatedProfile.technicalLanguages,
      });
    if (updatedProfile.location !== originalProfile.location)
      this.payload.push({
        op: "replace",
        path: "/location",
        value: updatedProfile.location,
      });
    if (
      !_.isEqual(
        updatedProfile.regionsLicensed,
        originalProfile.regionsLicensed
      )
    )
      this.payload.push({
        op: "replace",
        path: "/regionsLicensed",
        value: updatedProfile.regionsLicensed,
      });
    if (
      updatedProfile.insured?.insuredAmount !==
      originalProfile.insured?.insuredAmount
    )
      this.payload.push({
        op: "replace",
        path: "/insuredAmount",
        value: updatedProfile.insured?.insuredAmount,
      });
    if (
      updatedProfile.billingRate?.billingRate !==
      originalProfile.billingRate?.billingRate
    ) {
      this.payload.push({
        op: "replace",
        path: "/billingRate",
        value: updatedProfile.billingRate?.billingRate,
      });
    }
    if (
      updatedProfile.billingRate?.billingUnit !==
      originalProfile.billingRate?.billingUnit
    ) {
      this.payload.push({
        op: "replace",
        path: "/billingUnit",
        value: updatedProfile.billingRate?.billingUnit,
      });
    }
    if (
      !_.isEqual(
        updatedProfile.sampleApplications,
        originalProfile.sampleApplications
      )
    )
      this.payload.push({
        op: "replace",
        path: "/sampleApplications",
        value: updatedProfile.sampleApplications,
      });
    if (
      !_.isEqual(
        updatedProfile.professionalPublications,
        originalProfile.professionalPublications
      )
    )
      this.payload.push({
        op: "replace",
        path: "/professionalPublications",
        value: updatedProfile.professionalPublications,
      });
    if (!_.isEqual(updatedProfile.links, originalProfile.links))
      this.payload.push({
        op: "replace",
        path: "/links",
        value: updatedProfile.links,
      });
  }
}
