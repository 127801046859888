import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  minWidth: theme.spacing(30)
}));
const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: '#fff'
}));

export interface SortDropdownProps {
  sortBy: string;
  onSort: (value: string) => void;
}

export default function SortDropdown(props: Readonly<SortDropdownProps>) {
  return (
    <StyledFormControl variant="outlined" size='small'>
      <InputLabel id="sort-by">Sort By</InputLabel>
      <StyledSelect
        labelId="sort-by-label"
        value={props.sortBy}
        size="small"
        label="Sort By"
        onChange={(event) => props.onSort(event.target.value as string)}>
        <MenuItem key="relevance" value="relevance">
          Relevance
        </MenuItem>
        <MenuItem key="ratingDesc" value="Rating desc">
          Rating: High To Low
        </MenuItem>
        <MenuItem key="ratingAsc" value="Rating asc">
          Rating: Low To High
        </MenuItem>
        <Divider light={true} variant="middle" />
        <MenuItem key="rateAsc" value="BillingRate asc">
          Billing Rate: Low To High
        </MenuItem>
        <MenuItem key="rateDesc" value="BillingRate desc">
          Billing Rate: High To Low
        </MenuItem>
        <Divider light={true} variant="middle" />
        <MenuItem key="applicationsAsc" value="ApplicationsDrafted asc">
          Applications Drafted: Low To High
        </MenuItem>
        <MenuItem key="applicationsDesc" value="ApplicationsDrafted desc">
          Applications Drafted: High To Low
        </MenuItem>
        <Divider light={true} variant="middle" />
        <MenuItem key="experienceAsc" value="YearsExperience asc">
          Years Experience: Low To High
        </MenuItem>
        <MenuItem key="experienceDesc" value="YearsExperience desc">
          Years Experience: High To Low
        </MenuItem>
        <Divider light={true} variant="middle" />
        <MenuItem key="distanceAsc" value="Distance asc" disabled>
          Distance: Near To Far
        </MenuItem>
        <MenuItem key="distanceDesc" value="Distance desc" disabled>
          Distance: Far To Near
        </MenuItem>
      </StyledSelect>
    </StyledFormControl>
  );
}
