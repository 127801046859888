import Guid from "common/values/guid/guid";

export default class SelectedTeam {
  public targetTeamId: Guid;
  public targetTeamName?: string;

  constructor(targetTeamId: Guid, targetTeamName?: string) {
    this.targetTeamId = targetTeamId;
    this.targetTeamName = targetTeamName;
  }

  public generateTargetUrl(action: "create" | "request", location: Location): URL {
    let url = new URL(`/proposals/drafts/${action}`, location.origin);
    url.searchParams.append("tTeamId", this.targetTeamId.valueOf());
    if (this.targetTeamName) {
      url.searchParams.append("tTeamName", this.targetTeamName);
    }
    return url;
  }

  public static fromURLSearchParams(params: URLSearchParams): SelectedTeam | undefined {
    const targetTeamIdParam = params.get("tTeamId");
    const targetTeamNameParam = params.get("tTeamName");

    if (!targetTeamIdParam) return;

    return new SelectedTeam(new Guid(targetTeamIdParam), targetTeamNameParam ?? undefined);
  }
}
