import { Grid, useMediaQuery } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { styled, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import SearchResults from 'common/contracts/search-results';
import _, { Dictionary } from 'lodash';
import MarketplaceAPIService from 'marketplace/api/marketplace-api-service';
import MarketplaceSearchAPIRequest from 'marketplace/api/request-contracts/marketplace-search-api-request';
import MarketplaceSearchInfoAPIResponse from 'marketplace/api/response-contracts/marketplace-search-info-api-response';
import IndividualAPIService from 'marketplace/entities/individual/api/individual-api-service';
import Individual from 'marketplace/entities/individual/individual';
import MarketplaceTeam from 'marketplace/entities/marketplace-team/marketplace-team';
import ResultSkeleton from 'marketplace/view/result-skeleton';
import ResultsList from 'marketplace/view/results-list';
import { enqueueSnackbar } from 'notistack';
import React, { ChangeEvent, useEffect } from 'react';
import { NavigateFunction, SetURLSearchParams } from 'react-router-dom';
import Session from 'users/session/session';
import { useSession } from 'users/session/session-context';

const ComponentContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    paddingRight: 0
  },
  display: 'flex'
}));
const ResultCount = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    marginLeft: theme.spacing(2)
  }
}));
const LowerPagination = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: theme.spacing(1)
}));
const UpperPagination = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(1)
}));

type ResultsProps = {
  session: Readonly<Session>;
  searchTerm: string;
  category: string;
  filters: Dictionary<string>;
  sortBy: string;
  embedded?: boolean;
  selectingTeamLeader?: boolean;
  selectingTeamMember?: boolean;
  selectingTeam?: boolean;
  navigate?: NavigateFunction;
  searchParams?: URLSearchParams;
  setSearchParams?: SetURLSearchParams;
  onResults: (results: number | undefined) => void;
  onTeamMemberSelected?: (teamMember: Individual) => void;
  onTeamSelected?: (team: MarketplaceTeam) => void;
};

export default function Results(props: Readonly<ResultsProps>) {
  const {
    session,
    searchTerm,
    category,
    filters,
    sortBy,
    embedded,
    selectingTeamLeader,
    selectingTeamMember,
    selectingTeam,
    searchParams,
    setSearchParams,
    navigate,
    onResults,
    onTeamMemberSelected,
    onTeamSelected
  } = props;

  const [results, setResults] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState<number | undefined>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number | undefined>(25);
  const [numResults, setNumResults] = React.useState<number | undefined>(0);

  const isMediumDisplaySize = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'), { matchMedia: window.matchMedia });

  async function fetchResultList(searchTerm: string = '', filters: Dictionary<string> | undefined) {
    try {
      setLoading(true);
      let response: SearchResults<MarketplaceSearchInfoAPIResponse>;

      let request = new MarketplaceSearchAPIRequest();
      request.from = (page && rowsPerPage) ? page * rowsPerPage : 0;
      request.size = rowsPerPage ?? 0;
      request.query = searchTerm;
      request.category = category;
      request.sortBy = sortBy;

      request = Object.assign(request, filters);
      const individualApiService = new IndividualAPIService(session);
      const abortController = new AbortController();
      
      if (selectingTeamLeader) {
        response = await individualApiService.searchIndividuals(request,abortController, true);
      } else if (selectingTeamMember) {
        response = await individualApiService.searchIndividuals(request, abortController);
      } else {
        const marketplaceApiService = new MarketplaceAPIService(session);
        response = await marketplaceApiService.searchAll(request, new AbortController());
      }

      setResults(response.hits);
      setNumResults(response.total);
      setPage(response.from);
      setRowsPerPage(response.size);

      onResults(response.total);
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Couldn't get results. Please try again", { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    async function checkQueryParams() {

      if (!searchParams?.has('query') && !searchParams?.has('id') && !searchParams?.has('type')) {
        await fetchResultList(searchTerm, filters);
        return;
      }

      const id = searchParams?.get('id') ?? '';
      const type = searchParams?.get('type') ?? '';

      if (id !== '' && type !== '') {
        navigate?.(`/marketplace/${type}/${id}`);
        return;
      }

      const term = searchParams?.get('query') ?? searchTerm ?? '';
      let queryFilters = _.fromPairs(Array.from(searchParams?.entries()));

      setPage(parseInt(searchParams?.get('pagenumber') ?? '0'));
      await fetchResultList(term, queryFilters);
    }
    checkQueryParams();
  }, [searchTerm, category, sortBy, searchParams]);

  async function handlePageChange(_event: ChangeEvent<unknown>, page: number) {
    setResults([]);
    setPage(page);

    const params = searchParams;

    if (params) {
      params.set('pagenumber', page.toString());
      setSearchParams?.(params);
    }

    await fetchResultList(searchTerm, filters);
  }

  return (
    <ComponentContainer>
      <Grid container direction="column">
        <Grid item>
          {loading && (
            <ResultSkeleton />
          )}
          {results?.length < 1 && !loading && (
            <ResultCount variant="h4">
              0 Matching Results
            </ResultCount>
          )}
          {results?.length > 0 && !loading && (
            <UpperPagination container justifyContent="space-between" alignItems="center">
              <Grid item>
                <ResultCount variant="h6">
                  {numResults} Result{(numResults ?? 0) > 1 ? 's' : ''}
                </ResultCount>
              </Grid>
              <Grid item>
                <Pagination
                  count={Math.ceil((numResults ?? 0) / (rowsPerPage ?? 0))}
                  page={page}
                  size={isMediumDisplaySize ? 'medium' : 'large'}
                  onChange={handlePageChange}
                />
              </Grid>
            </UpperPagination>
          )}
        </Grid>
        <ResultsList
          results={results}
          embedded={embedded}
          selectingTeamLeader={selectingTeamLeader}
          selectingTeamMember={selectingTeamMember}
          selectingTeam={selectingTeam}
          navigate={navigate}
          onTeamMemberSelected={onTeamMemberSelected}
          onTeamSelected={onTeamSelected}
        />
        {results?.length > 0 && !loading && (
          <LowerPagination item xs>
            <Pagination
              count={Math.ceil((numResults ?? 0) / (rowsPerPage ?? 0))}
              page={page}
              size={isMediumDisplaySize ? 'medium' : 'large'}
              onChange={handlePageChange}
            />
          </LowerPagination>
        )}
      </Grid>
    </ComponentContainer>
  );
}
