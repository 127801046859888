import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import SaveIcon from "@mui/icons-material/Save";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Currency from "common/components/currency";
import { Currency as CurrencyType } from "common/values/currency/currency";
import Money from "common/values/money/money";
import FeeScheduleCategory from 'marketplace/entities/fee-schedule-category/fee-schedule-category';
import FeeScheduleBillingCode from "marketplace/values/fee-schedule-billing-code/fee-schedule-billing-code";
import FeeScheduleCategoryDescription from "marketplace/values/fee-schedule-category-description/fee-schedule-category-description";
import FeeScheduleCategoryName from "marketplace/values/fee-schedule-category-name/fee-schedule-category-name";
import Fee from "marketplace/values/fee/fee";
import { useState } from "react";

const FormContainer = styled("div")(({ theme }) => ({}));
const NameTextField = styled(TextField)(({ theme }) => ({
  margin: 0,
  marginBottom: theme.spacing(2),
  width: "100%",
}));
const DescriptionTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  width: "100%",
}));
const PerSymbol = styled(Grid)(({ theme }) => ({
  fontSize: "1.5em",
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));
const BillingCodeField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
  width: "100%",
}));
const FeeRate = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
const ButtonContainer = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(4),
  "& > button:not(:last-of-type)": {
    marginRight: theme.spacing(2),
  },
}));

type FeeScheduleCategoryProps = {
  category?: FeeScheduleCategory ;
  onCategoryAdded?: (category: FeeScheduleCategory) => void;
  onCategoryUpdated?: (originalName: FeeScheduleCategoryName | null, category: FeeScheduleCategory) => void;
  closeDialog?: () => void;
};

export default function FeeScheduleCategoryForm(
  props: Readonly<FeeScheduleCategoryProps>
) {
  const {
    category,
    onCategoryAdded,
    onCategoryUpdated,
    closeDialog
  } = props;

  const [formDirty, setFormDirty] = useState(false);
  const [formName, setFormName] = useState<string>(
    category?.name?.valueOf() ?? ""
  );
  const [formDescription, setFormDescription] = useState<string>(
    category?.description?.valueOf() ?? ""
  );
  const [formBillingCode, setFormBillingCode] = useState<string>(
    category?.billingCode?.valueOf() ?? ""
  );

  const [formFeeRate, setFormFeeRate] = useState<Money>(
    category?.fee?.rate ?? new Money(0, CurrencyType.USD)
  );
  const [formFeeUnit, setFormFeeUnit] = useState<string>(
    category?.fee?.unit ?? ""
  );
  const [isFixedFee, setIsFixedFee] = useState<boolean>(
    category?.fee?.isFixed ?? false
  );

  function formValid(): boolean {
    return Boolean(
      formName &&
      formDescription &&
      (formFeeRate?.amount >= 0 && (isFixedFee || formFeeUnit?.length > 0))
    );
  }

  function handleSaveClicked(): void {
    const newCategory = getStateAsFeeScheduleCategory();
    if (!newCategory) return;
    onCategoryAdded?.(newCategory);
    closeDialog?.();
  }

  function handleSaveChangesClicked(originalName: FeeScheduleCategoryName | null): void {
    if (!category) return;

    const updatedCategory = getStateAsFeeScheduleCategory();
    if (!updatedCategory) return;
    onCategoryUpdated?.(originalName, updatedCategory);
    closeDialog?.();
  }

  function getStateAsFeeScheduleCategory(): FeeScheduleCategory | undefined {
    if (!formName || !formDescription) return;
    return new FeeScheduleCategory(
      new FeeScheduleCategoryName(formName),
      new FeeScheduleCategoryDescription(formDescription),
      new Fee(
        formFeeRate,
        formFeeUnit,
        isFixedFee
      ),
      new FeeScheduleBillingCode(formBillingCode)
    );
  }

  return (
    <FormContainer>
      <NameTextField
        label="Category"
        sx={{ marginTop: 1 }}
        value={formName}
        required
        onChange={(event) => {
          setFormName(event.target.value);
          setFormDirty(true);
        }}
      />
      <DescriptionTextField
        variant="outlined"
        label="Description Of Work"
        value={formDescription}
        multiline
        minRows={5}
        required
        onChange={(event) => {
          setFormDescription(event.target.value);
          setFormDirty(true);
        }}
      />
      <div>
        <BillingCodeField
          label="Billing Code (optional)"
          variant="outlined"
          value={formBillingCode ?? ""}
          onChange={(event) => {
            setFormBillingCode(event.target.value);
            setFormDirty(true);
          }}
        />
        <FormControlLabel
          label={isFixedFee ? "Fixed fee" : "Fixed Fee?"}
          labelPlacement="end"
          control={
            <Checkbox
              color="primary"
              icon={<CheckBoxOutlineBlankIcon />}
              checked={isFixedFee}
              onChange={() => {
                setIsFixedFee((prevValue) => !prevValue);
                setFormDirty(true);
              }}
            />
          }
        />
        <FeeRate container direction="row" alignItems="center">
          <Grid item xs>
            <Currency
              value={formFeeRate}
              onChange={(currency: CurrencyType, amount: number) => {
                setFormFeeRate(
                  new Money(
                    amount,
                    currency ?? "USD"
                  )
                );
                setFormDirty(true);
              }}
            />
          </Grid>
          {!isFixedFee && (
            <>
              <PerSymbol item>/</PerSymbol>
              <Grid item xs>
                <TextField
                  label="Unit"
                  value={formFeeUnit}
                  onChange={(event) => {
                    setFormFeeUnit(event.target.value);
                    setFormDirty(true);
                  }}
                />
              </Grid>
            </>
          )}
        </FeeRate>
      </div>
      <ButtonContainer>
        <Button
          color="primary"
          variant="contained"
          disabled={!formDirty || !formValid()}
          startIcon={<SaveIcon />}
          onClick={!category ? handleSaveClicked : () => handleSaveChangesClicked(category.name)}
        >
          {category ? "Save" : "Add"}
        </Button>
      </ButtonContainer>
    </FormContainer>
  );
}
