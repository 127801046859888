import {
  Button, Icon, Paper, Table, TableBody, TableCell, TableHead,
  TableRow, Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { enqueueSnackbar } from "notistack";

import EntityOfficerInvitation from "legal-entities/entities/entity-officer-invitation/entity-officer-invitation";
import EntityOfficerInvitationAPIService from "legal-entities/entities/entity-officer-invitation/api/entity-officer-invitation-api-service";
import { ConfirmResponse, useConfirmDialog } from "app/providers/confirm-dialog";
import Session from "users/session/session";

const InvitationsTable = styled(Paper)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(2)
  },
  padding: theme.spacing(6),
  position: "relative",
}));

const InvitationsTableTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1.5)
}));

type EntityOfficerInvitationsTableProps = {
  invitations: EntityOfficerInvitation[],
  onInvitationRemoved: (invitation: EntityOfficerInvitation) => void,
  session: Readonly<Session>
}

export default function EntityOfficerInvitationsTable(props: EntityOfficerInvitationsTableProps) {
  const { invitations, onInvitationRemoved, session } = props;

  const confirm = useConfirmDialog();

  async function handleCancelInvite(invitation: EntityOfficerInvitation) {
    const response = await confirm({
      title: "Cancel Invitation?",
      message: "Are you sure you want to cancel this entity officer invitation?",
      okButtonText: "Yes",
      cancelButtonText: "No"
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      const service = new EntityOfficerInvitationAPIService(session);
      await service.cancelEntityOfficerInvitation(invitation);
      onInvitationRemoved(invitation);
      enqueueSnackbar("Canceled entity officer invitation", { variant: "success" });
    } catch (ex) {
      console.error(ex);
      enqueueSnackbar("Failed to cancel entity officer invitation", { variant: "error" });
    }
  }

  return (
    <InvitationsTable>
      <InvitationsTableTitle variant="h6">Entity Officer Invitations</InvitationsTableTitle>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">Email Address</TableCell>
            <TableCell align="left">Entity</TableCell>
            <TableCell align="left">Message</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(invitations.length < 1) && (
            <TableRow>
              <TableCell colSpan={3}>
                <Typography>No Pending Invitations</Typography>
              </TableCell>
            </TableRow>
          )}
          {invitations.map((invitation: EntityOfficerInvitation) => (
            <TableRow className="active-officer-invitation" key={invitation.id?.valueOf()} data-invitation-id={invitation.id?.valueOf()}>
              <TableCell align="left">
                <Typography>
                  {invitation.registrationInfo?.email?.valueOf() ?? ''}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>{invitation.entityName}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>{invitation.message ?? <Icon>remove</Icon>}</Typography>
              </TableCell>
              <TableCell align="right">
                <Button
                  onClick={() => handleCancelInvite(invitation)}
                  color="primary">
                  Cancel
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </InvitationsTable>
  );
}
