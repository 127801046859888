import * as React from 'react';
import { enqueueSnackbar } from 'notistack';
import { FileIcon, defaultStyles, DefaultExtensionType } from 'react-file-icon';
import {
  Avatar,
  Link,
  ListItemText,
  Typography,
  CircularProgress,
  styled,
  Divider
} from '@mui/material';
import { filesize } from "filesize";
import MessagingAPIService from 'messaging/api/messaging-api-service';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { useEffect } from 'react';
import Message from 'messaging/entities/message/message';
import Individual from 'marketplace/entities/individual/individual';
import { UserAvatarMap } from 'messaging/entities/forum/view/components/chat';
import { downloadFile } from 'common/helpers/utils';
import MessageFileInfo from 'messaging/values/attachment/message-file-info';
import Session from 'users/session/session';

const MessageContainer = styled('div')(({ theme }) => ({
  '@container (min-width: 0px)': {
    maxWidth: '90%'
  },
  '@container (min-width: 600px)': {
    maxWidth: '75%'
  },
  '@container (min-width: 800px)': {
    maxWidth: '50%'
  },
  '@container (min-width: 1000px)': {
    maxWidth: '40%'
  },
  margin: theme.spacing(0, 'auto', 4, 0),
  width: 'fit-content'
}));
const SystemMessageContainer = styled('div')(({ theme }) => ({
  '@container (min-width: 800px)': {
    width: '75%'
  },
  '@container (min-width: 0px)': {
    width: '100%'
  },
  margin: theme.spacing(0, 'auto', 4, 'auto'),
  width: '80%'
}));
const MessageInfo = styled('div')(({ theme }) => ({
  alignItems: 'baseline',
  display: 'flex',
  marginBottom: theme.spacing(0.5),
}));
const MessageAvatar = styled(Avatar)(({ theme }) => ({
  height: theme.spacing(4),
  marginRight: theme.spacing(2),
  width: theme.spacing(4)
}));
const SenderName = styled(Typography)(({ theme }) => ({
}));
const MessageSubject = styled('div')(({ theme }) => ({
  color: theme.palette.common.black,
  marginBottom: theme.spacing(1)
}));
const SubjectDivider = styled(Divider)(({ theme }) => ({
  '::before, ::after': {
    borderColor: theme.palette.common.black
  }
}));
const MessageTime = styled(Typography)(({ theme }) => ({
  fontSize: '0.8em',
  marginLeft: theme.spacing(1.32)
}));
const MessageContent = styled('div')(({ theme }) => ({
  backgroundColor: '#d6defd',
  borderRadius: theme.spacing(2),
  borderTopLeftRadius: 0,
  display: 'block',
  padding: theme.spacing(3),
  '& > p': {
    margin: 0
  },
  "& a": {
    color: theme.palette.common.black
  },
  "& blockquote": {
    borderLeft: '4px solid #ccc',
    color: '#ccc',
    margin: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  }
}));
const SystemMessageContent = styled(Typography)(({ theme }) => ({
  backgroundColor: '#e0e0e0',
  borderRadius: theme.spacing(0.66),
  display: 'block',
  textAlign: 'center',
  padding: theme.spacing(2),
  '& > p': {
    margin: 0
  }
}));
const MessageAttachment = styled('figure')(({ theme }) => ({
  margin: 0,
  marginTop: theme.spacing(2),
  overflow: "hidden",
  width: '100%',
  "& figcaption": {
    "& .MuiListItemText-primary": {
      fontSize: "0.85em",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    "& .MuiListItemText-secondary": {
      color: "rgba(0, 0, 0, 0.65)",
      fontSize: "0.8em"
    }
  }
}));
const AttachmentPreview = styled(Link)(({ theme }) => ({
  cursor: 'pointer'
}));
const FileTypeIconContainer = styled('div')(({ theme }) => ({
  width: theme.spacing(8)
}));
const LoadingIndicator = styled(CircularProgress)(({ theme }) => ({
  color: "black",
  display: "flex",
  height: theme.spacing(5),
  width: theme.spacing(5)
}));
const ImageAttachment = styled("img")(({ theme }) => ({
  width: "100%"
}));

type ReceivedMessageProps = {
  session: Readonly<Session>,
  message: Message;
  sender?: Individual;
  avatars?: UserAvatarMap;
}

export default function ReceivedMessage(props: Readonly<ReceivedMessageProps>) {
  const { session, message, sender, avatars } = props;

  const [loadingAttachment, setLoadingAttachment] = React.useState(false);
  const [imageData, setImageData] = React.useState<Blob[]>([]);

  useEffect(() => {
    const hasImagesAttached = message.attachments?.some((attachment: MessageFileInfo) => {
      return attachment.mimeType?.startsWith("image/")
    });

    if (!hasImagesAttached) return;

    loadImages(message);
  }, []);

  async function loadImages(message: Message) {
    setLoadingAttachment(true);

    try {
      const messagingService = new MessagingAPIService(session);
      let response = await messagingService.getMessageImages(message);
      setImageData(response);
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar('Failed to get message attachment. Please try again.', { variant: 'error' });
    } finally {
      setLoadingAttachment(false);
    }
  }

  async function downloadAttachment(message: Message, index: number) {
    try {
      const messagingService = new MessagingAPIService(session);
      const abortController = new AbortController();
      const response = await messagingService.getMessageAttachmentByIndex(message, index, abortController);
      downloadFile(response, message.attachments[index].name);
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar('Failed to get message attachment. Please try again.', { variant: 'error' });
    }
  }

  return (
    <>
      {(message?.senderId != null) ? (
        <MessageContainer>
          <MessageInfo>
            {(message?.senderId != null) && (
              <>
                <MessageAvatar src={avatars?.[message?.senderId.toString()]} />
                <SenderName>
                  {sender?.profile?.firstName ?? 'Unknown'} {sender?.profile?.lastName ?? 'Sender'}
                </SenderName>
              </>
            )}
            <MessageTime>
              <time>
                {message.publishedOn?.format('MM/DD/YY hh:mm A')}
              </time>
            </MessageTime>
          </MessageInfo>
          <MessageContent>
            <div>
              {(message?.senderId && message.subject && message.subject.length > 0) && (
                <MessageSubject>
                  <SubjectDivider>{message.subject}</SubjectDivider>
                </MessageSubject>
              )}
            </div>
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
              {message.content}
            </ReactMarkdown>
            {message.attachments?.map((attachment: MessageFileInfo, index: number) => (
              <MessageAttachment key={attachment.id.toString()}>
                {loadingAttachment && <LoadingIndicator thickness={2} />}
                {!loadingAttachment && (
                  <AttachmentPreview
                    onClick={() => downloadAttachment(message, index)}>
                    {attachment?.mimeType?.startsWith('image/') ? (
                      <ImageAttachment
                        alt={attachment.name}
                        src={imageData?.[index]?.toString()}
                      />
                    ) : (
                      <FileTypeIconContainer onClick={() => downloadAttachment(message, index)}>
                        <FileIcon
                          extension={attachment.name.split('.')[1]}
                          {...defaultStyles[attachment.name.split('.')[1] as DefaultExtensionType]}
                        />
                      </FileTypeIconContainer>
                    )}
                  </AttachmentPreview>
                )}
                <figcaption>
                  <ListItemText
                    primary={attachment.name}
                    secondary={filesize(attachment.fileSize)}
                  />
                </figcaption>
              </MessageAttachment>
            ))}
          </MessageContent>
        </MessageContainer>
      ) : (
        <SystemMessageContainer>
          <MessageInfo style={{ justifyContent: 'center' }}>
            <MessageTime>
              <time>
                {message.publishedOn?.format('MM/DD/YY hh:mm A')}
              </time>
            </MessageTime>
          </MessageInfo>
          <div>
            {(message?.senderId && message.subject && message.subject.length > 0) && (
              <MessageSubject>
                Subject: {message.subject}
              </MessageSubject>
            )}
          </div>
          <SystemMessageContent>
            <ReactMarkdown>{message.content}</ReactMarkdown>
            {message.attachments?.map((attachment: MessageFileInfo, index: number) => (
              <MessageAttachment key={attachment.id.toString()}>
                {loadingAttachment && <LoadingIndicator thickness={2} />}
                {!loadingAttachment && (
                  <AttachmentPreview
                    onClick={() => downloadAttachment(message, index)}>
                    {attachment?.mimeType?.startsWith('image/') ? (
                      <ImageAttachment
                        alt={attachment.name}
                        src={attachment.rawData ? URL.createObjectURL(attachment.rawData) : undefined}
                      />) : (
                      <FileTypeIconContainer onClick={() => downloadAttachment(message, index)}>
                        <FileIcon
                          extension={attachment.name.split('.')[1]}
                          {...defaultStyles[attachment.name.split('.')[1] as DefaultExtensionType]}
                        />
                      </FileTypeIconContainer>
                    )}
                  </AttachmentPreview>
                )}
                <figcaption>
                  <ListItemText
                    primary={attachment.name}
                    secondary={filesize(attachment.fileSize)}
                  />
                </figcaption>
              </MessageAttachment>
            ))}
          </SystemMessageContent>
        </SystemMessageContainer>
      )}
    </>
  )
}
