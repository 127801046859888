import { MTableToolbar } from "@material-table/core";
import FilterListIcon from "@mui/icons-material/FilterList";
import { styled } from "@mui/material/styles";
import { PageTab } from "app/routes/contracts/tabs";
import { FilterButtons, FilterChip, TableToolbarContainer } from "common/styles";
import { useSearchParams } from "react-router-dom";
import Session from "users/session/session";

const NavContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    marginBottom: 0
  },
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '-5px'
}));

export type TableToolbarComponentProps = {
  session: Session;
  activeTab: PageTab;
  tabs: React.ReactNode;
  mine: boolean;
  clientVendor: boolean;
  isMediumDisplaySize: boolean;
  setMine: React.Dispatch<React.SetStateAction<boolean>>;
  setClientVendor: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function TableToolbarComponent(props: Readonly<TableToolbarComponentProps>) {
  const {
    session,
    activeTab,
    tabs,
    mine,
    clientVendor,
    setMine,
    setClientVendor
  } = props;

  const [, setSearchParams] = useSearchParams();

  return (
    <TableToolbarContainer>
      <MTableToolbar {...props} />
      <NavContainer>
        {tabs}
        <FilterButtons>
          <FilterChip
            icon={<FilterListIcon />}
            label={`My ${activeTab === PageTab.Conflicts ? 'Conflicts' : 'Policies'}`}
            color="primary"
            variant={mine ? 'filled' : 'outlined'}
            onClick={() => {
              setMine(prevValue => !prevValue);
              setClientVendor(false);
              setSearchParams((prevParams) => {
                const params = new URLSearchParams(prevParams);
                if (!mine) {
                  params.set('context', 'mine');
                } else {
                  params.set('context', 'clientVendor');
                }
                return params;
              });
            }}
          />
          <FilterChip
            icon={<FilterListIcon />}
            label={`My ${session.context?.viewingAsVendor ? "Clients'" : "Vendors'"} ${activeTab === PageTab.Conflicts ? 'Conflicts' : 'Policies'}`}
            color="primary"
            variant={clientVendor ? 'filled' : 'outlined'}
            onClick={() => {
              setClientVendor(prevValue => !prevValue);
              setSearchParams((prevParams) => {
                const params = new URLSearchParams(prevParams);
                if (!clientVendor) {
                  params.set('context', 'clientVendor');
                } else {
                  params.set('context', 'mine');
                }
                return params;
              });
            }}
          />
        </FilterButtons>
      </NavContainer>
    </TableToolbarContainer>
  )
}
