import { Query, QueryResult } from "@material-table/core";

import { PageTab } from "app/routes/fee-schedules/tabs";
import Session from "users/session/session";
import FeeScheduleTemplate from "work/entities/fee-schedule-template/fee-schedule-template";
import FeeScheduleTemplateAPIService from "work/entities/fee-schedule-template/api/fee-schedule-template-api-service";

export default async function loadData(
  query: Query<FeeScheduleTemplate>,
  activeTab: PageTab,
  setLoading: (isLoading: boolean) => void,
  onDataLoaded?: (data: FeeScheduleTemplate[]) => void
): Promise<QueryResult<FeeScheduleTemplate>> {
  const emptyResponse = {
    data: [],
    page: 0,
    totalCount: 0,
  } as QueryResult<FeeScheduleTemplate>;

  const session = Session.loadFromStorage(() => {});

  try {
    setLoading(true);

    let feeSchedules: FeeScheduleTemplate[] = [];
    const feeScheduleService = new FeeScheduleTemplateAPIService(session);
    const abortController = new AbortController();
    if (session.hasEntity) {
      if (activeTab !== PageTab.Archived) {
        feeSchedules = await feeScheduleService.getFeeScheduleTemplates(
          session.entities[0]?.entityId, session.accountType, abortController);
      } else {
        feeSchedules = await feeScheduleService.getArchivedFeeScheduleTemplates(
          session.entities[0]?.entityId, session.accountType, abortController);
      }
    }

    onDataLoaded?.(feeSchedules);

    return {
      data: feeSchedules,
      page: query.page,
      totalCount: feeSchedules.length
    }
  } catch (error) {
    console.error(error);
    return emptyResponse;
  } finally {
    setLoading(false);
  }
}
