export default class RegistrationNumber extends String {
    public static fromObject(object: any) {
        if (!object) return;
        return new RegistrationNumber(object);
    }

    public toJSON() {
        return this.toString();
    }
}
