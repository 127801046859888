import Proposal, { CompleteProposalSpec, ProposalMetaInfo } from "work/entities/proposal/proposal";
import ProposalAPIService from "work/entities/proposal/api/proposal-api-service";
import Session from "users/session/session";


export default class ProposalFactory {
    public static createProposal(spec: CompleteProposalSpec, session: Readonly<Session>, metaInfo?: ProposalMetaInfo): Proposal {
        return new Proposal(new ProposalAPIService(session), spec, metaInfo);
    }

    public static updateProposal(oldProposal: Proposal, spec: CompleteProposalSpec, session: Readonly<Session>): Proposal {
        return new Proposal(new ProposalAPIService(session), spec, oldProposal.metaInfo);
    }
}