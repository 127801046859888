import { JsonPatchOperation } from "common/services/api/attorney-hub-api-service";
import MarketplaceTeam from "marketplace/entities/marketplace-team/marketplace-team";


export default class UpdateMarketplaceTeamAPIRequest {
  payload: JsonPatchOperation[] = [];

  constructor(
    originalTeam: MarketplaceTeam,
    updatedTeam: MarketplaceTeam
  ) {
    if (updatedTeam.profile.name !== originalTeam?.profile.name)
      this.payload.push({
        op: "replace",
        path: "/name",
        value: updatedTeam.profile.name,
      });
    if (updatedTeam.profile.description !== originalTeam?.profile.description)
      this.payload.push({
        op: "replace",
        path: "/description",
        value: updatedTeam.profile.description,
      });
    if (updatedTeam.profile.categories !== originalTeam?.profile.categories)
      this.payload.push({
        op: "replace",
        path: "/categories",
        value: updatedTeam.profile.categories ?? [],
      });
    if (updatedTeam.profile.avatarId?.valueOf() !== originalTeam?.profile.avatarId?.valueOf())
      this.payload.push({
        op: "replace",
        path: "/avatarId",
        value: updatedTeam.profile.avatarId?.valueOf(),
      });
    if (updatedTeam.isVisible !== originalTeam?.isVisible)
      this.payload.push({
        op: "replace",
        path: "/isVisible",
        value: updatedTeam.isVisible,
      });
  }
}
