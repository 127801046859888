import AttorneyHubAPIService from "common/services/api/attorney-hub-api-service";
import { AccountType } from "common/values/account-type/account-type";
import Guid from "common/values/guid/guid";
import DetailedWorkTeamAPIResponse from "work/values/team/api/response-contracts/detailed-work-team-api-response";
import WorkAgreementAPIResponse from "work/entities/work-agreement/api/response-contracts/work-agreement-api-response";
import WorkAgreement from "work/entities/work-agreement/work-agreement";
import FeeSchedule from "work/values/fee-schedule/fee-schedule";
import Team from "work/values/team/team";
import axios, { AxiosHeaders, CanceledError } from "axios";
import Session from "users/session/session";

export type WorkAgreementContextParam = "allactive" | "archive";
export interface IGetWorkAgreementParams {
    entityId: Guid;
    viewingAs: AccountType;
    context: WorkAgreementContextParam;
}
export default class WorkAgreementAPIService {

    private authHeaders: AxiosHeaders = new AxiosHeaders();

    constructor(session: Readonly<Session>) {
        if (!session.authToken?.value)
            throw new Error("Session must have an authToken to create a WorkAgreementAPIService");
        this.authHeaders.set("Authorization", `Bearer ${session.authToken.value}`);
    }

    async getWorkAsync(query: IGetWorkAgreementParams, abortController?: AbortController): Promise<WorkAgreement[]> {
        try {
            const url = new URL(
                `/work?entityId=${query.entityId.valueOf()}&viewingAs=${query.viewingAs}&context=${query.context}`,
                AttorneyHubAPIService.apiBaseUrl);
            const response = await axios.get(url.toString(), {
                headers: this.authHeaders,
                signal: abortController?.signal
            });
            const workAgreements: WorkAgreement[] = [];
            response.data?.forEach((data: WorkAgreementAPIResponse) => {
                const apiAgreement = Object.assign(new WorkAgreementAPIResponse(), data);
                workAgreements.push(apiAgreement.deserialize());
            });
            return workAgreements;
        } catch (error: any) {
            if (error.response?.status === 403) 
                throw new InvalidWorkUserError(error.response?.data?.message, query);          
            if(error instanceof CanceledError)
                throw error;
            throw new WorkAgreementAPIServiceError("getWorkAsync", error);
        }
    }

    async getWorkByIdAsync(workId: Guid, abortController?: AbortController): Promise<WorkAgreement> {
        try {
            const url = new URL(`/work/${workId.valueOf()}`, AttorneyHubAPIService.apiBaseUrl);
            const response = await axios.get(url.toString(), {
                headers: this.authHeaders,
                signal: abortController?.signal
            });
            return Object.assign(new WorkAgreementAPIResponse(), response.data).deserialize();
        } catch (error: any) {
            switch (error.response?.status) {
                case 403:
                    throw new InvalidWorkUserError(error.response?.data?.message, undefined, workId);
                case 404:
                    throw new WorkNotFoundError(error.response?.data?.message, workId);
                default:{
                    if(error instanceof CanceledError)
                        throw error;
                    throw new WorkAgreementAPIServiceError("getWorkByIdAsync", error);
                }
            }
        }
    }

    async getTeamsAsync(workId: Guid, abortController?: AbortController): Promise<Team[]> {
        try {
            const url = new URL(`/work/${workId.valueOf()}/teams`, AttorneyHubAPIService.apiBaseUrl);
            const response = await axios.get(url.toString(), {
                headers: this.authHeaders,
                signal: abortController?.signal
            });
            const teams: Team[] = [];
            response.data?.forEach((data: DetailedWorkTeamAPIResponse) => {
                teams.push(Object.assign(new DetailedWorkTeamAPIResponse(), data).deserialize());
            });
            return teams;
        } catch (error: any) {
            if(error instanceof CanceledError)
                throw error;
            if (error.response?.status === 403) 
                throw new InvalidWorkUserError(error.response?.data?.message, undefined, workId);           
            throw new WorkAgreementAPIServiceError("getTeamsAsync", error);
        }
    }

    async getFeeSchedulesAsync(
        entityId: Guid,
        representativeType?: string,
        abortController?: AbortController): Promise<FeeSchedule[]> {
        try {
            const url = representativeType ?
                new URL(`/work/${entityId.valueOf()}/fee-schedules?representativeType=${representativeType}`,
                    AttorneyHubAPIService.apiBaseUrl) :
                new URL(`/work/${entityId.valueOf()}/fee-schedules`,
                    AttorneyHubAPIService.apiBaseUrl);
            const response = await axios.get(url.toString(), {
                headers: this.authHeaders,
                signal: abortController?.signal
            });
            return response.data;
        } catch (error: any) {
            if(error instanceof CanceledError)
                throw error;
            if (error.response?.status === 403) 
                throw new InvalidWorkUserError(error.response?.data?.message, undefined, entityId); 
            throw new WorkAgreementAPIServiceError("getFeeSchedulesAsync", error);
        }
    }
}

export class WorkAgreementAPIServiceError extends Error {
    constructor(method: string, error: any) {
        super(`Error calling WorkAgreementAPIService.${method}: Error: ${error}`);
    }
}

export class WorkNotFoundError extends Error {
    workId: Guid;
    constructor(message: string, workId: Guid) {
        super(message);
        this.workId = workId;
    }
}

export class InvalidWorkUserError extends Error {
    query?: IGetWorkAgreementParams;
    workId?: Guid;
    constructor(message: string, query?: IGetWorkAgreementParams, workId?: Guid) {
        super(message);
        this.query = query;
        this.workId = workId;
    }
}