import InfoIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Checkbox,
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { styled } from "@mui/material/styles";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";

import LoadingButton from "common/components/loading-button";
import EmailAddress from "common/values/email-address/email-address";
import EmailAddressInput, { EmailAddressInputError } from "common/values/email-address/view/email-address-input";
import Guid from "common/values/guid/guid";
import Name from "common/values/name/name";
import NameInput, { NameInputError } from "common/values/name/view/name-input";
import { EntityNameInput } from "legal-entities/components";
import EntityAgreement, { EntityAgreementNotFoundError, InvalidEntityAgreementError } from "legal-entities/entities/entity-agreement/entity-agreement";
import EntityName from "legal-entities/values/entity-name/entity-name";
import { EntityNameInputError } from "legal-entities/values/entity-name/view/entity-name-input";
import { useNavigate, useParams } from "react-router";
import { useSession } from "users/session/session-context";
import EntityAgreementAPIService from "../api/entity-agreement-api-service";

const PageContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 2),
  textAlign: "center"
}));
const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4)
}));
const CheckList = styled(List)(({ theme }) => ({
  margin: "auto",
  maxWidth: theme.spacing(80)
}));
const Instructions = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(2, "auto", 1),
  maxWidth: theme.spacing(100)
}));
const ErrorList = styled(Box)(({ theme }) => ({
  color: theme.palette.error.main,
  textAlign: "center"
}));
const ErrorText = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(0.5),
}));
const AcceptButton = styled(LoadingButton)(({ theme }) => ({
  marginTop: theme.spacing(2)
}));
const HelperText = styled(Container)(({ theme }) => ({
  color: theme.palette.text.secondary,
  margin: theme.spacing(2, 'auto'),
  padding: theme.spacing(2)
}));
const MoreInfoIcon = styled(ListItemSecondaryAction)(({ theme }) => ({
  position: 'relative',
  right: 0,
  top: 0,
  transform: 'none'
}));

interface GeneralFormError extends String { };
type EntityAgreementFormError = GeneralFormError | EmailAddressInputError | NameInputError | EntityNameInputError;

type EntityAgreementFormErrors = {
  general?: GeneralFormError;
  email?: EmailAddressInputError;
  name?: NameInputError;
  entityName?: EntityNameInputError;
}

type EntityAgreementPageProps = {};

export default function EntityAgreementPage(props: Readonly<EntityAgreementPageProps>) {
  const [entityAgreement, setEntityAgreement] = useState<EntityAgreement>();
  const [formEntityName, setFormEntityName] = useState<EntityName>();
  const [formAdministratorName, setFormAdministratorName] = useState<Name>();
  const [formAdministratorEmail, setFormAdministratorEmail] = useState<EmailAddress>();
  const [formSkipAdminCreation, setFormSkipAdminCreation] = useState<boolean>(false);
  const [formEntityIsClient, setFormEntityIsClient] = useState<boolean>(true);
  const [formEntityIsVendor, setFormEntityIsVendor] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<EntityAgreementFormErrors>();
  const [saving, setSaving] = useState<boolean>(false);

  const session = useSession();
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();

  useEffect(() => {
    async function loadAgreement() {
      try {
        let agreement: EntityAgreement;
        const sessionAgreement = session.openEntityAgreements.find(
          (agreement: EntityAgreement) => agreement.id.toString() === params.id
        );

        if (sessionAgreement) {
          agreement = sessionAgreement;
        } else {
          if (!params.id) throw new Error("No agreement ID provided");
          const service = new EntityAgreementAPIService(session);
          const abortController = new AbortController();
          agreement = await service.getEntityAgreementById(new Guid(params.id), abortController);
        }

        setEntityAgreement(agreement);
        setFormEntityName(agreement.entityName);
      } catch (error: any) {
        console.error(error);
        enqueueSnackbar("Couldn't get agreement. Please try again.", { variant: "error" });
      }
    }
    if (!entityAgreement) {
      loadAgreement();
      return;
    }
    setFormEntityName(entityAgreement.entityName);
    setFormAdministratorName(entityAgreement.administratorName);
    setFormAdministratorEmail(entityAgreement.administratorEmail);
  }, [entityAgreement, params.id, session.openEntityAgreements]);

  function rebuildAgreement(
    entityName?: EntityName,
    emailAddress?: EmailAddress,
    name?: Name,
    errors?: EntityAgreementFormErrors
  ): EntityAgreement | undefined {
    if (!entityAgreement) throw EntityAgreementNotFoundError;
    if (!formValid(errors) || !entityName) return;

    try {
      const updatedEntityName = entityName ?? entityAgreement.entityName
      let updatedAgreement = new EntityAgreement(entityAgreement.id, updatedEntityName);
      updatedAgreement.termsFileId = entityAgreement.termsFileId;

      if (!formSkipAdminCreation) {
        updatedAgreement.administratorEmail = emailAddress;
        updatedAgreement.administratorName = name;
      }

      return updatedAgreement;
    } catch (error: any) {
      if (error instanceof InvalidEntityAgreementError) {
        let errors: EntityAgreementFormErrors = {};
        errors.general = error.message;
        setFormErrors(errors);
        return;
      }
      throw error;
    }
  }

  async function handleAcceptAgreement() {
    setSaving(true);

    const builtAgreement = rebuildAgreement(formEntityName, formAdministratorEmail, formAdministratorName);

    try {
      if (!entityAgreement) throw EntityAgreementNotFoundError;
      if (!builtAgreement) throw new InvalidEntityAgreementError("Agreement is invalid");
      const service = new EntityAgreementAPIService(session);
      const finalAgreement = await service.updateEntityAgreement(
        entityAgreement,
        builtAgreement
      );

      const authResponse = await service.acceptEntityAgreement(
        finalAgreement,
        formEntityIsClient,
        formEntityIsVendor
      );

      session.initializeFromAuthResponse(authResponse);

      navigate("/dashboard", { replace: true });
    } catch (error: any) {
      console.error(error);
      let message = "Couldn't accept agreement. Please try again";
      if (error.response?.status === 400) {
        message = error.response.data;
      }
      enqueueSnackbar(message, { variant: "error" });
    } finally {
      setSaving(false);
    }
  }

  function formValid(errors?: EntityAgreementFormErrors): boolean {
    if (errors === undefined) errors = formErrors;

    const hasRole = formEntityIsClient || formEntityIsVendor;

    const optionalAdminCheck = formSkipAdminCreation ||
      (formAdministratorEmail !== undefined && formAdministratorName !== undefined);

    return (
      formEntityName !== undefined &&
      hasRole &&
      optionalAdminCheck &&
      !errors?.entityName &&
      !errors?.general &&
      (formSkipAdminCreation || (
        (!formSkipAdminCreation && !errors?.email) &&
        (!formSkipAdminCreation && !errors?.name)
      ))
    )
  }

  function updateFormErrors(type: keyof EntityAgreementFormErrors, error?: EntityAgreementFormError) {
    let errors: EntityAgreementFormErrors = { ...formErrors };

    if (type === 'entityName') {
      errors.entityName = error as EntityNameInputError;
    }
    else if (type === 'email') {
      errors.email = error as EmailAddressInputError;
    }
    else if (type === 'name') {
      errors.name = error as NameInputError;
    }
    else {
      errors.general = error as GeneralFormError;
    }

    setFormErrors(errors);
    return errors;
  }

  return (
    <PageContainer>
      <Grid container direction="column">
        <Grid item component="form">
          <Title variant="h4">Terms Of Use</Title>
          <EntityNameInput
            label="Organization Name"
            entityName={formEntityName}
            variant="outlined"
            onChange={(updatedEntityName?: EntityName, error?: EntityNameInputError) => {
              if (updatedEntityName) setFormEntityName(updatedEntityName);
              const errors = updateFormErrors('entityName', error);
              rebuildAgreement(updatedEntityName, formAdministratorEmail, formAdministratorName, errors);
            }}
          />
        </Grid>
        <Grid item>
          <CheckList disablePadding>
            <ListItemButton
              disableGutters
              onClick={() => setFormEntityIsClient(!formEntityIsClient)}>
              <ListItemIcon>
                <Checkbox
                  id="entity-agreement-is-client-checkbox"
                  edge="start"
                  color="primary"
                  checked={formEntityIsClient}
                  onChange={(event) =>
                    setFormEntityIsClient(event.target.checked)
                  }
                />
              </ListItemIcon>
              <ListItemText
                primary={`Client: ${formEntityIsClient
                  ? "This Legal Entity will utilize services offered on the Marketplace."
                  : "Allow your Legal Entity to purchase and use legal services offered on the Marketplace?"
                  }`}
              />
              <MoreInfoIcon>
                <Tooltip
                  disableFocusListener
                  title={
                    "Setting this option will allow your Legal Entity to act as a client on the Marketplace. " +
                    "This option can be enabled later. "
                  }
                  placement="bottom"
                  arrow>
                  <IconButton size="small">
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </MoreInfoIcon>
            </ListItemButton>
            <ListItemButton
              disableGutters
              onClick={() => setFormEntityIsVendor(!formEntityIsVendor)}>
              <ListItemIcon>
                <Checkbox
                  id="entity-agreement-is-vendor-checkbox"
                  edge="start"
                  color="primary"
                  checked={formEntityIsVendor}
                  onChange={(event) =>
                    setFormEntityIsVendor(event.target.checked)
                  }
                />
              </ListItemIcon>
              <ListItemText
                primary={`Vendor: ${formEntityIsVendor
                  ? "This Legal Entity will offer services on the Marketplace."
                  : "Allow your Legal Entity to offer legal services on the Marketplace?"
                  }`}
              />
              <MoreInfoIcon>
                <Tooltip
                  disableFocusListener
                  title={
                    "Setting this option will allow your Legal Entity to act as a Vendor on the Marketplace. " +
                    "This option can be enabled later. "
                  }
                  placement="bottom"
                  arrow>
                  <IconButton size="small">
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </MoreInfoIcon>
            </ListItemButton>
            <ListItemButton
              disableGutters
              onClick={() =>
                setFormSkipAdminCreation(!formSkipAdminCreation)
              }>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  color="primary"
                  checked={!formSkipAdminCreation}
                  onChange={(event) =>
                    setFormSkipAdminCreation(!event.target.checked)
                  }
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  !formSkipAdminCreation
                    ? "Creating Additional Entity Administrator"
                    : "Optional: Create Additional Entity Administrator?"
                }
              />
              <MoreInfoIcon>
                <Tooltip
                  disableFocusListener
                  title={
                    "You can skip creating an additional Legal Entity Administrator fow now, " +
                    "and create one or more administrators at a later date"
                  }
                  placement="bottom"
                  arrow>
                  <IconButton size="small">
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </MoreInfoIcon>
            </ListItemButton>
            {!formSkipAdminCreation && (
              <ListItem disableGutters>
                <Instructions>
                  Enter contact details for another person who is legally
                  able to sign contracts on behalf of the organization:
                </Instructions>
              </ListItem>
            )}
          </CheckList>
        </Grid>
        {!formSkipAdminCreation && (
          <Grid item component="form">
            <NameInput
              idPrefix="entity-agreement-admin"
              variant="outlined"
              name={formAdministratorName}
              onChange={(updatedName?: Name, error?: NameInputError) => {
                if (updatedName) setFormAdministratorName(updatedName);
                const errors = updateFormErrors('name', error);
                rebuildAgreement(formEntityName, formAdministratorEmail, updatedName, errors);
              }}
            />
            <EmailAddressInput
              idPrefix="entity-agreement-admin"
              variant="outlined"
              emailAddress={formAdministratorEmail}
              onChange={(updatedEmailAddress?: EmailAddress, error?: EmailAddressInputError) => {
                if (updatedEmailAddress) setFormAdministratorEmail(updatedEmailAddress);
                const errors = updateFormErrors('email', error);
                rebuildAgreement(formEntityName, updatedEmailAddress, formAdministratorName, errors);
              }}
              onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                if (event.key === 'Enter' && formValid()) {
                  handleAcceptAgreement();
                }
              }}
            />
          </Grid>
        )}
        <Grid item component="form">
          {!formEntityIsClient && !formEntityIsVendor && (
            <Grid item>
              <ErrorList>
                <ErrorText>
                  Your Legal Entity must select Client, Vendor or both to
                  Accept Agreement
                </ErrorText>
              </ErrorList>
            </Grid>
          )}
          <AcceptButton
            id="entity-agreement-submit-button"
            variant="contained"
            color="primary"
            loading={saving}
            disabled={saving || !formValid()}
            startIcon={<CheckIcon />}
            onClick={handleAcceptAgreement}>
            Accept Agreement
          </AcceptButton>
        </Grid>
        <Grid item>
          <HelperText>
            <Typography>
              By clicking the accept button above you agree to be bound by
              the following terms
            </Typography>
          </HelperText>
        </Grid>
        <Grid item>
          {/* <LegalEntityTerms termsId={this.state.termsId} /> */}
        </Grid>
      </Grid>
    </PageContainer>
  );
}
