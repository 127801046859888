import { SvgIcon, Typography } from "@mui/material";
import { PageTitle } from "common/styles/table";
import React from "react";

interface Props {
  title: string;
  icon: React.ReactElement<typeof SvgIcon>;
};

export default function TableTitle(props: Readonly<Props>) {
  return (
    <PageTitle>
      {props.icon}
      <Typography variant='h6'>{props.title}</Typography>
    </PageTitle>
  );
}
