import Individual from "marketplace/entities/individual/individual";
import Guid from "common/values/guid/guid";
import Company from "marketplace/entities/company/company";
import IndividualProfile from "marketplace/values/individual-profile/individual-profile";
import CompanyProfile from "marketplace/values/company-profile/company-profile";
import EmailAddress from "common/values/email-address/email-address";
import PhoneNumber from "common/values/phone-number/phone-number";
import TechnicalBackgroundInfo from "marketplace/values/technical-background-info/technical-background-info";
import RegistrationNumber from "marketplace/values/registration-number/registration-number";
export default class MarketplaceSubscriberInfoAPIResponse {
    isVisible?: boolean;
    userId?: string;
    description?: string;
    individualId?: string;
    firstName?: string;
    lastName?: string;
    avatar?: string;
    emailAddress?: string;
    professionalRegistration?: string;
    technicalBackground?: string;
    location?: string;
    phoneNumber?: string;
    school?: string;
    resume?: string;
    potentialDiversityCandidate?: boolean;
    additionalInfo?: string;
    fluentLanguages?: string[];
    technicalLanguages?: string[];
    regionsRegistered?: string[];
    categories?: string[];
    collaborates?: boolean;
    companyId?: string;
    companyEntityId?: string;
    companyName?: string;

    deserialize(): Individual {
        if (!this.individualId) throw new Error("Individual id not returned.");
        if (!this.userId) throw new Error("User id not returned.");
        if (!this.companyId) throw new Error("Company id not returned.");
        if (!this.companyEntityId) throw new Error("Company entity id not returned.");

        const individual = new Individual(new Guid(this.individualId), new Guid(this.userId));
        individual.isVisible = this.isVisible ?? false;
        const userId = this.userId ? new Guid(this.userId) : undefined;
        if (this.firstName && this.lastName) {
            individual.profile = new IndividualProfile(userId, this.firstName, this.lastName);
            individual.profile.avatarId = this.avatar ? new Guid(this.avatar) : undefined;
            individual.profile.description = this.description;
            individual.profile.location = this.location;
            individual.profile.phoneNumber = new PhoneNumber(this.phoneNumber);
            individual.profile.email = this.emailAddress ? new EmailAddress(this.emailAddress) : undefined;
            individual.profile.school = this.school;
            individual.profile.resumeId = this.resume ? new Guid(this.resume) : undefined;
            individual.profile.professionalRegistration = new RegistrationNumber(this.professionalRegistration);
            individual.profile.technicalBackground = new TechnicalBackgroundInfo(this.technicalBackground);
            individual.profile.potentialDiversityCandidate = this.potentialDiversityCandidate || false;
            individual.profile.collaborates = this.collaborates || false;
            individual.profile.additionalInfo = this.additionalInfo;
            individual.profile.fluentLanguages = this.fluentLanguages || [];
            individual.profile.technicalLanguages = this.technicalLanguages || [];
            individual.profile.regionsRegistered = this.regionsRegistered || [];
            individual.profile.categories = this.categories || [];
        }

        individual.company = new Company(new Guid(this.companyId), new Guid(this.companyEntityId));
        if (this.companyName) {
            individual.company.profile = new CompanyProfile(this.companyName);
        }

        return individual;
    }
}
