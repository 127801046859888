import Guid from 'common/values/guid/guid';

export default class ForumSubscriberInvitationApiRequest {
    inviteeId: string;
    invitationMessage?: string;

    constructor(inviteeId: Guid, invitationMessage?: string) {
        this.inviteeId = inviteeId.toString();
        this.invitationMessage = invitationMessage;
    }
};