import _ from "lodash";
import Proposal from "work/entities/proposal/proposal";
import { useState } from "react";
import RedlinePopover from "work/entities/proposal/redlining/view/proposal-redline-popover";
import { styled } from "@mui/material/styles";

const DescriptionContainer = styled('span', { shouldForwardProp: prop => prop !== 'revised' })<{ revised: boolean }>(({ revised, theme }) => ({
  fontSize: '0.85em',
  textDecoration: revised ? 'underline dotted red' : 'none'
}));

type DescriptionProps = {
  proposal: Proposal;
};

export default function Description(props: Readonly<DescriptionProps>) {
  const { proposal } = props;

  const [popoverAnchor, setPopoverAnchor] = useState<HTMLElement | null>(null);

  return (
    <>
      <DescriptionContainer
        revised={
          proposal?.supersedes !== null && 
          !_.isEqual(proposal?.description, proposal?.supersedes?.description)
        }
        onMouseEnter={(event) => setPopoverAnchor(event.currentTarget)}
        onMouseLeave={(_event) => setPopoverAnchor(null)}>
        {proposal.description.valueOf()}
      </DescriptionContainer>
      <RedlinePopover
        columnName='description'
        proposal={proposal}
        open={Boolean(popoverAnchor)}
        anchorEl={popoverAnchor}
      />
    </>
  )
}
