import BadgeIcon from '@mui/icons-material/Badge';
import MessageIcon from '@mui/icons-material/Message';
import { Avatar, Button, Chip, Container, Divider, styled, Tooltip, Typography } from '@mui/material';
import { ConfirmDialogType } from 'app/providers/confirm-dialog';
import { CanceledError } from 'axios';
import Loader from 'common/components/loader';
import Guid from 'common/values/guid/guid';
import MarketplaceTeamAPIService from 'marketplace/entities/marketplace-team/api/marketplace-team-api-service';
import MarketplaceTeam from 'marketplace/entities/marketplace-team/marketplace-team';
import ViewTeam from 'marketplace/entities/marketplace-team/view/view-team';
import TeamProfile from 'marketplace/values/team-profile/team-profile';
import { Chat } from 'messaging/components';
import Forum from 'messaging/entities/forum/forum';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import UserNetworkConnectionAPIService from 'users/entities/user-network-connection/api/user-network-connection-api-service';
import NetworkButton from 'users/entities/user-network-connection/view/components/network-button';
import { useSession } from 'users/session/session-context';

const MainContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
  width: '100%'
}));
const HeaderContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(3, 0)
  },
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'column',
  gridColumn: '1 / auto',
  gridRowStart: '1',
  justifyContent: 'center',
  padding: theme.spacing(3),
  position: 'sticky',
  top: 0,
  width: '100%',
  zIndex: 10,
}));
const ChatContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: 0
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 1)
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(0, 4)
  },
  gridColumn: '1 / auto',
  gridRowStart: '2',
  gridRowEnd: 'none'
}));
const ContentContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    columnCount: 1
  },
  [theme.breakpoints.up('md')]: {
    columnCount: 2,
    margin: theme.spacing(0, 1)
  },
  [theme.breakpoints.up('lg')]: {
    margin: theme.spacing(0, 4)
  },
  columnFill: 'balance',
  columnGap: theme.spacing(1),
  gridColumn: '1 / auto',
  gridRowStart: '2'
}));
const HeaderInnerContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    justifyItems: 'center'
  },
  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: 'min-content auto',
    gridTemplateRows: 'min-content auto'
  },
  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: 'min-content auto 1fr'
  },
  alignItems: 'flex-start',
  gridColumnGap: theme.spacing(2),
  display: 'grid',
  width: '100%'
}));
const TitleContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  display: 'flex',
  flexDirection: 'column',
  height: '100%'
}));
const ButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  marginTop: theme.spacing(1),
  '& > *:not(:last-child)': {
    marginRight: theme.spacing(2)
  }
}));
const GridContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridGap: theme.spacing(2),
  marginBottom: theme.spacing(2),
  width: '100%'
}));
const StyledAvatar = styled(Avatar)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    height: theme.spacing(7),
    width: theme.spacing(7)
  },
  height: theme.spacing(15),
  width: theme.spacing(15)
}));
const FieldTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500
}));
const ProfileTypeChip = styled(Chip)(({ theme }) => ({
  color: theme.palette.text.disabled,
  margin: theme.spacing(0.25)
}));
const NameChipContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  alignItems: 'flex-start',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'flex-start'
}));
const DescriptionContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(1, 0),
    width: '100%'
  },
  [theme.breakpoints.up('sm')]: {
    gridColumn: '1 / span 2',
    gridRow: '2',
    margin: theme.spacing(2, 0)
  },
  [theme.breakpoints.up('lg')]: {
    gridColumn: '3',
    gridRow: '1',
    margin: 0
  },
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-between',
  margin: 0,
  textAlign: 'left',
  width: '100%'
}));
const DescriptionText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center'
  },
  fontSize: '1.1em',
  fontStyle: 'italic',
  lineHeight: '1.1em'
}));
const DescriptionDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(1),
  width: '100%'
}));
const MissingField = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled
}));
const MessageButtonContainer = styled('span')<{ networked: boolean }>(({ networked: inNetwork, theme }) => ({
  cursor: inNetwork ? 'pointer' : 'not-allowed'
}));

export type ViewTeamProfileProps = {
  teamId?: Guid;
  profile?: TeamProfile;
  confirmDialog?: ConfirmDialogType;
}

export default function ViewTeamProfile(props: Readonly<ViewTeamProfileProps>) {
  const { teamId, confirmDialog } = props;

  const [avatar, setAvatar] = useState<File | string>();
  const [forum, setForum] = useState<Forum | undefined>(undefined);
  const [team, setTeam] = useState<MarketplaceTeam>();
  const [teamProfile, setTeamProfile] = useState<TeamProfile | undefined>(props.profile);

  const [loading, setLoading] = useState(false);
  const [chatOpen, setChatOpen] = useState<boolean>(false);
  const [isNetworkedWithUser, setIsNetworkedWithUser] = useState<boolean>(false);

  const session = useSession();

  const loadProfile = async (abortController: AbortController) => {
    try {
      setLoading(true);

      if (!teamId) return;

      const teamService = new MarketplaceTeamAPIService(session);
      const returnedTeam = await teamService.getTeamById(teamId, abortController);

      if (!returnedTeam.id) throw new Error('Team not found');
      if (!returnedTeam.profile) throw new Error('Team profile not found');

      if (returnedTeam.profile.avatarId) {
        const returnedAvatar = await teamService.getTeamAvatar(returnedTeam.id, abortController);
        setAvatar(returnedAvatar);
      }
      if (returnedTeam.profile.contactUserId) {
        const inNetwork = session.isNetworkedWith(returnedTeam.profile.contactUserId);
        if (inNetwork) {
          setIsNetworkedWithUser(inNetwork);
          const networkService = new UserNetworkConnectionAPIService(session);
          const returnedForum = await networkService
            .getChatForumForNetworkConnection(
              returnedTeam.profile.contactUserId,
              abortController);
          setForum(returnedForum);
        }
      }

      setTeam(returnedTeam);
      setTeamProfile(returnedTeam.profile);
    } catch (error: any) {
      if (error instanceof CanceledError)
        return;
      console.error(error);
      enqueueSnackbar('Unable to load profile', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let abortController = new AbortController();
    loadProfile(abortController);
    return () => {
      abortController.abort();
      abortController = new AbortController();
    };
  }, []);

  return (
    <MainContainer>
      <HeaderContainer>
        <HeaderInnerContainer>
          <StyledAvatar src={avatar?.toString()} />
          <TitleContainer>
            <NameChipContainer>
              <Typography variant="h4">
                {teamProfile?.name}
              </Typography>
              <span>
                {teamProfile?.categories?.map((category) => (
                  <ProfileTypeChip key={category} label={category} size="small" />
                ))}
              </span>
            </NameChipContainer>
            <ButtonContainer>
              <NetworkButton
                userId={teamProfile?.contactUserId}
                confirmDialog={confirmDialog}
              />
              <Tooltip
                title={function () {
                  if (chatOpen) return 'Back to profile';
                  if (isNetworkedWithUser) return 'Send message';
                  return "You must be in the user's network to send a message";
                }()}>
                <MessageButtonContainer networked={isNetworkedWithUser}>
                  <Button
                    variant="contained"
                    startIcon={
                      chatOpen ? <BadgeIcon /> : <MessageIcon />
                    }
                    disableElevation
                    disabled={!isNetworkedWithUser}
                    color="primary"
                    onClick={() => setChatOpen(prevValue => !prevValue)}>
                    {chatOpen ? 'Back to profile' : 'Message'}
                  </Button>
                </MessageButtonContainer>
              </Tooltip>
            </ButtonContainer>
          </TitleContainer>
          <DescriptionContainer>
            <DescriptionText variant="subtitle1">
              {teamProfile?.description}
            </DescriptionText>
            <DescriptionDivider />
          </DescriptionContainer>
        </HeaderInnerContainer>
      </HeaderContainer>
      <ChatContainer>
        {loading && <Loader />}
        {chatOpen && forum && <Chat forums={[forum]} />}
      </ChatContainer>
      {(!chatOpen && !loading) && (
        <ViewTeam team={team} />
      )}
    </MainContainer >
  )
}
