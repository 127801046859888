import { TextField, TextFieldVariants } from "@mui/material";
import { styled } from "@mui/material/styles";
import Year, { InvalidYearError } from "common/values/year/year";
import { forwardRef, useState, useEffect } from "react";

const InputField = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginRight: 0,
    width: '100%'
  },
  marginBottom: theme.spacing(1.5),
  minWidth: theme.spacing(40),
  ":first-of-type": {
    marginRight: theme.spacing(1.5)
  }
}));

export interface YearInputError extends String { };

type YearInputProps = {
  className?: string;
  variant?: TextFieldVariants;
  year?: Year;
  notInPast?: boolean;
  notInFuture?: boolean;
  label?: string;
  onChange?: (year?: Year, error?: YearInputError) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
};

const YearInput = forwardRef((props: YearInputProps, ref) => {
  const { className, variant, year, notInPast, notInFuture, label, onChange, onKeyDown } = props;

  const [yearFieldText, setYearFieldText] = useState<string>(year?.valueOf() ?? '');
  const [yearFieldError, setYearFieldError] = useState<YearInputError>('');

  useEffect(() => {
    setYearFieldText(year?.valueOf() ?? '');
  }, [year]);

  function buildYear(inputText?: string): Year | undefined {
    try {
      if (!inputText) throw new InvalidYearError("Year  is required");
      return new Year(inputText, notInPast, notInFuture);
    } catch (error: any) {
      if (error instanceof InvalidYearError) {
        throw error;
      }
    }
  }

  function handleYearChanged(year?: string) {
    let newYear: Year | undefined;
    let newErrors: YearInputError = '';

    try {
      newYear = buildYear(year);
      setYearFieldError('');
    } catch (error: any) {
      newErrors = error.message;
      setYearFieldError(newErrors);
    } finally {
      onChange?.(newYear, newErrors);
    }
  }

  return (
    <InputField
      className={className}
      label={label ?? "Year"}
      onChange={(event) => {
        setYearFieldText(event.target.value);
        handleYearChanged(event.target.value);
      }}
      required
      value={yearFieldText}
      error={!!yearFieldError}
      helperText={yearFieldError}
      variant={variant}
      onKeyDown={onKeyDown}
    />
  );
});

export default YearInput;
