import Guid from "common/values/guid/guid";
import Name from "common/values/name/name";

export default class UserInfo {
    userId: Guid;
    individualId: Guid;
    name: Name;
    avatarUrl?: string;
    entityId: Guid;
    companyId: Guid;
    companyName: string;

    constructor(userId: Guid, individualId: Guid, name: Name, entityId: Guid, companyId: Guid, companyName: string, avatarUrl?: string){
        this.userId = userId;
        this.individualId = individualId;
        this.name = name;
        this.avatarUrl = avatarUrl;
        this.entityId = entityId;
        this.companyId = companyId;
        this.companyName = companyName;
    }

    public clone(): UserInfo {
        return new UserInfo(
            this.userId,
            this.individualId,
            this.name,
            this.entityId,
            this.companyId,
            this.companyName,
            this.avatarUrl
        );
    }

}