import FeeScheduleCategory from "work/values/fee-schedule-category/fee-schedule-category";

export default class WorkFeeScheduleCategoryAPIRequest {
    id?: string;
    name?: string;
    description?: string;
    billingCode?: string;
    feeRate?: number;
    feeCurrency?: string;
    feeUnit?: string;

    constructor(category: FeeScheduleCategory){
        if(!category.name) throw new Error("Fee Schedule Category name is required");
        this.id = category.id?.valueOf();
        this.name = category.name.valueOf();
        this.description = category.description?.valueOf();
        this.billingCode = category.billingCode?.valueOf();
        this.feeRate = category.fee?.rate?.amount;
        this.feeCurrency = category.fee?.rate?.currency.valueOf();
        this.feeUnit = category.fee?.unit ?? undefined;
    }

    public get payload(): object {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            billingCode: this.billingCode,
            feeRate: this.feeRate,
            feeCurrency: this.feeCurrency,
            feeUnit: this.feeUnit,
        };
    }
}
