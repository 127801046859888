import { Autocomplete, Chip, TextField } from "@mui/material";
import { useState } from "react";

type TagInputProps = {
  className?: string;
  tags?: string[];
  viewOnly?: boolean;
  helperText?: string;
  onTagsUpdated?: (tags: string[]) => void;
  onTagRemoved?: (tag: string) => void;
};

export default function TagInput(props: Readonly<TagInputProps>) {
  const { className, tags, viewOnly, helperText, onTagsUpdated, onTagRemoved } = props;

  const [tagInputOpen, setTagInputOpen] = useState<boolean>(false);

  return (
    <Autocomplete
      className={className}
      open={tagInputOpen}
      onOpen={() => !viewOnly && setTagInputOpen(true)}
      onClose={() => setTagInputOpen(false)}
      multiple
      disabled={viewOnly}
      options={[]}
      freeSolo
      filterOptions={(options, params) => params.inputValue !== '' ? [params.inputValue] : []}
      getOptionLabel={(option) => `Add "${option}" as new tag`}
      blurOnSelect
      clearOnBlur
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip 
            title="Click to remove tag"
            label={option} 
            {...getTagProps({ index })} 
            onDelete={(event) => {
              onTagRemoved?.(option);
            }}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            readOnly: viewOnly
          }}
          label="Tags"
          placeholder="Add new tag"
          helperText={helperText}
        />
      )}
      value={tags}
      onChange={(event, value, reason) => {
        onTagsUpdated?.(value);
      }}
    />
  );
}
