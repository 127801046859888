import { Action, MTableActions } from '@material-table/core';
import { ActionsContainer } from 'common/styles';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Button } from '@mui/material';

export type TableActionsComponentProps = {
  isMediumDisplaySize: boolean;
  handleExpandNetwork: () => void;
}

export function TableActionsComponent(props: Readonly<TableActionsComponentProps>): React.ReactElement {
  const { isMediumDisplaySize, handleExpandNetwork } = props;

  return (
    <ActionsContainer>
      {!isMediumDisplaySize && (
        <Button
          color='primary'
          startIcon={<OpenInNewIcon />}
          onClick={() => handleExpandNetwork()}>
          Expand Network
        </Button>
      )}
      <MTableActions {...props} />
    </ActionsContainer>
  );
}

export function getTableActions(isMediumDisplaySize: boolean, handleExpandNetwork: () => void): Action<object>[] {
  return [
    expandNetwork(isMediumDisplaySize, handleExpandNetwork)
  ];
}

function expandNetwork(isMediumDisplaySize: boolean, handleExpandNetwork: () => void): Action<object> {
  return {
    icon: OpenInNewIcon,
    iconProps: { color: 'primary', fontSize: 'large' },
    tooltip: 'Expand Network',
    position: 'toolbar',
    hidden: !isMediumDisplaySize,
    isFreeAction: true,
    onClick: () => handleExpandNetwork()
  };
}
