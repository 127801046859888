import React from 'react';
import { useNavigate } from 'react-router-dom';

import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SettingsIcon from '@mui/icons-material/Settings';
import { 
  Avatar, Box, Divider, IconButton, ListItemIcon, ListItemText, 
  MenuItem, MenuList, Popover, Typography 
} from '@mui/material';
import { styled } from "@mui/material/styles";

import { AccountType } from 'common/values/account-type/account-type';
import AccountSwitcher from 'common/values/account-type/view/account-switcher';

import { useSession } from 'users/session/session-context';
import UserEntityInfo from 'users/values/user-entity-info/user-entity-info';

const MenuContainer = styled('div')(({ theme }) => ({
  minWidth: theme.spacing(40)
}));
const UserAvatar = styled(Avatar)<{ accounttype: AccountType }>(({ theme, accounttype }) => ({
  backgroundColor: (accounttype === AccountType.Client) ? '#E19941' : '#404558',
  color: '#FFF',
  fontSize: '1em',
  height: theme.spacing(6),
  width: theme.spacing(6),
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.66em',
    height: theme.spacing(4),
    width: theme.spacing(4),
  }
}));
const UserInfoContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 2)
}));
const OptionsMenu = styled(MenuList)(({ theme }) => ({
  padding: theme.spacing(0, 0, 1, 0)
}));

type UserMenuProps = {}

export default function UserMenu(props: Readonly<UserMenuProps>) {
  const [avatarMenuAnchor, setAvatarMenuAnchor] = React.useState<Element>();

  const navigate = useNavigate();
  const session = useSession();

  function handleLogoutClick() {
    setAvatarMenuAnchor(undefined);
    session.logout();
    navigate('/login');
  }

  return (
    <React.Fragment>
      <IconButton
        aria-haspopup="true"
        onClick={(event) => setAvatarMenuAnchor(event.currentTarget)}>
        <UserAvatar accounttype={session.accountType}>
          {session.user?.initials}
        </UserAvatar>
      </IconButton>
      <Popover
        anchorEl={avatarMenuAnchor}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(avatarMenuAnchor)}
        onClose={() => setAvatarMenuAnchor(undefined)}>
        <MenuContainer>
          {session.canSwitchContext && (
            <>
              <AccountSwitcher />
              <Divider />
            </>
          )}
          <UserInfoContainer>
            <Typography variant="h6">{session.user?.fullName}</Typography>
            {session.entities?.map((entity: UserEntityInfo) => (
              <Typography key={entity.entityId.toString()} variant="body2">{entity.entityName}</Typography>
            ))}
          </UserInfoContainer>
          <OptionsMenu>
            <MenuItem onClick={() => navigate('/marketplace/profiles')}>
              <ListItemIcon>
                <AccountBoxIcon />
              </ListItemIcon>
              <ListItemText primary="My Profiles" />
            </MenuItem>
            <MenuItem onClick={() => { }}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </MenuItem>
            <MenuItem onClick={handleLogoutClick}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </MenuItem>
          </OptionsMenu>
        </MenuContainer>
      </Popover>
    </React.Fragment >
  );
}
