import Guid from "common/values/guid/guid";
import Notification from "notifications/entities/notification/notification";
import moment from "moment";
import NotificationTopic from "notifications/values/notification-topic/notification-topic";

export default class NotificationAPIResponse {
  id?: string;
  recipientUserId?: string;
  message?: string;
  topicCategory?: string;
  topicId?: string | null;
  seenAt?: string | null;
  actionRequired?: string;
  isSeen?: boolean;
  isCanceled?: boolean;
  createdDate?: string;

  constructor(data?: Partial<NotificationAPIResponse>) {
    Object.assign(this, data);
  }

  deserialize(): Notification {
    if (this.id === undefined) throw new Error("Message id not returned.");
    if (this.recipientUserId === undefined) throw new Error("Message recipientUserId not returned.");
    if (this.message === undefined) throw new Error("Message message not returned.");
    if (this.topicCategory === undefined) throw new Error("Message topicCategory not returned.");
    if (this.actionRequired === undefined) throw new Error("Message actionRequired not returned.");
    if (this.isSeen === undefined) throw new Error("Message isSeen not returned.");
    if (this.isCanceled === undefined) throw new Error("Message isCanceled not returned.");
    if (this.createdDate === undefined) throw new Error("Message createdDate not returned.");

    return new Notification(
      new Guid(this.id),
      new Guid(this.recipientUserId),
      this.message,
      new NotificationTopic(this.topicCategory, this.topicId ? new Guid(this.topicId) : null),
      this.actionRequired,
      this.seenAt ? moment(this.seenAt) : null,
      moment(this.createdDate),
      this.isSeen,
      this.isCanceled
    )
  }
}
