import Document from "documents/entities/document/document";
import _ from "lodash";

export default class UpdateDocumentAPIRequest {
  payload: Array<{ op: string, path: string, value: any }> = [];

  constructor(originalDocument: Document, updatedDocument: Document) {
    if (originalDocument.id.valueOf() !== updatedDocument.id.valueOf()) {
      throw new Error("Document Id cannot be changed");
    }
    if (originalDocument.creatorId?.valueOf() !== updatedDocument.creatorId?.valueOf()) {
      throw new Error("Creator Id cannot be changed");
    }
    if (originalDocument.fileType.valueOf() !== updatedDocument.fileType.valueOf()) {
      throw new Error("Document Type cannot be changed");
    }
    if (originalDocument.created?.valueOf() !== updatedDocument.created?.valueOf()) {
      throw new Error("Created Date cannot be changed");
    }


    if (updatedDocument.name?.valueOf() !== originalDocument.name?.valueOf()) {
      this.payload = [{ op: "replace", path: "/name", value: updatedDocument.name?.valueOf() ?? null }];
    }
    if (!_.isEqual(updatedDocument.readAccess, originalDocument.readAccess)) {
      this.payload.push({ op: "replace", path: "/readAccess", value: updatedDocument.readAccess });
    }
    if (!_.isEqual(updatedDocument.writeAccess, originalDocument.writeAccess)) {
      this.payload.push({ op: "replace", path: "/writeAccess", value: updatedDocument.writeAccess });
    }
    if (!_.isEqual(updatedDocument.topics, originalDocument.topics)) {
      this.payload.push({ op: "replace", path: "/topics", value: updatedDocument.topics });
    }
  }
}
