import AppsIcon from '@mui/icons-material/Apps';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import * as Constants from 'common/helpers/constants';
import { UseSearchParams } from 'common/hooks/use-search-params';
import React from 'react';
import { SetURLSearchParams } from 'react-router-dom';

const CategoryDropdown = styled(Button)(({ theme }) => ({
  backgroundColor: '#E19941',
  borderBottomRightRadius: 0,
  borderTopRightRadius: 0,
  color: '#FFF',
  height: '56px',
  fontSize: '0.9em',
  minWidth: '12rem',
  '&:hover': {
    backgroundColor: '#E19941'
  }
}));
const CategoryDropdownSmall = styled(Button)(({ theme }) => ({
  backgroundColor: '#E19941',
  color: '#FFF',
  height: '56px',
  marginRight: '1rem',
  '&:hover': {
    backgroundColor: '#E19941'
  },
  padding: 0
}));
const SearchBox = styled(TextField)(({ theme }) => ({
  boxSizing: 'border-box',
  width: '100%',
  '& label.Mui-focused': {
    color: '#000'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#E19941',
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0
    },
    '&:hover fieldset': {
      borderColor: '#E19941',
      borderWidth: '1px',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#E19941',
      borderWidth: '1px',
      boxShadow: 'inset -1px 0px 0px 1px #E19941',
      outline: 0
    }
  }
}));
const CategoryMenu = styled(Menu)(({ theme }) => ({
  zIndex: 10
}));

type SearchBarProps = {
  embedded?: boolean;
  searchTerm: string;
  onCategoryChanged: (category: any) => void;
  onSearch: (searchTerm: string) => void;
  onSearchCleared: (searchTerm: string) => void;
}

export default function SearchBar(props: Readonly<SearchBarProps>) {
  const { embedded, onCategoryChanged, onSearch, onSearchCleared } = props;

  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const searchParams = React.useRef<URLSearchParams>();
  const setSearchParams = React.useRef<SetURLSearchParams>();
  const categories = Constants.AllCategories.concat(Constants.MarketplaceCategories, Constants.PostCategories);

  const [categoryMenuOpen, setCategoryMenuOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState<string>(props.searchTerm);
  const [selectedCategory, setSelectedCategory] = React.useState(categories[0]);

  const isMediumDisplaySize = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  React.useEffect(() => {
    initFromQueryString();
    setSearchTerm(props.searchTerm);
  }, [props.searchTerm]);

  function initFromQueryString() {
    const params = searchParams.current;

    if (!params) return;

    if (params.has('query')) {
      setSearchTerm(params.get('query') ?? '');
    }

    const selectedCategory = categories.find(c => c.id === params.get('category'));

    if (!selectedCategory) return;

    setSelectedCategory(selectedCategory);
    onCategoryChanged(selectedCategory);
  }

  function handleMenuClose(event: React.MouseEvent) {
    if (anchorRef.current?.contains(event.target as Node)) return;
    setCategoryMenuOpen(false);
  }

  function handleCategorySelected(event: React.MouseEvent, id: string) {
    handleMenuClose(event);
    const selectedCategory = categories.find(category => category.id === id);
    if (!selectedCategory) return;
    setSelectedCategory(selectedCategory);
    setSearchTerm('');
    onCategoryChanged(selectedCategory);
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center">
      <Grid item>
        {!embedded && (
          <>
            <UseSearchParams 
              onSearchParams={(params, setParams) => {
                searchParams.current = params;
                setSearchParams.current = setParams;
              }} 
            />
            {!isMediumDisplaySize && (
              <CategoryDropdown
                ref={anchorRef}
                disableElevation
                variant="contained"
                endIcon={<ArrowDropDownIcon />}
                onClick={() => setCategoryMenuOpen(!categoryMenuOpen)}>
                {selectedCategory ? selectedCategory.name : 'All Categories'}
              </CategoryDropdown>
            )}
            {isMediumDisplaySize && (
              <CategoryDropdownSmall
                ref={anchorRef}
                disableElevation
                variant="contained"
                onClick={() => setCategoryMenuOpen(!categoryMenuOpen)}>
                <AppsIcon />
              </CategoryDropdownSmall>
            )}
          </>
        )}
      </Grid>
      <Grid item xs>
        <SearchBox
          variant="outlined"
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') onSearch(searchTerm);
            if (event.key === 'Escape') setSearchTerm('');
          }}
          InputProps={{
            placeholder: (
              selectedCategory ?
                selectedCategory.description :
                'Search Entire Marketplace'
            ),
            sx: {
              backgroundColor: '#fff',
              '& :placeholder-shown': {
                textOverflow: 'ellipsis'
              }
            },
            startAdornment: (
              <IconButton
                onClick={() => onSearch(searchTerm)}
                disabled={searchTerm === ''}
                size="large">
                <SearchIcon />
              </IconButton>
            ),
            endAdornment: (
              <IconButton
                style={{ display: searchTerm === '' ? 'none' : 'flex' }}
                onClick={() => {
                  setSearchTerm('');
                  onSearchCleared('');
                }}
                size="large">
                <CloseIcon />
              </IconButton>
            )
          }} />
        <CategoryMenu
          keepMounted
          open={categoryMenuOpen}
          onClose={handleMenuClose}
          anchorEl={anchorRef.current}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          PaperProps={{
            style: {
              maxHeight: 48 * 10
            }
          }}>
          {categories.map(category => (
            <MenuItem
              key={category.id}
              onClick={(event) => handleCategorySelected(event, category.id)}>
              {category.name}
            </MenuItem>
          ))}
        </CategoryMenu>
      </Grid>
    </Grid>
  );
}
