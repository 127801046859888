import UserEntityInfo from "users/values/user-entity-info/user-entity-info";
import UserOpenEntityAgreement from "legal-entities/entities/entity-agreement/user-open-entity-agreement";
import UserNetworkConnectionProfile from "users/entities/user-network-connection/user-network-connection-profile";
import UserBookmarkInfo from "users/values/user-bookmark-info/user-bookmark-info";
import EmailAddress from "common/values/email-address/email-address";
import Guid from "common/values/guid/guid";
import Name from "common/values/name/name";
import NetworkInvitationInfo from "users/entities/user-network-invitation/network-invitation-info";


export interface IUserInformation{
  id?: Guid;
  isVendor: boolean;
  isClient: boolean;
  isVendorRepresentative: boolean;
  isClientRepresentative: boolean;
  viewingAsVendor?: boolean;
  emailAddress: EmailAddress;
  fullName: Name;
  timestamp: Date | string;
  legalEntities: UserEntityInfo[];
  openEntityAgreements: UserOpenEntityAgreement[];
  hasOpenEntityAgreements: boolean;
  individualId?: Guid;
  networkConnections: UserNetworkConnectionProfile[];
  networkInvitations: NetworkInvitationInfo[];
  bookmarkedIndividuals: UserBookmarkInfo[];
  bookmarkedCompanies: UserBookmarkInfo[];
  bookmarkedTeams: UserBookmarkInfo[];
}

export default class UserInformation {
  public readonly id: Guid;
  public readonly isVendor: boolean;
  public readonly isClient: boolean;
  public readonly isVendorRepresentative: boolean;
  public readonly isClientRepresentative: boolean;
  public readonly viewingAsVendor: boolean;
  public readonly emailAddress: EmailAddress;
  public readonly fullName: Name;
  public readonly timestamp: Date | string;
  public readonly legalEntities: UserEntityInfo[];
  public readonly openEntityAgreements: UserOpenEntityAgreement[];
  public readonly hasOpenEntityAgreements: boolean;
  public readonly individualId?: Guid;
  public readonly networkConnections: UserNetworkConnectionProfile[];
  public readonly networkInvitations: NetworkInvitationInfo[];
  public readonly bookmarkedIndividuals: UserBookmarkInfo[];
  public readonly bookmarkedCompanies: UserBookmarkInfo[];
  public readonly bookmarkedTeams: UserBookmarkInfo[];

  constructor(userInfo: IUserInformation) {
    if (!userInfo.id) 
      throw new InvalidUserInfoError({ id: "Id is required." });

    this.id = userInfo.id;
    this.isVendor = userInfo.isVendor;
    this.isClient = userInfo.isClient;
    this.isVendorRepresentative = userInfo.isVendorRepresentative;
    this.isClientRepresentative = userInfo.isClientRepresentative;
    this.viewingAsVendor = userInfo.viewingAsVendor ?? false;
    this.emailAddress = userInfo.emailAddress;
    this.fullName = userInfo.fullName;
    this.timestamp = new Date(userInfo.timestamp);
    this.legalEntities = userInfo.legalEntities;
    this.openEntityAgreements = userInfo.openEntityAgreements;
    this.hasOpenEntityAgreements = userInfo.hasOpenEntityAgreements;
    this.individualId = userInfo.individualId;
    this.networkConnections = userInfo.networkConnections;
    this.networkInvitations = userInfo.networkInvitations;
    this.bookmarkedIndividuals = userInfo.bookmarkedIndividuals;
    this.bookmarkedCompanies = userInfo.bookmarkedCompanies;
    this.bookmarkedTeams = userInfo.bookmarkedTeams;
  }
}

export class InvalidUserInfoError extends Error {
  issues: any;

  constructor(issues: any) {
    super(`Invalid user info.`);
    this.issues = issues;
  }
}
