import { Query, QueryResult } from "@material-table/core";
import CompanyAPIService from "marketplace/entities/company/api/company-api-service";
import IndividualAPIService from "marketplace/entities/individual/api/individual-api-service";
import MarketplaceTeamAPIService from "marketplace/entities/marketplace-team/api/marketplace-team-api-service";
import { enqueueSnackbar } from "notistack";
import { PageTab } from "../tabs";
import { ProfileTableData } from "./columns";
import Session from "users/session/session";

const emptyResult: QueryResult<ProfileTableData> = {
  data: [],
  page: 0,
  totalCount: 0
};

export default async function loadData(
  query: Query<ProfileTableData>,
  activeTab: PageTab,
  setLoading: (isLoading: boolean) => void,
  setCompanyProfiles: (profiles: ProfileTableData[]) => void,
  setIndividualProfiles: (profiles: ProfileTableData[]) => void,
  fetchAvatars: (data: ProfileTableData[]) => Promise<void>,
  session: Readonly<Session>
): Promise<QueryResult<ProfileTableData>> {
  setLoading(true);

  try {
    // Only call endpoint associated with the current active tab
    let vendors: ProfileTableData[] | null = null;
    const abortController = new AbortController();
    if (activeTab === PageTab.Company) {
      const marketplaceCompanyService = new CompanyAPIService(session);
      vendors = await marketplaceCompanyService.getCompanyVendors(abortController) as ProfileTableData[];
      setCompanyProfiles(vendors);
    } else if (activeTab === PageTab.Teams) {
      const marketplaceTeamService = new MarketplaceTeamAPIService(session);
      vendors = await marketplaceTeamService.getTeamVendors(abortController) as ProfileTableData[];
      // Also load individual profiles 
      // (team creation is disabled when there are no individual profiles)
      const marketplaceIndividualService = new IndividualAPIService(session);
      const individualResponse = await marketplaceIndividualService.getIndividualVendors(abortController) as ProfileTableData[];
      setIndividualProfiles(individualResponse);
    } else if (activeTab === PageTab.Individual) {
      const marketplaceIndividualService = new IndividualAPIService(session);
      vendors = await marketplaceIndividualService.getIndividualVendors(abortController) as ProfileTableData[];
      setIndividualProfiles(vendors);
    }

    if (!vendors || vendors.length < 1) {
      return emptyResult;
    }

    await fetchAvatars(vendors);

    return {
      data: vendors,
      page: 0,
      totalCount: vendors.length
    };
  } catch (error: any) {
    console.error(error);
    enqueueSnackbar("Couldn't get profiles. Please try again", { variant: 'error' });
    return emptyResult;
  } finally {
    setLoading(false);
  }
}
