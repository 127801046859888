import Guid from "common/values/guid/guid";
import DocumentTopic from "documents/values/document-topic";

export default class DocumentFormApiRequest {
    public formData: FormData;

    constructor(file: File, name?: string | null, topics?: DocumentTopic[], templateIds?: Guid[]) {
        this.formData = new FormData();
        this.formData.append('File', file);
        if(name)
            this.formData.append('FileName', name);
        if(topics){
            topics.forEach(topic => {
                this.formData.append('Topics', topic.toJSON());
            });
        }
        if(templateIds){
            templateIds.forEach(templateId => {
                this.formData.append('TemplateIds', templateId.toString());
            });
        }
    }
}