import Money from "common/values/money/money";

export default class Fee { 
    rate?: Money;
    unit?: string;
    isFixed: boolean;

    constructor(rate?: Money, unit?: string, isFixed?: boolean) {
        this.rate = rate;
        this.unit = unit;
        this.isFixed = isFixed || false;
    }

    static areSame(original: Fee, updated: Fee): boolean {
        if(!original.rate?.isEqualTo(updated.rate)) {
            return false;
        }
        if (original.unit !== updated.unit) {
            return false;
        }
        if (original.isFixed !== updated.isFixed) {
            return false;
        }
        return true;
    }
}