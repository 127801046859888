import _ from 'lodash';

import React, { useEffect } from 'react';
import { useLoaderData, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import MaterialTable, { MaterialTableProps } from '@material-table/core';
import AssignmentIcon from '@mui/icons-material/Assignment';
import {
  Tab,
  Tabs, Theme, useMediaQuery
} from '@mui/material';

import { PageContainer, TableTheme } from 'common/styles/table';

import { getTableColumns } from 'app/routes/work/table/columns';
import loadData from 'app/routes/work/table/data-loader';
import { NoRowsPlaceholder } from 'app/routes/work/table/no-rows-placeholder';
import TableToolbarComponent from 'app/routes/work/table/toolbar';
import { PageTab } from 'app/routes/work/tabs';
import TableContainerComponent from 'common/components/table/container';
import TableTitle from 'common/components/table/title';
import { useSession } from 'users/session/session-context';
import WorkAgreement from 'work/entities/work-agreement/work-agreement';

type WorkProps = {};

export default function Work(_props: Readonly<WorkProps>) {
  const tableRef = React.useRef<MaterialTable<WorkAgreement> & MaterialTableProps<WorkAgreement>>();

  const [loading, setLoading] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(PageTab.Active);
  const [tableBodyHeight, setTableBodyHeight] = React.useState(0);
  const tabFromUrl = useLoaderData() as string;
  const [searchParams] = useSearchParams();
  const session = useSession();
  const navigate = useNavigate();
  const isMediumDisplaySize = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const tabs = (
    <Tabs
      value={activeTab}
      indicatorColor="primary"
      textColor="primary"
      onChange={(_event, newValue) => {
        navigate(`/work/${newValue}`);
      }}>
      <Tab value={PageTab.Active} label="Active" />
      <Tab value={PageTab.Archived} label="Archived" />
    </Tabs>
  );

  // Handle account type change
  useEffect(() => {
    tableRef?.current?.onQueryChange({});
  }, [session.accountType]);

  // Handle URL changes
  useEffect(() => {
    setActiveTab(tabFromUrl ? tabFromUrl as PageTab : PageTab.Active);
    tableRef?.current?.onQueryChange({});
  }, [searchParams, tabFromUrl, tableRef]);

  // Handle component mount
  useEffect(() => {
    function computeTableHeight() {
      const tableHead = document.querySelector('thead.MuiTableHead-root');
      const tableFoot = document.querySelector('tfoot.MuiTableFooter-root');
      const siteFooter = document.querySelector('footer#siteFooter');

      const tableOffsetTop = tableHead?.getBoundingClientRect().top;
      const tableFootHeight = tableFoot?.getBoundingClientRect().height;
      const siteFooterHeight = siteFooter?.getBoundingClientRect().height;

      if (!tableOffsetTop || !tableFootHeight || !siteFooterHeight) return;

      setTableBodyHeight(Math.floor(window.innerHeight - tableOffsetTop - tableFootHeight - siteFooterHeight));
    }

    const debouncedResize = _.debounce(() => computeTableHeight(), 250);
    debouncedResize();
    window.addEventListener('resize', debouncedResize);

    return () => {
      window.removeEventListener('resize', debouncedResize);
    }
  }, []);

  return (
    <PageContainer>
      <MaterialTable
        key={activeTab}
        tableRef={tableRef}
        title={<TableTitle title="Work" icon={<AssignmentIcon />} />}
        columns={getTableColumns(activeTab, tableRef, session?.user?.id)}
        data={(query) => loadData(query, activeTab, session, setLoading)}
        isLoading={loading}
        options={{
          debounceInterval: 250,
          emptyRowsWhenPaging: false,
          filtering: true,
          filterRowStyle: TableTheme.filterRow,
          grouping: true,
          minBodyHeight: tableBodyHeight,
          maxBodyHeight: tableBodyHeight,
          pageSize: 10,
          pageSizeOptions: [10, 25, 50],
          paginationType: isMediumDisplaySize ? 'normal' : 'stepped',
          showFirstLastPageButtons: !isMediumDisplaySize,
          selection: activeTab === PageTab.Active,
        }}
        components={{
          Container: props => TableContainerComponent({ ...props }),
          Toolbar: props => TableToolbarComponent({
            tableRef,
            activeTab,
            tabs,
            isMediumDisplaySize,
            ...props
          })
        }}
        localization={{
          body: {
            emptyDataSourceMessage: <NoRowsPlaceholder tableBodyHeight={tableBodyHeight} />
          }
        }}
      />
    </PageContainer>
  );
}
