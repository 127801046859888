export default class MarketplaceSearchAPIRequest {
  query?: string;
  category?: string;
  sortBy?: string;
  name?: string;
  yearEstablished?: number | null;
  numberOfEmployees?: number | null;
  contactPersonUserId?: string;
  description?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  website?: string;
  firstName?: string;
  lastName?: string;
  school?: string;
  professionalRegistration?: string;
  technicalBackground?: string;
  numberOfApplicationsDraftedProsecuted?: number | null;
  numberOfAllowedApplications?: number | null;
  barAdmissionYear?: number | null;
  isOnCall?: boolean;
  insuredAmount?: number | null;
  billingRate?: string;
  potentialDiversityCandidate?: boolean;
  collaborates?: boolean;
  additionalInfo?: string;
  fluentLanguages?: string[];
  technicalLanguages?: string[];
  regionsRegistered?: string[];
  regionsLicensed?: string[];
  limitToVendorReps?: boolean;
  from: number = 0;
  size: number = 10;
}