import Guid from "common/values/guid/guid";
import { Change, diffLines, diffWords } from "diff";
import _ from "lodash";
import TextChange, {
  TextChangeCollection,
} from "work/entities/proposal/redlining/_diff/text-change";
import {
  RedlineAction,
  RedlineChange,
} from "work/entities/proposal/redlining/redline-change";
import {
  HumanReadableProposalFieldName,
  ProposalFieldName,
} from "work/values/constants";

export interface IRedlineableField {
  isEqualTo(other: any): boolean;
  fromObject(obj: object | string | boolean | number): IRedlineableField | null;
  toJSON(): object | string | boolean | number;
  clone(): IRedlineableField;
  replacesId?: Guid;
}

export interface IComparableFieldEntryWithId extends IRedlineableField {
  id: Guid;
}

export type RedlineSubField = {
  textChangeCollection: TextChangeCollection;
  diffWords: boolean;
};

export default class FieldRedline<T extends IRedlineableField> {
  private _field: ProposalFieldName;
  private _fieldId: Guid | null;
  private _originalEntry: T | null;
  private _revisedEntry: T | null;
  private _currentEntry: T | null | undefined;
  private _sessionHistory: RedlineChange[] = [];
  private _diffWords: boolean;
  private _textChangeCollection: TextChangeCollection =
    new TextChangeCollection();
  private _subFieldTextChangeCollection: Map<keyof T, RedlineSubField> =
    new Map();

  constructor(
    field: ProposalFieldName,
    fieldId: Guid | null,
    originalEntry: T | null,
    revisedEntry: T | null,
    changes?: TextChangeCollection,
    diffWords: boolean = false
  ) {
    this._field = field;
    this._fieldId = fieldId;
    this._originalEntry = originalEntry;
    this._revisedEntry = revisedEntry;
    this._diffWords = diffWords;
    if (changes) {
      this._textChangeCollection = changes;
    } else {
      this.updateDiff();
    }
    this.resetCurrentEntry();
  }
  get field(): ProposalFieldName {
    return this._field;
  }
  get fieldId(): Guid | null {
    return this._fieldId;
  }

  get label(): string {
    return HumanReadableProposalFieldName[this._field];
  }

  get originalEntry(): T | null {
    return this._originalEntry;
  }

  get revisedEntry(): T | null {
    return this._revisedEntry;
  }

  get currentEntry(): T | null | undefined {
    return this._currentEntry;
  }

  get changes(): TextChange[] {
    return this._textChangeCollection.changes;
  }
  set changes(newChanges: TextChange[]) {
    this._textChangeCollection.changes = newChanges;
  }

  get textChangeCollection(): TextChangeCollection {
    return this._textChangeCollection;
  }
  set textChangeCollection(newCollection: TextChangeCollection) {
    this._textChangeCollection = newCollection;
  }

  get sessionHistory(): RedlineChange[] {
    return this._sessionHistory;
  }

  public get isNewlyAdded(): boolean {
    return Boolean(
      this._currentEntry && !this.wasRedlined && !this._revisedEntry
    );
  }

  get isNewlyRevised(): boolean {
    return Boolean(
      this._currentEntry && !this.wasRedlined && this._revisedEntry
    );
  }

  get wasRedlined(): boolean {
    if (this._originalEntry === null && this._revisedEntry === null) {
      return false;
    } else if (this._revisedEntry === null) {
      return true;
    } else {
      return !this.revisedEntry?.isEqualTo(this.originalEntry);
    }
  }

  get isResolved(): boolean {
    return this._currentEntry !== undefined;
  }

  get isAdded(): boolean {
    return (
      (this.originalEntry === null && this.revisedEntry !== null) ||
      (this.revisedEntry === null && this.currentEntry !== null)
    );
  }

  get isRemoved(): boolean {
    return (
      (this.originalEntry !== null && this.revisedEntry === null) ||
      (this.revisedEntry !== null && this.currentEntry === null)
    );
  }

  get isNewlyRemoved(): boolean {
    return this.revisedEntry !== null && this.currentEntry === null;
  }

  get isRejected(): boolean {
    return this.isResolved && this.wasRedlined && !this.revisionAccepted;
  }

  get revisionAccepted(): boolean {
    if (this._currentEntry === null && this._revisedEntry === null) {
      return true;
    }
    return this._currentEntry?.isEqualTo(this._revisedEntry) ?? false;
  }

  get revisionRejected(): boolean {
    return this.isResolved && this.wasRedlined && !this.revisionAccepted;
  }

  get wasModified(): boolean {
    return this._currentEntry !== undefined && !this.revisionAccepted;
  }

  get canBeUndone(): boolean {
    return (this.wasRedlined && this.isResolved) || this.wasModified;
  }

  public registerSubField(fieldName: keyof T, diffWords: boolean = false) {
    if (this._subFieldTextChangeCollection.has(fieldName)) return;
    this._subFieldTextChangeCollection.set(fieldName, {
      textChangeCollection: new TextChangeCollection(),
      diffWords,
    });
  }
  public getSubFieldTextChanges(fieldName: keyof T): TextChangeCollection {
    const subFieldChanges =
      this._subFieldTextChangeCollection.get(fieldName)?.textChangeCollection;
    if (!subFieldChanges) throw new Error("Subfield not registered");
    return subFieldChanges;
  }

  public accept(): FieldRedline<T> {
    const newRedline = this.clone();
    newRedline._currentEntry = newRedline._revisedEntry;
    newRedline.updateDiff();
    const redlineChange = RedlineChange.fromObject({
      field: this._field,
      fieldId: this._fieldId,
      action: RedlineAction.Accept,
      isResolved: true,
      textChanges: this._textChangeCollection.changes,
    });
    newRedline._sessionHistory.push(redlineChange);
    return newRedline;
  }

  public reject(): FieldRedline<T> {
    const newRedline = this.clone();
    newRedline._currentEntry = newRedline._originalEntry;
    newRedline.updateDiff();
    const redlineChange = RedlineChange.fromObject({
      field: this._field,
      fieldId: this._fieldId,
      action: RedlineAction.Reject,
      isResolved: true,
      textChanges: this._textChangeCollection.changes,
    });
    newRedline._sessionHistory.push(redlineChange);
    return newRedline;
  }

  public edit(newEntry: T | null): FieldRedline<T> {
    const newRedline = this.clone();
    newRedline._currentEntry = newEntry;
    newRedline.updateDiff();
    const redlineChange = RedlineChange.fromObject({
      field: this._field,
      fieldId: this._fieldId,
      action: RedlineAction.Edit,
      isResolved: true,
      textChanges: this._textChangeCollection.changes,
    });
    newRedline._sessionHistory.push(redlineChange);
    return newRedline;
  }

  public remove(): FieldRedline<T> {
    const newRedline = this.clone();
    newRedline._currentEntry = null;
    newRedline.updateDiff();
    const redlineChange = RedlineChange.fromObject({
      field: this._field,
      fieldId: this._fieldId,
      action: RedlineAction.Remove,
      isResolved: true,
      textChanges: this._textChangeCollection.changes,
    });
    newRedline._sessionHistory.push(redlineChange);
    return newRedline;
  }

  public add(newEntry: T): FieldRedline<T> {
    const newRedline = this.clone();
    newRedline._currentEntry = newEntry;
    newRedline.updateDiff();
    const redlineChange = RedlineChange.fromObject({
      field: this._field,
      fieldId: this._fieldId,
      action: RedlineAction.Add,
      isResolved: true,
      textChanges: this._textChangeCollection.changes,
    });
    newRedline._sessionHistory.push(redlineChange);
    return newRedline;
  }

  public undo(): FieldRedline<T> {
    let action: RedlineAction;
    if (this.wasRedlined && this.revisionAccepted) {
      action = RedlineAction.UndoAccept;
    } else if (this.wasRedlined && this.revisionRejected) {
      action = RedlineAction.UndoReject;
    } else {
      action = RedlineAction.UndoEdit;
    }

    const newRedline = this.clone();
    newRedline.resetCurrentEntry();
    newRedline.updateDiff();

    const redlineChange = RedlineChange.fromObject({
      field: this._field,
      fieldId: this._fieldId,
      action: action,
      isResolved: false,
      textChanges: this._textChangeCollection.changes,
    });
    newRedline._sessionHistory.push(redlineChange);
    return newRedline;
  }
  public clearSessionHistory(): FieldRedline<T> {
    const newRedline = this.clone();
    newRedline._sessionHistory = [];
    return newRedline;
  }

  public clone(): FieldRedline<T> {
    const clonedCurrentEntry =
      this._currentEntry?.clone() ?? this._currentEntry;
    const clonedRevisedEntry =
      this._revisedEntry?.clone() ?? this._revisedEntry;
    const clonedOriginalEntry =
      this._originalEntry?.clone() ?? this._originalEntry;
    const clonedTextChangeCollection = this._textChangeCollection.clone();
    const clonedFieldRedline = Object.create(this);
    return Object.assign(clonedFieldRedline, {
      _currentEntry: clonedCurrentEntry,
      _revisedEntry: clonedRevisedEntry,
      _originalEntry: clonedOriginalEntry,
      _textChangeCollection: clonedTextChangeCollection,
    });
  }

  public toJSON() {
    return {
      _field: this._field,
      _fieldId: this._fieldId ? this._fieldId.toJSON() : this._fieldId,
      _originalEntry: this._originalEntry
        ? this._originalEntry.toJSON()
        : this._originalEntry,
      _revisedEntry: this._revisedEntry
        ? this._revisedEntry.toJSON()
        : this._revisedEntry,
      _currentEntry: this._currentEntry
        ? this._currentEntry.toJSON()
        : this._currentEntry,
      _sessionHistory: this._sessionHistory.map((change) => change.toJSON()),
      _diffWords: this._diffWords,
      _textChangeCollection: this._textChangeCollection.toJSON(),
    };
  }

  public static fromObject<T extends IRedlineableField>(
    prototype: T,
    obj: any
  ): FieldRedline<T> {
    const field = obj._field;
    const fieldId = Guid.fromObject(obj._fieldId) ?? null;
    const originalEntry = prototype.fromObject(obj._originalEntry) as T | null;
    const revisedEntry = prototype.fromObject(obj._revisedEntry) as T | null;
    const currentEntry =
      obj._currentEntry === null || obj._currentEntry === undefined
        ? obj._currentEntry
        : (prototype.fromObject(obj._currentEntry) as T | null);
    const sessionHistory = new Array<RedlineChange>();
    if (obj._sessionHistory) {
      obj._sessionHistory.forEach((change: any) => {
        sessionHistory.push(RedlineChange.fromObject(change));
      });
    }

    const changes = obj._textChangeCollection?.changes
      ? TextChangeCollection.fromObjects(obj._textChangeCollection.changes)
      : undefined;
    const newFieldRedline = new FieldRedline<T>(
      field,
      fieldId,
      originalEntry,
      revisedEntry,
      changes,
      obj._diffWords
    );
    newFieldRedline._currentEntry = currentEntry;
    newFieldRedline._sessionHistory = sessionHistory;
    return newFieldRedline;
  }

  private resetCurrentEntry() {
    if (this._originalEntry === null && this.revisedEntry === null) {
      this._currentEntry = null;
    } else if (
      this._revisedEntry &&
      this._originalEntry &&
      this._revisedEntry.isEqualTo(this._originalEntry)
    ) {
      this._currentEntry = this._originalEntry;
    } else {
      this._currentEntry = undefined;
    }
  }

  private updateDiff() {
    this._textChangeCollection = new TextChangeCollection();
    if(this._originalEntry === null && this._revisedEntry === null && !this._currentEntry) {
      return;
    }

    let previous: string;
    let next: string;
    if (this._currentEntry !== undefined) {
      previous = this._revisedEntry?.toString() ?? "";
      next = this._currentEntry?.toString() ?? "";
    } else {
      previous = this._originalEntry?.toString() ?? "";
      next = this._revisedEntry?.toString() ?? "";
    }

    const diff = this._diffWords
      ? diffWords(previous, next)
      : diffLines(previous, next);

    diff.forEach((change: Change) => {
      this._textChangeCollection.changes.push(
        new TextChange(this._textChangeCollection, change)
      );
    });

    this.updateSubFieldDiffs();
  }

  private updateSubFieldDiffs() {
    for (const [fieldName, subFieldChanges] of this
      ._subFieldTextChangeCollection) {
      const previous = this._revisedEntry?.[fieldName]?.toString() ?? "";
      const next = this._currentEntry?.[fieldName]?.toString() ?? "";
      const diff = subFieldChanges.diffWords
        ? diffWords(previous, next)
        : diffLines(previous, next);
      subFieldChanges.textChangeCollection = new TextChangeCollection();
      diff.forEach((change: Change) => {
        subFieldChanges.textChangeCollection.changes.push(
          new TextChange(subFieldChanges.textChangeCollection, change)
        );
      });
    }
  }
}

export class FieldRedlineArray<T extends IComparableFieldEntryWithId> {
  private _field: ProposalFieldName;
  private _sessionHistory: RedlineChange[] = [];
  private _subFieldsToRegister: { fieldName: keyof T; diffWords: boolean }[] =
    [];
  private _lastEntryUpdated: Guid | undefined;
  protected _redlines: FieldRedline<T>[] = [];

  constructor(
    proposalField: ProposalFieldName,
    originalEntries: T[],
    revisedEntries: T[]
  ) {
    this._field = proposalField;

    originalEntries.forEach((originalEntry) => {
      let redlineField: FieldRedline<T> | undefined;

      const modifiedEntry = revisedEntries.find((revisedEntry: T) =>
        revisedEntry.replacesId?.isEqualTo(originalEntry.id)
      );
      if (modifiedEntry) {
        redlineField = new FieldRedline<T>(
          proposalField,
          originalEntry.id,
          originalEntry,
          modifiedEntry
        );
      }

      const remainingEntry = revisedEntries.find((revisedEntry: T) =>
        revisedEntry.id?.isEqualTo(originalEntry.id)
      );
      if (remainingEntry) {
        redlineField =
          redlineField ??
          new FieldRedline<T>(
            proposalField,
            originalEntry.id,
            originalEntry,
            originalEntry
          );
      }

      redlineField =
        redlineField ??
        new FieldRedline<T>(
          proposalField,
          originalEntry.id,
          originalEntry,
          null
        );
      this._redlines.push(redlineField);
    });

    const addedEntries = revisedEntries
      .filter((revisedEntry) => !revisedEntry.replacesId)
      .filter(
        (revisedEntry) =>
          !originalEntries.find((originalEntry) =>
            originalEntry.id?.isEqualTo(revisedEntry.id)
          )
      );
    addedEntries.forEach((addedEntry) => {
      this._redlines.push(
        new FieldRedline<T>(proposalField, addedEntry.id, null, addedEntry)
      );
    });
  }

  public get field(): ProposalFieldName {
    return this._field;
  }

  public get redlines(): FieldRedline<T>[] {
    return this._redlines;
  }

  public get sessionHistory(): RedlineChange[] {
    return [
      ...this._sessionHistory,
      ...this._redlines.flatMap((redline) => redline.sessionHistory),
    ];
  }

  public get isResolved(): boolean {
    return this._redlines.every((redline) => redline.isResolved);
  }

  public get isEmpty(): boolean {
    return this._redlines.every((redline) => redline.currentEntry === null);
  }

  public get revisionAccepted(): boolean {
    return this._redlines.every((redline) => redline.revisionAccepted);
  }

  public get currentIds(): Guid[] {
    return this._redlines
      .map((redline) => redline.currentEntry?.id)
      .filter((id) => id !== undefined) as Guid[];
  }
  public get wasRedlined(): boolean {
    return this._redlines.some((redline) => redline.wasRedlined);
  }
  public get wasModified(): boolean {
    return this._redlines.some((redline) => redline.wasModified);
  }
  public get canBeUndone(): boolean {
    return this._redlines.some((redline) => redline.canBeUndone);
  }

  public get lastEntryUpdated(): Guid | undefined {
    return this._lastEntryUpdated;
  }

  public clearSessionHistory(): FieldRedlineArray<T> {
    const newRedlineArray = this.clone();
    newRedlineArray._sessionHistory = [];
    newRedlineArray._redlines = newRedlineArray._redlines.map((redline) =>
      redline.clearSessionHistory()
    );
    return newRedlineArray;
  }
  public registerSubField(fieldName: keyof T, diffWords: boolean = false) {
    this._subFieldsToRegister.push({ fieldName, diffWords });
    this._redlines.forEach((redline) =>
      redline.registerSubField(fieldName, diffWords)
    );
  }

  public acceptAll(): FieldRedlineArray<T> {
    let newRedlineArray = this.clone();
    for (const redline of this._redlines) {
      if (redline.fieldId) {
        newRedlineArray = newRedlineArray.acceptRedlineById(redline.fieldId);
      }
    }
    return newRedlineArray;
  }

  public rejectAll(): FieldRedlineArray<T> {
    let newRedlineArray = this.clone();
    for (const redline of this._redlines) {
      if (redline.fieldId) {
        newRedlineArray = newRedlineArray.rejectRedlineById(redline.fieldId);
      }
    }
    return newRedlineArray;
  }

  public undoAll(): FieldRedlineArray<T> {
    let newRedlineArray = this.clone();
    for (const redline of this._redlines) {
      if (redline.fieldId) {
        newRedlineArray = newRedlineArray.undoRedlineById(redline.fieldId);
      }
    }
    return newRedlineArray;
  }

  public acceptRedlineById(fieldId: Guid): FieldRedlineArray<T> {
    const newRedline = this.clone();
    const target = newRedline._redlines.find((redline) =>
      redline.fieldId?.isEqualTo(fieldId)
    );
    if (target) {
      const index = newRedline._redlines.indexOf(target);
      newRedline._redlines[index] = target.accept();
      this._lastEntryUpdated = fieldId;
    }
    return newRedline;
  }

  public rejectRedlineById(fieldId: Guid): FieldRedlineArray<T> {
    const newRedlineArray = this.clone();
    const targetIndex = newRedlineArray._redlines.findIndex((redline) =>
      redline.fieldId?.isEqualTo(fieldId)
    );
    if (targetIndex === -1) throw new Error("Redline not found");
    const targetRedline = newRedlineArray._redlines[targetIndex];
    newRedlineArray._redlines[targetIndex] = targetRedline.reject();
    this._lastEntryUpdated = fieldId;
    return newRedlineArray;
  }

  public removeAll(): FieldRedlineArray<T> {
    let newRedlineArray = this.clone();
    for (const redline of this._redlines) {
      if (redline.fieldId) {
        newRedlineArray = newRedlineArray.removeEntryByFieldId(redline.fieldId);
      }
    }
    return newRedlineArray;
  }

  public undoRedlineById(fieldId: Guid): FieldRedlineArray<T> {
    const newRedlineArray = this.clone();
    const target = newRedlineArray._redlines.find((redline) =>
      redline.fieldId?.isEqualTo(fieldId)
    );
    if (target?.isNewlyAdded && !target.originalEntry) {
      newRedlineArray._redlines.splice(
        newRedlineArray._redlines.indexOf(target),
        1
      );
      const redlineChange = RedlineChange.fromObject({
        field: this._field,
        fieldId: fieldId,
        action: RedlineAction.Remove,
        isResolved: false,
        textChanges: target.textChangeCollection.changes,
      });
      this._sessionHistory.push(redlineChange);
    } else if (target) {
      const index = newRedlineArray._redlines.indexOf(target);
      newRedlineArray._redlines[index] = target.undo();
    }
    return newRedlineArray;
  }

  public removeEntryByFieldId(fieldId?: Guid): FieldRedlineArray<T> {
    if (!fieldId) throw new Error("No id provided");
    const newRedlineArray = this.clone();
    const targetIndex = newRedlineArray._redlines.findIndex((fieldRedline) =>
      fieldRedline.fieldId?.isEqualTo(fieldId)
    );
    if (targetIndex === -1) throw new Error("Entry not found");
    const targetFieldRedline = newRedlineArray._redlines[targetIndex];

    if (targetFieldRedline.isNewlyAdded && !targetFieldRedline.originalEntry) {
      newRedlineArray._redlines.splice(targetIndex, 1);
      const redlineChange = RedlineChange.fromObject({
        field: this._field,
        fieldId: fieldId,
        action: RedlineAction.Remove,
        isResolved: true,
        textChanges: targetFieldRedline.textChangeCollection.changes,
      });
      this._sessionHistory.push(redlineChange);
    } else {
      newRedlineArray._redlines[targetIndex] = targetFieldRedline.remove();
      this._lastEntryUpdated = fieldId;
    }

    return newRedlineArray;
  }

  public replaceEntryById(fieldId: Guid, newEntry: T): FieldRedlineArray<T> {
    if (!fieldId) throw new Error("No id provided");
    const newRedlineArray = this.clone();
    const targetIndex = newRedlineArray._redlines.findIndex(
      (fieldRedline) =>
        fieldRedline.currentEntry?.id.isEqualTo(fieldId) ||
        fieldRedline.revisedEntry?.id.isEqualTo(fieldId) ||
        fieldRedline.originalEntry?.id.isEqualTo(fieldId)
    );
    if (targetIndex === -1) throw new Error("Entry not found");
    const targetRedline = newRedlineArray._redlines[targetIndex];
    const newRedline = targetRedline.edit(newEntry);
    newRedlineArray._redlines[targetIndex] = newRedline;
    this._lastEntryUpdated = fieldId;
    return newRedlineArray;
  }
  public getFieldRedlineById(fieldId: Guid): FieldRedline<T> | undefined {
    return this._redlines.find((fieldRedline) => {
      return (
        fieldRedline.currentEntry?.id.isEqualTo(fieldId) ||
        fieldRedline.revisedEntry?.id.isEqualTo(fieldId) ||
        fieldRedline.originalEntry?.id.isEqualTo(fieldId) ||
        fieldRedline.fieldId?.isEqualTo(fieldId)
      );
    });
  }

  public updateRedline(fieldRedline: FieldRedline<T>): FieldRedlineArray<T> {
    const newRedlineArray = this.clone();
    const targetIndex = newRedlineArray._redlines.findIndex((redline) =>
      redline.fieldId?.isEqualTo(fieldRedline.fieldId)
    );
    if (targetIndex === -1) throw new Error("Entry not found");
    newRedlineArray._redlines[targetIndex] = fieldRedline;
    this._lastEntryUpdated = fieldRedline.fieldId ?? undefined;
    return newRedlineArray;
  }

  public static fromArrayObject<T extends IComparableFieldEntryWithId>(
    prototype: T,
    arrayObject: any
  ): FieldRedlineArray<T> {
    const redlineArray = new FieldRedlineArray<T>(arrayObject._field, [], []);
    arrayObject._redlines.forEach((fieldRedline: any) => {
      const redlineInstance = FieldRedline.fromObject<T>(
        prototype,
        fieldRedline
      );
      if (redlineInstance) redlineArray._redlines.push(redlineInstance);
    });
    redlineArray._sessionHistory = new Array<RedlineChange>();
    arrayObject._sessionHistory.forEach((change: any) => {
      redlineArray._sessionHistory.push(RedlineChange.fromObject(change));
    });
    return redlineArray;
  }

  public clone(): FieldRedlineArray<T> {
    const entries = new Array<FieldRedline<T>>();
    for (const redline of this._redlines) {
      entries.push(redline.clone());
    }
    const fieldRedlineArrayClone = Object.create(this);
    fieldRedlineArrayClone._redlines = entries;
    return fieldRedlineArrayClone;
  }

  public toJSON() {
    return {
      _field: this._field,
      _redlines: this._redlines.map((redline) => redline.toJSON()),
      _sessionHistory: this._sessionHistory.map((change) => change.toJSON()),
    };
  }

  public addEntry(entry: T): FieldRedlineArray<T> {
    const newRedlineArray = this.clone();

    const existingFieldRedline = newRedlineArray._redlines.find(
      (redline) =>
        redline.revisedEntry?.id.isEqualTo(entry.id) ||
        redline.originalEntry?.id.isEqualTo(entry.id)
    );

    if (existingFieldRedline?.currentEntry)
      throw new Error("Entry already exists in redline");

    if (
      existingFieldRedline?.revisedEntry &&
      existingFieldRedline?.originalEntry
    ) {
      // Entry was removed and added again
      newRedlineArray._redlines[
        newRedlineArray._redlines.indexOf(existingFieldRedline)
      ] = existingFieldRedline.undo();
      newRedlineArray._lastEntryUpdated = entry.id;
      return newRedlineArray;
    }
    if (existingFieldRedline?.revisedEntry) {
      // Entry addition was requested in redline
      newRedlineArray._redlines[
        newRedlineArray._redlines.indexOf(existingFieldRedline)
      ] = existingFieldRedline.accept();
      newRedlineArray._lastEntryUpdated = entry.id;
      return newRedlineArray;
    }
    if (existingFieldRedline?.originalEntry) {
      // Entry removal was requested in redline
      newRedlineArray._redlines[
        newRedlineArray._redlines.indexOf(existingFieldRedline)
      ] = existingFieldRedline.reject();
      newRedlineArray._lastEntryUpdated = entry.id;
      return newRedlineArray;
    }

    const fieldRedline = new FieldRedline<T>(this._field, entry.id, null, null);
    newRedlineArray._redlines.push(fieldRedline.add(entry));
    newRedlineArray._lastEntryUpdated = entry.id;
    return newRedlineArray;
  }
}
