import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import {
  Avatar,
  Badge,
  Divider,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  styled,
  Tooltip
} from '@mui/material';
import Individual from 'marketplace/entities/individual/individual';
import MessagingAPIService from 'messaging/api/messaging-api-service';
import Forum from 'messaging/entities/forum/forum';
import { UserAvatarMap } from 'messaging/entities/forum/view/components/chat';
import * as React from 'react';
import { useEffect } from 'react';
import { useSession } from 'users/session/session-context';


const SubscriberMenu = styled(Popover)(({ theme }) => ({
  zIndex: 99999
}))
const SubscriberNotificationList = styled(List)(({ theme }) => ({
  maxHeight: theme.spacing(40),
  overflowY: 'auto',
  maxWidth: theme.spacing(50),
  minWidth: theme.spacing(30)
}));
const SubscriberIcon = styled(IconButton)(({ theme }) => ({
  alignSelf: 'center',
  color: '#666',
  height: 'fit-content',
  marginLeft: 'auto',
  display: 'flex'
}));

type ChatComponentProps = {
  subscribers: Individual[];
  forum: Forum;
  avatars: UserAvatarMap;
  onAddSubscriber: () => void;
  onLeaveConversation: () => void;
  disableLeaveButton?: boolean;
}

export default function SubscribersComponent(props: Readonly<ChatComponentProps>) {
  const { avatars, onAddSubscriber, onLeaveConversation } = props;

  const [menuAnchor, setMenuAnchor] = React.useState<HTMLElement | null>(null);
  const [subscribers, setSubscribers] = React.useState<Individual[]>([]);
  const [forum, setForum] = React.useState<Forum>(props.forum);
  const session = useSession();

  useEffect(() => {
    setSubscribers(props.subscribers);
    setForum(props.forum);
  }, [props.subscribers, props.forum]);

  async function leaveConversation() {
    const messageService = new MessagingAPIService(session);
    if (subscribers.length === 1) {
      await messageService.deleteForum(forum);
    } else {
      const newSubscribers = subscribers.filter(subscriber => subscriber.id !== session?.user?.id);
      forum.subscriberIds = newSubscribers.map(subscriber => subscriber.id);
      await messageService.updateSubscribersForForum(forum);
    }
    onLeaveConversation();
  }

  return (
    <React.Fragment>
      <Tooltip title="Subscriber List">
        <span>
          <SubscriberIcon
            aria-label="open subscriber list"
            onClick={(event) => setMenuAnchor(menuAnchor ? null : event.currentTarget)}
            size="large">
            <Badge badgeContent={subscribers?.length}>
              <GroupAddIcon />
            </Badge>
          </SubscriberIcon>
        </span>
      </Tooltip>
      <SubscriberMenu
        anchorEl={menuAnchor}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(menuAnchor)}
        onClose={() => setMenuAnchor(null)}>
        <SubscriberNotificationList dense>
          {subscribers.map((subscriber: Individual) => (
            <ListItemButton
              // onClick={(event) => this.handleSubscriberClick(event, subscriber)}>
              key={subscriber.id.toString()}>
              <ListItemAvatar>
                <Avatar src={avatars[subscriber.id.toString()]} />
              </ListItemAvatar>
              <ListItemText
                primary={subscriber.getFullName()}
                secondary={subscriber.company?.profile?.name} />
            </ListItemButton>
          ))}
        </SubscriberNotificationList>
        <Divider />
        {forum.creatorId &&
          <List dense>
            <ListItemButton onClick={() => {
              setMenuAnchor(null);
              onAddSubscriber();
            }}>
              <ListItemIcon><GroupAddIcon /></ListItemIcon>
              <ListItemText>Add People</ListItemText>
            </ListItemButton>
            {!props.disableLeaveButton &&
              <ListItemButton onClick={() => {
                setMenuAnchor(null);
                leaveConversation();
              }}>
                <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                <ListItemText>Leave Conversation</ListItemText>
              </ListItemButton>
            }
          </List>}
      </SubscriberMenu>
    </React.Fragment >
  );
}

