import SaveIcon from '@mui/icons-material/Save';
import { TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import LoadingButton from 'common/components/loading-button';
import URLInput, { URLInputError } from 'common/components/url-input';
import Guid from 'common/values/guid/guid';
import YearInput, { YearInputError } from 'common/values/year/view/year-input';
import Year from 'common/values/year/year';
import CompanyAPIService from 'marketplace/entities/company/api/company-api-service';
import Company from 'marketplace/entities/company/company';
import CompanyProfile from 'marketplace/values/company-profile/company-profile';
import CompanyProfileCategoriesSelect from 'marketplace/values/company-profile/view/company-profile-categories-select';
import CompanyProfileContactPersonInput from 'marketplace/values/company-profile/view/company-profile-contact-person-input';
import CompanyProfileNameInput from 'marketplace/values/company-profile/view/company-profile-name-input';
import CompanyProfileNumEmployeesInput from 'marketplace/values/company-profile/view/company-profile-num-employees-input';
import IndividualSummary from 'marketplace/values/individual-summary/individual-summary';
import AvatarUpload from 'marketplace/view/avatar-upload';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useSession } from 'users/session/session-context';

const MainContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: 'repeat(4, auto)',
  width: '100%'
}));
const HeaderContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    '& > *': {
      margin: 0,
      padding: theme.spacing(0, 0, 3, 0),
      width: '100%',
    },
    '&:not(last-child)': {
      paddingBottom: theme.spacing(0.5)
    }
  },
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'row',
  gridColumn: '1 / auto',
  gridRowStart: '1',
  justifyContent: 'center',
  padding: theme.spacing(3, 0, 4, 0),
  position: 'sticky',
  top: 0,
  width: '100%',
  zIndex: 10,
}));
const ContentContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    columnCount: 1
  },
  [theme.breakpoints.up('md')]: {
    columnCount: 2
  },
  columnFill: 'balance',
  columnGap: theme.spacing(4),
  gridColumn: '1 / auto',
  gridRowStart: '2',
  paddingTop: theme.spacing(0.75)
}));
const AboutYou = styled('section')(({ theme }) => ({
  gridColumn: '1 / span 3',
  gridRowStart: '3',
  marginTop: theme.spacing(1)
}));
const AboutYouTextField = styled(TextField)(({ theme }) => ({
  width: '100%'
}));
const AvatarNameContainer = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center'
}));
const YearEstablishedInput = styled(YearInput)(({ theme }) => ({
  minWidth: theme.spacing(20),
  marginBottom: theme.spacing(2),
  width: '100%'
}));
const NumEmployeesInput = styled(CompanyProfileNumEmployeesInput)(({ theme }) => ({
  minWidth: theme.spacing(20),
  marginBottom: theme.spacing(2),
  width: '100%'
}));
const ContactPersonInput = styled(CompanyProfileContactPersonInput)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  width: '100%'
}));
const CompanyWebsite = styled(URLInput)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  width: '100%'
}));
const CompanyLocation = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  width: '100%'
}));
const ButtonContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  bottom: 0,
  gridColumn: '1',
  gridRowStart: '4',
  justifySelf: 'flex-start',
  padding: theme.spacing(2, 0),
  position: 'sticky',
  width: '100%',
  zIndex: 10
}));

export type EditCompanyProfileProps = {
  isVisible?: boolean;
  companyId?: Guid;
  contactId?: Guid;
  profile?: CompanyProfile;
  onFormDirty?: () => void;
  onSave?: (company: Company) => void;
}

export default function EditCompanyProfile(props: Readonly<EditCompanyProfileProps>) {
  const { 
    isVisible, 
    contactId, 
    profile,
    onFormDirty,
    onSave
  } = props;

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [companyId, setCompanyId] = useState<Guid | undefined>(props.companyId);
  const [originalCompanyProfile, setOriginalCompanyProfile] = useState<CompanyProfile | undefined>(profile);
  const [updatedCompanyProfile, setUpdatedCompanyProfile] = useState<CompanyProfile | undefined>(profile);

  const [avatar, setAvatar] = useState<File | string>();

  const [formCompanyName, setFormCompanyName] = useState<string>(profile?.name ?? '');
  const [formContactPerson, setFormContactPerson] = useState<IndividualSummary | null>(null);
  const [formYearEstablished, setFormYearEstablished] = useState<string>(profile?.yearEstablished?.toString() ?? '');
  const [formNumberOfEmployees, setFormNumberOfEmployees] = useState<string>(profile?.numberOfEmployees?.toString() ?? '');
  const [formWebsiteUrl, setFormWebsiteUrl] = useState<string>(profile?.website?.toString() ?? '');
  const [formLocation, setFormLocation] = useState<string>(profile?.location ?? '');
  const [formCategories, setFormCategories] = useState<string[]>(profile?.categories ?? []);
  const [formDescription, setFormDescription] = useState<string>(profile?.description ?? '');
  const [formValidated, setFormValidated] = useState(false);

  const session = useSession();

  const validateProfile = (profile: CompanyProfile) => {
    if (!profile) return;
    const hasCategories: boolean = profile.categories?.length > 0;
    const hasName: boolean = profile.name.length > 0;
    const hasContactPerson: boolean = !!profile.contactId;
    setFormValidated(hasCategories && hasName && hasContactPerson);
  }

  const canSave = (): boolean => !loading && !saving && dirty && formValidated;

  const loadProfile = useCallback(async () => {
    try {
      setLoading(true);

      if (!companyId) return;

      const service = new CompanyAPIService(session);

      const returnedCompany = await service.getCompanyById(companyId, new AbortController());

      if (!returnedCompany) throw new Error('Company not found');
      if (!returnedCompany.profile) throw new Error('Company profile not found');

      if (returnedCompany.profile.avatarId) {
        const returnedAvatar = await service.getCompanyAvatar(companyId, new AbortController());
        setAvatar(returnedAvatar);
      }

      setUpdatedCompanyProfile(returnedCompany.profile);
      setFormCompanyName(returnedCompany.profile.name);
      setFormYearEstablished(returnedCompany.profile.yearEstablished?.toString() ?? '');
      setFormNumberOfEmployees(returnedCompany.profile.numberOfEmployees?.toString() ?? '');
      setFormWebsiteUrl(returnedCompany.profile.website?.toString() ?? '');
      setFormLocation(returnedCompany.profile.location ?? '');
      setFormCategories(returnedCompany.profile.categories ?? []);
      setFormDescription(returnedCompany.profile.description ?? '');

      validateProfile(returnedCompany.profile);
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar('Unable to load profile', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }, [companyId]);

  useEffect(() => {
    // Creating a new profile
    if (!companyId) {
      setUpdatedCompanyProfile(new CompanyProfile("New Profile"));
      return;
    }
    // Loading existing profile
    loadProfile();
  }, [loadProfile, companyId]);

  function constructCompany(): Company {
    if (!session.company?.entityId) throw new Error('Company EntityId not found');
    if (!updatedCompanyProfile) throw new Error('Company profile not found');

    return new Company(
      companyId ?? Guid.generate(),
      session.company?.entityId,
      updatedCompanyProfile,
      contactId,
      isVisible
    );
  }

  async function handleSaveButtonClick() {
    if (companyId)
      await updateProfile();
    else
      await createProfile();
  }

  async function updateProfile() {
    if (!companyId || !originalCompanyProfile || !updatedCompanyProfile) return;

    try {
      setSaving(true);
      const service = new CompanyAPIService(session);
      const returnedCompany = await service.updateCompany(
        companyId,
        Boolean(isVisible),
        formContactPerson?.id,
        originalCompanyProfile,
        updatedCompanyProfile
      );
      setOriginalCompanyProfile(returnedCompany.profile);
      setUpdatedCompanyProfile(returnedCompany.profile);
      enqueueSnackbar('Profile changes saved', { variant: 'success' });
      onSave?.(returnedCompany);
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar('Unable to update profile', { variant: 'error' });
    } finally {
      setSaving(false);
      setDirty(false);
    }
  }

  async function createProfile() {
    try {
      setSaving(true);
      const companyToCreate = constructCompany();
      const service = new CompanyAPIService(session);
      const returnedCompany = await service.createCompany(companyToCreate);
      setCompanyId(returnedCompany.id);
      setOriginalCompanyProfile(returnedCompany.profile);
      setUpdatedCompanyProfile(returnedCompany.profile);
      if (avatar) {
        await createAvatar(returnedCompany.id, avatar as File);
      }
    } catch (error: any) {
      console.error(`There was a problem creating the company profile: ${error}`);
      enqueueSnackbar(`Problem while creating profile ${error.message}`, { variant: 'error' });
    } finally {
      setSaving(false);
      setDirty(false);
    }
  }

  async function createAvatar(companyId: Guid, avatar: File) {
    if (!companyId || !avatar) return;

    try {
      const service = new CompanyAPIService(session);
      await service.createCompanyAvatar(companyId, avatar);
    } catch (error: any) {
      console.error(error);
      throw new Error('Unable to create avatar');
    }
  }

  return (
    <MainContainer>
      <HeaderContainer>
        <AvatarNameContainer>
          <AvatarUpload
            accept="image/jpeg, image/png"
            id={companyId}
            type="company"
            avatar={avatar}
            onUpload={(updatedAvatar) => setAvatar(updatedAvatar)}
            session={session}
          />
          <CompanyProfileNameInput
            name={formCompanyName}
            onNameChange={(name: string) => {
              setFormCompanyName(name);
              if (!updatedCompanyProfile) return;
              if (name === updatedCompanyProfile.name) return;
              let updatedProfile = updatedCompanyProfile.clone();
              updatedProfile = updatedProfile.updateName(name);
              setUpdatedCompanyProfile(updatedProfile);
              setDirty(true);
              onFormDirty?.();
              validateProfile(updatedProfile);
            }}
          />
        </AvatarNameContainer>
        <CompanyProfileCategoriesSelect
          categories={formCategories}
          onChange={async (updatedCategories: string[]) => {
            setFormCategories(updatedCategories);
            if (!updatedCompanyProfile) return;
            if (updatedCategories === updatedCompanyProfile.categories) return;
            let updatedProfile = updatedCompanyProfile.clone();
            updatedProfile = updatedProfile.updateCategories(updatedCategories);
            setUpdatedCompanyProfile(updatedProfile);
            setDirty(true);
            onFormDirty?.();
            validateProfile(updatedProfile);
          }}
        />
      </HeaderContainer>
      <ContentContainer>
        <ContactPersonInput
          required
          contactId={contactId}
          onChange={(value: IndividualSummary | null) => {
            setFormContactPerson(value);
            if (!updatedCompanyProfile || !value?.id) return;
            if (value.id.isEqualTo(updatedCompanyProfile.contactId)) return;
            let updatedProfile = updatedCompanyProfile.clone();
            updatedProfile = updatedProfile.updateContactId(value.id);
            setUpdatedCompanyProfile(updatedProfile);
            setDirty(true);
            onFormDirty?.();
            validateProfile(updatedProfile);
          }}
        />
        <YearEstablishedInput
          label='Year Established'
          variant='outlined'
          year={Year.isValid(formYearEstablished) ? new Year(formYearEstablished) : undefined}
          notInFuture
          onChange={(year?: Year, error?: YearInputError) => {
            if (!year) return;
            setFormYearEstablished(year.toString());
            if (!updatedCompanyProfile) return;
            if (year.toString() === updatedCompanyProfile.yearEstablished?.toString()) return;
            let updatedProfile = updatedCompanyProfile.clone();
            updatedProfile = updatedProfile.updateYearEstablished(year?.toNumber());
            setUpdatedCompanyProfile(updatedProfile);
            setDirty(true);
            onFormDirty?.();
            validateProfile(updatedProfile);
          }}
        />
        <NumEmployeesInput
          numEmployees={formNumberOfEmployees}
          onChange={(numEmployees: string) => {
            setFormNumberOfEmployees(numEmployees);
            if (!updatedCompanyProfile) return;
            if (numEmployees === updatedCompanyProfile.numberOfEmployees?.toString()) return;
            let updatedProfile = updatedCompanyProfile.clone();
            updatedProfile = updatedProfile.updateNumberOfEmployees((numEmployees.length > 0 && (isNaN(Number(numEmployees)) || Number(numEmployees) < 0 || Number(numEmployees) > new Date().getFullYear()) ? undefined : Number(numEmployees)));
            setUpdatedCompanyProfile(updatedProfile);
            setDirty(true);
            onFormDirty?.();
            validateProfile(updatedProfile);
          }}
        />
        <CompanyWebsite
          variant="outlined"
          url={formWebsiteUrl?.toString()}
          onChange={(url: string, error?: URLInputError) => {
            setFormWebsiteUrl(url);
            if (error) setFormValidated(false);
            if (!updatedCompanyProfile || !url || error) return;
            let updatedProfile = updatedCompanyProfile.clone();
            updatedProfile = updatedProfile.updateWebsite(new URL(url));
            setUpdatedCompanyProfile(updatedProfile);
            setDirty(true);
            onFormDirty?.();
            validateProfile(updatedProfile);
          }}
        />
        <CompanyLocation
          label="Location"
          variant="outlined"
          value={formLocation}
          onChange={(event) => {
            const location = event.target.value;
            setFormLocation(location);
            if (!updatedCompanyProfile) return;
            if (location === updatedCompanyProfile.location) return;
            let updatedProfile = updatedCompanyProfile.clone();
            updatedProfile = updatedProfile.updateLocation(location);
            setUpdatedCompanyProfile(updatedProfile);
            setDirty(true);
            onFormDirty?.();
            validateProfile(updatedProfile);
          }}
        />
      </ContentContainer>
      <AboutYou>
        <Typography variant="h6">
          About Your Company
        </Typography>
        <AboutYouTextField
          id="addtlInfo"
          value={formDescription}
          onChange={(event) => {
            setFormDescription(event.target.value);
            if (!updatedCompanyProfile) return;
            if (event.target.value === updatedCompanyProfile.description) return;
            let updatedProfile = updatedCompanyProfile.clone();
            updatedProfile = updatedProfile.updateDescription(event.target.value);
            setUpdatedCompanyProfile(updatedProfile);
            setDirty(true);
            onFormDirty?.();
            validateProfile(updatedProfile);
          }}
          multiline={true}
          minRows={4}
          placeholder='Additional info displayed on your profile'
          variant="outlined"
        />
      </AboutYou>
      <ButtonContainer>
        <LoadingButton
          id="saveButton"
          variant="contained"
          startIcon={<SaveIcon />}
          loading={saving}
          color="primary"
          disabled={!canSave()}
          onClick={handleSaveButtonClick}>
          {companyId ? 'Save Changes' : 'Create Profile'}
        </LoadingButton>
      </ButtonContainer>
    </MainContainer >
  );
}
