import FilterListIcon from "@mui/icons-material/FilterList";
import { Button, Chip, Popover, Theme, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDialog } from "app/providers/dialog";
import { DialogProps } from "common/components/dialog";
import Individual from "marketplace/entities/individual/individual";
import MarketplaceTeam from "marketplace/entities/marketplace-team/marketplace-team";
import { Filters } from "marketplace/helpers/filters";
import {
  ArrayChip,
  FilterChip,
  RangeChip,
  TextChip,
} from "marketplace/view/filter-chip";
import { FilterChips } from "marketplace/view/filter-chips";
import FilterList from "marketplace/view/filter-list";
import Results from "marketplace/view/results";
import SearchBar from "marketplace/view/search-bar";
import SortButton from "marketplace/view/sort-button";
import React, { useEffect } from "react";
import { useSession } from "users/session/session-context";

const MainContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
}));
const SearchContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  backgroundColor: "antiquewhite",
  borderRadius: theme.spacing(0.5),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(3.5),
  paddingRight: theme.spacing(3.5),
  paddingTop: theme.spacing(2),
  position: "sticky",
  width: "100%",
  zIndex: 10,
}));
const MainContent = styled("div")(({ theme }) => ({
  display: "flex",
  flex: 1,
  height: "100%",
  overflowY: "auto",
}));
const ResultsContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("xl")]: {
    marginTop: 0,
  },
}));
const ResultsPane = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("xl")]: {
    overflowX: "hidden",
    overflowY: "visible",
    padding: theme.spacing(0),
  },
  backgroundColor: "#FAFAFA",
  flex: 1,
  padding: theme.spacing(0, 1),
}));
const ChipContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("xl")]: {
    marginLeft: theme.spacing(1),
  },
}));
const SearchFilterChip = styled(Chip)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  marginRight: theme.spacing(1),
}));
const TopBar = styled("div")(({ theme }) => ({
  alignItems: "center",
  backdropFilter: "blur(7px) saturate(200%)",
  backgroundColor: "rgba(250, 250, 250, 0.87)",
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(1, 0),
  position: "sticky",
  top: 0,
  zIndex: 10,
}));
const FilterButtons = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("xl")]: {
    marginLeft: theme.spacing(1),
  },
}));
const ShowResultsButton = styled(Button)(({ theme }) => ({
  display: "block",
  margin: "auto",
}));

type MarketplaceProps = {
  selectingLeader?: boolean;
  selectingMember?: boolean;
  selectingTeam?: boolean;
  onTeamMemberSelected?: (teamMember: Individual) => void;
  onTeamSelected?: (team: MarketplaceTeam) => void;
};

export default function Marketplace(props: Readonly<MarketplaceProps>) {
  const {
    selectingLeader,
    selectingMember,
    selectingTeam,
    onTeamMemberSelected,
    onTeamSelected,
  } = props;

  const resultsRef = React.useRef<HTMLDivElement>(null);
  const filtersRef = React.useRef<FilterChips>(new FilterChips());

  const [searchTerm, setSearchTerm] = React.useState("");
  const [filters, setFilters] = React.useState<FilterChips>(filtersRef.current);
  const [sortBy, setSortBy] = React.useState("relevance");
  const [category, setCategory] = React.useState("all");
  const [numResults, setNumResults] = React.useState(0);
  const [filterPopoverAnchor, setFilterPopoverAnchor] =
    React.useState<HTMLButtonElement | null>(null);

  const { openDialog, closeDialog } = useDialog();

  const session = useSession();

  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  useEffect(() => {
    filtersRef.current.addRange(Filters);
    setFilters(new FilterChips(filtersRef.current.chips));
  }, []);

  function handleFilterChanged(updatedFilter: FilterChip) {
    if (!updatedFilter.value) return;

    const targetChip = filtersRef.current.findByName(updatedFilter.filterName);

    if (!targetChip) return;

    if (targetChip instanceof ArrayChip || targetChip instanceof RangeChip) {
      filtersRef.current.updateValue(
        updatedFilter.filterName,
        updatedFilter.value as string[]
      );
    } else {
      filtersRef.current.updateValue(
        updatedFilter.filterName,
        updatedFilter.value as string
      );
    }

    setFilters(new FilterChips(filtersRef.current.chips));
  }

  function handleFilterEnabled(updatedFilter: FilterChip) {
    filtersRef.current.enable(updatedFilter.filterName);
    setFilters(new FilterChips(filtersRef.current.chips));
  }

  function handleFilterDisabled(updatedFilter: FilterChip) {
    filtersRef.current.disable(updatedFilter.filterName);
    setFilters(new FilterChips(filtersRef.current.chips));

    if (updatedFilter.filterName === "query") {
      setSearchTerm("");
    }
  }

  function handleCategoryChanged(category: {
    id: any;
    name: any;
    description?: string;
  }) {
    setNumResults(0);
    setSearchTerm("");
    setCategory(category.id);
    setSortBy("relevance");

    filtersRef.current.disableAll();
    filtersRef.current.addRange(Filters);

    if (category.id !== "all") {
      filtersRef.current.enable(category.id);
      const updatedFilter = new TextChip("category", category.name);
      handleFilterChanged(updatedFilter);
    }

    setFilters(new FilterChips(filtersRef.current.chips));
  }

  function handleSearch(term: string = "") {
    setSearchTerm(term);
    filtersRef.current.updateValue("query", term);
    filtersRef.current.enable("query");
    setFilters(new FilterChips(filtersRef.current.chips));
  }

  function handleSort(sortField: string) {
    setSortBy(sortField);
  }

  function openFilterDialog() {
    const dialogProps: DialogProps = {
      MuiProps: {
        fullScreen: true,
        keepMounted: true,
        disablePortal: true,
      },
      title: (
        <ShowResultsButton
          size="large"
          variant="contained"
          disableElevation={true}
          color="primary"
          onClick={closeDialog}
        >
          Show {numResults} Result{numResults === 1 ? null : "s"}
        </ShowResultsButton>
      ),
      component: (
        <FilterList
          dialog={true}
          filters={filtersRef.current}
          onChange={handleFilterChanged}
          onAdded={handleFilterEnabled}
          onRemoved={handleFilterDisabled}
        />
      ),
    };
    openDialog(dialogProps);
  }

  return (
    <MainContainer>
      <SearchContainer>
        <SearchBar
          embedded={true}
          searchTerm={searchTerm}
          onCategoryChanged={handleCategoryChanged}
          onSearch={handleSearch}
          onSearchCleared={() => {
            handleFilterDisabled(filtersRef.current.findByName("query")!);
            handleSearch();
          }}
        />
      </SearchContainer>
      <MainContent>
        <ResultsPane ref={resultsRef}>
          <TopBar>
            <ChipContainer>
              {filters.chips.every((chip: FilterChip) => !chip.enabled) && (
                <SearchFilterChip
                  label="No Active Filters"
                  disabled
                  size="small"
                />
              )}
              {filters.chips.map((chip: FilterChip) => (
                <SearchFilterChip
                  key={chip.filterName}
                  label={chip.label}
                  size="small"
                  style={{ display: !chip.enabled ? "none" : "inline-flex" }}
                  onDelete={() => handleFilterDisabled(chip)}
                />
              ))}
            </ChipContainer>
            <FilterButtons>
              <Button
                size="large"
                color="primary"
                startIcon={<FilterListIcon />}
                onClick={(event) =>
                  mdUp
                    ? setFilterPopoverAnchor(event.currentTarget)
                    : openFilterDialog()
                }
              >
                Filters
              </Button>
              <Popover
                anchorEl={filterPopoverAnchor}
                open={Boolean(filterPopoverAnchor)}
                onClose={() => setFilterPopoverAnchor(null)}
              >
                <FilterList
                  dialog={true}
                  filters={filters}
                  onChange={handleFilterChanged}
                  onAdded={handleFilterEnabled}
                  onRemoved={handleFilterDisabled}
                />
              </Popover>
              <SortButton sortBy={sortBy} onSort={handleSort} />
            </FilterButtons>
          </TopBar>
          <ResultsContainer>
            <Results
              session={session}
              embedded={true}
              selectingTeamLeader={selectingLeader}
              selectingTeamMember={selectingMember}
              selectingTeam={selectingTeam}
              category={category}
              searchTerm={searchTerm}
              filters={filters.toDictionary()}
              sortBy={sortBy}
              onResults={(results: number | undefined) =>
                setNumResults(results ?? 0)
              }
              onTeamMemberSelected={onTeamMemberSelected}
              onTeamSelected={onTeamSelected}
            />
          </ResultsContainer>
        </ResultsPane>
      </MainContent>
    </MainContainer>
  );
}
