import axios, {
  AxiosHeaders,
  CanceledError
} from "axios";
import AttorneyHubAPIService from "common/services/api/attorney-hub-api-service";
import Guid from "common/values/guid/guid";
import { LegalEntityNotFoundError } from "legal-entities//entities/legal-entity/legal-entity";
import AdministratorInvitation, { 
  AdministratorInvitationNotFoundError, 
  UnauthorizedAdministratorInvitationAccessError 
} from "legal-entities/entities/administrator-invitation/administrator-invitation";
import AdministratorInvitationAPIResponse from "legal-entities/entities/administrator-invitation/api/response-contracts/administrator-invitation-api-response";
import LegalEntity from "legal-entities/entities/legal-entity/legal-entity";
import AcceptEntityUserInvitationAPIRequest from 'legal-entities/entities/user-entity-member-invitation/api/request-contracts/accept-entity-user-invitation-api-request';
import { InvalidRegistrationInfoError } from "legal-entities/values/registration-info";
import AuthInfoAPIResponse from "users/entities/user/api/response-contracts/auth-info-api-response";
import { AuthResponse } from "users/entities/user/api/auth-email-api-service";
import Session from "users/session/session";

export default class AdministratorInvitationAPIService {

  private headers: AxiosHeaders = new AxiosHeaders();

  constructor(session: Readonly<Session>) {
    //some calls in this service will be made by users without valid session auth tokens
    if (session.authToken?.value) {
      this.headers.set("Authorization", `Bearer ${session.authToken.value}`);
    }
  }

  async createEntityAdminInvitation(invitation: AdministratorInvitation): Promise<void> {
    const url = new URL(`/legal-entities/administrator-invitations`, AttorneyHubAPIService.apiBaseUrl);
    try {
      await axios.post(
        url.toString(),
        invitation,
        {
          headers: this.headers.concat({ 'Content-Type': 'application/json' })
        }
      );
    } catch (error: any) {
      if (error instanceof CanceledError)
        throw error;
      throw new AdministratorInvitationAPIServiceError("createEntityAdminInvitation", error, invitation);
    }
  }

  async getAdministratorInvitationById(id: Guid, abortController: AbortController): Promise<AdministratorInvitation> {
    const url = new URL(`/legal-entities/admin-invitations/${id.valueOf()}`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.get(
        url.toString(),
        {
          headers: this.headers,
          signal: abortController?.signal
        }
      );
      const responseData: AdministratorInvitationAPIResponse = Object.assign(
        new AdministratorInvitationAPIResponse(), 
        response.data
      );
      return responseData.deserialize();
    } catch (error: any) {
      if (error instanceof CanceledError)
        throw error;
      if (error.response.status === 404)
        throw new AdministratorInvitationNotFoundError(id);
      throw new AdministratorInvitationAPIServiceError("getAdministratorInvitationById", error);
    }
  }

  async getAdminInvitationsForLegalEntity(entity: LegalEntity, abortController: AbortController): Promise<AdministratorInvitation[]> {
    const url = new URL(`/legal-entities/admin-invitations?entityId=${entity.id.valueOf()}`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.get(
        url.toString(),
        {
          headers: this.headers,
          signal: abortController?.signal
        }
      );
      const adminInvitations: AdministratorInvitation[] = [];
      response.data.forEach((item: Partial<AdministratorInvitationAPIResponse>) => {
        const responseData = Object.assign(new AdministratorInvitationAPIResponse(), item);
        adminInvitations.push(responseData.deserialize());
      });
      return adminInvitations;
    } catch (error: any) {
      if (error instanceof CanceledError)
        throw error;
      if (error.response.status === 403)
        throw new UnauthorizedAdministratorInvitationAccessError(entity.id);
      if (error.response.status === 404)
        throw new LegalEntityNotFoundError(entity.id);
      throw new AdministratorInvitationAPIServiceError("getAdminInvitationsForLegalEntity", error);
    }
  }

  async acceptAdministratorInvitation(
    invitation: AdministratorInvitation,
    session: Session,
    isClient?: boolean,
    isVendor?: boolean
  ): Promise<void> {
    if (!invitation.id)
      throw new Error("Invitation ID is required to accept an invitation.");
    if (!invitation.registrationInfo?.password)
      throw new Error("Password is required to accept an invitation.");
    const url = new URL(`/legal-entities/admin-invitations/${invitation.id.valueOf()}/accept`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const apiResponse = await axios.post(
        url.toString(),
        new AcceptEntityUserInvitationAPIRequest(
          invitation.registrationInfo.password,
          invitation.registrationInfo.name,
          isClient,
          isVendor
        ),
        {
          headers: this.headers.concat({ 'Content-Type': 'application/json' })
        }
      );
      const authInfo: AuthInfoAPIResponse = Object.assign(new AuthInfoAPIResponse(), apiResponse.data);
      const authResponse: AuthResponse = authInfo.deserialize();
      session.initializeFromAuthResponse(authResponse);
    } catch (error: any) {
      if (error instanceof CanceledError)
        throw error;
      if (error.response.status === 400)
        throw new InvalidRegistrationInfoError(error.response.data);
      if (error.response.status === 404)
        throw new AdministratorInvitationNotFoundError(invitation.id);
      throw new AdministratorInvitationAPIServiceError("acceptAdministratorInvitation", error, invitation);
    }
  }

  async cancelAdministratorInvitation(invitation: AdministratorInvitation): Promise<void> {
    if (!invitation.id)
      throw new Error("Invitation ID is required to cancel an invitation.");
    const url = new URL(`/legal-entities/admin-invitations/${invitation.id.valueOf()}/cancel`, AttorneyHubAPIService.apiBaseUrl);
    try {
      await axios.post(
        url.toString(),
        {},
        {
          headers: this.headers
        }
      );
    } catch (error: any) {
      if (error?.response?.status === 404) {
        throw new AdministratorInvitationNotFoundError(invitation.id);
      }
      throw new AdministratorInvitationAPIServiceError("cancelAdministratorInvitation", error, invitation);
    }
  }
}

export class AdministratorInvitationAPIServiceError extends Error {
  methodName: string;
  error: any;
  invitation?: AdministratorInvitation;
  constructor(methodName: string, error: any, invitation?: AdministratorInvitation) {
    super(`Error in AdministratorInvitationAPIService.${methodName}: ${error.message}`);
    this.methodName = methodName;
    this.error = error;
    this.invitation = invitation;
  }
}