import MaterialTable, { MaterialTableProps } from '@material-table/core';
import ContactsIcon from '@mui/icons-material/Contacts';
import { Theme, useMediaQuery } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { ConfirmResponse, useConfirmDialog } from 'app/providers/confirm-dialog';
import { useDialog } from 'app/providers/dialog';
import { TableActionsComponent, getTableActions } from 'app/routes/network/table/contacts/actions';
import { getTableColumns as getContactsColumns } from 'app/routes/network/table/contacts/columns';
import loadData from 'app/routes/network/table/data-loader';
import { NoRowsPlaceholder } from 'app/routes/network/table/no-rows-placeholder';
import { getTableColumns as getPendingColumns } from 'app/routes/network/table/pending/columns';
import TableToolbarComponent from 'app/routes/network/table/toolbar';
import { PageTab } from 'app/routes/network/tabs';
import TableContainerComponent from 'common/components/table/container';
import TableTitle from 'common/components/table/title';
import { PageContainer, TableTheme } from 'common/styles';
import Guid from 'common/values/guid/guid';
import EntityMember from 'legal-entities/entities/entity-member/entity-member';
import _ from 'lodash';
import ViewIndividualProfile from 'marketplace/values/individual-profile/view/view-individual-profile';
import ChatDialog from 'messaging/entities/forum/view/components/chat-dialog';
import { enqueueSnackbar } from 'notistack';
import * as React from 'react';
import { useEffect } from 'react';
import { Params, useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import UserNetworkConnectionAPIService from 'users/entities/user-network-connection/api/user-network-connection-api-service';
import NetworkedUserInfo from 'users/entities/user-network-connection/networked-user-info';
import NetworkInvitationAPIService from 'users/entities/user-network-invitation/api/network-invitation-api-service';
import NetworkInvitationInfo from 'users/entities/user-network-invitation/network-invitation-info';
import { useSession } from 'users/session/session-context';

export type NetworkTableData = NetworkedUserInfo | EntityMember | NetworkInvitationInfo;
type NetworkProps = {};

export default function Network(props: Readonly<NetworkProps>) {
  const tableRef = React.useRef<MaterialTable<NetworkTableData> & MaterialTableProps<NetworkTableData>>();

  const [loading, setLoading] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(PageTab.Contacts);
  const [tableBodyHeight, setTableBodyHeight] = React.useState(0);

  const routeParams = useLoaderData() as Params<string>;
  const location = useLocation();
  const navigate = useNavigate();
  const session = useSession();
  const isMediumDisplaySize = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const confirm = useConfirmDialog();
  const { openDialog, closeAllDialogs } = useDialog();

  const tabs = (
    <Tabs
      value={activeTab}
      indicatorColor="primary"
      textColor="primary"
      onChange={(_event, newValue) => navigate(`/network/${newValue}`)}>
      <Tab value={PageTab.Contacts} label="Contacts" />
      <Tab value={PageTab.Coworkers} label="Coworkers" />
      <Tab value={PageTab.Pending} label="Pending" />
    </Tabs>
  );

  // Close any open dialogs when the URL changes
  useEffect(() => {
    closeAllDialogs();
  }, [location]);

  // Handle account type change
  useEffect(() => {
    tableRef?.current?.onQueryChange({});
  }, [session.accountType]);

  // Handle URL changes
  useEffect(() => {
    if (routeParams.tab) {
      setActiveTab(routeParams.tab as PageTab);
    } else {
      navigate(`/network/${PageTab.Contacts}`);
    }

    if (routeParams.action === 'message' && routeParams.id) {
      handleSendMessageToContact(new Guid(routeParams.id));
    }

    if (routeParams.action === 'view' && routeParams.id) {
      handleViewProfile(new Guid(routeParams.id));
    }
  }, [routeParams]);

  // Handle component mount
  useEffect(() => {
    function computeTableHeight() {
      const tableHead = document.querySelector('thead.MuiTableHead-root');
      const tableFoot = document.querySelector('tfoot.MuiTableFooter-root');
      const siteFooter = document.querySelector('footer#siteFooter');

      const tableOffsetTop = tableHead?.getBoundingClientRect().top;
      const tableFootHeight = tableFoot?.getBoundingClientRect().height;
      const siteFooterHeight = siteFooter?.getBoundingClientRect().height;

      if (!tableOffsetTop || !tableFootHeight || !siteFooterHeight) return;

      setTableBodyHeight(Math.floor(window.innerHeight - tableOffsetTop - tableFootHeight - siteFooterHeight));
    }

    const debouncedResize = _.debounce(() => computeTableHeight(), 250);
    debouncedResize();
    window.addEventListener('resize', debouncedResize);

    return () => {
      window.removeEventListener('resize', debouncedResize);
    }
  }, []);

  function getColumns() {
    if (activeTab === PageTab.Pending) {
      return getPendingColumns(
        (individualId) => navigate(`/network/${PageTab.Pending}/view/${individualId}`),
        handleAcceptInvitation,
        handleRejectInvitation,
        handleCancelInvitation,
        session
      );
    }

    return getContactsColumns(
      activeTab,
      session,
      (individualId) => navigate(`/network/${activeTab}/view/${individualId}`),
      (userId) => navigate(`/network/${activeTab}/message/${userId}`),
      handleRemoveNetworkConnection,
      handleSendProposalToContact
    );
  }

  async function handleAcceptInvitation(invitationId: Guid): Promise<void> {
    if (!invitationId) Promise.reject(new Error('No network invitation id provided'));
    try {
      const networkInvitationService = new NetworkInvitationAPIService(session);
      const connection = await networkInvitationService.acceptNetworkInvitation(invitationId);
      session.addNetworkConnection(connection.toProfileConnection(session.user?.id));
      tableRef?.current?.onQueryChange();  // Force table data refresh
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar('Failed to accept invitation', { variant: 'error' });
    }
  }

  async function handleRejectInvitation(invitationId: Guid): Promise<void> {
    try {
      if (!invitationId) Promise.reject(new Error('No network invitation id provided'));
      const networkInvitationService = new NetworkInvitationAPIService(session);
      await networkInvitationService.rejectNetworkInvitation(invitationId);
      session.removeNetworkInvitation(invitationId);
      tableRef?.current?.onQueryChange();  // Force table data refresh
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar('Failed to reject invitation', { variant: 'error' });
    }
  }

  async function handleCancelInvitation(invitationId: Guid): Promise<void> {
    try {
      if (!invitationId)
        Promise.reject(new Error('No network invitation id provided'));

      const response = await confirm({
        title: 'Cancel Network Connection Invitation?',
        message: 'Are you sure you want to cancel this network connection invitation?',
        okButtonText: 'Yes',
        cancelButtonText: 'No'
      });

      if (response === ConfirmResponse.Cancel) return;

      const networkInvitationService = new NetworkInvitationAPIService(session);
      await networkInvitationService.cancelNetworkInvitation(invitationId);
      session.removeNetworkInvitation(invitationId);
      tableRef?.current?.onQueryChange();  // Force table data refresh
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar('Failed to cancel invitation', { variant: 'error' });
    }
  }

  async function handleRemoveNetworkConnection(connectionId?: Guid): Promise<void> {
    try {
      if (!connectionId)
        throw new Error('No network connection id provided');

      const response = await confirm({
        title: 'Remove Network Connection',
        message: 'Are you sure you want to remove this network connection?',
        okButtonText: 'Remove'
      });

      if (response === ConfirmResponse.Cancel) return;

      const userNetworkService = new UserNetworkConnectionAPIService(session);
      await userNetworkService.removeNetworkConnection(connectionId);
      session.removeNetworkConnection(connectionId);
      tableRef?.current?.onQueryChange();  // Force table data refresh
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar('Failed to remove network connection', { variant: 'error' });
    }
  }

  async function handleSendProposalToContact(targetUrl: URL): Promise<void> {
    navigate(targetUrl);
  }

  async function handleSendMessageToContact(userId?: Guid): Promise<void> {
    try {
      if (!userId) throw new Error('No user id provided');

      const userNetworkService = new UserNetworkConnectionAPIService(session);
      const forum = await userNetworkService.getChatForumForNetworkConnection(userId);
      const parentPath = new URL(".", window.location.origin + window.location.pathname);

      openDialog(
        {
          title: 'Message',
          contentSxProps: {
            display: 'flex',
            overflowX: 'hidden',
            paddingBottom: 0
          },
          MuiProps: {
            fullWidth: true,
            maxWidth: 'md'
          },
          component: <ChatDialog forums={[forum]} />,
        },
        () => navigate(parentPath.pathname)
      );
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar('Failed to send message', { variant: 'error' });
    }
  }

  function handleViewProfile(individualId?: Guid): void {
    if (!individualId) return;

    const parentPath = new URL(".", window.location.origin + window.location.pathname);

    openDialog(
      {
        titleStyle: {
          position: 'absolute',
          right: 0,
          top: 0,
        },
        contentSxProps: {
          display: 'flex',
          overflowX: 'hidden'
        },
        MuiProps: {
          maxWidth: 'lg',
          fullWidth: false
        },
        component: (
          <ViewIndividualProfile
            individualId={individualId}
            confirmDialog={confirm}
          />
        )
      },
      () => navigate(parentPath.pathname)
    );
  }

  function handleExpandNetwork(): void {
    navigate('/marketplace');
  }

  return (
    <PageContainer>
      <MaterialTable
        key={activeTab}
        tableRef={tableRef}
        title={
          <TableTitle title="Manage Network" icon={<ContactsIcon />} />
        }
        columns={getColumns()}
        data={(query) => loadData(query, activeTab, setLoading, session)}
        actions={getTableActions(isMediumDisplaySize, handleExpandNetwork)}
        isLoading={loading}
        options={{
          debounceInterval: 250,
          defaultExpanded: true,
          emptyRowsWhenPaging: false,
          filtering: true,
          filterRowStyle: TableTheme.filterRow,
          grouping: true,
          minBodyHeight: tableBodyHeight,
          maxBodyHeight: tableBodyHeight,
          pageSize: 10,
          pageSizeOptions: [10, 25, 50],
          paginationType: isMediumDisplaySize ? 'normal' : 'stepped',
          showFirstLastPageButtons: !isMediumDisplaySize
        }}
        components={{
          Container: props => TableContainerComponent({ ...props }),
          Actions: props => TableActionsComponent({
            isMediumDisplaySize,
            handleExpandNetwork,
            ...props
          }),
          Toolbar: props => TableToolbarComponent({
            tableRef,
            activeTab,
            tabs,
            isMediumDisplaySize,
            ...props
          })
        }}
        localization={{
          body: {
            emptyDataSourceMessage: <NoRowsPlaceholder tableBodyHeight={tableBodyHeight} />
          }
        }}
      />
    </PageContainer>
  )
}
