import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const Container = styled('div')(({ theme }) => ({
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2)
}));
const Icon = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: theme.spacing(1)
}));
const Title = styled('h2')(({ theme }) => ({
  marginBottom: theme.spacing(0.25),
  marginTop: 0
}));
const Description = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary
}));

export type EmptyTableMessageProps = {
  icon?: React.ReactNode;
  title: string;
  description?: string;
}

export default function EmptyTableMessage(props: Readonly<EmptyTableMessageProps>) {
  const {
    icon,
    title,
    description
  } = props;

  return (
    <Container>
      <Icon>{icon}</Icon>
      <Title>{title}</Title>
      <Description variant="body2">{description}</Description>
   </Container>
  )
}
