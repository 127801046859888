import { Query, QueryResult } from "@material-table/core";
import BookmarkAPIService from "marketplace/entities/bookmark/api/bookmark-api-service";
import BookmarkedCompany from "marketplace/entities/bookmarked-company/bookmarked-company";
import BookmarkedIndividual from "marketplace/entities/bookmarked-individual/bookmarked-individual";
import BookmarkedTeam from "marketplace/entities/bookmarked-team/bookmarked-team";

import { PageTab } from "../tabs";
import Session from "users/session/session";

export type MarketplaceBookmark = BookmarkedIndividual | BookmarkedCompany | BookmarkedTeam;

const emptyResponse = {
  data: [],
  page: 0,
  totalCount: 0
};

export default async function loadData(
  query: Query<MarketplaceBookmark>,
  activeTab: PageTab,
  setLoading: (isLoading: boolean) => void,
  session: Session
): Promise<QueryResult<MarketplaceBookmark>> {
  setLoading(true);

  try {
    let bookmarks: MarketplaceBookmark[] = [];
    let bookmarkService = new BookmarkAPIService(session);
    let abortController = new AbortController();
    switch (activeTab) {
      case PageTab.Individual:
        bookmarks = await bookmarkService.getAllIndividualBookmarksForUser(abortController);
        break;
      case PageTab.Company:
        bookmarks = await bookmarkService.getAllCompanyBookmarksForUser(abortController);
        break;
      case PageTab.Team:
        bookmarks = await bookmarkService.getAllTeamBookmarksForUser(abortController);
        break;
      default:
        break;
    }

    if (bookmarks.length === 0) return emptyResponse;

    return {
      data: bookmarks,
      page: 0,
      totalCount: bookmarks.length
    };
  } catch (error: any) {
    console.error(error);

    return emptyResponse;
  } finally {
    setLoading(false);
  }
}
