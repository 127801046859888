import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { ipahLogo } from 'app/assets';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import AuthEmailAPIService from "users/entities/user/api/auth-email-api-service";
import UserCredentials from 'users/values/user-credentials/user-credentials';
import Footer from 'common/components/footer';
import LoginForm from 'users/entities/user/view/login-form';
import PasswordResetForm from 'users/entities/user/view/password-reset-form';
import PasswordResetRequestForm from 'users/entities/user/view/password-reset-request-form';
import { useSearchParams } from 'react-router-dom';
import backgroundImage from 'app/assets/andrew-neel-cckf4TsHAuw-unsplash.jpg';

const MainContainer = styled(Grid)(({ theme }) => ({
  height: '100vh'
}));

const ImageContainer = styled(Grid)(({ theme }) => ({
  backgroundImage: `url(${backgroundImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundColor: 'gray',
  backgroundSize: 'cover',
  backgroundPosition: 'center'
}));

const FormContainer = styled('form')(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: '#404558',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  minHeight: '100vh',
  paddingBottom: theme.spacing(4),
  paddingLeft: '10%',
  paddingRight: '10%',
  [theme.breakpoints.down('lg')]: {
    paddingTop: '10%'
  },
  [theme.breakpoints.up("sm")]: {
    paddingTop: '20%'
  }
}));

const AttorneyHubLogo = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    marginBottom: '2em',
  },
  [theme.breakpoints.up("sm")]: {
    marginBottom: '6em',
  },
  width: '90%'
}));

const ForgotPasswordButton = styled(Button)(({ theme }) => ({
  "&.MuiButton-root": {
    color: 'white'
  },
  "&.MuiButton-root:hover": {
    textDecoration: "none",
    backgroundColor: "rgba(255, 255, 255, 0.08)"
  }
}));

interface Props { };

export default function Login(props: Readonly<Props>) {
  const [showPasswordReset, setShowPasswordReset] = React.useState(false);
  const [action, setAction] = React.useState<string | null>(null);
  const [userId, setUserId] = React.useState<string | null>(null);
  const [email, setEmail] = React.useState<string | null>(null);
  const [token, setToken] = React.useState<string | null>(null);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    function handleQueryString() {
      setAction(searchParams.get('action'));
      setUserId(searchParams.get('userId'));
      setEmail(searchParams.get('email'));
      setToken(searchParams.get('token'));
    }
    handleQueryString();
  }, []);

  async function handlePasswordReset(password: string) {
    if (!email || !password) return;
    try {
      const credentials = new UserCredentials(email, password);
      await AuthEmailAPIService.login(credentials, true);
      navigate('/dashboard', { replace: true });
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <MainContainer container>
        <ImageContainer item xs={false} sm={4} lg={7} xl={8} />
        <Grid item xs={12} sm={8} lg={5} xl={4} component={Paper} elevation={6} square>
          <FormContainer>
            <div style={{ width: '100%' }}>
              <AttorneyHubLogo src={ipahLogo} alt="IPAH logo" />
              {action !== 'confirm-password-reset' && (
                <>
                  {!showPasswordReset && <LoginForm />}
                  {showPasswordReset && (
                    <>
                      <Typography variant="h4" align="center" style={{ color: 'white' }}>
                        Reset Password
                      </Typography>
                      <PasswordResetRequestForm />
                    </>
                  )}
                  <ForgotPasswordButton
                    size="small"
                    startIcon={showPasswordReset && <ArrowBackIcon />}
                    onClick={() => setShowPasswordReset(prevValue => (!prevValue))}>
                    {showPasswordReset ? 'Back To Login' : 'Forgot Password?'}
                  </ForgotPasswordButton>
                </>
              )}
              {action === 'confirm-password-reset' && (
                <>
                  <Typography variant="h4" color="textPrimary" align="center" style={{ color: 'white' }}>
                    Create New Password
                  </Typography>
                  <PasswordResetForm
                    userId={userId}
                    token={token}
                    onPasswordReset={handlePasswordReset} />
                </>
              )}
            </div>
            <Grid item xl={12}>
              <Footer mode='dark' />
            </Grid>
          </FormContainer>
        </Grid>
      </MainContainer>
    </React.Fragment>
  )
}
