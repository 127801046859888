import { Action, MTableActions } from '@material-table/core';
import { Upload as UploadIcon } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { PageTab } from 'app/routes/contracts/tabs';
import LoadingButton from 'common/components/loading-button';
import { ActionsContainer } from 'common/styles';

export interface TableActionsComponentProps {
  activeTab: PageTab;
  isMediumDisplaySize: boolean;
  uploading: boolean;
  onUpload: () => void;
}

export function TableActionsComponent(props: Readonly<TableActionsComponentProps>): React.ReactElement {
  return (
    <ActionsContainer>
      {!props.isMediumDisplaySize && (
        <LoadingButton
          color='primary'
          loading={props.uploading}
          startIcon={<UploadIcon />}
          onClick={() => props.onUpload()}>
          {`Upload ${props.activeTab}`}
        </LoadingButton>
      )}
      <MTableActions {...props} />
    </ActionsContainer>
  );
}

export function getTableActions(
  activeTab: PageTab,
  isMediumDisplaySize: boolean,
  uploading: boolean,
  onUpload: () => void
): Action<object>[] {
  return [
    uploadDocumentFile(activeTab, isMediumDisplaySize, uploading, onUpload)
  ];
}

function uploadDocumentFile(
  activeTab: PageTab,
  isMediumDisplaySize: boolean,
  uploading: boolean,
  onUpload: () => void
): Action<object> {
  return {
    icon: () => uploading 
      ? <CircularProgress style={{ color: '#999' }} /> 
      : <UploadIcon color='primary' fontSize='large' />,
    iconProps: { 
      color: 'primary', 
      fontSize: 'large' 
    },
    tooltip: `Upload ${activeTab}`,
    position: 'toolbar',
    hidden: !isMediumDisplaySize,
    disabled: uploading,
    isFreeAction: true,
    onClick: onUpload
  };
}
