import Guid from "common/values/guid/guid";
import Date from "common/values/date/date";
import Individual from "marketplace/entities/individual/individual";
import Session from "users/session/session";
import EntityRepresentative from "work/entities/entity-representative/entity-representative";
import EntityVendorRepresentative from "work/entities/entity-vendor-representative/entity-vendor-representative";
import ProposalRedline, {
  RedlinedDates,
  RedlinedDocuments,
} from "work/entities/proposal/redlining/proposal-redline";
import WorkAgreement from "work/entities/work-agreement/work-agreement";
import { ProposalAction, ProposalFieldName, ProposalStatus } from "work/values/constants";
import FeeScheduleCategory from "work/values/fee-schedule-category/fee-schedule-category";
import ProjectDescription from "work/values/project-description/project-description";
import ProjectName from "work/values/project-name/project-name";
import ProposalReviewer from "work/values/proposal-reviewer";
import DetailedTeam from "work/values/team/detailed-team";
import Team from "work/values/team/team";
import User from "users/entities/user/user";
import EntityClientRepresentative from "work/entities/entity-client-representative/entity-client-representative";
import UserInfo from "work/values/user-info/user-info";
import WorkDocument from "work/values/work-document/work-document";
import Percent from "common/values/percent/percent";
import FieldRedline, { FieldRedlineArray } from "work/entities/proposal/redlining/field-redline";
import AHBoolean from "common/values/boolean/boolean";
import { FeeScheduleRedline } from "work/entities/proposal/redlining/fee-schedule-redline/fee-schedule-redline";

export default class Proposal {
  private readonly _RFPId?: Guid;
  private readonly _apiService: IProposalAPIService;

  private readonly _id?: Guid;
  private readonly _name: ProjectName;
  private readonly _description: ProjectDescription;
  private readonly _creator?: EntityRepresentative;
  private readonly _creatorInfo?: UserInfo;
  private readonly _createdDate?: Date;
  private readonly _lastUpdated?: Date;

  private readonly _workAgreement: WorkAgreement;

  private readonly _negotiable: boolean = true;
  private readonly _responseDueBy?: Date;

  private readonly _supersedes?: Proposal;
  private readonly _supersededById: Guid | null;

  private readonly _clientReviewers: ProposalReviewer[] = [];
  private readonly _vendorReviewers: ProposalReviewer[] = [];
  private readonly _status: ProposalStatus = ProposalStatus.AwaitingSubmission;
  private readonly _availableActions: { [key in ProposalAction]: Guid[] };

  private readonly _details?: ProposalDetails;
  private readonly _detailed: boolean = false;

  private _redlining?: ProposalRedline;

  // Stores a reference to the instance of the proposal
  // Used because Material Table strips out methods
  readonly _instance: Proposal;

  constructor(
    apiService: IProposalAPIService,
    spec: CompleteProposalSpec,
    metaInfo?: ProposalMetaInfo,
    details?: ProposalDetails,
    isDetailed: boolean = false
  ) {
    this._apiService = apiService;

    this._name = spec.name;
    this._description = spec.description;

    const workAgreement = Object.assign(
      new WorkAgreement(spec.name.clone(), spec.client.clone()),
      spec.workAgreement
    );
    this._workAgreement = workAgreement.clone();
    this._workAgreement.RFPId = metaInfo?.RFPId;

    this._negotiable = spec.negotiable;
    this._responseDueBy = spec.responseDueBy;
    this._clientReviewers = spec.clientReviewers ?? [];
    this._vendorReviewers = spec.vendorReviewers ?? [];

    this._RFPId = metaInfo?.RFPId;
    this._id = metaInfo?.id;
    this._createdDate = metaInfo?.createdDate;
    this._lastUpdated = metaInfo?.lastUpdated;
    this._creator = metaInfo?.creator;
    this._creatorInfo = metaInfo?.creatorInfo;
    this._supersededById = metaInfo?.supersededById ?? null;
    this._status = metaInfo?.status ?? ProposalStatus.AwaitingSubmission;
    this._availableActions = metaInfo?.availableActions ?? {
      [ProposalAction.Submit]: [],
      [ProposalAction.Approve]: [],
      [ProposalAction.Reject]: [],
      [ProposalAction.Revise]: [],
      [ProposalAction.Hire]: [],
      [ProposalAction.Delete]: [],
      [ProposalAction.Cancel]: [],
      [ProposalAction.Edit]: [],
      [ProposalAction.Manage]: [],
      [ProposalAction.Review]: [],
    };
    this._supersedes = details?.supersedes;
    this._details = details;
    this._instance = this;
    this._redlining = details?.redlining;
    if (!this._redlining) {
      this.createRedline(details?.supersedes);
    }
    this._detailed = isDetailed;
  }

  public get RFPId(): Guid | undefined {
    return this._RFPId;
  }

  public get id(): Guid | null {
    return this._id ? this._id?.clone() : null;
  }
  public get creator(): EntityRepresentative | null {
    return this._creator?.clone() ?? null;
  }
  public get creatorInfo(): UserInfo | null {
    return this._creatorInfo?.clone() ?? null;
  }
  public get createdDate(): Date | null {
    return this._createdDate?.clone() ?? null;
  }
  public get lastUpdated(): Date | null {
    return this._lastUpdated?.clone() ?? null;
  }
  public get status(): ProposalStatus {
    return this._status;
  }
  public get name(): ProjectName {
    return this._name.clone();
  }
  public get description(): ProjectDescription {
    return this._description?.clone();
  }
  public get negotiable(): boolean {
    return this._negotiable;
  }
  public get responseDueBy(): Date | undefined {
    return this._responseDueBy?.clone();
  }
  public get clientReviewers(): ProposalReviewer[] {
    return [...this._clientReviewers];
  }
  public get vendorReviewers(): ProposalReviewer[] {
    return [...this._vendorReviewers];
  }
  public get supersedes(): Proposal | null {
    return this._supersedes ?? null;
  }
  public get supersededById(): Guid | null {
    return this._supersededById ?? null;
  }

  public get client(): EntityClientRepresentative | null {
    return this._workAgreement.client?.clone() ?? null;
  }
  public get vendors(): EntityVendorRepresentative[] | null {
    return this._workAgreement.vendors.map((vendor) => vendor.clone()) ?? null;
  }
  public get team(): Team | null {
    return this._workAgreement.team?.clone() ?? null;
  }
  public get teamRestricted(): AHBoolean {
    return this._workAgreement.teamRestricted;
  }
  public get feeSchedule(): FeeScheduleCategory[] {
    return (
      this._workAgreement.feeSchedule?.map((category) => category.clone()) ?? []
    );
  }
  public get startDate(): Date | undefined {
    return this._workAgreement.startDate?.clone();
  }
  public get endDate(): Date | undefined {
    return this._workAgreement.endDate?.clone();
  }
  public get discount(): Percent {
    return this._workAgreement.discount;
  }
  public get clientPolicyDocuments(): WorkDocument[] {
    return this._workAgreement.clientPolicyDocuments.map((doc) => doc.clone());
  }
  public get vendorPolicyDocuments(): WorkDocument[] {
    return this._workAgreement.vendorPolicyDocuments.map((doc) => doc.clone());
  }
  public get conflictsDocuments(): WorkDocument[] {
    return this._workAgreement.conflictsDocuments.map((doc) => doc.clone());
  }
  public get clientTeamTemplateIds(): Guid[] {
    const templates = this._workAgreement.clientTeamTemplateIds;
    return templates.map((id) => id.clone());
  }
  public get vendorTeamTemplateIds(): Guid[] {
    const templates = this._workAgreement.vendorTeamTemplateIds;
    return templates.map((id) => id.clone());
  }
  public get clientFeeScheduleTemplateIds(): Guid[] {
    const templates = this._workAgreement.clientFeeScheduleTemplateIds;
    return templates.map((id) => id.clone());
  }
  public get vendorFeeScheduleTemplateIds(): Guid[] {
    const templates = this._workAgreement.vendorFeeScheduleTemplateIds;
    return templates.map((id) => id.clone());
  }
  public get conflictsCheckWaived(): AHBoolean {
    return this._workAgreement.conflictsCheckWaived ?? new AHBoolean(false);
  }
  public get availableActions(): { [key in ProposalAction]: Guid[] } {
    return this._availableActions;
  }
  public get details(): ProposalDetails | undefined {
    return this._details;
  }
  public get isDetailed(): boolean {
    return this._detailed;
  }

  public clone(): Proposal {
    return new Proposal(
      this._apiService,
      this.spec,
      this.metaInfo,
      this.details
    );
  }

  public hasFee(category: FeeScheduleCategory): boolean {
    return (
      this._workAgreement.feeSchedule?.some((cat) =>
        cat.name?.isEqualTo(category.name)
      ) ?? false
    );
  }

  public isAssociatedWithUserId(id: Guid): boolean {
    if (this._creator?.userId.isEqualTo(id)) return true;
    if (this._clientReviewers.some((reviewer) => reviewer.userId.isEqualTo(id)))
      return true;
    if (this._vendorReviewers.some((reviewer) => reviewer.userId.isEqualTo(id)))
      return true;
    if (this._status !== ProposalStatus.AwaitingSubmission) {
      if (this._workAgreement.client?.userId.isEqualTo(id)) return true;
      if (this._workAgreement.team?.leader?.userId.isEqualTo(id)) return true;
      if (
        this._workAgreement.team?.memberUserIds.some((userId) =>
          userId.isEqualTo(id)
        )
      )
        return true;
    }
    return false;
  }

  public get spec(): CompleteProposalSpec {
    const workAgreement = this._workAgreement.clone();

    return {
      client: workAgreement.client,
      name: this._name.clone(),
      description: this._description.clone(),
      workAgreement: workAgreement,
      negotiable: this._negotiable,
      responseDueBy: this._responseDueBy?.clone() ?? undefined,
      clientReviewers: this.clientReviewers.map((reviewer) => reviewer.clone()),
      vendorReviewers: this.vendorReviewers.map((reviewer) => reviewer.clone()),
    };
  }

  public get metaInfo(): ProposalMetaInfo {
    if (!this._id || !this._createdDate || !this._creator)
      throw new Error(
        "Cannot get meta info for a proposal that has not been saved."
      );
    return {
      RFPId: this._RFPId,
      id: this._id?.clone(),
      createdDate: this._createdDate.clone(),
      lastUpdated: this._lastUpdated?.clone() ?? this._createdDate.clone(),
      creator: this._creator?.clone(),
      creatorInfo: this._creatorInfo?.clone(),
      status: this._status,
      availableActions: this._availableActions,
    };
  }

  public get redline(): ProposalRedline | undefined {
    return this._redlining ?? undefined;
  }

  public async save(
    session: Readonly<Session>,
    originalProposal?: Proposal
  ): Promise<Proposal> {
    if (this._id !== undefined) {
      if (!originalProposal) {
        throw new Error(
          "Cannot save a proposal without the original proposal."
        );
      }
      return await this._apiService.updateProposal(originalProposal, this);
    } else {
      return await this._apiService.createProposal(this, session);
    }
  }

  public async submit(userId?: Guid): Promise<Proposal> {
    if (!userId) {
      throw new Error("Cannot submit a proposal without a user id.");
    }
    if (this.creator?.userId.valueOf() !== userId.valueOf()) {
      throw new Error("Cannot submit a proposal that you did not create.");
    }
    if (this._status !== ProposalStatus.AwaitingSubmission) {
      throw new Error(
        "Cannot submit a proposal that has already been submitted."
      );
    }
    if (this._workAgreement.team === undefined) {
      throw new Error("Cannot submit a proposal without a team.");
    }

    return await this._apiService.submitProposal(this);
  }

  public async approve(userId?: Guid): Promise<Proposal> {
    if (!userId) {
      throw new Error("Cannot approve a proposal without a user id.");
    }
    if (!this.isAssociatedWithUserId(userId)) {
      throw new Error(
        "Cannot approve a proposal that you are not associated with."
      );
    }
    if (
      this.status !== ProposalStatus.AwaitingApprovalByClient &&
      this.status !== ProposalStatus.AwaitingApprovalByTeam &&
      this.status !== ProposalStatus.AwaitingApprovalByTeamLeader &&
      this.status !== ProposalStatus.AwaitingApprovalByVendors
    ) {
      throw new Error(
        "Cannot approve a proposal that is not awaiting approval."
      );
    }
    if (
      !this._workAgreement.feeSchedule ||
      this._workAgreement.feeSchedule.length === 0
    ) {
      throw new Error("Cannot approve a proposal without a fee schedule.");
    }
    if (this._workAgreement.feeSchedule.some((category) => !category.fee)) {
      throw new Error("Cannot approve a proposal with deferred fees.");
    }
    if (!this._workAgreement.team) {
      throw new Error("Cannot approve a proposal without a team.");
    }
    if (
      !this._workAgreement.conflictsCheckWaived &&
      this._workAgreement.conflictsDocuments.length === 0
    ) {
      throw new Error(
        "Cannot approve a proposal without a conflicts check or waiver."
      );
    }

    const updatedProposal = await this._apiService.approveProposal(this);
    return updatedProposal;
  }

  public async reject(userId?: Guid): Promise<Proposal> {
    if (!userId) {
      throw new Error("Cannot reject a proposal without a user id.");
    }
    if (!this.isAssociatedWithUserId(userId)) {
      throw new Error(
        "Cannot reject a proposal that you are not associated with."
      );
    }
    if (
      this.status !== ProposalStatus.AwaitingApprovalByClient &&
      this.status !== ProposalStatus.AwaitingApprovalByTeam &&
      this.status !== ProposalStatus.AwaitingApprovalByTeamLeader &&
      this.status !== ProposalStatus.AwaitingApprovalByVendors
    ) {
      throw new Error(
        "Cannot reject a proposal that is not awaiting approval."
      );
    }

    return await this._apiService.rejectProposal(this);
  }

  public async hire(userId?: Guid): Promise<Proposal> {
    if (!userId) {
      throw new Error("Cannot hire a proposal without a user id.");
    }
    if (this._workAgreement.hireDate !== undefined) {
      throw new Error("Cannot hire a proposal that has already been hired.");
    }
    if (this.status !== ProposalStatus.AwaitingHire) {
      throw new Error("Cannot hire a proposal that is not awaiting hire.");
    }

    return await this._apiService.hireProposal(this);
  }

  public async delete(userId?: Guid): Promise<void> {
    if (!userId) {
      throw new Error("Cannot delete a proposal without a user id.");
    }
    if (this.creator?.userId.valueOf() !== userId.valueOf()) {
      throw new Error("Cannot delete a proposal that you did not create.");
    }
    if (this.status !== ProposalStatus.AwaitingSubmission) {
      throw new Error(
        "Cannot delete a proposal that has already been submitted."
      );
    }

    return await this._apiService.deleteProposal(this);
  }

  public async cancel(userId?: Guid): Promise<Proposal> {
    if (!userId) {
      throw new Error("Cannot cancel a proposal without a user id.");
    }
    if (this.creator?.userId.valueOf() !== userId.valueOf()) {
      throw new Error("Cannot cancel a proposal that you did not create.");
    }

    return await this._apiService.cancelProposal(this);
  }

  private async createRedline(previousVersion: Proposal | undefined) {
    if (
      !this.id ||
      !this.name ||
      !this.description ||
      !this.details ||
      this.status === ProposalStatus.AwaitingSubmission
    )
      return;
    const session = Session.loadFromStorage(() => {});

    if (
      !previousVersion ||
      this.creator?.userId.isEqualTo(session.user?.id) ||
      !this.supersedes
    ) {
      previousVersion = this;
    }

    const nameRedline = new FieldRedline<ProjectName>(
      ProposalFieldName.Name,
      null,
      previousVersion.name,
      this.name,
      undefined,
      true
    );

    const descriptionRedline = new FieldRedline<ProjectDescription>(
      ProposalFieldName.Description,
      null,
      previousVersion?.description,
      this.description,
      undefined,
      true
    );    

    const teamRedline = new FieldRedlineArray<Individual>(
      ProposalFieldName.Team,
      previousVersion?.details?.team?.members ?? [],
      this.details?.team?.members ?? []
    );
    const feeScheduleRedline = FeeScheduleRedline.fromCategories(
      previousVersion?.feeSchedule,
      this.feeSchedule
    );

    const conflictsCheckWaivedRedline = new FieldRedline<AHBoolean>(
      ProposalFieldName.WaiveConflictsCheck,
      null,
      new AHBoolean(previousVersion?.conflictsCheckWaived.valueOf() ?? false),
      new AHBoolean(this.conflictsCheckWaived.valueOf() ?? false)
    );
    const teamRestricted = new FieldRedline<AHBoolean>(
      ProposalFieldName.TeamRestriction,
      null,
      new AHBoolean(previousVersion?.teamRestricted.valueOf() ?? false),
      new AHBoolean(this.teamRestricted.valueOf() ?? false)
    );
    const documentsRedline: RedlinedDocuments = {
      clientPolicyDocuments: new FieldRedlineArray<WorkDocument>(
        ProposalFieldName.ClientPolicies,
        previousVersion?.clientPolicyDocuments,
        this.clientPolicyDocuments
      ),
      vendorPolicyDocuments: new FieldRedlineArray<WorkDocument>(
        ProposalFieldName.VendorPolicies,
        previousVersion?.vendorPolicyDocuments,
        this.vendorPolicyDocuments
      ),
      conflictsDocuments: new FieldRedlineArray<WorkDocument>(
        ProposalFieldName.Conflicts,
        previousVersion?.conflictsDocuments,
        this.conflictsDocuments
      ),
    };
    const datesRedline: RedlinedDates = {
      startDate: new FieldRedline<Date>(
        ProposalFieldName.StartDate,
        null,
        previousVersion?.startDate ? previousVersion.startDate.clone() : null,
        this.startDate ? this.startDate.clone() : null
      ),
      endDate: new FieldRedline<Date>(
        ProposalFieldName.EndDate,
        null,
        previousVersion?.endDate ? previousVersion.endDate.clone() : null,
        this.endDate ? this.endDate.clone() : null
      ),
      responseDueBy: new FieldRedline<Date>(
        ProposalFieldName.ResponseDueBy,
        null,
        previousVersion?.responseDueBy ? previousVersion.responseDueBy.clone() : null,
        this.responseDueBy ? this.responseDueBy.clone() : null
      ),
    };
    const discountRedline = new FieldRedline<Percent>(
      ProposalFieldName.Discount,
      null,
      previousVersion?.discount ?? null,
      this.discount ?? null
    );

    this._redlining = new ProposalRedline(
      nameRedline,
      descriptionRedline,
      datesRedline,
      teamRedline,
      feeScheduleRedline,
      conflictsCheckWaivedRedline,
      teamRestricted,
      documentsRedline,
      discountRedline
    );
  }

  userCanRevise(user?: User | null): boolean {
    if (!user?.id) return false;
    return this.availableActions[ProposalAction.Revise].some((id) =>
      id.isEqualTo(user.id)
    );
  }

  userCanApprove(user?: User | null): boolean {
    if (!user?.id) return false;
    if (
      !this._workAgreement.conflictsCheckWaived &&
      this._workAgreement.conflictsDocuments.length === 0
    )
      return false;
    return this.availableActions[ProposalAction.Approve].some((id) =>
      id.isEqualTo(user.id)
    );
  }

  userCanReject(user?: User | null): boolean {
    if (!user?.id) return false;
    return this.availableActions[ProposalAction.Reject].some((id) =>
      id.isEqualTo(user.id)
    );
  }

  userCanHire(user?: User | null): boolean {
    if (!user?.id) return false;
    return this.availableActions[ProposalAction.Hire].some((id) =>
      id.isEqualTo(user.id)
    );
  }

  public async saveRedlining(): Promise<Proposal | undefined> {
    if (!this._redlining || !this._id) return;

    const proposalId: Guid = this._id;

    function makeReplacer() {
      let isInitial = true;

      return (key: string, value: any) => {
        if (isInitial) {
          isInitial = false;
          return value;
        }
        if (key === "parent") return undefined;
        return value;
      };
    }

    const replacer = makeReplacer();
    const clearedHistoryRedline = this._redlining.clearSessionHistory();
    const redliningJSON = clearedHistoryRedline.toJSON();

    const redliningJson = JSON.stringify(redliningJSON, replacer);
    return await this._apiService.saveRedlining(proposalId, redliningJson);
  }

  public get redlinedRevision(): Proposal {
    if (!this._redlining?.isResolved)
      throw new Error("Cannot load an unresolved redline revision.");
    const revisionSpec = this.spec;
    if (!revisionSpec.workAgreement){
      throw new Error(
        "Cannot load a redlined revision without a work agreement."
      );
    }
    
    if(this._redlining.name.currentEntry){
      revisionSpec.name = this._redlining.name.currentEntry;
    }
    
    if(this._redlining.description.currentEntry){
      revisionSpec.description = this._redlining.description.currentEntry;
    }

    revisionSpec.responseDueBy = this._redlining.responseDueBy.currentEntry?.clone()
    revisionSpec.workAgreement.startDate = this._redlining.startDate.currentEntry?.clone()
    revisionSpec.workAgreement.endDate = this._redlining.endDate.currentEntry?.clone()

    if (!this.team?.leader)
      throw new Error("Cannot load a redlined revision without a team leader.");
    const memberUserIds: Guid[] = [];
    this._redlining.team.redlines
      .filter((redlineMember) => redlineMember.currentEntry?.userId)
      .forEach((redlineMember) => {
        if (redlineMember.currentEntry?.userId)
          memberUserIds.push(redlineMember.currentEntry.userId);
      });
    const team = new Team(this.team.leader, memberUserIds);
    revisionSpec.workAgreement.team = team;    

    revisionSpec.workAgreement.feeSchedule = [];
    for(const redlineCategory of this._redlining.feeSchedule){
      if(!redlineCategory.currentEntry) continue;
      revisionSpec.workAgreement.feeSchedule.push(redlineCategory.currentEntry);
    }

    revisionSpec.workAgreement.conflictsCheckWaived =
      this._redlining.conflictsCheckWaived.currentEntry ?? new AHBoolean(false);
    revisionSpec.workAgreement.conflictsDocuments =
      this._redlining.conflictsDocuments.redlines
        .filter((redline) => redline.currentEntry)
        .map((redline) => redline.currentEntry) as WorkDocument[];
    revisionSpec.workAgreement.clientPolicyDocuments =
      this._redlining.clientPolicyDocuments.redlines
        .filter((redline) => redline.currentEntry)
        .map((redline) => redline.currentEntry) as WorkDocument[];
    revisionSpec.workAgreement.vendorPolicyDocuments =
      this._redlining.vendorPolicyDocuments.redlines
        .filter((redline) => redline.currentEntry)
        .map((redline) => redline.currentEntry) as WorkDocument[];
    revisionSpec.workAgreement.discount =
      this._redlining.discount.currentEntry ?? new Percent(0);
    const revision = new Proposal(
      this._apiService,
      revisionSpec,
      this.metaInfo,
      this.details
    );
    return revision;
  }

  public updateRedline(redline: ProposalRedline): Proposal {
    const newProposal = this.clone();
    newProposal._redlining = redline;
    return newProposal;
  }

  public async requestRevision(session: Readonly<Session>): Promise<Proposal> {
    const newRevision = await this._apiService.requestRevision(this, session);
    return newRevision;
  }
}

export interface IProposalAPIService {
  saveRedlining(id: Guid, redliningJson: string): Promise<Proposal>;
  getProposalRevisions(
    id: Guid,
    abortController?: AbortController
  ): Promise<Proposal[]>;
  createProposal(
    proposal: Proposal,
    session: Readonly<Session>
  ): Promise<Proposal>;
  updateProposal(
    originalProposal: Proposal,
    updatedProposal: Proposal
  ): Promise<Proposal>;
  deleteProposal(proposal: Proposal): Promise<void>;
  hireProposal(proposal: Proposal): Promise<Proposal>;
  cancelProposal(proposal: Proposal): Promise<Proposal>;
  submitProposal(proposal: Proposal): Promise<Proposal>;
  approveProposal(proposal: Proposal): Promise<Proposal>;
  rejectProposal(proposal: Proposal): Promise<Proposal>;
  requestRevision(
    proposal: Proposal,
    session: Readonly<Session>
  ): Promise<Proposal>;
}

export type CompleteProposalSpec = {
  client: EntityClientRepresentative;
  name: ProjectName;
  description: ProjectDescription;
  negotiable: boolean;
  workAgreement?: WorkAgreement;
  responseDueBy?: Date;
  startDate?: Date;
  endDate?: Date;
  clientReviewers?: ProposalReviewer[];
  vendorReviewers?: ProposalReviewer[];
};

export type ProposalMetaInfo = {
  RFPId?: Guid;
  id: Guid;
  createdDate: Date;
  lastUpdated: Date;
  creator: EntityRepresentative;
  creatorInfo?: UserInfo;
  status: ProposalStatus;
  supersededById?: Guid;
  availableActions: { [key in ProposalAction]: Guid[] };
};

export type ProposalDetails = {
  client: Individual;
  team?: DetailedTeam;
  redlining?: ProposalRedline;
  supersedes?: Proposal;
  supersededById?: Guid;
};

export type FreelyPatchableFields = {
  reviewers: ProposalReviewer[];
  teamTemplateIds: Guid[];
  feeScheduleTemplateIds: Guid[];
};

export type ProposalField = {
  name: ProposalFieldName;
  id?: Guid | null;
};