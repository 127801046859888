import MaterialTable, {
  Action,
  Column,
  MTableActions,
  MTableToolbar,
  MaterialTableProps,
  Query,
  QueryResult,
} from "@material-table/core";
import DownloadIcon from "@mui/icons-material/Download";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import UploadIcon from "@mui/icons-material/Upload";
import {
  CheckboxProps,
  CircularProgress,
  IconButton,
  Tab,
  Tabs,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import LoadingButton from "common/components/loading-button";
import TableContainerComponent from "common/components/table/container";
import EmptyTableMessage from "common/components/table/empty-table-message";
import { downloadFile } from "common/helpers/utils";
import {
  ActionsContainer,
  TableTheme,
  TableToolbarContainer,
} from "common/styles";
import Guid from "common/values/guid/guid";
import DocumentAPIService from "documents/entities/document/api/document-api-service";
import Document from "documents/entities/document/document";
import DocumentTopic from "documents/values/document-topic";
import { DocumentType } from "documents/values/document-type";
import { enqueueSnackbar } from "notistack";
import React, { useEffect } from "react";
import { FileIcon, defaultStyles } from "react-file-icon";
import Session from "users/session/session";
import { useSession } from "users/session/session-context";
import PaginationParameters from "common/contracts/pagination-parameters";
import DocumentTopicParameters from "documents/entities/document/api/request-contracts/document-topic-parameters";
import DocumentOrderParameters from "documents/entities/document/api/request-contracts/document-order-parameters";
import DocumentFilterParameters from "documents/entities/document/api/request-contracts/document-filter-parameters";
import DateRangePicker from "common/components/date-range-picker";
import moment from "moment";

const FileName = styled(Typography)(() => ({
  fontSize: "0.95em",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));
const NameContainer = styled("div")(({ theme }) => ({
  alignItems: "center",
  columnGap: theme.spacing(1),
  display: "flex",
  maxWidth: "20rem",
  paddingLeft: theme.spacing(2),
  svg: {
    width: "24px",
  },
}));
const TableContainer = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  "& .MuiToolbar-root:first-of-type": {
    padding: 0,
  },
  "& #tableContainer": {
    borderTop: 0,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    "& table.MuiTable-root": {
      tableLayout: "fixed !important",
    },
  },
}));

export type TableDocument = Document & { tableData: { checked: boolean } };

type DocumentSelectorProps = {
  isLoading?: boolean;
  documentType: "conflicts" | "policy";
  selectedTab?: "templates" | "documents";
  selectedDocumentIds: Guid[];
  selectedTemplateIds?: Guid[];
  onDocumentSelectionChanged: (documents: TableDocument[]) => void;
  onCreateDocumentFromTemplate: (templateId: Guid) => void;
};

export default function DocumentSelector(
  props: Readonly<DocumentSelectorProps>
) {
  const {
    documentType,
    selectedTab,
    selectedDocumentIds,
    selectedTemplateIds,
    onDocumentSelectionChanged,
    onCreateDocumentFromTemplate,
  } = props;

  const documentFileUploaderRef = React.useRef<HTMLInputElement | null>(null);
  const tableRef = React.useRef<
    MaterialTable<Document> & MaterialTableProps<Document>
  >();

  const [isLoading, setIsLoading] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState<"templates" | "documents">(
    selectedTab ?? "templates"
  );
  const [availableDocuments, setAvailableDocuments] = React.useState<
    Document[]
  >([]);
  const [columns] = React.useState<Column<Document>[]>(getTableColumns());

  const session = useSession();
  const isMediumDisplaySize = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  useEffect(() => {
    refreshSelectedDocuments();
  }, [selectedDocumentIds, selectedTemplateIds, availableDocuments]);

  function refreshSelectedDocuments() {
    const tableIds: Guid[] = [];
    for (const rowData of (tableRef.current as any).dataManager.data) {
      tableIds.push(rowData.id);
      try {
        (tableRef.current as any).dataManager.changeRowSelected(
          selectedDocumentIds.some((id) => id.isEqualTo(rowData.id)),
          [rowData.tableData.index]
        );
      } catch (error) {
        continue;
      }
    }
    const selectedCount = tableIds.filter((tableId) => {
      return selectedDocumentIds.some(selectedId => tableId.isEqualTo(selectedId))
    }).length;
    (tableRef.current as any).dataManager.selectedCount = selectedCount;
  }

  function getUploadIcon() {
    return isUploading ? (
      <CircularProgress style={{ color: "#999" }} />
    ) : (
      <UploadIcon color="primary" fontSize="large" />
    );
  }

  function getTableActions(): Action<object>[] {
    return [
      {
        icon: getUploadIcon,
        iconProps: {
          color: "primary",
          fontSize: "large",
        },
        tooltip: `Upload ${
          activeTab === "templates" ? "Template" : "Document"
        }`,
        position: "toolbar",
        hidden: !isMediumDisplaySize || activeTab === "documents",
        disabled: isUploading,
        isFreeAction: true,
        onClick: () => documentFileUploaderRef.current?.click(),
      }
    ];
  }

  function getDateRangePicker(props: {
    columnDef: Column<Document>;
    onFilterChanged: (columnId: number, value: any) => void;
  }): React.ReactElement {
    const { columnDef, onFilterChanged } = props;

    const minDate =
      (columnDef as any).tableData.filterValue?.minDate ??
      columnDef.defaultFilter?.minDate;
    const maxDate =
      (columnDef as any).tableData.filterValue?.maxDate ??
      columnDef.defaultFilter?.maxDate;

    return (
      <DateRangePicker
        minDate={minDate}
        maxDate={maxDate}
        onMinDateChange={(newDate) => {
          const dateRange = {
            minDate: newDate,
            maxDate: maxDate,
          };
          onFilterChanged((columnDef as any).tableData.id as number, dateRange);
        }}
        onMaxDateChange={(newDate) => {
          const dateRange = {
            minDate: minDate,
            maxDate: newDate,
          };
          onFilterChanged((columnDef as any).tableData.id as number, dateRange);
        }}
      />
    );
  }

  function getTableColumns(): Column<Document>[] {
    return [
      {
        field: "name",
        title: "Name",
        type: "string",
        width: "42.5%",
        render: (rowData) => renderNameColumn(rowData),
      },
      {
        field: "created",
        title: "Created",
        type: "date",
        align: "right",
        width: "42.5%",
        render: (rowData) => rowData.created?.format("MM/DD/YY hh:mm A"),
        filterComponent: getDateRangePicker,
      },
      {
        title: "Actions",
        field: "actions",
        filtering: false,
        align: "right",
        width: "15%",
        render: (rowData: Document) => (
          <>
            <Tooltip title="Create Document from Template">
              <span>
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    onCreateDocumentFromTemplate(rowData.id);
                  }}
                >
                  <ContentPasteGoIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Download">
              <span>
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    handleDownload(rowData.id);
                  }}
                >
                  <DownloadIcon color="primary" />
                </IconButton>
              </span>
            </Tooltip>
          </>
        ),
      },
    ];
  }

  function renderNameColumn(rowData: Document): React.ReactNode {
    const fileType = rowData.fileType.toString() as keyof typeof DocumentType;

    return (
      <NameContainer>
        <FileIcon
          extension={fileType}
          {...defaultStyles[
            fileType.toLowerCase() as keyof typeof defaultStyles
          ]}
        />
        <FileName>{rowData.name}</FileName>
      </NameContainer>
    );
  }

  function getTableContainerComponent(props: any): React.ReactElement {
    return (
      <TableContainer>{TableContainerComponent({ ...props })}</TableContainer>
    );
  }

  function getTableActionsComponent(props: any): React.ReactElement {
    return (
      <ActionsContainer>
        {!isMediumDisplaySize && activeTab === "templates" && (
          <LoadingButton
            color="primary"
            loading={isUploading}
            startIcon={<UploadIcon />}
            onClick={() => documentFileUploaderRef.current?.click()}
          >
            Upload Template
          </LoadingButton>
        )}
        <MTableActions {...props} />
      </ActionsContainer>
    );
  }

  function getTableToolbarComponent(props: any) {
    return (
      <TableToolbarContainer>
        <MTableToolbar {...props} />
        <Tabs
          value={activeTab}
          onChange={(_event, value) => {
            setActiveTab(value);
            tableRef?.current?.onQueryChange();
          }}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Templates" value="templates" />
          <Tab label="Documents In Use" value="documents" />
        </Tabs>
      </TableToolbarContainer>
    );
  }

  async function loadData(
    session: Session,
    abortController: AbortController,
    query: Query<Document>,
    documentType: string,
    setLoading: (isLoading: boolean) => void
  ): Promise<QueryResult<Document>> {
    const emptyResponse = {
      data: [],
      page: 0,
      totalCount: 0,
    } as QueryResult<Document>;

    try {
      setLoading(true);
      const apiService = new DocumentAPIService(session);
      const paginationParams = new PaginationParameters(
        query.page + 1,
        query.pageSize
      );
      const order = query.orderByCollection.find(
        (order) => order.sortOrder !== undefined
      );
      const orderParams = new DocumentOrderParameters(
        order?.orderByField === "name" ? "name" : "created",
        order?.orderDirection === "asc" ? "asc" : "desc"
      );
      const topicParams = new DocumentTopicParameters(
        activeTab === "documents" ? "Work.Proposal" : undefined,
        undefined,
        documentType
      );
      const createdFilterValue = query.filters.find(
        (filter) => filter.column.field === "created"
      )?.value;
      const filterParams = new DocumentFilterParameters(
        query.filters.find((filter) => filter.column.field === "name")?.value,
        createdFilterValue?.minDate
          ? moment(createdFilterValue.minDate).format("MM-DD-YYYY")
          : undefined,
        createdFilterValue?.maxDate
          ? moment(createdFilterValue.maxDate).format("MM-DD-YYYY")
          : undefined
      );
      const paginatedResponse = await apiService.getDocumentsInfo(
        abortController,
        paginationParams,
        topicParams,
        orderParams,
        filterParams
      );

      setAvailableDocuments(paginatedResponse.data);

      return {
        data: paginatedResponse.data,
        page: paginatedResponse.pageNumber - 1,
        totalCount: paginatedResponse.totalElements,
      };
    } catch (error) {
      console.error(error);

      return emptyResponse;
    } finally {
      setLoading(false);
    }
  }

  async function handleDownload(id: Guid): Promise<void> {
    try {
      if (!id) Promise.reject(new Error("No document id provided"));
      const service = new DocumentAPIService(session);
      const response = await service.downloadDocument(id);
      downloadFile(response);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to download file", { variant: "error" });
    }
  }

  function getDocumentType() {
    if (documentType === "conflicts" && activeTab === "templates") {
      return "ConflictsTemplate";
    }
    if (documentType === "conflicts" && activeTab === "documents") {
      return "Conflicts";
    }
    if (documentType === "policy" && activeTab === "templates") {
      return "PolicyTemplate";
    }
    if (documentType === "policy" && activeTab === "documents") {
      return "Policy";
    }
    return "";
  }

  async function handleDocumentFileUpload(
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> {
    if (!event.currentTarget.files) return;

    const file: File = event.currentTarget.files[0];

    try {
      setIsUploading(true);

      let context: string | undefined;
      context = documentType === "conflicts" ? "Conflicts" : "Policy";
      if (activeTab === "templates") {
        context = context.concat("Template");
      }
      const service = new DocumentAPIService(session);
      await service.createDocument(file, file.name, [
        new DocumentTopic(undefined, undefined, context),
      ]);
      enqueueSnackbar("Uploaded file", { variant: "success" });
      tableRef?.current?.onQueryChange(); // Force table data refresh
    } catch (error: any) {
      if (error.response && error.response.status === 415) {
        enqueueSnackbar(error.response.data, { variant: "error" });
      } else if (error.response && error.response.status === 422) {
        enqueueSnackbar("Unsupported file type", { variant: "error" });
      } else {
        enqueueSnackbar("Unknown error occurred during upload", {
          variant: "error",
        });
      }
    } finally {
      event.target.value = "";
      setIsUploading(false);
    }
  }
  function getRowStyle(rowData: Document): React.CSSProperties {
    const color = selectedDocumentIds.some((id) => rowData.id.isEqualTo(id))
      ? "rgba(0, 0, 0, 0.5)"
      : "inherit";
    let bgColor = selectedTemplateIds?.some((id) => rowData.id.isEqualTo(id))
      ? "rgba(0, 200, 0, 0.1)"
      : "inherit";
    if (selectedDocumentIds.some((id) => rowData.id.isEqualTo(id))) {
      bgColor = TableTheme.row.selected.backgroundColor;
    }

    return {
      backgroundColor: bgColor,
      color: color,
    };
  }

  function getSelectionProps(rowData: Document): CheckboxProps {
    return {
      indeterminate:
        !selectedDocumentIds?.some((id) => rowData.id.isEqualTo(id)) &&
        selectedTemplateIds?.some((id) => rowData.id.isEqualTo(id)),
    };
  }

  return (
    <>
      <MaterialTable
        tableRef={tableRef}
        columns={columns}
        actions={getTableActions()}
        data={(query) =>
          loadData(
            session,
            new AbortController(),
            query,
            getDocumentType(),
            setIsLoading
          )
        }
        isLoading={props.isLoading || isLoading}
        options={{
          tableWidth: "variable",
          debounceInterval: 250,
          emptyRowsWhenPaging: false,
          filtering: true,
          filterRowStyle: TableTheme.filterRow,
          grouping: false,
          maxBodyHeight: "50vh",
          minBodyHeight: "50vh",
          pageSize: 10,
          pageSizeOptions: [1, 10, 25, 50],
          paginationType: isMediumDisplaySize ? "normal" : "stepped",
          rowStyle: getRowStyle,
          showFirstLastPageButtons: !isMediumDisplaySize,
          showTitle: false,
          selection: true,
          selectionProps: getSelectionProps,
          search: false,
          showTextRowsSelected: false,
        }}
        onSelectionChange={(_data, _rowData) => {
          const currentDocuments = (tableRef.current as any)?.dataManager
            .data as TableDocument[];
          onDocumentSelectionChanged(currentDocuments);
        }}
        components={{
          Container: (props) => getTableContainerComponent(props),
          Actions: (props) =>
            getTableActionsComponent({
              isMediumDisplaySize: isMediumDisplaySize,
              uploading: isUploading,
              onUpload: () => documentFileUploaderRef.current?.click(),
              ...props,
            }),
          Toolbar: (props) => getTableToolbarComponent(props),
        }}
        localization={{
          body: {
            emptyDataSourceMessage: (
              <EmptyTableMessage
                icon={
                  <FileCopyOutlinedIcon
                    fontSize="large"
                    style={{ opacity: 0.5 }}
                  />
                }
                title="No Documents"
                description="Upload a document to get started"
              />
            ),
          },
        }}
      />
      <input
        type="file"
        hidden={true}
        ref={documentFileUploaderRef}
        onChange={handleDocumentFileUpload}
      />
    </>
  );
}
