import {
  Action, MTableActions
} from "@material-table/core";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Button, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { PageTab } from "app/routes/marketplace/profiles/tabs";
import { ActionsContainer } from "common/styles/table";
import { NavigateFunction, useNavigate } from "react-router";

const NewProfileButtonContainer = styled("span")<{ disabled: boolean }>(
  ({ disabled, theme }) => ({
    cursor: disabled ? "not-allowed" : "default",
  })
);

const newProfileIcon = (activeTab: PageTab) => {
  if (activeTab === PageTab.Individual) return <PersonAddIcon />;
  if (activeTab === PageTab.Company) return <DomainAddIcon />;
  if (activeTab === PageTab.Teams) return <GroupAddIcon />;
};

export interface TableActionsComponentProps {
  isMediumDisplaySize: boolean;
  activeTab: PageTab;
  navigate: NavigateFunction;
  disableAddProfileButton: () => boolean;
  addProfileDisabledMessage: () => string;
}

export function TableActionsComponent(
  props: Readonly<TableActionsComponentProps>
): React.ReactElement {
  const navigate = useNavigate();
  
  return (
    <ActionsContainer>
      {!props.isMediumDisplaySize && (
        <Tooltip title={props.addProfileDisabledMessage()}>
          <span>
            <NewProfileButtonContainer
              disabled={props.disableAddProfileButton()}
            >
              <Button
                color="primary"
                disabled={props.disableAddProfileButton()}
                startIcon={newProfileIcon(props.activeTab)}
                onClick={() => navigate(`/marketplace/profiles/${props.activeTab}/create`)}
              >
                New Profile
              </Button>
            </NewProfileButtonContainer>
          </span>
        </Tooltip>
      )}
      <MTableActions {...props} />
    </ActionsContainer>
  );
}

export function getTableActions(props: TableActionsComponentProps): Action<object>[] {
  return [
    createProfile(props),
  ];
}

function createProfile(props: TableActionsComponentProps): Action<object> {
  return {
    icon: () => newProfileIcon(props.activeTab),
    iconProps: { color: "primary", fontSize: "large" },
    tooltip: props.addProfileDisabledMessage(),
    position: "toolbar",
    hidden: !props.isMediumDisplaySize,
    disabled: props.disableAddProfileButton(),
    isFreeAction: true,
    onClick: () => props.navigate(`/marketplace/profiles/${props.activeTab}/create`)
  };
}
