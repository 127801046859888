export default class AHBoolean {
  private _value: boolean;

  constructor(value: boolean) {
    this._value = value;
  }
  
  isEqualTo(other: AHBoolean): boolean {
    return this.valueOf() === other?.valueOf();
  }

  public valueOf(): boolean {
    return this._value;
  }

  public static get Prototype(): AHBoolean {
    return new AHBoolean(false);
  }

  public fromObject(obj: any): AHBoolean {
    return new AHBoolean(obj);
  }
  public toJSON(): any {
    return this.valueOf();
  }
  public clone(): AHBoolean {
    return new AHBoolean(this.valueOf());
  }
}
