import Guid from "common/values/guid/guid";
import { Moment } from "moment";


export default class ProposalReviewer {
    userId: Guid;
    canEdit: boolean;
    dateApproved?: Moment;
    customMessage?: string;

    constructor(userId: Guid, canEdit: boolean, customMessage?: string, dateApproved?: Moment) {    
        this.userId = userId;
        this.canEdit = canEdit;
        this.customMessage = customMessage;
        this.dateApproved = dateApproved;
    }

    public clone(): ProposalReviewer {  
        return new ProposalReviewer(this.userId.clone(), this.canEdit, this.customMessage, this.dateApproved);
    }

    public toJSON() : any {
        return {
            userId: this.userId,
            canEdit: this.canEdit,
            dateApproved: this.dateApproved ?? '',
            customMessage: this.customMessage ?? ''
        }
    }
}