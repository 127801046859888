import axios, {
  AxiosHeaders
} from "axios";
import AttorneyHubAPIService from "common/services/api/attorney-hub-api-service";
import EmailAddress from "common/values/email-address/email-address";
import Guid from "common/values/guid/guid";
import Password from "common/values/password/password";
import UserOpenEntityAgreement from "legal-entities/entities/entity-agreement/user-open-entity-agreement";
import moment from "moment";
import UserNetworkConnectionProfile from "users/entities/user-network-connection/user-network-connection-profile";
import NetworkInvitationInfo from "users/entities/user-network-invitation/network-invitation-info";
import LoginWithEmailAPIRequest from "users/entities/user/api/request-contracts/login-with-email-api-request";
import ResetPasswordConfirmationAPIRequest from "users/entities/user/api/request-contracts/reset-password-confirmation-api-request";
import ResetPasswordRequestAPIRequest from "users/entities/user/api/request-contracts/reset-password-request-api-request";
import AuthInfoAPIResponse, { AuthContext } from "users/entities/user/api/response-contracts/auth-info-api-response";
import User from "users/entities/user/user";
import { InvalidCredentialsError } from "users/errors";
import UserAuthToken from "users/values/user-auth-token/user-auth-token";
import UserBookmarkInfo from "users/values/user-bookmark-info/user-bookmark-info";
import UserCredentials from "users/values/user-credentials/user-credentials";
import UserEntityInfo from "users/values/user-entity-info/user-entity-info";
import IUserVendorViewAPIRequest from "./request-contracts/user-vendor-view-api-request";


export type AuthResponse = {
  user: User;
  context: AuthContext;
  authToken: UserAuthToken;
  legalEntities: UserEntityInfo[];
  openEntityAgreements: UserOpenEntityAgreement[];
  networkConnections: UserNetworkConnectionProfile[];
  networkInvitations: NetworkInvitationInfo[];
  bookmarkedIndividuals: UserBookmarkInfo[];
  bookmarkedCompanies: UserBookmarkInfo[];
  bookmarkedTeams: UserBookmarkInfo[];
}

export default class AuthEmailAPIService {

  private static jsonHeaders(): AxiosHeaders {
    return Object.assign(new AxiosHeaders(), {
      "Content-Type": "application/json"
    });
  }

  static async login(credentials: UserCredentials, rememberMe: boolean = false):
    Promise<AuthResponse> {
    try {
      const url = new URL("/users/login", AttorneyHubAPIService.apiBaseUrl);
      const response = await axios.post(
        url.toString(),
        new LoginWithEmailAPIRequest(credentials, rememberMe),
        {
          headers: this.jsonHeaders()
        }
      );

      const authInfo: AuthInfoAPIResponse = Object.assign(
        new AuthInfoAPIResponse(),
        response.data
      );

      const user = authInfo.deserializeUser();
      const context = authInfo.deserializeContext();
      const authToken = authInfo.deserializeAuthToken();
      const legalEntities = authInfo.deserializeUserEntityInfo();
      const openEntityAgreements = authInfo.deserializeOpenEntityAgreements();
      const networkConnections = authInfo.deserializeNetworkConnections();
      const networkInvitations = authInfo.deserializeNetworkInvitations();
      const bookmarkedIndividuals = authInfo.deserializeBookmarkedIndividuals();
      const bookmarkedCompanies = authInfo.deserializeBookmarkedCompanies();
      const bookmarkedTeams = authInfo.deserializeBookmarkedTeams();

      return {
        user,
        context,
        authToken,
        legalEntities,
        openEntityAgreements,
        networkConnections,
        networkInvitations,
        bookmarkedIndividuals,
        bookmarkedCompanies,
        bookmarkedTeams
      };
    } catch (error: any) {
      switch (error.response?.status) {
        case 400:
          console.warn(error.message);
          throw new InvalidCredentialsError();
        case 401:
          throw new InvalidCredentialsError();
        default:
          throw new UserAPIServiceError("login", error);
      }
    }
  }

  static async logout(): Promise<void> {
    console.warn("Logout API call not implemented");
  }

  static async requestPasswordReset(email: EmailAddress): Promise<void> {
    try {
      const url = new URL("/users/request-password-reset", AttorneyHubAPIService.apiBaseUrl);
      await axios.post(
        url.toString(),
        new ResetPasswordRequestAPIRequest(email),
        {
          headers: this.jsonHeaders()
        }
      );
    } catch (error: any) {
      if (error.response?.status === 400)
        console.warn(error.message);
      else
        throw new UserAPIServiceError("requestPasswordReset", error);
    }
  }

  static async comfirmPasswordReset(password: Password, userId: Guid, token: string): Promise<UserAuthToken | undefined> {
    try {
      const url = new URL("/users/confirm-password-reset", AttorneyHubAPIService.apiBaseUrl);
      const response = await axios.post(
        url.toString(),
        new ResetPasswordConfirmationAPIRequest(password, userId, token),
        {
          headers: this.jsonHeaders()
        }
      );
      const authTokenData = response.data;
      AttorneyHubAPIService.setAuthHeader(authTokenData.value); //<-- this should be removed once axios is used in all service calls
      const authToken = new UserAuthToken(authTokenData.value, moment(authTokenData.expiry));
      return authToken;
    } catch (error: any) {
      if (error.response?.status === 400)
        console.warn(error.message);
      throw new UserAPIServiceError("comfirmPasswordReset", error);
    }
  }

  static async updateUserVendorView(viewingAsVendor: boolean, authToken?: Readonly<UserAuthToken>): Promise<void> {
    if (!authToken)
      throw new Error("No auth token provided for updateUserVendorView");
    try {
      const url = new URL("/users/user-vendor-view", AttorneyHubAPIService.apiBaseUrl);
      await axios.patch(
        url.toString(),
        new IUserVendorViewAPIRequest(viewingAsVendor), {
        headers:
          Object.assign(new AxiosHeaders(), {
            "Authorization": `Bearer ${authToken.value}`
          })
      });
    } catch (error: any) {
      if (error.response?.status === 401) {
        throw new ExpiredTokenError();
      }
      throw new UserAPIServiceError("updateUserVendorView", error);
    }
  }
}

export class ExpiredTokenError extends Error {
  constructor() {
    super("Expired token");
  }
}

export class UserAPIServiceError extends Error {
  method: string;
  error: any;
  constructor(method: string, error: any) {
    super(error?.message || "An error occurred");
    this.method = method;
    this.error = error;
  }
}