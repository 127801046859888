import MaterialTable, { MaterialTableProps, MTableToolbar } from "@material-table/core";
import { FilterList as FilterListIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { getTableColumns } from "app/routes/work/table/columns";
import { PageTab } from "app/routes/work/tabs";
import ActiveFiltersList from "common/components/table/active-filters-list";
import { FilterButtons, FilterChip, TableToolbarContainer } from "common/styles";
import { useLocation, useNavigate } from "react-router-dom";
import WorkAgreement from "work/entities/work-agreement/work-agreement";

const NavContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    marginBottom: 0
  },
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '-5px'
}));

export type TableToolbarComponentProps = {
  tableRef: React.MutableRefObject<(MaterialTable<WorkAgreement> & MaterialTableProps<WorkAgreement>) | undefined>;
  activeTab: PageTab;
  tabs: React.ReactNode;
  activeFilters: any[];
  selectedRows: WorkAgreement[];
  inbox: boolean;
  outbox: boolean;
  isMediumDisplaySize: boolean;
  clearFilters: () => void;
  setInbox: (inbox: boolean) => void;
  setOutbox: (outbox: boolean) => void;
}

export default function TableToolbarComponent(props: Readonly<TableToolbarComponentProps>) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <TableToolbarContainer rowsselected={props?.selectedRows?.length > 0 ? true : undefined }>
      <MTableToolbar {...props} />
      <NavContainer>
        {props.tabs}
      <FilterButtons>
        {props.activeTab === PageTab.Active && (
          <>
            <FilterChip
              icon={<FilterListIcon />}
              label="Inbox"
              color="primary"
              variant={props.inbox ? 'filled' : 'outlined'}
              onClick={() => {
                props.setInbox(!props.inbox);
                const newPath = new URL(location.pathname, window.location.origin);
                newPath.searchParams.set('inbox', (!props.inbox).toString());
                navigate(newPath.pathname + newPath.search);
              }}
            />
            <FilterChip
              icon={<FilterListIcon />}
              label="Outbox"
              color="primary"
              variant={props.outbox ? 'filled' : 'outlined'}
              onClick={() => {
                props.setOutbox(!props.outbox);
                const newPath = new URL(location.pathname, window.location.origin);
                newPath.searchParams.set('outbox', (!props.inbox).toString());
                navigate(newPath.pathname + newPath.search);
              }}
            />
          </>
        )}
      </FilterButtons>
      </NavContainer>
      {props?.activeFilters?.length > 0 && (
        <ActiveFiltersList
          tableRef={props.tableRef}
          columns={getTableColumns(props.activeTab, props.tableRef)}
          activeFilters={props.activeFilters}
          onClear={props.clearFilters} />
      )}
    </TableToolbarContainer>
  )
}
