import MaterialTable, { MaterialTableProps, MTableToolbar } from "@material-table/core";
import { FilterList as FilterListIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { getToolbarTableColumns } from "app/routes/proposals/table/toolbar-columns";
import { PageTab } from "app/routes/proposals/tabs";
import ActiveFiltersList from "common/components/table/active-filters-list";
import { FilterButtons, FilterChip, TableToolbarContainer } from "common/styles";
import { useSearchParams } from "react-router-dom";
import Proposal from "work/entities/proposal/proposal";

const NavContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    marginBottom: 0
  },
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '-5px'
}));

export type TableToolbarComponentProps = {
  tableRef: React.MutableRefObject<(MaterialTable<Proposal> & MaterialTableProps<Proposal>) | undefined>;
  activeTab: PageTab;
  tabs: React.ReactNode;
  activeFilters: any[];
  selectedRows: Proposal[];
  inbox: boolean;
  outbox: boolean;
  pendingReview: boolean;
  isMediumDisplaySize: boolean;
  clearFilters: () => void;
  setInbox: (inbox: boolean) => void;
  setOutbox: (outbox: boolean) => void;
  setPendingReview: (pendingReview: boolean) => void;
}

export default function TableToolbarComponent(props: Readonly<TableToolbarComponentProps>) {
  const {
    tableRef,
    activeTab,
    tabs,
    activeFilters,
    selectedRows,
    inbox,
    outbox,
    pendingReview,
    clearFilters,
    setInbox,
    setOutbox,
    setPendingReview
  } = props;

  const [, setSearchParams] = useSearchParams();

  return (
    <TableToolbarContainer rowsselected={selectedRows.length > 0 ? true : undefined}>
      <MTableToolbar {...props} />
      <NavContainer>
        {tabs}
        <FilterButtons>
          {activeTab === PageTab.Active && (
            <>
              <FilterChip
                icon={<FilterListIcon />}
                label="Inbox"
                color="primary"
                variant={inbox ? 'filled' : 'outlined'}
                onClick={() => {
                  setInbox(!inbox);
                  setOutbox(false);
                  setPendingReview(false);
                  setSearchParams((prevParams) => {
                    const params = new URLSearchParams(prevParams);
                    if (!inbox) {
                      params.set('context', 'inbox');
                    } else {
                      params.delete('context');
                    }
                    return params;
                  });
                }}
              />
              <FilterChip
                icon={<FilterListIcon />}
                label="Outbox"
                color="primary"
                variant={outbox ? 'filled' : 'outlined'}
                onClick={() => {
                  setOutbox(!outbox);
                  setInbox(false);
                  setPendingReview(false);
                  setSearchParams((prevParams) => {
                    const params = new URLSearchParams(prevParams);
                    if (!outbox) {
                      params.set('context', 'outbox');
                    } else {
                      params.delete('context');
                    }
                    return params;
                  });
                }}
              />
              <FilterChip
                icon={<FilterListIcon />}
                label="Pending Your Review"
                color="primary"
                variant={pendingReview ? 'filled' : 'outlined'}
                onClick={() => {
                  setPendingReview(!pendingReview);
                  setInbox(false);
                  setOutbox(false);
                  setSearchParams((prevParams) => {
                    const params = new URLSearchParams(prevParams);
                    if (!pendingReview) {
                      params.set('context', 'activereview');
                    } else {
                      params.delete('context');
                    }
                    return params;
                  });
                }}
              />
            </>
          )}
          {activeTab === PageTab.Drafts && (
            <FilterChip
              icon={<FilterListIcon />}
              label="Pending Your Review"
              color="primary"
              variant={pendingReview ? 'filled' : 'outlined'}
              onClick={() => {
                setPendingReview(!pendingReview);
                setInbox(false);
                setOutbox(false);
                setSearchParams((prevParams) => {
                  const params = new URLSearchParams(prevParams);
                  if (!pendingReview) {
                    params.set('context', 'draftreview');
                  } else {
                    params.delete('context');
                  }
                  return params;
                });
              }}
            />
          )}
        </FilterButtons>
      </NavContainer>
      {activeFilters.length > 0 && (
        <ActiveFiltersList
          tableRef={tableRef}
          columns={getToolbarTableColumns(activeTab, tableRef)}
          activeFilters={activeFilters}
          onClear={clearFilters} />
      )}
    </TableToolbarContainer>
  )
}
