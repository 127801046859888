import { ThemeProvider, Typography, createTheme } from '@mui/material';
import { styled } from "@mui/material/styles";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import ErrorList from 'common/components/error-list';
import React, { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { InvalidEmailAddressError, InvalidPasswordError } from 'common/errors';
import EmailAddress from 'common/values/email-address/email-address';
import Password from 'common/values/password/password';
import { useSession } from 'users/session/session-context';
import { InvalidCredentialsError } from 'users/errors';
import LoadingButton from 'common/components/loading-button';
import LoginIcon from '@mui/icons-material/Login';

const PageTitle = styled(Typography)(() => ({
  color: '#fff'
}));
const CredentialInput = styled(TextField)(() => ({
  "&.MuiTextField-root": {
    "& .Mui-focused:not(.Mui-error)": {
      color: "#fff",
      "&.MuiInputBase-root::after": {
        borderBottomColor: "#fff"
      }
    },
  }
}));
const RememberMeToggle = styled(FormControlLabel)(() => ({
  color: '#fff',
  "& .MuiCheckbox-root": {
    color: '#fff',
  }
}));
const LoginButton = styled(LoadingButton)(() => ({
  backgroundColor: '#E19941',
  margin: '1.5em 0',
  "&.MuiButton-root": {
    color: 'black',
    '&:hover': {
      backgroundColor: '#FFB949',
    },
    "&.Mui-disabled": {
      color: "rgba(255, 255, 255, 0.3)",
      boxShadow: "none",
      backgroundColor: "rgba(255, 255, 255, 0.12)"
    }
  }
}));
const ErrorItem = styled('li')(() => ({
  listStyleType: 'none'
}));

type CredentialErrors = {
  general?: string[];
  email?: string;
  password?: string[];
}

type LoginFormProps = { };

export default function LoginForm(props: Readonly<LoginFormProps>) {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [rememberMeChecked, setRememberMeChecked] = React.useState(false);
  const [loginFailed, setLoginFailed] = React.useState(false);
  const [errors, setErrors] = React.useState<CredentialErrors>({ email: '', password: [] });
  const [formValid, setFormValid] = React.useState(false);
  const [signingIn, setSigningIn] = React.useState(false);

  const session = useSession();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const darkTheme = createTheme({ palette: { mode: 'dark' } });
  
  const validateForm = useCallback(() => {
    clearErrors();

    let emailValidationErrors: string = '';
    let passwordValidationErrors: string[] = [];

    try {
      EmailAddress.validate(email);
    } catch (error: any) {
      if (error instanceof InvalidEmailAddressError) {
        if (!email) 
          emailValidationErrors = 'Email is required';
        else
          emailValidationErrors = 'Email is invalid';
      }
    }

    try {
      Password.validate(password);
    } catch (error: any) {
      if (error instanceof InvalidPasswordError) {
        passwordValidationErrors = error.issues.password;
      }
    }

    setFormValid(!emailValidationErrors && passwordValidationErrors.length === 0);
    setErrors({
      email: emailValidationErrors,
      password: passwordValidationErrors
    });
  }, [email, password]);

  useEffect(() => {
    if (!email && !password) return;
    validateForm();
  }, [email, password, validateForm]);

  function clearErrors() {
    setErrors({ general: [], email: '', password: [] });
  }

  function handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value);
  }

  function handlePasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
    setPassword(event.target.value);
  }

  async function handleLoginSubmit() {
    setLoginFailed(false);
    clearErrors();
    setSigningIn(true);

    try {
      await session.login(
        new EmailAddress(email), 
        new Password(password), 
        rememberMeChecked
      );
      navigate(from, { replace: true });
    } catch (error: any) {
      if(error instanceof InvalidCredentialsError){
        setErrors({ general: ['Invalid email or password.'] });
      } else {
        console.error(error);
        setErrors({ general: ['Unknown error occurred. Please try again later.'] });
      }
      setLoginFailed(true);
    } finally {
      setSigningIn(false);
    }
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <PageTitle variant="h4" align="center">
        Sign In
      </PageTitle>
      <CredentialInput
        variant="filled"
        margin="normal"
        required
        fullWidth
        id="login-email-input"
        label="Email Address"
        name="email"
        onChange={handleEmailChange}
        value={email}
        autoComplete="email"
        autoFocus
        error={loginFailed || (errors.email !== undefined && errors.email.length > 0)}
        helperText={<ErrorItem key={errors.email}>{errors.email}</ErrorItem>}
      />
      <CredentialInput
        variant="filled"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="login-password-input"
        onChange={handlePasswordChange}
        onKeyDown={(event) => {
          event.key === 'Enter' && formValid && handleLoginSubmit();
        }}
        value={password}
        autoComplete="current-password"
        error={loginFailed || (errors.password && errors.password.length > 0)}
        helperText={errors.password?.map((err: string) => <ErrorItem key={err}>{err}</ErrorItem>)}
      />
      <ErrorList errors={errors.general} />
      <RememberMeToggle
        label="Remember me"
        control={
          <Checkbox
            value="remember"
            checked={rememberMeChecked}
            onChange={(event) => setRememberMeChecked(event.target.checked)}
          />
        }
      />
      <LoginButton
        id="login-submit-button"
        fullWidth
        variant="contained"
        color="primary"
        startIcon={<LoginIcon/>}
        disabled={!formValid || signingIn}
        loading={signingIn}
        onClick={handleLoginSubmit}>
        {signingIn ? 'Signing In...' : 'Sign In'}
      </LoginButton>
    </ThemeProvider>
  )
}
