import ClearIcon from '@mui/icons-material/Clear';
import FilterListIcon from '@mui/icons-material/FilterList';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import React, { useEffect } from 'react';

const ActiveFiltersGrid = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: 'aliceblue',
  color: '#3f51b5',
  paddingLeft: theme.spacing(3.5),
  padding: theme.spacing(1)
}));
const ActiveFiltersLabel = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: theme.spacing(1)
}));
const ActiveFilterChip = styled(Chip)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1)
}));
const ClearFiltersButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(1)
}));

interface Props {
  tableRef: React.MutableRefObject<any>;
  columns: any[];
  activeFilters: any[];
  onClear: () => void;
}

export default function ActiveFilters(props: Readonly<Props>) {
  const [columns, setColumns] = React.useState<any[]>(props.columns);
  const [activeFilters, setActiveFilters] = React.useState<any[]>(props.activeFilters);

  useEffect(() => {
    setColumns(props.columns);
    setActiveFilters(props.activeFilters);
  }, [props.columns, props.activeFilters]);

  function onFilterRemoved(filter: any) {
    setActiveFilters([]);

    props.tableRef?.current.state.columns.forEach((column: any) => {
      column.tableData.filterValue = undefined;
    });

    const tableFilterIndex = props.tableRef.current.state.columns.findIndex((c: any) =>
      c.field === filter.column.field);
    props.tableRef.current.state.columns[tableFilterIndex].tableData.filterValue = undefined;

    let newActiveFilters = [...activeFilters];
    let activeFilterIndex = newActiveFilters.findIndex(f => f.column.field === filter.column.field);
    newActiveFilters.splice(activeFilterIndex);
    newActiveFilters = newActiveFilters.filter(Boolean);

    setActiveFilters(newActiveFilters);
    props.tableRef?.current.onFilterChange(
      columns.findIndex(c => c.field === filter.column.field), null);
  }

  return (
    <ActiveFiltersGrid container direction="row">
      <ActiveFiltersLabel item>
        <Button
          color="primary"
          variant="text"
          style={{ pointerEvents: 'none' }}
          startIcon={<FilterListIcon />}>
          Active Filters:
        </Button>
      </ActiveFiltersLabel>
      <Grid item>
        {activeFilters.map((filter) => {
          let dateRange = `${moment(filter.value.startDate).format('MM/DD/yy')} -> ${moment(filter.value.endDate).format('MM/DD/yy')}`;
          return (
            <ActiveFilterChip
              key={filter.value}
              label={`${filter.column.title}: 
                  ${filter.value?.startDate ? dateRange : filter.value}`}
              onDelete={filter?.deletable ? (event) => onFilterRemoved(filter) : undefined} />
          )
        })}
        <ClearFiltersButton
          size="small"
          color="primary"
          variant="outlined"
          onClick={() => props.onClear()}
          startIcon={<ClearIcon />}>
          Clear Filters
        </ClearFiltersButton>
      </Grid>
    </ActiveFiltersGrid>
  )
}
