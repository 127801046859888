import axios, {
  AxiosHeaders,
  CanceledError
} from "axios";
import Session from "users/session/session";
import AttorneyHubAPIService from "common/services/api/attorney-hub-api-service";
import Guid from "common/values/guid/guid";
import UpdateFeeScheduleAPIRequest from "marketplace/entities/fee-schedule/api/request-contracts/update-fee-schedule-api-request";
import FeeSchedule from "marketplace/entities/fee-schedule/fee-schedule";
import MarketplaceFeeScheduleAPIRequest from "marketplace/entities/fee-schedule/api/request-contracts/marketplace-fee-schedule-api-request";
import MarketplaceFeeScheduleAPIResponse from "marketplace/entities/fee-schedule/api/response-contracts/marketplace-fee-schedule-api-response";


export default class FeeScheduleAPIService {

  private headers: AxiosHeaders = new AxiosHeaders();

  constructor(session: Readonly<Session>) {
    if (!session.authToken) throw new Error("Cannot create FeeScheduleAPIService without session.");
    this.headers.set("Authorization", `Bearer ${session.authToken.value}`);
  }

  async getFeeSchedulesForTeam(teamId: Guid, abortController: AbortController): Promise<FeeSchedule[]> {
    const url = new URL(`/marketplace/teams/${teamId.valueOf()}/fee-schedules`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.get(
        url.toString(), {
        headers: this.headers,
        signal: abortController.signal
      });
      let feeSchedules = new Array<FeeSchedule>();
      response.data.forEach((feeSchedule: any) =>
        feeSchedules.push(new MarketplaceFeeScheduleAPIResponse(feeSchedule).deserialize())
      );
      return feeSchedules;
    }
    catch (error: any) {
      if (error instanceof CanceledError) 
        throw error;
      switch (error.response?.status) {
        case 403: throw new FeeScheduleHiddenTeamError("User does not have access to the requested team");
        case 404: throw new FeeScheduleTeamNotFoundError("Fee schedule team was not found");
        default: throw new FeeScheduleServiceError("getFeeSchedulesForTeam", error);
      }
    }
  }

  async createFeeSchedule(teamId: Guid, feeSchedule: FeeSchedule): Promise<FeeSchedule> {
    const url = new URL(`/marketplace/teams/${teamId.valueOf()}/fee-schedules`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.post(
        url.toString(),
        new MarketplaceFeeScheduleAPIRequest(
          feeSchedule.name,
          feeSchedule.tags,
          feeSchedule.categories
        ),
        {
          headers: this.headers.concat({ 'Content-Type': 'multipart/form-data' }),
        }
      );
      return new MarketplaceFeeScheduleAPIResponse(response.data).deserialize();
    } catch (error: any) {
      switch (error.response?.status) {
        case 400: throw new FeeScheduleServiceError("createFeeSchedule", `Unable to create fee schedule: ${error.response?.data}`);
        case 403: throw new FeeScheduleServiceError("createFeeSchedule", "User does not have access to the requested team");
        case 404: throw new FeeScheduleTeamNotFoundError("Fee schedule category was not found");
        default: throw new FeeScheduleServiceError("createFeeSchedule", error);
      }
    }
  }

  async updateFeeSchedule(teamId: Guid, originalFeeSchedule: FeeSchedule, updatedFeeSchedule: FeeSchedule): Promise<FeeSchedule> {
    const url = new URL(`/marketplace/teams/${teamId.valueOf()}/fee-schedules/${originalFeeSchedule.id}`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.patch(
        url.toString(),
        new UpdateFeeScheduleAPIRequest(originalFeeSchedule, updatedFeeSchedule).payload,
        {
          headers: this.headers.concat({ 'Content-Type': 'multipart/form-data' }),
        }
      );
      return new MarketplaceFeeScheduleAPIResponse(response.data).deserialize();
    } catch (error: any) {
      switch (error.response?.status) {
        case 400: throw new FeeScheduleServiceError("updateFeeSchedule", `Unable to update fee schedule: ${error.response.data}`);
        case 403: throw new FeeScheduleServiceError("updateFeeSchedule", "User does not have access to the requested team");
        case 404: throw new FeeScheduleTeamNotFoundError("Fee schedule was not found");
        default: throw new FeeScheduleServiceError("updateFeeSchedule", error);
      }
    }
  }

  async deleteFeeSchedule(teamId: Guid, scheduleId: Guid): Promise<void> {
    const url = new URL(`/marketplace/teams/${teamId.valueOf()}/fee-schedules/${scheduleId}`, AttorneyHubAPIService.apiBaseUrl);
    try {
      await axios.delete(
        url.toString(),
        {
          headers: this.headers,
        }
      );
    } catch (error: any) {
      switch (error.response?.status) {
        case 403: throw new FeeScheduleServiceError("deleteFeeSchedule", "User does not have access to the requested team");
        case 404: throw new FeeScheduleTeamNotFoundError("Fee schedule was not found");
        default: throw new FeeScheduleServiceError("deleteFeeSchedule", error);
      }
    }
  }
}

export class FeeScheduleServiceError extends Error {
  method: string;
  error: any;
  constructor(method: string, error: any) {
    super(`Error in FeeScheduleAPIService.${method}: ${error.message}`);
    this.method = method;
    this.error = error;
  }
}

export class FeeScheduleHiddenTeamError extends Error { }
export class FeeScheduleTeamNotFoundError extends Error { }