import MaterialTable, { Action, Column } from '@material-table/core';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Icon,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDialog } from 'app/providers/dialog';
import { ConfirmResponse, useConfirmDialog } from 'app/providers/confirm-dialog';
import _ from 'lodash';
import FeeScheduleCategory from 'marketplace/entities/fee-schedule-category/fee-schedule-category';
import FeeScheduleCategoryForm from "marketplace/entities/fee-schedule-category/view/fee-schedule-category-form";
import FeeScheduleCategoryName from 'marketplace/values/fee-schedule-category-name/fee-schedule-category-name';
import React, { useEffect } from 'react';


const FeeCategoryButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(2)
}));
const TableContainer = styled(Box)(({ theme }) => ({
  border: '1px solid #eee',
  marginTop: theme.spacing(2),
  maxWidth: theme.spacing(100)
}));

type FeeScheduleCategoriesProps = {
  className?: string,
  initialCategories: Array<FeeScheduleCategory>,
  viewOnly?: boolean,
  onCategoryAdded?: (category: FeeScheduleCategory) => void,
  onCategoryUpdated?: (originalName: FeeScheduleCategoryName | null, updatedCategory: FeeScheduleCategory) => void,
  onCategoryRemoved?: (name: FeeScheduleCategoryName) => void
};

export default function FeeScheduleCategories(props: Readonly<FeeScheduleCategoriesProps>) {
  const {
    className,
    initialCategories,
    viewOnly,
    onCategoryAdded,
    onCategoryUpdated,
    onCategoryRemoved
  } = props;

  const [categories, setCategories] = React.useState<Array<FeeScheduleCategory>>(initialCategories ?? []);

  const confirm = useConfirmDialog();
  const { openDialog, closeDialog } = useDialog();

  useEffect(() => {
    setCategories(initialCategories ?? []);
  }, [initialCategories]);

  const columns: Column<FeeScheduleCategory>[] = [
    {
      field: 'name',
      title: 'Name',
      render: (rowData: FeeScheduleCategory) => <strong>{rowData.name}</strong>
    },
    {
      field: 'description',
      title: 'Description'
    },
    {
      field: 'fee.isFixed',
      title: 'Fixed?',
      type: 'boolean'
    },
    {
      field: 'fee.rate',
      title: 'Fee',
      type: 'currency',
      render: (rowData: FeeScheduleCategory) =>
          new Intl.NumberFormat(navigator.language,
            {
              style: 'currency',
              currency: rowData.fee?.rate?.currency.toString() ?? 'USD',
            }
          ).format(rowData.fee?.rate?.amount ?? 0),
    },
    {
      field: 'fee.unit',
      title: 'Per',
      emptyValue: <Icon>remove</Icon>
    },
    {
      field: 'billingCode',
      title: 'Code',
      emptyValue: <Icon>remove</Icon>,
      render: (rowData: FeeScheduleCategory) => rowData.billingCode ? rowData.billingCode.valueOf() : <Icon>remove</Icon>
    }
  ];

  const actions: Action<FeeScheduleCategory>[] = [
    {
      icon: () => <CloseIcon color="error" />,
      tooltip: 'Remove Category',
      onClick: (event, rowData) => {
        if (_.isArray(rowData)) return;
        handleRemoveButtonClicked(event, rowData.name);
      }
    }
  ];

  async function handleRemoveButtonClicked(_event: MouseEvent, name: FeeScheduleCategoryName | null) {
    if (name == null) return;
    const feeScheduleCategory = categories.find((category: FeeScheduleCategory) => category.name === name);

    if (!feeScheduleCategory) return;

    const response = await confirm({
      title: 'Delete Fee Schedule Category?',
      message: `Delete the ${feeScheduleCategory.name} fee schedule category?`,
      okButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    });

    if (response === ConfirmResponse.Cancel) return;

    setCategories(categories.filter(category => category.name !== name));
    onCategoryRemoved?.(name);
  }

  function handleCategoryAdded(category: FeeScheduleCategory) {
    setCategories([...categories, category]);
    onCategoryAdded?.(category);
  }

  function beginAddCategory() {
    openDialog({
      title: 'Create Fee Schedule Category',
      component: (
        <FeeScheduleCategoryForm
          onCategoryAdded={handleCategoryAdded}
          closeDialog={closeDialog}
        />
      )
    });
  }

  /**
   * Handles when a category is updated
   */
  function handleCategoryUpdated(originalName: FeeScheduleCategoryName | null, updatedCategory: FeeScheduleCategory) {
    const updatedCategories = categories.map(category => {
      if (category.name === originalName) return updatedCategory;
      return category;
    }) as FeeScheduleCategory[];

    setCategories(updatedCategories);
    onCategoryUpdated?.(originalName, updatedCategory);
  }

  /**
   * Handles when the edit button is clicked
   * @param category The category to edit
   */
  function beginEditCategory(category: FeeScheduleCategory | undefined | null) {
    if (!category || viewOnly) return;

    openDialog({
      title: 'Edit Fee Schedule Category',
      component: (
        <FeeScheduleCategoryForm
          category={category}
          onCategoryUpdated={handleCategoryUpdated}
          closeDialog={closeDialog}
        />
      )
    });
  }

  function getTableContainer(props: any) {
    return <TableContainer {...props} />;
  }

  return (
    <div className={className}>
      {!viewOnly && (
        <FeeCategoryButton
          startIcon={<AddIcon />}
          onClick={beginAddCategory}
          variant="outlined">
          Add New Category
        </FeeCategoryButton>
      )}
      <MaterialTable
        columns={columns}
        actions={!viewOnly ? actions : []}
        data={categories}
        options={{
          showTitle: false,
          selection: false,
          search: false,
          toolbar: false,
          actionsColumnIndex: -1
        }}
        components={{
          Container: props => getTableContainer(props)
        }}
        localization={{
          header: {
            actions: ''
          },
          body: {
            emptyDataSourceMessage: (
              <Typography>There aren't any fee schedule categories</Typography>
            )
          }
        }}
        onRowClick={!viewOnly ? (_event, rowData) => beginEditCategory(rowData) : undefined}
      />
    </div>
  )
}
