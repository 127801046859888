import CommentIcon from "@mui/icons-material/Comment";
import {
  Badge,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Tooltip
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  ConfirmResponse,
  useConfirmDialog,
} from "app/providers/confirm-dialog";
import { downloadFile } from "common/helpers/utils";
import AHBoolean from "common/values/boolean/boolean";
import Guid from "common/values/guid/guid";
import DocumentAPIService from "documents/entities/document/api/document-api-service";
import Document from "documents/entities/document/document";
import DocumentSelector, { TableDocument } from "documents/view/document-selector";
import DocumentUploadOverlay from "documents/view/document-upload-overlay";
import Forum from "messaging/entities/forum/forum";
import { enqueueSnackbar } from "notistack";
import React from "react";
import { useSession } from "users/session/session-context";
import Proposal from "work/entities/proposal/proposal";
import { ProposalTab } from "work/entities/proposal/redlining/view/proposal-redline-dialog";
import { getForumForField } from "work/entities/proposal/utils/comment-utils";
import ProposalBuilder from "work/entities/proposal/utils/proposal-builder";
import { ProposalFieldName } from "work/values/constants";
import SelectedDocumentList from "work/values/work-document/view/selected-document-list";
import WorkDocument, { WorkDocumentType } from "work/values/work-document/work-document";

const ListContainer = styled(Grid)<{ waived: boolean }>(
  ({ theme, waived }) => ({
    flexWrap: 'nowrap',
    minHeight: "20rem",
    opacity: waived ? 0.25 : 1,
    pointerEvents: waived ? "none" : "auto",
    width: '100%'
  })
);
const DocumentSelectorContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  width: '100%'
}));
const SelectDocs = styled(SelectedDocumentList)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    marginTop: theme.spacing(2),
  },
  maxHeight: '60vh',
  marginLeft: theme.spacing(2),
  overflowY: 'auto'
}));
const HeadControls = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  paddingLeft: theme.spacing(2),
}));
const SectionDivider = styled(Divider)(({ theme }) => ({
  alignItems: 'center',
  margin: theme.spacing(0, 2, 0, 4)
}));
const Uploader = styled(DocumentUploadOverlay)(({ theme }) => ({
  backgroundColor: 'rgba(250, 250, 250, 0.5)',
  backdropFilter: 'blur(5px) saturate(200%)',
  position: 'absolute',
  top: 0,
  left: 0,
  margin: '1rem',
  width: 'calc(100% - 2rem)',
  height: 'calc(100% - 2rem)',
  zIndex: 9999
}));

type ConflictsUploadProps = {
  activeTab: ProposalTab;
  proposalBuilder: ProposalBuilder;
  proposal?: Proposal;
  disableCommenting?: boolean;
  commentForums?: Forum[];
  disableEditing?: boolean;
  onCommentsClicked: (id?: Guid, name?: string) => void;
  onChange: (newProposal?: Proposal) => void;
};

export default function ConflictsTab(props: Readonly<ConflictsUploadProps>) {
  const {
    activeTab,
    proposalBuilder,
    proposal,
    disableCommenting,
    commentForums,
    disableEditing,
    onCommentsClicked,
    onChange,
  } = props;

  const [isDownloadingFile, setIsDownloadingFile] = React.useState<Guid | null>(null);
  const [conflictsCheckWaivedValue, setConflictsCheckWaivedValue] = React.useState<boolean>(proposal?.conflictsCheckWaived.valueOf() ?? false);
  const [selectedDocuments, setSelectedDocuments] = React.useState<Array<WorkDocument>>(proposal?.conflictsDocuments ?? []);
  const [creatingDocumentFromTemplate, setCreatingDocumentFromTemplate] = React.useState<Guid | null>(null);

  const confirm = useConfirmDialog();
  const session = useSession();

  async function handleConflictsDocumentRemoved(documentId: Guid): Promise<void> {
    const response = await confirm({
      title: "Remove Conflicts Document",
      message: "Are you sure you want to remove this conflicts document?",
    });

    if (response === ConfirmResponse.Cancel) return;

    proposalBuilder.setConflictsDocuments(
      (
        proposalBuilder.currentSpec.workAgreement?.conflictsDocuments ?? []
      ).filter((doc) => !doc.id.isEqualTo(documentId))
    );
    setSelectedDocuments(prevValue => prevValue.filter((document) => !document.id.isEqualTo(documentId)));
    onChange();
  }

  async function handleDownloadDocumentById(
    documentId: Guid,
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> {
    event?.stopPropagation();
    try {
      setIsDownloadingFile(documentId);
      const documentApiService = new DocumentAPIService(session);
      const conflictsDocument = await documentApiService.downloadDocument(
        documentId
      );
      downloadFile(conflictsDocument);
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        "Failed to download conflicts document. Please try again",
        { variant: "error" }
      );
    } finally {
      setIsDownloadingFile(null);
    }
  }

  function handleDocumentSelectionChanged(documents: TableDocument[]) {
    let currentDocuments = proposalBuilder.currentSpec.workAgreement?.conflictsDocuments ?? [];
    let selectedDocs = [...selectedDocuments];

    for (const doc of documents) {
      if (doc.tableData.checked) {
        if (!currentDocuments.some(document => document.id.isEqualTo(doc.id))) {
          currentDocuments.push(WorkDocument.fromDocument(doc, WorkDocumentType.Conflicts));
        }
        if (!selectedDocs.some((document) => document.id.isEqualTo(doc.id))) {
          selectedDocs.push(WorkDocument.fromDocument(doc, WorkDocumentType.Conflicts));
        }
      } else if (!doc.tableData.checked) {
        selectedDocs = selectedDocs.filter((document) => !document.id.isEqualTo(doc.id));
        currentDocuments = currentDocuments.filter(document => !document.id.isEqualTo(doc.id));
      }
    }
    proposalBuilder.setConflictsDocuments(currentDocuments);

    setSelectedDocuments(selectedDocs);
    onChange();
  }

  function handleConflictsDocumentAdded(document: Document) {
    if (!(document instanceof Document)) {
      console.error("attempted to add unavailable document to conflicts");
      return;
    }
    let currentDocuments = proposalBuilder.currentSpec.workAgreement?.conflictsDocuments ?? [];
    let selectedDocs = [...selectedDocuments];
    const newDocument = WorkDocument.fromDocument(document, WorkDocumentType.Conflicts);
    selectedDocs.push(newDocument);
    currentDocuments.push(newDocument);
    proposalBuilder.setConflictsDocuments(currentDocuments);
    setSelectedDocuments(selectedDocs);
    setCreatingDocumentFromTemplate(null);
    onChange();
  }

  function getShouldShowBadge(documentId?: Guid): boolean {
    return !getForumForField(
      ProposalFieldName.Conflicts,
      documentId,
      commentForums
    );
  }

  function renderCommentsButton(document?: Document) {
    return (
      <Tooltip
        title={
          disableCommenting ? "Save proposal to enable commenting" : "Comments"
        }
      >
        <span>
          <IconButton
            size="small"
            disabled={disableCommenting}
            onClick={(event) => {
              event.stopPropagation();
              onCommentsClicked(document?.id, document?.name?.valueOf());
            }}
          >
            <Badge
              variant="dot"
              color="secondary"
              overlap="circular"
              invisible={getShouldShowBadge(document?.id)}
            >
              <CommentIcon fontSize="medium" />
            </Badge>
          </IconButton>
        </span>
      </Tooltip>
    );
  }

  
  async function handleBeginCreateDocumentFromTemplate(templateId: Guid | undefined | null) {
    if (!templateId) return;
    setCreatingDocumentFromTemplate(templateId);
    handleDownloadDocumentById(templateId);
  }

  function renderDocumentSelector() {
    let selectedTemplateIds: Array<Guid> = [];
    selectedDocuments.forEach((doc) => {
      if (doc instanceof Document) {
        selectedTemplateIds = [...selectedTemplateIds, ...doc.templateIds];
      }
    });
   
    return (
      <DocumentSelector
        documentType='conflicts'
        selectedDocumentIds={selectedDocuments.map((doc) => doc.id)}
        selectedTemplateIds={selectedTemplateIds}
        onDocumentSelectionChanged={handleDocumentSelectionChanged}
        onCreateDocumentFromTemplate={handleBeginCreateDocumentFromTemplate}
      />
    )
  }

  async function handleConflictsCheckWaiverChanged(checked: AHBoolean) {
    if (proposalBuilder.currentSpec.workAgreement?.conflictsDocuments?.length) {
      const response = await confirm({
        title: "Remove Conflicts Documents?",
        message: "Waiving the conflicts check will remove the documents that are already on the proposal"
      });

      if (response === ConfirmResponse.Cancel) return;
    }

    setConflictsCheckWaivedValue(checked.valueOf());
    setSelectedDocuments([]);
    proposalBuilder.setConflictsCheckWaived(checked);
    proposalBuilder.setConflictsDocuments([]);
    onChange();
  }

  if (activeTab !== ProposalTab.Conflicts) return null;

  return (
    <>
      <HeadControls>
        <FormControlLabel
          control={
            <Switch
              checked={conflictsCheckWaivedValue}
              onChange={(_event, checked) =>
                handleConflictsCheckWaiverChanged(new AHBoolean(checked))
              }
              color="primary"
            />
          }
          label={
            conflictsCheckWaivedValue ? (
              <strong>Conflicts Check Waived</strong>
            ) : (
              "Waive Conflicts Check"
            )
          }
        />
        {renderCommentsButton()}
      </HeadControls>
      <ListContainer waived={conflictsCheckWaivedValue} container direction="row">
        <DocumentSelectorContainer item>
          {!disableEditing && renderDocumentSelector()}
        </DocumentSelectorContainer>
        <Grid item xs>
          <SectionDivider orientation="vertical" />
        </Grid>
        <Grid item xs>
          <SelectDocs
            documents={selectedDocuments}
            documentType={WorkDocumentType.Conflicts}
            downloadingFileId={isDownloadingFile}
            disableCommenting={disableCommenting}
            commentForums={commentForums}
            disableEditing={disableEditing}
            onCommentsClicked={onCommentsClicked}
            onDownload={handleDownloadDocumentById}
            onDocumentRemoved={handleConflictsDocumentRemoved}
            onDocumentAdded={handleConflictsDocumentAdded}
          />
        </Grid>
      </ListContainer>
      {creatingDocumentFromTemplate && (
        <Uploader
          session={session}
          documentType={WorkDocumentType.Conflicts}
          templateId={creatingDocumentFromTemplate}
          onDocumentUploaded={handleConflictsDocumentAdded}
          onClose={() => setCreatingDocumentFromTemplate(null)}
        />
      )}
    </>
  );
}
