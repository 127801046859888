import Guid from "common/values/guid/guid";
import Name from "common/values/name/name";
import MarketplaceIndividualPendingInvitation from "marketplace/entities/marketplace-individual-pending-invitation/marketplace-individual-pending-invitation";

export default class MarketplaceIndividualPendingInvitationAPIResponse {
    id?: string;
    userId?: string;
    firstName?: string;
    lastName?: string;
    avatar?: string;
    invitationId?: string;
    message?: string;

    constructor(values: Partial<MarketplaceIndividualPendingInvitationAPIResponse>) {
        Object.assign(this, values);
    }

    deserialize(): MarketplaceIndividualPendingInvitation {
        if (!this.id) throw new Error("Individual ID is required, was not returned by the API");
        if (!this.userId) throw new Error("User ID is required, was not returned by the API");
        if (!this.invitationId) throw new Error("Invitation ID is required, was not returned by the API");

        const name = new Name(this.firstName, this.lastName);

        return new MarketplaceIndividualPendingInvitation(
            new Guid(this.id),
            new Guid(this.userId),
            name,
            new Guid(this.invitationId),
            this.message ?? '',
            this.avatar ? new Guid(this.avatar) : undefined
        );
    }
}
