import { InputAdornment, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Moment } from 'moment';
import React from 'react';

const PickerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  flexDirection: 'column',
  gap: theme.spacing(0.5)
}));
const DateControl = styled(DatePicker)(({ theme }) => ({
  width: '15rem'
}));
const FilterLabel = styled('span')(({ theme }) => ({
  color: theme.palette.text.disabled,
  fontSize: '0.8rem',
  minWidth: '2rem'
}));

type DateRangePickerProps = {
  minDate: Moment | null;
  maxDate: Moment | null;
  onMinDateChange: (date: Moment | null) => void;
  onMaxDateChange: (date: Moment | null) => void;
};

export default function DateRangePicker(props: Readonly<DateRangePickerProps>) {
  const { 
    onMinDateChange, 
    onMaxDateChange 
  } = props;

  const [minDate, setMinDate] = React.useState<Moment | null>(props.minDate);
  const [maxDate, setMaxDate] = React.useState<Moment | null>(props.maxDate);

  return (
    <PickerContainer>
      <DateControl
        disableFuture
        maxDate={maxDate ?? undefined}
        value={minDate}
        slotProps={{
          textField: {
            InputProps: {
              startAdornment: (
                <Tooltip title="Filter by Min Date">
                  <InputAdornment position="start">
                    <FilterLabel>from</FilterLabel><FilterListIcon />
                  </InputAdornment>
                </Tooltip>
              )
            }
          },
          field: { clearable: true },
        }}
        onChange={(newDate: Moment | null) => {
          setMinDate(newDate);
          onMinDateChange(newDate);
        }}
      />
      <DateControl
        disableFuture
        minDate={minDate ?? undefined}
        value={maxDate}
        slotProps={{
          textField: {
            InputProps: {
              startAdornment: (
                <Tooltip title="Filter by Max Date">
                  <InputAdornment position="start">
                    <FilterLabel>to</FilterLabel><FilterListIcon />
                  </InputAdornment>
                </Tooltip>
              )
            }
          },
          field: { clearable: true },
        }}
        onChange={(newDate: Moment | null) => {
          setMaxDate(newDate);
          onMaxDateChange(newDate);
        }}
      />
    </PickerContainer>
  )
}
