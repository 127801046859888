import { JsonPatchOperation } from "common/services/api/attorney-hub-api-service";
import EntityAgreement from "legal-entities/entities/entity-agreement/entity-agreement";

export default class UpdateEntityAgreementAPIRequest {
  payload: JsonPatchOperation[] = [];

  constructor(oldAgreement: EntityAgreement, newAgreement: EntityAgreement) {
    this.updateAgreementAdministrator(newAgreement, oldAgreement);

    if (newAgreement.termsFileId && !oldAgreement.termsFileId) {
      this.payload.push({
        op: "replace",
        path: "/TermsFileId",
        value: newAgreement.termsFileId.valueOf(),
      });
    }

    if (
      newAgreement.entityName.valueOf() !== oldAgreement.entityName.valueOf()
    ) {
      this.payload.push({
        op: "replace",
        path: "/EntityName",
        value: newAgreement.entityName.valueOf(),
      });
    }
  }

  private updateAgreementAdministrator(
    newAgreement: EntityAgreement,
    oldAgreement: EntityAgreement
  ) {
    if (
      newAgreement.administratorName &&
      (!oldAgreement.administratorName ||
        (newAgreement.administratorName &&
          !newAgreement.administratorName.isEqualTo(
            oldAgreement.administratorName
          )))
    ) {
      this.payload.push({
        op: "replace",
        path: "/AdministratorFirstName",
        value: newAgreement.administratorName.firstName?.valueOf(),
      });
      this.payload.push({
        op: "replace",
        path: "/AdministratorLastName",
        value: newAgreement.administratorName.lastName?.valueOf(),
      });
    } else if (
      !newAgreement.administratorName &&
      oldAgreement.administratorName
    ) {
      this.payload.push({
        op: "replace",
        path: "/AdministratorFirstName",
        value: null,
      });
      this.payload.push({
        op: "replace",
        path: "/AdministratorLastName",
        value: null,
      });
    }

    if (newAgreement.administratorEmail && !oldAgreement.administratorEmail) {
      this.payload.push({
        op: "replace",
        path: "/AdministratorEmail",
        value: newAgreement.administratorEmail.valueOf(),
      });
    } else if (
      !newAgreement.administratorEmail &&
      oldAgreement.administratorEmail
    ) {
      this.payload.push({
        op: "replace",
        path: "/AdministratorEmail",
        value: null,
      });
    }
  }
}
