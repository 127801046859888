import { MutableRefObject } from "react";

import MaterialTable, {
  Column,
  MaterialTableProps,
} from "@material-table/core";

import Guid from "common/values/guid/guid";

import { PageTab } from "app/routes/work/tabs";
import WorkAgreement from "work/entities/work-agreement/work-agreement";


export function getTableColumns(
  activeTab: PageTab,
  tableRef: MutableRefObject<
    (MaterialTable<WorkAgreement> & MaterialTableProps<WorkAgreement>) | undefined
  >,
  userId?: Guid
): Column<WorkAgreement>[] {
  return [
    {
      field: "name",
      title: "Name",
      width: 150,
    },
    {
      field: "description",
      title: "Description",
      width: 400,
    },
    {
      field: "hireDate",
      title: "Hire Date",
      type: "date",
      render: (rowData) => {
        return rowData.hireDate ? rowData.hireDate.value.format("MM/DD/YYYY") : "";
      }
    },
    {
      field: "startDate",
      title: "Start Date",
      type: "date",
      render: (rowData) => {
        return rowData.startDate ? rowData.startDate.value.format("MM/DD/YYYY") : "";
      }
    },
    {
      field: "endDate",
      title: "End Date",
      type: "date",
      render: (rowData) => {
        return rowData.endDate ? rowData.endDate.value.format("MM/DD/YYYY") : "";
      }
    },
    {
      title: "Actions",
      align: "right",
      filtering: false,
      draggable: false,
      sorting: false,
      grouping: false,
      disableClick: true,
      hiddenByColumnsButton: true,
      removable: false,
      width: "fit-content",
    },
  ];
}
