import Guid from "common/values/guid/guid";
import EntityName from "legal-entities/values/entity-name/entity-name";
import UserEntityInfo, { InvalidUserEntityInfoError } from "users/values/user-entity-info/user-entity-info";

export default class UserEntityInfoAPIResponse {
  entityId?: string;
  entityName?: string;
  isManager?: boolean;
  isAdmin?: boolean;
  isOfficer?: boolean;
  entityVendorFlag?: boolean;
  entityClientFlag?: boolean;

  deserialize(): UserEntityInfo {
    if (!this.entityId) throw new InvalidUserEntityInfoError("entityId is required")
    if (!this.entityName) throw new InvalidUserEntityInfoError("entityName is required")
    if (this.isManager === undefined) throw new InvalidUserEntityInfoError("isManager is required")
    if (this.isAdmin === undefined) throw new InvalidUserEntityInfoError("isAdmin is required")
    if (this.isOfficer === undefined) throw new InvalidUserEntityInfoError("isOfficer is required")
    if (this.entityVendorFlag === undefined) throw new InvalidUserEntityInfoError("entityVendorFlag is required")
    if (this.entityClientFlag === undefined) throw new InvalidUserEntityInfoError("entityClientFlag is required")

    const userEntityInfo: UserEntityInfo = new UserEntityInfo({
      entityId: new Guid(this.entityId),
      entityName: new EntityName(this.entityName),
      isManager: this.isManager,
      isAdmin: this.isAdmin,
      isOfficer: this.isOfficer,
      entityVendorFlag: this.entityVendorFlag,
      entityClientFlag: this.entityClientFlag
    });
    return userEntityInfo;
  }
}
