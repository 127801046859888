import CreateForumAPIRequest from "messaging/entities/forum/api/request-contracts/create-forum-api-request";
import Message from "messaging/entities/message/message";
import {Moment} from "moment";

type BulkMessageRequest = {
    id: string;
    existingForumId?: string;
    newForum?: CreateForumAPIRequest;
    subject: string;
    contentPlainText: string;
    deletedAt?: Moment
}

export default class BulkMessageAPIRequest {
    public payload: Array<BulkMessageRequest>;

    constructor(messages: Message[]) {
        this.payload = [];
        messages.forEach((message) => {
            if(!message.id) throw new Error("Message must have an id");
            this.payload.push({
                id: message.id.valueOf(),
                existingForumId: message.forum.id?.valueOf(),
                newForum: !message.forum.id ? new CreateForumAPIRequest(message.forum) : undefined,
                subject: message.subject ?? "",
                contentPlainText: message.content,
                deletedAt: message.deletedAt
            });
        });
    }
}
