import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CommentIcon from "@mui/icons-material/Comment";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import UndoIcon from "@mui/icons-material/Undo";
import {
  Badge,
  IconButton,
  IconButtonProps,
  Input,
  InputBaseComponentProps,
  TextField,
  TextFieldProps,
  Tooltip,
} from "@mui/material";
import { green, lightBlue } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import {
  DatePicker,
  DatePickerProps,
  PickersDay,
  PickersDayProps,
} from "@mui/x-date-pickers";
import Date from "common/values/date/date";
import Forum from "messaging/entities/forum/forum";
import { Moment } from "moment";
import { enqueueSnackbar } from "notistack";
import { forwardRef } from "react";
import { ProposalField } from "work/entities/proposal/proposal";
import { ChangeType } from "work/entities/proposal/redlining/_diff/view/diff-changes";
import DiffText from "work/entities/proposal/redlining/_diff/view/diff-text";
import FieldRedline from "work/entities/proposal/redlining/field-redline";
import { getForumForField } from "work/entities/proposal/utils/comment-utils";

const DiffTextContainer = styled("div")(({ theme }) => ({
  flexDirection: "column",
  height: "100%",
  paddingRight: theme.spacing(2),
  width: "100%",
}));
const DiffInputContainer = styled("div")(({ theme }) => ({
  width: "100%",
}));
const DiffInputSpan = styled("span")(({ theme }) => ({
  "&.MuiInputBase-input": {
    display: "flex",
    flexDirection: "column",
  },
}));
const RedlineDatePicker = styled(DatePicker<Moment>)(({ theme }) => ({
  minWidth: "20rem",
  width: "min-content",
}));
const CalendarDay = styled(PickersDay<Moment>, {
  shouldForwardProp: (prop) =>
    !["resolved", "changeType"].includes(prop.toString()),
})<{ changeType?: ChangeType; resolved: boolean }>(
  ({ changeType, resolved, theme }) => ({
    "&.MuiPickersDay-root": {
      "&:disabled": {
        color: (function (): string {
          if (
            changeType === ChangeType.added ||
            changeType === ChangeType.removed
          ) {
            return theme.palette.text.primary;
          }
          return "revert";
        })(),
      },
      backgroundColor: (function (): string {
        if (changeType === ChangeType.added && resolved) return lightBlue[700];
        if (changeType === ChangeType.added) return green[300];
        return "unset";
      })(),
      border: (function (): string {
        if (changeType === ChangeType.removed)
          return `2px solid ${theme.palette.error.main}`;
        return "unset";
      })(),
      textDecoration: (function (): string {
        if (changeType === ChangeType.removed) return "line-through";
        return "unset";
      })(),
      textDecorationColor: (function (): string {
        if (changeType === ChangeType.removed) return theme.palette.error.main;
        return "unset";
      })(),
      textDecorationThickness: (function (): string {
        if (changeType === ChangeType.removed) return "2px";
        return "unset";
      })(),
    },
  })
);
const ActionButton = styled(IconButton)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(0.5),
}));

interface RedlinePickersDayProps extends PickersDayProps<Moment> {
  originalDate: Date | null;
  revisedDate: Date | null;
  currentDate: Date | null | undefined;
  resolved: boolean;
}

function RevisedDay(props: RedlinePickersDayProps) {
  const { originalDate, revisedDate, currentDate, resolved, ...otherProps } =
    props;

  let changeType = ChangeType.unchanged;

  if (!props.outsideCurrentMonth) {
    if (originalDate?.value.isSame(props.day, "day")) {
      changeType = ChangeType.removed;
    }
    if (
      revisedDate?.value.isSame(props.day, "day") ||
      (currentDate?.value.isSame(props.day, "day") && Boolean(resolved))
    ) {
      changeType = ChangeType.added;
    }
  }

  return (
    <CalendarDay
      {...otherProps}
      disabled={props.disabled || changeType !== ChangeType.unchanged}
      selected={props.selected || changeType !== ChangeType.unchanged}
      changeType={changeType}
      resolved={Boolean(resolved)}
    />
  );
}

const DatePickerTextField = (props: any) => {
  const { dateRedline, focused, onTraverseIn, ...otherProps } = props;

  return (
    <TextField
      {...otherProps}
      label={dateRedline.label}
      focused={focused}
      InputProps={{
        ...otherProps.InputProps,
        ...{
          inputComponent: DiffDateInputComponent,
          inputProps: {
            ...{ ...otherProps.inputProps },
            ...{ dateRedline, onTraverseIn },
          },
        },
      }}
    />
  );
};

interface DiffDateInputComponentProps extends InputBaseComponentProps {
  dateRedline: FieldRedline<Date>;
  onTraverseIn?: () => void;
}

const DiffDateInputComponent = forwardRef(
  (
    props: DiffDateInputComponentProps,
    forwardedRef: React.ForwardedRef<HTMLInputElement>
  ) => {
    const { dateRedline, onFocus, onBlur, onTraverseIn } = props;

    return (
      <DiffTextContainer>
        <DiffInputContainer className={props.className}>
          <DiffInputSpan>
            <DiffText
              changes={dateRedline.changes}
              allResolved={dateRedline.isResolved}
              disablePopover={true}
              onClick={onTraverseIn}
            />
          </DiffInputSpan>
        </DiffInputContainer>
        <Input
          ref={forwardedRef}
          style={{ position: "absolute", height: 0, width: 0 }}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      </DiffTextContainer>
    );
  }
);
export interface RedlineDateInputProps extends DatePickerProps<Moment> {
  dateRedline: FieldRedline<Date>;
  focused: boolean;
  commentForums: Forum[];
  onCommentsClicked?: () => void;
  onDateRedlineChange?: (
    newRedline: FieldRedline<Date>,
    traversalFieldOverride?: ProposalField
  ) => void;
  onTraverseIn?: () => void;
}

const DateRedlinePicker = (props: Readonly<RedlineDateInputProps>) => {
  const {
    dateRedline,
    focused,
    disabled,
    commentForums,
    onCommentsClicked,
    onDateRedlineChange,
    onTraverseIn,
    ...otherProps
  } = props;

  function handleChangeAccepted() {
    try {
      onDateRedlineChange?.(dateRedline.accept());
    } catch (error) {
      console.error("Error accepting change.", error);
      enqueueSnackbar("Error accepting change.", { variant: "error" });
    }
  }

  function handleChangeRejected() {
    try {
      onDateRedlineChange?.(dateRedline.reject());
    } catch (error) {
      console.error("Error rejecting change.", error);
      enqueueSnackbar("Error rejecting change.", { variant: "error" });
    }
  }

  function handleChangeEdited(newDate: Date | null) {
    try {
      onDateRedlineChange?.(dateRedline.edit(newDate), {
        name: dateRedline.field,
        id: dateRedline.fieldId,
      });
    } catch (error) {
        console.error("Error editing change.", error);
        enqueueSnackbar("Error editing change.", { variant: "error" });
      }
  }

  function handleRemoveClicked() {
    try {
      onDateRedlineChange?.(dateRedline.edit(null), {
        name: dateRedline.field,
        id: dateRedline.fieldId,
      });
    } catch (error) {
      console.error("Error removing change.", error);
      enqueueSnackbar("Error removing change.", { variant: "error" });
    }
  }
  function handleUndoChanges() {
    try {
      onDateRedlineChange?.(dateRedline.undo(), {
        name: dateRedline.field,
        id: dateRedline.fieldId,
      });
    } catch (error) {
      console.error("Error undoing changes.", error);
      enqueueSnackbar("Error undoing changes.", { variant: "error" });
    }
  }

  const ActionButtons = (buttonProps: IconButtonProps) => {
    if (!dateRedline) return null;

    return (
      <>
        {!disabled && (
          <>
            {!dateRedline.isResolved && (
              <>
                <Tooltip title="Accept Change">
                  <span>
                    <ActionButton onClick={() => handleChangeAccepted()}>
                      <CheckIcon color="success" />
                    </ActionButton>
                  </span>
                </Tooltip>
                <Tooltip title="Reject Change">
                  <span>
                    <ActionButton onClick={() => handleChangeRejected()}>
                      <CloseIcon color="error" />
                    </ActionButton>
                  </span>
                </Tooltip>
              </>
            )}
            {dateRedline.isResolved && dateRedline.currentEntry && (
              <Tooltip title="Remove">
                <span>
                  <ActionButton
                    onClick={() => {
                      handleRemoveClicked();
                    }}
                  >
                    <CloseIcon />
                  </ActionButton>
                </span>
              </Tooltip>
            )}
            {dateRedline.canBeUndone && (
              <Tooltip title="Undo Changes">
                <span>
                  <ActionButton onClick={handleUndoChanges}>
                    <UndoIcon />
                  </ActionButton>
                </span>
              </Tooltip>
            )}
            <Tooltip title="Edit">
              <span>
                <ActionButton
                  {...buttonProps}
                  onClick={(event) => {
                    onTraverseIn?.();
                    buttonProps.onClick?.(event);
                  }}
                />
              </span>
            </Tooltip>
          </>
        )}
        <Tooltip title="Comments">
          <span>
            <IconButton
              size="small"
              onClick={(event) => {
                onCommentsClicked?.();
              }}
            >
              <Badge
                variant="dot"
                color="secondary"
                overlap="circular"
                invisible={
                  !getForumForField(dateRedline.field, undefined, commentForums)
                }
              >
                <CommentIcon fontSize="medium" />
              </Badge>
            </IconButton>
          </span>
        </Tooltip>
      </>
    );
  };

  return (
    <RedlineDatePicker
      {...otherProps}
      disablePast
      referenceDate={
        dateRedline.currentEntry?.value ??
        dateRedline.revisedEntry?.value ??
        dateRedline.originalEntry?.value
      }
      onChange={(date: Moment | null) =>
        handleChangeEdited(date ? new Date(date) : null)
      }
      slots={{
        openPickerIcon: EditCalendarIcon,
        openPickerButton: ActionButtons as React.ElementType<
          IconButtonProps,
          keyof React.JSX.IntrinsicElements
        >,
        day: RevisedDay as any,
        textField: DatePickerTextField,
      }}
      slotProps={{
        day: {
          originalDate: dateRedline.originalEntry,
          revisedDate: dateRedline.revisedEntry,
          currentDate: dateRedline.currentEntry,
          resolved: dateRedline.isResolved,
        } as RedlinePickersDayProps,
        textField: {
          variant: "outlined",
          margin: "normal",
          dateRedline: dateRedline,
          focused: focused,
          InputLabelProps: {
            shrink:
              Boolean(dateRedline.currentEntry) ||
              Boolean(dateRedline.originalEntry) ||
              Boolean(dateRedline.revisedEntry),
          },
          onFocus: onTraverseIn,
          onTraverseIn,
        } as TextFieldProps,
      }}
    />
  );
};

export default DateRedlinePicker;
