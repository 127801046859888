import Text from 'common/values/text/text';
export default class ProjectDescription extends Text {
    public static get Prototype(): ProjectDescription {
        return new ProjectDescription('Prototype');
    }
    public override fromObject(obj: any): ProjectDescription | null {
        return new ProjectDescription(obj);
    }
    public toJSON(): any {
        return this.valueOf();
    }
}