import Guid from "common/values/guid/guid";

/** 
    * Represents a topic that a document can be associated with.
    * Must have at least one of the properties provided.
    * If entityId is provided, entityClass must be provided.
*/
export default class DocumentTopic {
  entityClass?: string;
  entityId?: Guid;
  context?: string;

  constructor(entityClass?: string, entityId?: Guid, context?: string) {
    if ((!entityClass || entityClass.length === 0)
      && (!context || context.length === 0)
      && !entityId) {
      throw new Error("At least one of the properties must be provided");
    }
    if (entityId && (!entityClass || entityClass.length === 0)) {
      throw new Error("entityClass must be provided when entityId is provided");
    }

    this.entityClass = entityClass;
    this.entityId = entityId;
    this.context = context;
  }

  toJSON(): string {
    const topic = {
      entityClass: this.entityClass,
      entityId: this.entityId?.valueOf(),
      context: this.context
    };
    return JSON.stringify(topic);
  }

  static fromObject(object: any): DocumentTopic {
    if (typeof object === "string") {
      object = JSON.parse(object);
    }
    const entityId = object.entityId ? Guid.fromObject(object.entityId) : undefined;
    return new DocumentTopic(object.entityClass, entityId, object.context);
  }
}
