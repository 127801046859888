import { Column } from "@material-table/core";
import DeleteIcon from "@mui/icons-material/Delete";
import ExitIcon from "@mui/icons-material/TransitEnterexit";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Avatar, Box, Chip, Icon, IconButton, Link, ListItemIcon, ListItemText, MenuItem, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { PageTab } from "app/routes/marketplace/profiles/tabs";
import { AvatarMap } from "app/routes/marketplace/profiles/types";
import RowActionMenu from "common/components/table/row-action-menu";
import { ActionsCellContainer } from "common/styles/table";
import Company from "marketplace/entities/company/company";
import Individual from "marketplace/entities/individual/individual";
import MarketplaceTeam from "marketplace/entities/marketplace-team/marketplace-team";
import React from "react";
import Session from "users/session/session";

const NameCellContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center'
}));
const NameLink = styled(Link)(({ theme }) => ({
  cursor: 'pointer',
  marginLeft: theme.spacing(1)
}));
const CategoryChip = styled(Chip)(({ theme }) => ({
  color: '#777',
  marginRight: theme.spacing(0.4),
}));

export type ProfileTableData = Individual & Company & MarketplaceTeam;

export function getTableColumns(
  activeTab: PageTab,
  avatars: AvatarMap,
  disableRowProfileEditing: (rowData: ProfileTableData) => boolean,
  handlePublish: (rowData: ProfileTableData) => Promise<void>,
  handleEdit: (rowData: ProfileTableData) => void,
  handleDelete: (rowData: ProfileTableData) => Promise<void>,
  handleExitTeam: (rowData: ProfileTableData) => Promise<void>,
  session: Readonly<Session>
): Column<ProfileTableData>[] {
  return [
    {
      field: 'name',
      title: 'Name',
      render: (rowData) => renderNameColumn(
        rowData,
        avatars,
        handleEdit
      )
    },
    {
      field: 'profile.categories',
      title: 'Categories',
      emptyValue: <Icon>remove</Icon>,
      render: renderCategoriesColumn
    },
    {
      field: 'isVisible',
      title: 'Searchable On Marketplace?',
      type: 'boolean',
      hidden: !session.context?.isVendor,
      render: (rowData) => {
        if (session.context?.viewingAsVendor) {
          return renderVisibilityColumn(
            rowData,
            disableRowProfileEditing,
            handlePublish);
        }
      }
    },
    {
      title: 'Actions',
      align: 'right',
      filtering: false,
      draggable: false,
      sorting: false,
      grouping: false,
      disableClick: true,
      hiddenByColumnsButton: true,
      removable: false,
      width: 'fit-content',
      render: (rowData) => renderActionsColumn(
        activeTab,
        rowData,
        handlePublish,
        handleEdit,
        handleDelete,
        handleExitTeam,
        session
      )
    }
  ];
}

function renderNameColumn(
  rowData: ProfileTableData,
  avatars: AvatarMap,
  handleEdit: (rowData: ProfileTableData) => void
): React.ReactNode {
  return (
    <NameCellContainer>
      <Avatar src={avatars[rowData.profile?.avatarId?.toString() ?? '']} />
      <NameLink onClick={() => handleEdit(rowData)}>
        <Typography>
          {rowData.profile.name ?? `${rowData?.profile?.firstName} ${rowData?.profile?.lastName}`}
        </Typography>
      </NameLink>
    </NameCellContainer >
  );
}

function renderCategoriesColumn(rowData: ProfileTableData) {
  if (rowData.profile?.categories.length < 1) return <Icon>remove</Icon>;
  return (
    <div>
      {rowData.profile?.categories?.map((label: string) => (
        <CategoryChip
          key={label}
          label={label}
          size="small"
        />
      ))}
    </div>
  );
}

function renderVisibilityColumn(
  rowData: ProfileTableData,
  disableRowProfileEditing: (rowData: ProfileTableData) => boolean,
  handlePublish: (rowData: ProfileTableData) => Promise<void>
) {
  return (
    <Tooltip
      title={
        rowData.isVisible ?
          'Profile is searchable on Marketplace' :
          'Profile is hidden from Marketplace'
      }>
      <IconButton
        onClick={() => handlePublish(rowData)}
        disabled={disableRowProfileEditing(rowData)}
        size="large">
        {rowData.isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
      </IconButton>
    </Tooltip>
  );
}

function renderActionsColumn(
  activeTab: PageTab,
  rowData: ProfileTableData,
  handlePublish: (rowData: ProfileTableData) => Promise<void>,
  handleEdit: (rowData: ProfileTableData) => void,
  handleDelete: (rowData: ProfileTableData) => Promise<void>,
  handleLeaveTeam: (rowData: ProfileTableData) => Promise<void>,
  session: Readonly<Session>
) {
  // TODO: Disable actions if profile is not editable
  return (
    <ActionsCellContainer>
      <RowActionMenu menuItems={getMenuItems(
        activeTab,
        rowData,
        () => handlePublish(rowData),
        () => handleEdit(rowData),
        () => handleDelete(rowData),
        () => handleLeaveTeam(rowData),
        session
      )} />
    </ActionsCellContainer>
  );
}

function getMenuItems(
  activeTab: PageTab,
  rowData: ProfileTableData,
  onPublish: () => Promise<void>,
  onEdit: () => void,
  onDelete: () => Promise<void>,
  onLeaveTeam: () => Promise<void>,
  session: Readonly<Session>
): React.ReactNode[] {

  const nodes: React.ReactNode[] = [];
  if (session.context?.viewingAsVendor) {
    nodes.push(
      <MenuItem key="publish" onClick={onPublish}>
        <ListItemIcon>
          {rowData.isVisible ? <VisibilityOffIcon fontSize="small" /> : <VisibilityIcon fontSize="small" />}
        </ListItemIcon>
        <ListItemText>{rowData.isVisible ? 'Hide From Marketplace' : 'Show On Marketplace'}</ListItemText>
      </MenuItem>
    );
  }
  nodes.push(
    [<MenuItem key="edit" onClick={onEdit}>
      <ListItemIcon><EditIcon fontSize="small" /></ListItemIcon>
      <ListItemText>Edit Profile</ListItemText>
    </MenuItem>
    ]);
  if (activeTab === PageTab.Teams) {
    const teamProfile = rowData as MarketplaceTeam;
    if (teamProfile.leader?.userId?.isEqualTo(session.user?.id)) {
      nodes.push(
        <MenuItem key="delete" onClick={onDelete}>
          <ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      );
    } else {
      nodes.push(
        <MenuItem key="leave" onClick={onLeaveTeam}>
          <ListItemIcon><ExitIcon fontSize="small" /></ListItemIcon>
          <ListItemText>Leave Team</ListItemText>
        </MenuItem>);
    }
  }
  return nodes;
}
