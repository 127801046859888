import FeeScheduleCategory from "marketplace/entities/fee-schedule-category/fee-schedule-category";

export default class MarketplaceFeeScheduleCategoryAPIRequest {
    name: string;
    description?: string;
    billingCode?: string;
    feeRate?: number;
    feeCurrency?: string;
    feeUnit?: string;

    constructor(feeCategory: FeeScheduleCategory) {
        const name = feeCategory.name.valueOf();
        if(!name) 
            throw new Error("Fee schedule category name is required");

        this.name = name;
        this.description = feeCategory.description?.valueOf() ?? "";
        this.billingCode = feeCategory.billingCode.valueOf() ?? "";
        this.feeRate = feeCategory.fee?.rate?.amount;
        this.feeCurrency = feeCategory.fee?.rate?.currency;
        this.feeUnit = feeCategory.fee.unit;
    }
}