import Guid from "common/values/guid/guid";
import TopicCategory from "notifications/values/topic-category/topic-category";

export default class NotificationTopic {
  category?: TopicCategory | string;
  id?: Guid | null;

  constructor(category?: string, id?: Guid | null) {
    this.category = category;
    this.id = id;
  }
}
