import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import WorkIcon from '@mui/icons-material/Work';
import { IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PageTab } from 'app/routes/proposals/tabs';
import Guid from 'common/values/guid/guid';
import Proposal from 'work/entities/proposal/proposal';
import ActionsMenu from './actions-menu';
import { ProposalAction, ProposalStatus } from 'work/values/constants';

const ActionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end'
}));
const ActionButton = styled(IconButton)(({ theme }) => ({
  color: 'black'
}));

type ActionsColumnProps = {
  userId?: Guid;
  proposal: Proposal;
  activeTab: PageTab;
  onAction: (action: ProposalAction, proposal: Proposal) => Promise<void>;
  onChangeTab?: (tab: PageTab) => void;
};

export default function ActionsColumn(props: Readonly<ActionsColumnProps>) {
  const {
    userId,
    proposal,
    activeTab,
    onAction,
    onChangeTab
  } = props;

  return (
    <ActionContainer>
      {proposal.availableActions?.Edit.some(id => id.isEqualTo(userId)) && (
        <Tooltip title='Edit Proposal'>
          <span>
            <ActionButton
              onClick={() => onAction(ProposalAction.Edit, proposal)}
              size="large">
              <EditIcon color='primary' />
            </ActionButton>
          </span>
        </Tooltip>
      )}
      {proposal.availableActions?.Submit?.some(id => id.isEqualTo(userId)) && (
        <Tooltip title={proposal?.team ? 'Submit Proposal' : 'Proposal has no team to send to'}>
          <span>
            <IconButton
              style={{
                color: proposal?.team ? 'black' : 'rgba(0, 0, 0, 0.54)',
                cursor: proposal?.team ? 'pointer' : 'default'
              }}
              onClick={async () => {
                if (!proposal?.team) return;
                onAction(ProposalAction.Submit, proposal)
                  .then(() => onChangeTab && onChangeTab(PageTab.Active))
                  .catch(() => { });
              }}
              size="large">
              <SendIcon color='primary' />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {proposal.availableActions?.Revise?.some(id => id.isEqualTo(userId)) && (
        <Tooltip title={proposal.status === ProposalStatus.RevisionRequested ? 'Show Requested Changes' : 'Review/Revise Proposal'}>
          <span>
            <ActionButton
              onClick={() => onAction(ProposalAction.Revise, proposal)}
              size="large">
              <EditIcon color='primary' />
            </ActionButton>
          </span>
        </Tooltip>
      )}
      {activeTab !== PageTab.Archived && proposal.availableActions?.Hire?.some(id => id.isEqualTo(userId)) && (
        <Tooltip title='Hire Proposal'>
          <span>
            <ActionButton
              onClick={() => {
                onAction(ProposalAction.Hire, proposal)
                  .catch(() => { });
              }}
              size="large">
              <WorkIcon color='primary' />
            </ActionButton>
          </span>
        </Tooltip>
      )}
      <ActionsMenu
        activeTab={activeTab}
        proposal={proposal._instance}
        onAction={onAction}
      />
    </ActionContainer>
  );
}
