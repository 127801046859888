import Guid from "common/values/guid/guid";
import Name from "common/values/name/name";
import EntityName from "legal-entities/values/entity-name/entity-name";
import EmailAddress from "common/values/email-address/email-address";


export default class EntityMember {
    public readonly userId: Guid;
    public readonly memberId: Guid;
    public readonly individualId: Guid;
    public readonly name: Name;
    public readonly entityId: Guid;
    public readonly entityName: EntityName;
    public readonly email: EmailAddress
    public readonly isAdmin: boolean;
    public readonly isOfficer: boolean;
    public readonly isActive: boolean;
    public readonly entityVendorFlag?: boolean;
    public readonly entityClientFlag?: boolean;
    public readonly created?: Date;
    public readonly avatarId?: Guid;
    public readonly description?: string;
    public readonly location?: string;
    public readonly school?: string;
    public readonly phoneNumber?: string;
    public readonly resumeId?: Guid;
    public readonly professionalRegistration?: string;
    public readonly technicalBackground?: string;
    public readonly numberOfApplicationsDraftedProsecuted?: number;
    public readonly numberOfAllowedApplications?: number;
    public readonly barAdmissionYear?: number;
    public readonly isOnCall?: boolean;
    public readonly insuredAmount?: number;
    public readonly billingRate?: string;
    public readonly potentialDiversityCandidate?: boolean;
    public readonly collaborates?: boolean;
    public readonly additionalInfo?: string;
    public readonly categories: string[];
    public readonly links: URL[];
    public readonly professionalPublications: URL[];
    public readonly sampleApplications: URL[];
    public readonly fluentLanguages: string[];
    public readonly technicalLanguages: string[];
    public readonly regionsRegistered: string[];
    public readonly regionsLicensed: string[];

    /**
     * Returns the user id.
     */
    public get id(): Guid {
        return this.memberId;
    }

    /**
     * Returns true if the user is an admin and/or an officer.
     */
    public get isManager(): boolean {
        return this.isAdmin || this.isOfficer;
    }

    constructor(values: Partial<EntityMember>) {
        if (!values.userId) throw new InvalidEntityMemberInfoError({ id: 'userId is required' });
        if (!values.memberId) throw new InvalidEntityMemberInfoError({ memberId: 'memberId is required' });
        if (!values.individualId) throw new InvalidEntityMemberInfoError({ individualId: 'individualId is required' });
        if (!values.name) throw new InvalidEntityMemberInfoError({ name: 'name is required' });
        if (!values.entityId) throw new InvalidEntityMemberInfoError({ entityId: 'entityId is required' });
        if (!values.entityName) throw new InvalidEntityMemberInfoError({ entityName: 'entityName is required' });
        if (!values.email) throw new InvalidEntityMemberInfoError({ email: 'email is required' });
        if (values.isAdmin === undefined) throw new InvalidEntityMemberInfoError({ isAdmin: 'isAdmin is required' });
        if (values.isOfficer === undefined) throw new InvalidEntityMemberInfoError({ isOfficer: 'isOfficer is required' });
        if (values.isActive === undefined) throw new InvalidEntityMemberInfoError({ isActive: 'isActive is required' });

        this.userId = values.userId;
        this.memberId = values.memberId;
        this.individualId = values.individualId;
        this.name = values.name;
        this.entityId = values.entityId;
        this.entityName = values.entityName;
        this.email = values.email;
        this.isAdmin = values.isAdmin;
        this.isOfficer = values.isOfficer;
        this.isActive = values.isActive;
        this.entityVendorFlag = values.entityVendorFlag;
        this.entityClientFlag = values.entityClientFlag;
        this.created = values.created;
        this.avatarId = values.avatarId;
        this.description = values.description;
        this.location = values.location;
        this.school = values.school;
        this.phoneNumber = values.phoneNumber;
        this.resumeId = values.resumeId;
        this.professionalRegistration = values.professionalRegistration;
        this.technicalBackground = values.technicalBackground;
        this.numberOfApplicationsDraftedProsecuted = values.numberOfApplicationsDraftedProsecuted;
        this.numberOfAllowedApplications = values.numberOfAllowedApplications;
        this.barAdmissionYear = values.barAdmissionYear;
        this.isOnCall = values.isOnCall;
        this.insuredAmount = values.insuredAmount;
        this.billingRate = values.billingRate;
        this.potentialDiversityCandidate = values.potentialDiversityCandidate;
        this.collaborates = values.collaborates;
        this.additionalInfo = values.additionalInfo;
        this.categories = values.categories ?? [];
        this.links = values.links ? values.links.map(link => new URL(link)) : [];
        this.professionalPublications = values.professionalPublications ? values.professionalPublications.map(link => new URL(link)) : [];
        this.sampleApplications = values.sampleApplications ? values.sampleApplications.map(link => new URL(link)) : [];
        this.fluentLanguages = values.fluentLanguages ?? [];
        this.technicalLanguages = values.technicalLanguages ?? [];
        this.regionsRegistered = values.regionsRegistered ?? [];
        this.regionsLicensed = values.regionsLicensed ?? [];
    }
}

export class InvalidEntityMemberInfoError extends Error {
    issues: any;

    constructor(issues: any) {
        super(`Invalid entity member info.`);
        this.issues = issues;
    }
}

export class UnauthorizedEntityMemberAccessError extends Error {
    constructor(entityId: Guid) {
        super(`Unauthorized access to legal entity members for entity ${entityId}`);
    }
}

export class EntityMemberNotFoundException extends Error {
    constructor(memberId: Guid) {
        super(`Entity member ${memberId} not found`);
    }
}
