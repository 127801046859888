import MaterialTable, { Action, Column, MaterialTableProps, MTableActions } from '@material-table/core';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Button, Tooltip } from '@mui/material';
import { ActionsContainer } from 'common/styles/table';

import { AccountType } from 'common/values/account-type/account-type';
import { useNavigate } from 'react-router';
import Session from 'users/session/session';
import FeeScheduleTemplate from 'work/entities/fee-schedule-template/fee-schedule-template';

type FeeScheduleTableRef = React.MutableRefObject<(MaterialTable<FeeScheduleTemplate> & MaterialTableProps<FeeScheduleTemplate>) | undefined>;
export interface TableActionsComponentProps extends MaterialTableProps<FeeScheduleTemplate> {
  tableRef: FeeScheduleTableRef;
  session: Session;
  isMediumDisplaySize: boolean;
  columns: Column<FeeScheduleTemplate>[]
}

export function TableActionsComponent(props: Readonly<TableActionsComponentProps>): React.ReactElement {
  const isContextRep = props.session?.isContextRepresentative;
  const context = props.session?.accountType === AccountType.Vendor ? 'vendor' : 'client';

  const navigate = useNavigate();

  return (
    <ActionsContainer>
      {!props.isMediumDisplaySize && (
        <Tooltip title={isContextRep ? '' : `You must be an entity ${context} representative to create fee schedules`}>
          <span>
            <Button
              color='primary'
              startIcon={<AddBoxIcon />}
              disabled={!isContextRep}
              onClick={() => navigate("/fee-schedules/templates/create")}>
              New Template Fee Schedule
            </Button>
          </span>
        </Tooltip>
      )}
      <MTableActions {...props} />
    </ActionsContainer>
  );
}

export function getTableActions(props: TableActionsComponentProps): Action<object>[] {
  return [NewTemplateFeeSchedule(props)];
}

function NewTemplateFeeSchedule(props: TableActionsComponentProps): Action<object> {
  const navigate = useNavigate();

  return {
    icon: AddBoxIcon,
    iconProps: { color: 'primary', fontSize: 'large' },
    tooltip: 'New Template Fee Schedule',
    position: 'toolbar',
    hidden: !props.isMediumDisplaySize,
    isFreeAction: true,
    onClick: () => navigate('/fee-schedules/templates/create')
  };
}
