const VALID_EMAIL_REGEXP_PATTERN = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/g;

export default class EmailAddress {
    private _value: string;

    constructor(value: string) {
        this._value = value;
        EmailAddress.validate(value);
    }

    static validate(value: string) {
        if (!value) {
            throw new InvalidEmailAddressError('cannot be empty');
        }
        const validEmailRegExp = new RegExp(VALID_EMAIL_REGEXP_PATTERN);
        const emailIsValid = validEmailRegExp.test(value);
        if (!emailIsValid) {
            throw new InvalidEmailAddressError(value);
        }
    }

    static isValid(value: string): boolean {
        try {
            EmailAddress.validate(value);
            return true;
        } catch (error) {
            return false;
        }
    }

    public isEqualTo(other?: EmailAddress): boolean {
        if (!other) {
            return false;
        }
        return this.valueOf() === other.valueOf();
    }

    public valueOf(): string {
        return this._value;
    }

    public toString(): string {
        return this.valueOf();
    }

    public static fromObject(object: any): EmailAddress {
        if (!object) throw new Error('Email object is missing required fields');
        return new EmailAddress(object);
    }
    public toJSON() {
        return this.valueOf();
    }
}

export class InvalidEmailAddressError extends Error {
    value: string;
    constructor(value: string) {
        super(`Must be in the format of [account]@[domain].[tld]`);
        this.value = value;
    }
}