import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/EditOutlined';
import {
  Button, Chip, CircularProgress, Link, Table, TableBody, TableCell, TableHead, TableRow, Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ConfirmResponse, useConfirmDialog } from 'app/providers/confirm-dialog';
import { useDialog } from 'app/providers/dialog';
import LoadingButton from 'common/components/loading-button';
import Guid from 'common/values/guid/guid';
import FeeScheduleAPIService from 'marketplace/entities/fee-schedule/api/fee-schedule-api-service';
import FeeSchedule from 'marketplace/entities/fee-schedule/fee-schedule';
import FeeScheduleTag from 'marketplace/values/fee-schedule-tag/fee-schedule-tag';
import MarketplaceFeeScheduleDialog from 'marketplace/view/marketplace-fee-schedule-dialog';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useSession } from 'users/session/session-context';

const NoFeeScheduleText = styled(Typography)(({ theme }) => ({
  color: '#999',
  padding: theme.spacing(1)
}));
const StyledLink = styled(Link)(({ theme }) => ({
  marginRight: theme.spacing(1)
})) as typeof Link;
const StyledChip = styled(Chip)(({ theme }) => ({
  marginRight: theme.spacing(0.2),
  outlined: true,
  size: 'small'
})) as typeof Chip;

type FeeSchedulesPanelProps = {
  teamId: Guid;
  leaderUserId?: Guid;
  onPanelUpdated: () => void;
}

export default function FeeSchedulesPanel(props: Readonly<FeeSchedulesPanelProps>) {
  const { teamId, leaderUserId, onPanelUpdated } = props;

  const [loading, setLoading] = React.useState(false);
  const [feeSchedules, setFeeSchedules] = React.useState<FeeSchedule[]>([]);
  const [deletingFeeScheduleId, setDeletingFeeScheduleId] = React.useState<Guid | undefined>(undefined);

  const session = useSession();
  const confirm = useConfirmDialog();
  const { openDialog, closeDialog } = useDialog();

  useEffect(() => {
    getFeeSchedules();
  }, []);

  async function getFeeSchedules() {
    if (!teamId) return;

    try {
      setLoading(true);
      const service = new FeeScheduleAPIService(session);
      const feeSchedules = await service.getFeeSchedulesForTeam(teamId, new AbortController());
      setFeeSchedules(feeSchedules);
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Couldn't get fee schedules. Please try again", { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }

  async function deleteFeeSchedule(feeScheduleId: Guid | undefined) {
    if (!feeScheduleId) return;

    const response = await confirm({
      title: 'Delete fee schedule?',
      message: "Are you sure you want to delete this fee schedule?",
      okButtonText: 'Delete'
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      setDeletingFeeScheduleId(feeScheduleId);
      const service = new FeeScheduleAPIService(session);
      await service.deleteFeeSchedule(teamId, feeScheduleId);
      onPanelUpdated();
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Couldn't delete fee schedule. Please try again", { variant: 'error' });
    } finally {
      let updatedFeeSchedules = [...feeSchedules];
      const feeScheduleIndex = updatedFeeSchedules.findIndex(feeSchedule => feeSchedule.id?.isEqualTo(feeScheduleId));
      updatedFeeSchedules.splice(feeScheduleIndex, 1);
      setFeeSchedules(updatedFeeSchedules);
      setDeletingFeeScheduleId(undefined);
    }
  }

  function handleAddFeeSchedule() {
    openDialog({
      title: 'Add Fee Schedule',
      component: (
        <MarketplaceFeeScheduleDialog
          teamId={teamId}
          onChanged={() => {
            onPanelUpdated();
            closeDialog();
          }}
        />
      )
    });
  }

  function handleEditFeeSchedule(feeSchedule: FeeSchedule) {
    openDialog({
      title: leaderUserId?.isEqualTo(session.user?.id) ? 'Edit Fee Schedule' : null,
      component: (
        <MarketplaceFeeScheduleDialog
          teamId={teamId}
          feeSchedule={feeSchedule}
          onChanged={
            () => {
              onPanelUpdated();
              closeDialog();
            }}
          viewOnly={leaderUserId?.valueOf() !== session.user?.id?.valueOf()}
        />
      )
    });
  }

  return (
    <React.Fragment>
      <Table>
        <TableHead style={{ backgroundColor: 'aliceblue' }}>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Tags</TableCell>
            <TableCell># of Categories</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && (
            <TableRow>
              <TableCell colSpan={4} align="center">
                <CircularProgress size={33} />
              </TableCell>
            </TableRow>
          )}
          {feeSchedules.length < 1 && !loading && (
            <TableRow>
              <TableCell colSpan={4}>
                <NoFeeScheduleText>No Fee Schedules</NoFeeScheduleText>
              </TableCell>
            </TableRow>
          )}
          {feeSchedules.length > 0 && (
            <>
              {feeSchedules.map((feeSchedule: FeeSchedule) => (
                <TableRow key={feeSchedule.id?.toString()}>
                  <TableCell>
                    <StyledLink
                      component={Typography}
                      onClick={() => handleEditFeeSchedule(feeSchedule)}>
                      {
                        leaderUserId?.valueOf() !== session.user?.id?.valueOf()
                          ? feeSchedule.name
                          : <strong>{feeSchedule.name}</strong>
                      }

                    </StyledLink>
                  </TableCell>
                  <TableCell>
                    {feeSchedule.tags.map((tag: FeeScheduleTag) => (
                      <StyledChip
                        key={tag.toString()}
                        label={tag.toString()}
                      />
                    ))}
                  </TableCell>
                  <TableCell>
                    {feeSchedule.categories.length}
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      startIcon={<EditIcon />}
                      disabled={leaderUserId?.valueOf() !== session.user?.id?.valueOf()}
                      onClick={() => handleEditFeeSchedule(feeSchedule)}>
                      Edit
                    </Button>
                    <LoadingButton
                      startIcon={<DeleteIcon />}
                      loading={deletingFeeScheduleId?.valueOf() === feeSchedule.id?.valueOf()}
                      disabled={leaderUserId?.valueOf() !== session.user?.id?.valueOf()}
                      onClick={() => deleteFeeSchedule(feeSchedule.id)}>
                      Delete
                    </LoadingButton>
                  </TableCell>
                </TableRow>
              ))}
            </>
          )}
        </TableBody>
      </Table>
      <Button
        startIcon={<AddIcon />}
        disabled={leaderUserId?.valueOf() !== session.user?.id?.valueOf()}
        onClick={handleAddFeeSchedule}>
        Add Fee Schedule
      </Button>
    </React.Fragment>
  );
}
