import Guid from "common/values/guid/guid";
import { ProposalFieldName } from "work/values/constants";
export enum Audience {
    VendorReviewers = "vendorReviewers",
    ClientReviewers = "clientReviewers",
    AllReviewers = "allReviewers"
}
export class TopicContext {
    audience: Audience;
    field: ProposalFieldName;
    fieldId?: Guid;

    constructor(audience: Audience, field: ProposalFieldName, fieldId?: Guid) {
        this.audience = audience;
        this.field = field;
        this.fieldId = fieldId;
    }

    toJSON(): object {
        return {
            audience: this.audience,
            field: this.field,
            fieldId: this.fieldId?.valueOf()
        };
    }
}