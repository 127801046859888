import { styled } from '@mui/material/styles';

import { emptyInboxPlaceholder } from 'app/assets';

import { EmptyMessage } from 'common/styles';


const AllCaughtUp = styled('img')(({ theme }) => ({
  maxHeight: '100px',
  padding: theme.spacing(2),
  width: '100vw'
}));

interface Props {
  tableBodyHeight: number;
}

export function NoRowsPlaceholder(props: Readonly<Props>) {
  return (
    <EmptyMessage tableBodyHeight={props.tableBodyHeight}>
      <AllCaughtUp alt="All Caught Up" src={emptyInboxPlaceholder} />
    </EmptyMessage>
  );
}
