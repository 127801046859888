import MaterialTable from '@material-table/core';
import { LockOpen } from '@mui/icons-material';
import PersonAdd from '@mui/icons-material/PersonAdd';
import PersonAddDisabled from '@mui/icons-material/PersonAddDisabled';
import SearchIcon from '@mui/icons-material/Search';
import SecurityIcon from '@mui/icons-material/Security';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { Box, IconButton, Link, Paper, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ConfirmResponse, useConfirmDialog } from 'app/providers/confirm-dialog';
import { useDialog } from 'app/providers/dialog';
import EditCompanyProfile from 'marketplace/values/company-profile/view/edit-company-profile';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { useNavigate } from 'react-router';
import SuperUserAPIService from 'users/entities/super-user/api/super-user-api-service';
import AuthEmailAPIService, { AuthResponse } from "users/entities/user/api/auth-email-api-service";
import User from 'users/entities/user/user';
import { useSession } from 'users/session/session-context';

const UserForm = styled(Paper)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2)
  },
  padding: theme.spacing(6)
}));
const InnerContainer = styled(Box)(() => ({
  margin: 'auto',
  textAlign: 'center',
  width: '100%'
}));
const SearchBox = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
  width: '100%'
}));

export default function UsersAdmin() {
  const tableRef = React.useRef<User>();

  const [query, setQuery] = React.useState('');
  const [userSearchResults, setUserSearchResults] = React.useState<User[]>([]);
  const [loading, setLoading] = React.useState(false);

  const confirm = useConfirmDialog();
  const session = useSession();
  const navigate = useNavigate();
  const { openDialog } = useDialog();

  function resetState() {
    setQuery('');
    setUserSearchResults([]);
    setLoading(false);
  }

  async function handleUserSearch() {
    try {
      setLoading(true);
      const superUserService = new SuperUserAPIService(session);
      const users = await superUserService.searchUsers(query);

      if (users.length < 1) {
        setUserSearchResults([]);
        return;
      }

      setUserSearchResults(users);

    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Couldn't get user", { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }

  async function registerSuperUser(_event: any, user: User) {
    if (!user) return;
    const response = await confirm({
      title: `Register ${user.email.valueOf()} as a superuser?`,
      message: "Are you sure you want to register this user as a superuser?",
      okButtonText: "Yes",
      cancelButtonText: "No"
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      const superUserService = new SuperUserAPIService(session);
      await superUserService.register(user);
      handleUserSearch();
    } catch (err: any) {
      console.error("Couldn't register superuser");
      enqueueSnackbar("Couldn't register superuser", { variant: 'error' });
    }
  }

  async function unRegisterSuperUser(_event: any, user: User) {
    const response = await confirm({
      title: `Unregister ${user.email.valueOf()} as a superuser?`,
      message: "Are you sure you want to unregister this user as a superuser?",
      okButtonText: "Yes",
      cancelButtonText: "No"
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      const superUserService = new SuperUserAPIService(session);
      await superUserService.unregister(user);
      handleUserSearch();
    } catch (err: any) {
      console.error("Couldn't unregister superuser");
      enqueueSnackbar("Couldn't unregister superuser", { variant: 'error' });
    }
  }


  async function disableUser(_event: any, user: User) {
    const response = await confirm({
      title: `Disable ${user.email.valueOf()}?`,
      message: "Are you sure you want to disable this user?",
      okButtonText: "Yes",
      cancelButtonText: "No"
    })

    if (response === ConfirmResponse.Cancel) return;

    try {
      const superUserService = new SuperUserAPIService(session);
      await superUserService.disableUser(user);
      enqueueSnackbar("Disabled user.", { variant: 'success' });
      await handleUserSearch();
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Couldn't disable user", { variant: 'error' });
    }
  }

  async function enableUser(_event: any, user: User) {
    const response = await confirm({
      title: `Enable ${user.email}?`,
      message: "Are you sure you want to enable this user?",
      okButtonText: "Yes",
      cancelButtonText: "No"
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      const superUserService = new SuperUserAPIService(session);
      await superUserService.enableUser(user);
      enqueueSnackbar("Re-enabled user.", { variant: 'success' });
      await handleUserSearch();
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Couldn't re-enable user", { variant: 'error' });
    }
  }

  async function resetUserPassword(_event: any, data: User | User[]) {
    let user: User;
    if (Array.isArray(data)) {
      user = data[0];
    }
    else {
      user = data;
    }
    const response = await confirm({
      title: `Reset ${user.email}'s password?`,
      message: "Are you sure you want to reset this user's password?",
      okButtonText: "Yes",
      cancelButtonText: "No"
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      await AuthEmailAPIService.requestPasswordReset(user.email);
      enqueueSnackbar("User's password reset.", { variant: 'success' });
      resetState();
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Couldn't reset user password", { variant: 'error' });
    }
  }

  async function loginAsUser(_event: any, data: User | User[]) {
    let user: User;
    if (Array.isArray(data)) {
      user = data[0];
    }
    else {
      user = data;
    }
    const response = await confirm({
      title: `Login as ${user.email}?`,
      message: "Are you sure you want to login as this user?",
      okButtonText: "Yes",
      cancelButtonText: "No"
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      const superUserService = new SuperUserAPIService(session);
      const authResponse: AuthResponse = await superUserService.loginAsUser(user);
      session.initializeFromAuthResponse(authResponse);
      navigate('/dashboard', { replace: true });
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Couldn't login as user", { variant: 'error' });
    }
  }

  return (
    <React.Fragment>
      <UserForm>
        <InnerContainer>
          <SearchBox
            variant="outlined"
            label="User Search"
            value={query}
            onChange={(event) => setQuery(event.target.value)}
            onKeyDown={(event) => { if (event.key === 'Enter') { handleUserSearch(); } }}
            InputProps={{
              endAdornment: (
                <IconButton onClick={handleUserSearch} disabled={!query} size="large">
                  <SearchIcon />
                </IconButton>
              )
            }}
          />
        </InnerContainer>
      </UserForm>
      <UserForm>
        <MaterialTable
          tableRef={tableRef}
          title="Users"
          columns={[
            {
              field: "email",
              title: "Email",
              render: rowData => <strong>{rowData.userIsDisabled ? <s><i>{rowData.email.valueOf()}</i></s> : rowData.email.valueOf()}</strong>
            },
            { field: "name.firstName", title: "First Name" },
            { field: "name.lastName", title: "Last Name" },
            {
              field: "companyName",
              title: "Company",
              render: (rowData) =>
                rowData.companyId &&
                <Link
                  style={{
                    color: '#000',
                    cursor: 'pointer',
                    textDecoration: 'underline'
                  }}
                  onClick={() => openDialog({
                    component: (
                      <EditCompanyProfile
                        isVisible={false}
                        companyId={rowData.companyId}
                      />
                    )
                  })
                  }>
                  <Typography style={{ marginLeft: '1rem' }}>
                    {rowData.companyName}
                  </Typography>
                </Link>
            },
          ]}
          actions={[
            (rowData) => ({
              icon: () => <SecurityIcon
                color={rowData.isSuperUser ? 'error' : 'primary'}
              />,
              tooltip: rowData.isSuperUser ? 'Unregister As Superuser' : 'Register As Superuser',
              isFreeAction: false,
              onClick: (event) => {
                rowData.isSuperUser ?
                  unRegisterSuperUser(event, rowData) :
                  registerSuperUser(event, rowData)
              }
            }),
            {
              icon: VpnKeyIcon,
              tooltip: 'Force Password Reset',
              isFreeAction: false,
              onClick: (event, rowData) => {
                resetUserPassword(event, rowData);
              }
            },
            {
              icon: LockOpen,
              tooltip: 'Login As User',
              isFreeAction: false,
              onClick: (event, rowData) => {
                loginAsUser(event, rowData);
              }
            },
            (rowData) => ({
              icon: () => rowData.userIsDisabled ? <PersonAdd color='primary' /> : <PersonAddDisabled color='error' />,
              tooltip: rowData.userIsDisabled ? 'Enable User' : 'Disable User',
              isFreeAction: false,
              onClick: (event) => {
                rowData.userIsDisabled ?
                  enableUser(event, rowData) :
                  disableUser(event, rowData)
              }
            })
          ]}
          data={userSearchResults}
          isLoading={loading}
          options={{
            grouping: false,
            search: false,
            draggable: false,
            debounceInterval: 500,
            filtering: false,
            pageSize: 10,
            pageSizeOptions: [10, 25, 50],
            actionsColumnIndex: -1
          }}
          localization={{
            body: {
              emptyDataSourceMessage: (
                <Typography variant="h6">
                  No Users Found
                </Typography>
              )
            }
          }}
        />
      </UserForm>
    </React.Fragment>
  );
}
