import CommentIcon from "@mui/icons-material/Comment";
import {
  Badge,
  Divider,
  Grid,
  IconButton,
  Tooltip
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  ConfirmResponse,
  useConfirmDialog,
} from "app/providers/confirm-dialog";
import { downloadFile } from "common/helpers/utils";
import Guid from "common/values/guid/guid";
import DocumentAPIService from "documents/entities/document/api/document-api-service";
import Document from "documents/entities/document/document";
import DocumentSelector, { TableDocument } from "documents/view/document-selector";
import DocumentUploadOverlay from "documents/view/document-upload-overlay";
import Forum from "messaging/entities/forum/forum";
import { enqueueSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useSession } from "users/session/session-context";
import Proposal from "work/entities/proposal/proposal";
import { ProposalTab } from "work/entities/proposal/redlining/view/proposal-redline-dialog";
import { getForumForField } from "work/entities/proposal/utils/comment-utils";
import ProposalBuilder from "work/entities/proposal/utils/proposal-builder";
import { ProposalFieldName } from "work/values/constants";
import SelectedDocumentList from "work/values/work-document/view/selected-document-list";
import WorkDocument, { WorkDocumentType } from "work/values/work-document/work-document";

const ListContainer = styled(Grid)(() => ({
  flexWrap: 'nowrap',
  minHeight: "20rem",
  width: '100%'
}));
const DocumentSelectorContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  width: '100%'
}));
const SelectedDocsList = styled(SelectedDocumentList)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    marginTop: theme.spacing(2),
  },
  maxHeight: '60vh',
  marginLeft: theme.spacing(2),
  overflowY: 'auto'
}));
const HeadControls = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "end",
  marginBottom: theme.spacing(2),
  paddingLeft: theme.spacing(2),
}));
const SectionDivider = styled(Divider)(({ theme }) => ({
  alignItems: 'center',
  margin: theme.spacing(0, 2, 0, 4)
}));
const Uploader = styled(DocumentUploadOverlay)(({ theme }) => ({
  backgroundColor: 'rgba(250, 250, 250, 0.5)',
  backdropFilter: 'blur(5px) saturate(200%)',
  position: 'absolute',
  top: 0,
  left: 0,
  margin: '1rem',
  width: 'calc(100% - 2rem)',
  height: 'calc(100% - 2rem)',
  zIndex: 9999
}));

type PolicyUploadProps = {
  activeTab: ProposalTab;
  proposalBuilder: ProposalBuilder;
  disableCommenting?: boolean;
  commentForums?: Forum[];
  disableEditing?: boolean;
  onCommentsClicked: (id?: Guid, name?: string) => void;
  onChange: (newProposal?: Proposal) => void;
};

export default function PoliciesTab(props: Readonly<PolicyUploadProps>) {
  const {
    activeTab,
    proposalBuilder,
    disableCommenting,
    commentForums,
    disableEditing,
    onCommentsClicked,
    onChange,
  } = props;

  const [creatingDocumentFromTemplate, setCreatingDocumentFromTemplate] = React.useState<Guid | null>(null);
  const [isDownloadingFile, setIsDownloadingFile] = React.useState<Guid | null>(null);
  const [selectedDocuments, setSelectedDocuments] = React.useState<Array<WorkDocument>>([]);

  const confirm = useConfirmDialog();
  const session = useSession();

  useEffect(() => {
    setSelectedDocuments(session.context?.viewingAsVendor
      ? proposalBuilder.currentSpec.workAgreement?.vendorPolicyDocuments ?? []
      : proposalBuilder.currentSpec.workAgreement?.clientPolicyDocuments ?? []);
  }, []);

  async function handlePolicyDocumentRemoved(documentId: Guid): Promise<void> {
    const response = await confirm({
      title: "Remove Policy Document",
      message: "Are you sure you want to remove this policy document?",
    });

    if (response === ConfirmResponse.Cancel) return;

    if (session.context?.viewingAsVendor) {
      proposalBuilder.setVendorPolicyDocuments(
        (
          proposalBuilder.currentSpec.workAgreement?.vendorPolicyDocuments ?? []
        ).filter((doc) => !doc.id.isEqualTo(documentId))
      );
    } else {
      proposalBuilder.setClientPolicyDocuments(
        (
          proposalBuilder.currentSpec.workAgreement?.clientPolicyDocuments ?? []
        ).filter((doc) => !doc.id.isEqualTo(documentId))
      );
    }
    setSelectedDocuments(prevValue => prevValue.filter((document) => !document.id.isEqualTo(documentId)));
    onChange();
  }

  async function handleDownloadDocumentById(
    documentId: Guid,
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> {
    event?.stopPropagation();
    try {
      setIsDownloadingFile(documentId);
      const documentApiService = new DocumentAPIService(session);
      const policyDocument = await documentApiService.downloadDocument(
        documentId
      );
      downloadFile(policyDocument);
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to download policy document. Please try again", {
        variant: "error",
      });
    } finally {
      setIsDownloadingFile(null);
    }
  }

  function handleDocumentSelectionChanged(documents: TableDocument[]) {
    let currentDocuments = session.context?.viewingAsVendor
      ? proposalBuilder.currentSpec.workAgreement?.vendorPolicyDocuments ?? []
      : proposalBuilder.currentSpec.workAgreement?.clientPolicyDocuments ?? [];
    let selectedDocs = [...selectedDocuments];

    for (const doc of documents) {
      if (doc.tableData.checked) {
        if (!currentDocuments.some(document => document.id.isEqualTo(doc.id))) {
          currentDocuments.push(WorkDocument.fromDocument(doc, session.context?.viewingAsVendor ? WorkDocumentType.VendorPolicy : WorkDocumentType.ClientPolicy));
        }
        if (!selectedDocs.some((document) => document.id.isEqualTo(doc.id))) {
          selectedDocs.push(WorkDocument.fromDocument(doc, session.context?.viewingAsVendor ? WorkDocumentType.VendorPolicy : WorkDocumentType.ClientPolicy));
        }
      } else if (!doc.tableData.checked) {
        selectedDocs = selectedDocs.filter((document) => !document.id.isEqualTo(doc.id));
        currentDocuments = currentDocuments.filter(document => !document.id.isEqualTo(doc.id));
      }
    }

    if (session.context?.viewingAsVendor) {
      proposalBuilder.setVendorPolicyDocuments(currentDocuments);
    } else {
      proposalBuilder.setClientPolicyDocuments(currentDocuments);
    }
    setSelectedDocuments(selectedDocs);
    onChange();
  }

  function handlePolicyDocumentAdded(policyDocument: Document) {
    let currentDocuments =  session.context?.viewingAsVendor ?
      proposalBuilder.currentSpec.workAgreement?.vendorPolicyDocuments ?? [] :
      proposalBuilder.currentSpec.workAgreement?.clientPolicyDocuments ?? [];

    let selectedDocs = [...selectedDocuments];
    const newDocument = WorkDocument.fromDocument(policyDocument, session.context?.viewingAsVendor ? WorkDocumentType.VendorPolicy : WorkDocumentType.ClientPolicy);
    selectedDocs.push(newDocument);
    currentDocuments.push(newDocument);
    if(session.context?.viewingAsVendor){
      proposalBuilder.setVendorPolicyDocuments(currentDocuments);
    }
    else {
      proposalBuilder.setClientPolicyDocuments(currentDocuments);
    }
    
    setSelectedDocuments(selectedDocs);
    setCreatingDocumentFromTemplate(null);
    onChange();
  }

  function getShouldShowBadge(documentId?: Guid): boolean {
    return !getForumForField(
      session.context?.viewingAsVendor
        ? ProposalFieldName.VendorPolicies
        : ProposalFieldName.ClientPolicies,
      documentId,
      commentForums
    );
  }

  async function handleBeginCreateDocumentFromTemplate(templateId: Guid | undefined | null) {
    if (!templateId) return;
    setCreatingDocumentFromTemplate(templateId);
    handleDownloadDocumentById(templateId);
  }

  function renderCommentsButton(document?: Document) {
    return (
      <Tooltip
        title={
          disableCommenting ? "Save proposal to enable commenting" : "Comments"
        }
      >
        <span>
          <IconButton
            size="small"
            disabled={disableCommenting}
            onClick={(event) => {
              event.stopPropagation();
              onCommentsClicked(document?.id, document?.name?.valueOf());
            }}
          >
            <Badge
              variant="dot"
              color="secondary"
              overlap="circular"
              invisible={getShouldShowBadge(document?.id)}
            >
              <CommentIcon fontSize="medium" />
            </Badge>
          </IconButton>
        </span>
      </Tooltip>
    );
  }

  function renderDocumentSelector() {
    let selectedTemplateIds: Array<Guid> = [];
    selectedDocuments.forEach((doc) => {
      if (doc instanceof Document) {
        selectedTemplateIds = [...selectedTemplateIds, ...doc.templateIds];
      }
    });    
    return (
      <DocumentSelector
        documentType='policy'
        selectedDocumentIds={selectedDocuments.map((doc) => doc.id)}
        selectedTemplateIds={selectedTemplateIds}
        onDocumentSelectionChanged={handleDocumentSelectionChanged}
        onCreateDocumentFromTemplate={handleBeginCreateDocumentFromTemplate}
      />
    )
  }

  if (activeTab !== ProposalTab.Policies) return null;
  return (
    <>
      <HeadControls>{renderCommentsButton()}</HeadControls>
      <ListContainer container direction="row">
        <DocumentSelectorContainer item>
          {!disableEditing && renderDocumentSelector()}
        </DocumentSelectorContainer>
        <Grid item xs>
          <SectionDivider orientation="vertical" />
        </Grid>
        <Grid item xs>
          <SelectedDocsList
            documents={selectedDocuments}
            documentType={session.context?.viewingAsVendor ? WorkDocumentType.VendorPolicy : WorkDocumentType.ClientPolicy}
            downloadingFileId={isDownloadingFile}
            disableCommenting={disableCommenting}
            commentForums={commentForums}
            disableEditing={disableEditing}
            onCommentsClicked={onCommentsClicked}
            onDownload={handleDownloadDocumentById}
            onDocumentRemoved={handlePolicyDocumentRemoved}
            onDocumentAdded={handlePolicyDocumentAdded}
          />
        </Grid>
      </ListContainer>
      {creatingDocumentFromTemplate && (
        <Uploader
          session={session}
          documentType={session.context?.viewingAsVendor ? WorkDocumentType.VendorPolicy : WorkDocumentType.ClientPolicy}
          templateId={creatingDocumentFromTemplate}
          onDocumentUploaded={handlePolicyDocumentAdded}
          onClose={() => setCreatingDocumentFromTemplate(null)}
        />
      )}
    </>
  );

}
