import moment, { Moment } from "moment";

export default class Date {
    private _value: Moment;

    constructor(value?: Moment) {
        this._value = value ?? moment();
        if(!this._value?.isValid()){
            throw new Error('Invalid date');
        }
    }

    get value(): Moment {
        return this._value;
    }

    isEqualTo(other: Date | null): boolean {
        return this._value.isSame(other?._value);
    }

    toString(): string {
        return this._value.format('MM/DD/YYYY');
    }

    isSame(other?: Date): boolean {
        if (!other) {
            return false;
        }
        return this._value.isSame(other._value);
    }

    toISOString(): string {
        return this._value.toISOString();
    }

    format(format: string): string {
        return this._value.format(format);
    }

    public clone(): Date {
        return new Date(this._value);
    }

    public fromObject(obj: object): Date | null {
        if (!obj) return null;
        return new Date(moment(obj));
    }
    public toJSON(): string {
        return this._value.toISOString();
    }

    public static get Prototype(): Date {
        return new Date();
    }
}