import { Query, QueryResult } from "@material-table/core";

import AdministratorInvitationAPIService from "legal-entities/entities/administrator-invitation/api/administrator-invitation-api-service";
import EntityMemberAPIService from "legal-entities/entities/entity-member/api/entity-member-api-service";
import LegalEntity from "legal-entities/entities/legal-entity/legal-entity";
import Session from "users/session/session";
import { EntityAdministrationTableData } from "app/routes/entity-administration";
import { PageTab } from "app/routes/entity-administration/tabs";

export default async function loadData(
  query: Query<EntityAdministrationTableData>,
  activeTab: PageTab,
  setLoading: (isLoading: boolean) => void,
  session: Session | undefined
): Promise<QueryResult<EntityAdministrationTableData>> {
  const emptyResponse = {
    data: [],
    page: 0,
    totalCount: 0
  } as QueryResult<EntityAdministrationTableData>;
  try {
    setLoading(true);

    let response: QueryResult<EntityAdministrationTableData> = emptyResponse;
    const abortController = new AbortController();
    switch (activeTab) {
      case PageTab.Users:
        if (!session?.hasEntity) return emptyResponse;
        const firstEntity = new LegalEntity(session.entities[0].entityId, session.entities[0].entityName);
        const entityMemberService = new EntityMemberAPIService(session);  
        const members = await entityMemberService.getLegalEntityMembersUserInfo(firstEntity, abortController);
        response.data = members;
        response.totalCount = members.length;
        break;
      case PageTab.Invitations:
        if (!session?.isLoggedInAsAdmin) return emptyResponse;

        const firstUserEntityInfo = session.entities[0];
        const legalEntity = new LegalEntity(
          firstUserEntityInfo.entityId,
          firstUserEntityInfo.entityName
        );
        const adminService = new AdministratorInvitationAPIService(session);
        const invitations =
          await adminService.getAdminInvitationsForLegalEntity(legalEntity, abortController);
        response.data = invitations;
        response.totalCount = invitations.length;
        break;
      default:
        break;
    }

    return response;
  } catch (error: any) {
    console.error(error);
    return emptyResponse;
  } finally {
    setLoading(false);
  }
}
