import Guid from "common/values/guid/guid";
import FeeRedline, {
  FeeType,
} from "work/entities/proposal/redlining/fee-redline/fee-redline";
import FieldRedline from "work/entities/proposal/redlining/field-redline";
import FeeScheduleCategory from "work/values/fee-schedule-category/fee-schedule-category";
import { ProposalFieldName } from "work/values/constants";
import FeeScheduleBillingCode from "work/values/fee-schedule-billing-code/fee-schedule-billing-code";
import FeeScheduleCategoryDescription from "work/values/fee-schedule-category-description/fee-schedule-category-description";
import FeeScheduleCategoryName from "work/values/fee-schedule-category-name/fee-schedule-category-name";
import {
  RedlineAction,
  RedlineChange,
} from "work/entities/proposal/redlining/redline-change";
import TextChange, {
  TextChangeCollection,
} from "work/entities/proposal/redlining/_diff/text-change";
import { diffLines } from "diff";
import _ from "lodash";
export class FeeScheduleCategoryRedline {
  private _field: ProposalFieldName = ProposalFieldName.FeeSchedule;
  private _fieldId: Guid;
  private _originalEntry: FeeScheduleCategory | null;
  private _revisedEntry: FeeScheduleCategory | null;
  private _currentEntry: FeeScheduleCategory | null | undefined;
  private _sessionHistory: RedlineChange[] = [];

  nameRedline?: FieldRedline<FeeScheduleCategoryName>;
  descriptionRedline?: FieldRedline<FeeScheduleCategoryDescription>;
  billingCodeRedline?: FieldRedline<FeeScheduleBillingCode>;
  feeRedline?: FeeRedline;

  constructor(
    fieldId: Guid,
    originalEntry: FeeScheduleCategory | null,
    revisedEntry: FeeScheduleCategory | null
  ) {
    this._fieldId = fieldId;
    this._originalEntry = originalEntry;
    this._revisedEntry = revisedEntry;

    if (
      (originalEntry === null && revisedEntry === null) ||
      (originalEntry &&
        revisedEntry &&
        originalEntry.isEqualTo(revisedEntry))
    ) {
      this._currentEntry = revisedEntry;
    }

    this.refreshSubRedlineValues();
  }
  public clone(): FeeScheduleCategoryRedline {
    const clone = FeeScheduleCategoryRedline.fromObject(this.toJSON());
    if (!clone) throw new Error("Could not clone redline");
    return clone;
  }

  toJSON(): any {
    return {
      _field: this._field, 
      _fieldId: this._fieldId.valueOf(),
      _originalEntry: this._originalEntry ? this._originalEntry.toJSON() : this._originalEntry,
      _revisedEntry: this._revisedEntry ? this._revisedEntry.toJSON() : this._revisedEntry,
      _currentEntry: this._currentEntry ? this._currentEntry.toJSON() : this._currentEntry
    };
  }

  public static fromObject(object: any): FeeScheduleCategoryRedline {
    const fieldId = Guid.fromObject(object._fieldId?.valueOf());
    if(!fieldId){
      throw new Error("FieldId is required");
    }

    const originalEntry = object._originalEntry
      ? FeeScheduleCategory.Prototype.fromObject(object._originalEntry)
      : null;
    const revisedEntry = object._revisedEntry
      ? FeeScheduleCategory.Prototype.fromObject(object._revisedEntry)
      : null;
    const redlineCategory = new FeeScheduleCategoryRedline(
      fieldId,
      originalEntry,
      revisedEntry
    );
    if (object._currentEntry === null) {
      redlineCategory._currentEntry = null;
    } else if (object._currentEntry) {
      redlineCategory._currentEntry = FeeScheduleCategory.Prototype.fromObject(
        object._currentEntry
      );
    }
    redlineCategory.refreshSubRedlineValues();

    return redlineCategory;
  }

  private refreshSubRedlineValues() {
    this.refreshNameRedline();
    this.refreshDescriptionRedline();
    this.refreshBillingCodeRedline();
    this.refreshFeeRedline();
  }

  private refreshFeeRedline() {
    let originalFee: FeeType = false;
    let revisedFee: FeeType = false;
    let currentFee: FeeType | undefined = undefined;

    if (this._originalEntry?.fee === null) {
      originalFee = null;
    } else if (this._originalEntry?.fee) {
      originalFee = this._originalEntry.fee;
    }

    if (this._revisedEntry?.fee === null) {
      revisedFee = null;
    } else if (this._revisedEntry?.fee) {
      revisedFee = this._revisedEntry.fee;
    }

    if (this._currentEntry === null) {
      currentFee = false;
    } else if (this._currentEntry?.fee === null) {
      currentFee = null;
    } else if (this._currentEntry?.fee) {
      currentFee = this._currentEntry.fee;
    }

    this.feeRedline = new FeeRedline(originalFee, revisedFee);
    this.feeRedline = this.feeRedline.updateFee(currentFee);
  }

  private refreshBillingCodeRedline() {
    this.billingCodeRedline = new FieldRedline<FeeScheduleBillingCode>(
      ProposalFieldName.FeeSchedule,
      null,
      this._originalEntry?.billingCode ?? null,
      this._revisedEntry?.billingCode ?? null,
    );
    if(this._currentEntry === null){
      this.billingCodeRedline = this.billingCodeRedline.remove();
    } else if(this._currentEntry){
      this.billingCodeRedline = this.billingCodeRedline.edit(this._currentEntry.billingCode);
    }
  }

  private refreshDescriptionRedline() {
    this.descriptionRedline = new FieldRedline<FeeScheduleCategoryDescription>(
      ProposalFieldName.FeeSchedule,
      null,
      this._originalEntry?.description ?? null,
      this._revisedEntry?.description ?? null,
      undefined,
      true
    );
    if(this._currentEntry === null){
      this.descriptionRedline = this.descriptionRedline.remove();
    } else if(this._currentEntry){
      this.descriptionRedline = this.descriptionRedline.edit(this._currentEntry.description);
    }
  }

  private refreshNameRedline() {
    this.nameRedline = new FieldRedline<FeeScheduleCategoryName>(
      ProposalFieldName.FeeSchedule,
      null,
      this._originalEntry?.name ?? null,
      this._revisedEntry?.name ?? null
    );
    if(this._currentEntry === null){
      this.nameRedline = this.nameRedline.remove();
    } else if(this._currentEntry){
      this.nameRedline = this.nameRedline.edit(this._currentEntry.name);
    }
  }
  
  public get fieldId(): Guid {
    return this._fieldId;
  }

  public get originalEntry(): FeeScheduleCategory | null {
    return this._originalEntry;
  }

  public get revisedEntry(): FeeScheduleCategory | null {
    return this._revisedEntry;
  }

  public get currentEntry(): FeeScheduleCategory | null | undefined {
    return this._currentEntry;
  }

  public get sessionHistory(): RedlineChange[] {
    return this._sessionHistory;
  }

  public get isNewlyAdded(): boolean {
    if (this._originalEntry) return false;
    return Boolean(this._currentEntry) && Boolean(!this._revisedEntry);
  }
  public get isAdded(): boolean {
    return (
      (!this.isResolved &&
        Boolean(this._revisedEntry && !this._originalEntry)) ||
      (this.isResolved && Boolean(this._currentEntry && !this._revisedEntry))
    );
  }
  public get isRemoved(): boolean {
    return (
      (!this.isResolved &&
        Boolean(!this._revisedEntry && this._originalEntry)) ||
      (this.isResolved && Boolean(!this._currentEntry))
    );
  }
  public get isResolved(): boolean {
    return this._currentEntry !== undefined;
  }

  public get revisionAccepted(): boolean {
    if (this._currentEntry === undefined) return false;
    if (this._currentEntry === null && this._revisedEntry === null) {
      return true;
    } else if (this._revisedEntry === null) {
      return false;
    }
    return Boolean(this._currentEntry?.isEqualTo(this._revisedEntry));
  }

  public get isRejected(): boolean {
    if (
      this._currentEntry === null &&
      this._originalEntry === null &&
      this._revisedEntry !== null
    ) {
      return true;
    }
    if (
      this._originalEntry &&
      !this._revisedEntry?.isEqualTo(this._originalEntry) &&
      this._currentEntry?.isEqualTo(this._originalEntry)
    ) {
      return true;
    }
    return false;
  }

  public get wasRedlined(): boolean {
    if (this._revisedEntry === null && this._originalEntry !== null) {
      return true;
    }
    if (this._originalEntry === null && this._revisedEntry !== null) {
      return true;
    }
    if (
      this._originalEntry &&
      !this._revisedEntry?.isEqualTo(this._originalEntry)
    ) {
      return true;
    }
    return false;
  }

  public get wasModified(): boolean {
    if (this._currentEntry === undefined) return false;
    if (this._currentEntry === this._revisedEntry) return false;
    if (
      this._currentEntry &&
      this._revisedEntry &&
      this._currentEntry.isEqualTo(this._revisedEntry)
    )
      return false;
    return true;
  }

  public get canBeUndone(): boolean {
    return (
      (this.wasRedlined && this.isResolved) ||
      this.wasModified ||
      this.isNewlyAdded
    );
  }

  public accept(): FeeScheduleCategoryRedline {
    const newFeeScheduleCategoryRedline = this.clone();
    newFeeScheduleCategoryRedline._currentEntry = this._revisedEntry;
    newFeeScheduleCategoryRedline.refreshSubRedlineValues();

    const parent = new TextChangeCollection();
    const redlineChange = RedlineChange.fromObject({
      field: ProposalFieldName.FeeSchedule,
      fieldId: this._fieldId,
      action: RedlineAction.Accept,
      isResolved: true,
      textChanges: diffLines(
        this._originalEntry?.toString() ?? "",
        this._revisedEntry?.toString() ?? ""
      ).map((change) => new TextChange(parent, change)),
    });
    newFeeScheduleCategoryRedline._sessionHistory.push(redlineChange);
    return newFeeScheduleCategoryRedline;
  }

  public reject(): FeeScheduleCategoryRedline {
    let newFeeScheduleCategoryRedline = this.clone();
    newFeeScheduleCategoryRedline._currentEntry = this._originalEntry;
    newFeeScheduleCategoryRedline.refreshSubRedlineValues();

    const parent = new TextChangeCollection();
    const redlineChange = RedlineChange.fromObject({
      field: ProposalFieldName.FeeSchedule,
      fieldId: this._fieldId,
      action: RedlineAction.Reject,
      isResolved: true,
      textChanges: diffLines(
        this._originalEntry?.toString() ?? "",
        this._revisedEntry?.toString() ?? ""
      ).map((change) => new TextChange(parent, change)),
    });
    newFeeScheduleCategoryRedline._sessionHistory.push(redlineChange);
    return newFeeScheduleCategoryRedline;
  }

  public removeCategory(): FeeScheduleCategoryRedline {
    const newFeeScheduleCategoryRedline = this.clone();
    newFeeScheduleCategoryRedline._currentEntry = null;
    newFeeScheduleCategoryRedline.refreshSubRedlineValues();

    const parent = new TextChangeCollection();
    const redlineChange = RedlineChange.fromObject({
      field: ProposalFieldName.FeeSchedule,
      fieldId: this._fieldId,
      action: RedlineAction.Remove,
      isResolved: true,
      textChanges: diffLines(
        this._originalEntry?.toString() ?? "",
        this._revisedEntry?.toString() ?? ""
      ).map((change) => new TextChange(parent, change)),
    });
    newFeeScheduleCategoryRedline._sessionHistory.push(redlineChange);
    return newFeeScheduleCategoryRedline;
  }

  public updateCategory(
    newCategory: FeeScheduleCategory | null
  ): FeeScheduleCategoryRedline {
    const newFeeScheduleCategoryRedline = this.clone();
    newFeeScheduleCategoryRedline._currentEntry = newCategory;
    newFeeScheduleCategoryRedline.refreshSubRedlineValues();

    const parent = new TextChangeCollection();
    let previous: string;
    let next: string;
    if (this._currentEntry !== undefined) {
      previous = this._revisedEntry?.toString() ?? "";
      next = this._currentEntry?.toString() ?? "";
    } else {
      previous = this._originalEntry?.toString() ?? "";
      next = this._revisedEntry?.toString() ?? "";
    }

    const diff = diffLines(previous, next);
    const redlineChange = RedlineChange.fromObject({
      field: ProposalFieldName.FeeSchedule,
      fieldId: this._fieldId,
      action: RedlineAction.Edit,
      isResolved: true,
      textChanges: diff.map((change) => new TextChange(parent, change)),
    });
    newFeeScheduleCategoryRedline._sessionHistory.push(redlineChange);
    return newFeeScheduleCategoryRedline;
  }

  public undo(): FeeScheduleCategoryRedline {
    const newFeeScheduleCategoryRedline = this.clone();
    if (
      newFeeScheduleCategoryRedline._revisedEntry === null &&
      newFeeScheduleCategoryRedline._originalEntry === null
    ) {
      newFeeScheduleCategoryRedline._currentEntry = null;
    } else if (
      newFeeScheduleCategoryRedline._revisedEntry &&
      newFeeScheduleCategoryRedline._originalEntry &&
      newFeeScheduleCategoryRedline._revisedEntry.isEqualTo(
        newFeeScheduleCategoryRedline._originalEntry
      )
    ) {
      newFeeScheduleCategoryRedline._currentEntry =
        newFeeScheduleCategoryRedline._revisedEntry;
    } else {
      newFeeScheduleCategoryRedline._currentEntry = undefined;
    }
    newFeeScheduleCategoryRedline.refreshSubRedlineValues();

    const parent = new TextChangeCollection();
    const redlineChange = RedlineChange.fromObject({
      field: ProposalFieldName.FeeSchedule,
      fieldId: this._fieldId,
      action: RedlineAction.Remove,
      isResolved: true,
      textChanges: diffLines(
        this._revisedEntry?.toString() ?? "",
        this._currentEntry?.toString() ?? ""
      ).map((change) => new TextChange(parent, change)),
    });
    newFeeScheduleCategoryRedline._sessionHistory.push(redlineChange);
    return newFeeScheduleCategoryRedline;
  }
}

export class FeeScheduleRedline extends Array<FeeScheduleCategoryRedline> {
  private _sessionHistory: RedlineChange[] = [];
  private _lastEntryUpdated: Guid | undefined;

  public clearSessionHistory() : FeeScheduleRedline {
    const newRedline = this.clone();
    newRedline._sessionHistory = [];
    return newRedline;
  }
  public static fromCategories(
    originalCategories: FeeScheduleCategory[],
    revisedCategories: FeeScheduleCategory[]
  ) {
    const redlineCategories = new FeeScheduleRedline();

    originalCategories.forEach((originalCategory) => {
      let revisedOriginalCategory = revisedCategories.find((revisedCategory) =>
        revisedCategory.id?.isEqualTo(originalCategory.id)
      );
      let redlineCategory: FeeScheduleCategoryRedline;
      if (revisedOriginalCategory !== undefined) {
        redlineCategory = new FeeScheduleCategoryRedline(
          originalCategory.id,
          originalCategory,
          revisedOriginalCategory
        );
      } else {
        redlineCategory = new FeeScheduleCategoryRedline(
          originalCategory.id,
          originalCategory,
          null
        );
      }
      redlineCategories.push(redlineCategory);
    });

    const addedCategories = revisedCategories.filter(
      (revisedCategory) =>
        !originalCategories.find((originalCategory) =>
          originalCategory.id?.isEqualTo(revisedCategory.id)
        )
    );
    addedCategories.forEach((addedCategory) => {
      redlineCategories.push(
        new FeeScheduleCategoryRedline(addedCategory.id, null, addedCategory)
      );
    });

    return redlineCategories;
  }

  public get isResolved(): boolean {
    return this.every((redlineCategory) => redlineCategory.isResolved);
  }
  public get revisionAccepted(): boolean {
    return this.every((redlineCategory) => redlineCategory.revisionAccepted);
  }

  public get wasRedlined(): boolean {
    return this.some((redlineCategory) => redlineCategory.wasRedlined);
  }
  public get wasModified(): boolean {
    return this.some((redlineCategory) => redlineCategory.wasModified);
  }

  public get canBeUndone(): boolean {
    return this.some((redlineCategory) => redlineCategory.canBeUndone);
  }

  public get sessionHistory(): RedlineChange[] {
    return [
      ...this._sessionHistory,
      ...this.flatMap((redline) => redline.sessionHistory),
    ];
  }

  public get lastEntryUpdated(): Guid | undefined {
    return this._lastEntryUpdated;
  }

  public acceptAll(): FeeScheduleRedline {
    let newRedline = this.clone();
    for (const redline of this) {
        newRedline = newRedline.acceptRedlineById(redline.fieldId);
    }
    return newRedline;
  }

  public rejectAll(): FeeScheduleRedline {
    let newRedline = this.clone();
    for (const redline of this) {
        newRedline = newRedline.acceptRedlineById(redline.fieldId);
    }
    return newRedline;
  }

  public undoAll(): FeeScheduleRedline {
    let newRedline = this.clone();
    for (const redline of this) {
      if (redline.canBeUndone) {
        newRedline = newRedline.undoRedlineById(redline.fieldId);
      }
    }
    return newRedline;
  }

  public static fromObjects(objectArray: any[]) {
    const redlineCategories = new FeeScheduleRedline();
    objectArray.forEach((redlineCategory: any) => {
      const categoryInstance =
        FeeScheduleCategoryRedline.fromObject(redlineCategory);
      if (categoryInstance) redlineCategories.push(categoryInstance);
    });
    return redlineCategories;
  }

  public clone() {
    const jsonCategories = this.map((category) => category.toJSON());
    return FeeScheduleRedline.fromObjects(jsonCategories);
  }

  public toJSON(): any {
    const categoriesJson = [];
    for (const category of this) {
      categoriesJson.push(category.toJSON());
    }
    return categoriesJson;
  }

  public updateRedline(
    newFeeScheduleCategoryRedline: FeeScheduleCategoryRedline
  ): FeeScheduleRedline {
    const newFeeScheduleRedline = this.clone();
    const targetCategory = newFeeScheduleRedline.find((existingCategory) =>
      existingCategory.fieldId?.isEqualTo(newFeeScheduleCategoryRedline.fieldId)
    );
    if (!targetCategory) {
      console.warn("Could not find category to update");
      return newFeeScheduleRedline;
    }
    const targetIndex = newFeeScheduleRedline.findIndex((existingCategory) =>
      existingCategory.fieldId?.isEqualTo(newFeeScheduleCategoryRedline.fieldId)
    );
    newFeeScheduleRedline[targetIndex] = newFeeScheduleCategoryRedline;
    newFeeScheduleRedline._lastEntryUpdated = newFeeScheduleCategoryRedline.fieldId;
    return newFeeScheduleRedline;
  }

  public addEntry(category: FeeScheduleCategory): FeeScheduleRedline {
    const newFeeScheduleRedline = this.clone();
    const existingCategoryIndex = newFeeScheduleRedline.findIndex(
      (redlineCategory) => redlineCategory.fieldId.isEqualTo(category.id)
    );
    if (existingCategoryIndex >= 0) {
      newFeeScheduleRedline[existingCategoryIndex] =
        newFeeScheduleRedline[existingCategoryIndex].updateCategory(category);
    } else {
      let newCategoryRedline = new FeeScheduleCategoryRedline(
        category.id,
        null,
        null
      );
      newCategoryRedline = newCategoryRedline.updateCategory(category);
      newFeeScheduleRedline.push(newCategoryRedline);
      newFeeScheduleRedline._lastEntryUpdated = category.id;
    }
    return newFeeScheduleRedline;
  }

  public removeEntryByFieldId(categoryId: Guid): FeeScheduleRedline {
    if (!categoryId) throw new Error("No id provided");
    const newFeeScheduleRedline = this.clone();
    const targetIndex = newFeeScheduleRedline.findIndex((category) =>
      category.fieldId?.isEqualTo(categoryId)
    );
    if (targetIndex === -1) throw new Error("Category not found");
    const targetCategoryRedline = newFeeScheduleRedline[targetIndex];

    if (
      targetCategoryRedline.isNewlyAdded &&
      targetCategoryRedline.isResolved
    ) {
      newFeeScheduleRedline.splice(targetIndex, 1);
    } else {
      newFeeScheduleRedline[targetIndex] =
        targetCategoryRedline.removeCategory();
      newFeeScheduleRedline._lastEntryUpdated = categoryId;
    }

    return newFeeScheduleRedline;
  }

  public removeCategory(category: FeeScheduleCategory): FeeScheduleRedline {
    if (!category) throw new Error("No id provided");
    const newFeeScheduleRedline = this.clone();
    const targetIndex = newFeeScheduleRedline.findIndex((categoryRedline) =>
      categoryRedline.fieldId.isEqualTo(category.id)
    );
    if (targetIndex === -1) throw new Error("Category not found");
    const targetCategoryRedline = newFeeScheduleRedline[targetIndex];

    if (
      targetCategoryRedline.isNewlyAdded &&
      targetCategoryRedline.isResolved
    ) {
      newFeeScheduleRedline.splice(targetIndex, 1);
    } else {
      newFeeScheduleRedline[targetIndex] =
        targetCategoryRedline.removeCategory();
      newFeeScheduleRedline._lastEntryUpdated = category.id;
    }

    return newFeeScheduleRedline;
  }

  public replaceEntryById(fieldId: Guid, category: FeeScheduleCategory): FeeScheduleRedline {
    const newFeeScheduleRedline = this.clone();
    const targetIndex = newFeeScheduleRedline.findIndex((existingCategory) =>
      existingCategory.fieldId?.isEqualTo(fieldId)
    );
    if (targetIndex === -1)
      throw new Error("Could not find category to update");
    const targetCategory = newFeeScheduleRedline[targetIndex];
    newFeeScheduleRedline[targetIndex] =
      targetCategory.updateCategory(category);
    newFeeScheduleRedline._lastEntryUpdated = fieldId;
    return newFeeScheduleRedline;
  }

  public acceptRedlineById(
    feeScheduleCategoryId: Guid
  ): FeeScheduleRedline {
    const newFeeScheduleRedline = this.clone();
    const target = newFeeScheduleRedline.find((redlineFee) =>
      redlineFee.fieldId.isEqualTo(feeScheduleCategoryId)
    );

    if (target) {
      const index = newFeeScheduleRedline.indexOf(target);
      newFeeScheduleRedline[index] = target.accept();
      newFeeScheduleRedline._lastEntryUpdated = feeScheduleCategoryId;
    } 
    return newFeeScheduleRedline;
  }

  public rejectRedlineById(
    feeScheduleCategoryId: Guid
  ): FeeScheduleRedline {
    const newFeeScheduleRedline = this.clone();
    const target = newFeeScheduleRedline.find((redlineFee) =>
      redlineFee.fieldId.isEqualTo(feeScheduleCategoryId)
    );

    if (target) {
      const index = newFeeScheduleRedline.indexOf(target);
      newFeeScheduleRedline[index] = target.reject();
      newFeeScheduleRedline._lastEntryUpdated = feeScheduleCategoryId;
    } 
    return newFeeScheduleRedline;
  }

  public undoRedlineById(
    feeScheduleCategoryId: Guid
  ): FeeScheduleRedline {
    const newFeeScheduleRedline = this.clone();
    const target = newFeeScheduleRedline.find((redlineFee) =>
      redlineFee.fieldId.isEqualTo(feeScheduleCategoryId)
    );

    const parent = new TextChangeCollection();
    if (target && !target.isNewlyAdded) {
      const index = newFeeScheduleRedline.indexOf(target);
      newFeeScheduleRedline[index] = target.undo();
      newFeeScheduleRedline._lastEntryUpdated = feeScheduleCategoryId;
    } else if (target?.isNewlyAdded) {
      newFeeScheduleRedline.splice(newFeeScheduleRedline.indexOf(target), 1);
      const redlineChange = RedlineChange.fromObject({
        field: ProposalFieldName.FeeSchedule,
        fieldId: feeScheduleCategoryId,
        action: RedlineAction.Remove,
        isResolved: false,
        textChanges: diffLines(
          target.revisedEntry?.toString() ?? "",
          target.currentEntry?.toString() ?? ""
        ).map((change) => new TextChange(parent, change)),
      });
      this._sessionHistory.push(redlineChange);
    }
    return newFeeScheduleRedline;
  }

  public getMatchingCategoryRedline(
    category: FeeScheduleCategory
  ): FeeScheduleCategoryRedline | null {
    if (!category.name) return null;
    return (
      this.find(
        (redlineCategory) =>
          redlineCategory.fieldId.isEqualTo(category.id) ||
          [
            redlineCategory.originalEntry?.name.toString(),
            redlineCategory.revisedEntry?.name.toString(),
            redlineCategory.currentEntry?.name.toString(),
          ]
            .filter((name) => name)
            .includes(category.name?.toString())
      ) ?? null
    );
  }
}
