import { useState } from 'react';

import SendIcon from '@mui/icons-material/Send';
import {
  Box, Paper,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { enqueueSnackbar } from 'notistack';

import LoadingButton from 'common/components/loading-button';
import EmailAddress from 'common/values/email-address/email-address';
import EmailAddressInput, { EmailAddressInputError } from 'common/values/email-address/view/email-address-input';
import Name from 'common/values/name/name';
import NameInput, { NameInputError } from 'common/values/name/view/name-input';
import EntityOfficerInvitation, { InvalidEntityOfficerInvitationError } from 'legal-entities/entities/entity-officer-invitation/entity-officer-invitation';
import EntityName from 'legal-entities/values/entity-name/entity-name';
import EntityNameInput, { EntityNameInputError } from 'legal-entities/values/entity-name/view/entity-name-input';
import InvitationMessage from 'legal-entities/values/invitation-message/invitation-message';
import InvitationMessageInput, { MessageInputError } from 'legal-entities/values/invitation-message/view/invitation-message-input';
import RegistrationInfo, { InvalidRegistrationInfoError } from 'legal-entities/values/registration-info';
import EntityOfficerInvitationAPIService from 'legal-entities/entities/entity-officer-invitation/api/entity-officer-invitation-api-service';
import Session from 'users/session/session';


const InvitationForm = styled(Paper)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2)
  },
  padding: theme.spacing(6),
  position: 'relative',
  width: '100%'
}));
const InvitationInputContainer = styled(Box)(({ theme }) => ({
  maxWidth: theme.spacing(30)
}));
const InvitationFormTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1.5)
}));

interface GeneralFormError extends String { };
type InvitationFormError = GeneralFormError | EmailAddressInputError | NameInputError | EntityNameInputError | MessageInputError;

type InvitationFormErrors = {
  general?: GeneralFormError;
  email?: EmailAddressInputError;
  name?: NameInputError;
  entityName?: EntityNameInputError;
  message?: MessageInputError;
}

type EntityOfficerInvitationFormProps = {
  className?: string;
  onInvitationCreated: (invitation: EntityOfficerInvitation) => void,
  session: Readonly<Session>
}

export default function EntityOfficerInvitationForm(props: EntityOfficerInvitationFormProps) {
  const { className, session } = props;

  const [newInvitation, setNewInvitation] = useState<EntityOfficerInvitation>();
  const [invitationEmail, setInvitationEmail] = useState<EmailAddress>();
  const [invitationEntityName, setInvitationEntityName] = useState<EntityName>();
  const [invitationName, setInvitationName] = useState<Name>();
  const [invitationMessage, setInvitationMessage] = useState<InvitationMessage>();
  const [formErrors, setFormErrors] = useState<InvitationFormErrors>();
  const [sending, setSending] = useState<boolean>(false);

  function buildNewInvitation(): EntityOfficerInvitation | undefined {
    if (!formValid()) return;

    try {
      const invitationRegistrationInfo = new RegistrationInfo(invitationName!, invitationEmail!);
      const newInvitation = new EntityOfficerInvitation(
        invitationEntityName!,
        invitationRegistrationInfo,
        invitationMessage
      );
      setNewInvitation(newInvitation);
      return newInvitation;
    } catch (error: any) {
      if (error instanceof InvalidEntityOfficerInvitationError ||
        error instanceof InvalidRegistrationInfoError) {
        let errors: InvitationFormErrors = {};
        errors.general = error.message;
        setFormErrors(errors);
        return;
      }
      throw error;
    }
  }

  async function handleSendInvite() {
    setSending(true);

    try {
      const builtInvitation = buildNewInvitation();
      if (!builtInvitation) 
        throw new InvalidEntityOfficerInvitationError("Invitation is invalid");
      const service = new EntityOfficerInvitationAPIService(session);
      const updatedInvitation = await service.inviteEntityOfficer(builtInvitation);
      enqueueSnackbar("Sent entity officer invitation", { variant: 'success' });
      setNewInvitation(undefined);
      props.onInvitationCreated(updatedInvitation);
    } catch (ex) {
      console.error(ex);
      enqueueSnackbar("Failed to send entity officer invitation", { variant: 'error' });
    } finally {
      clearForm();
      setSending(false);
    }
  }

  function formValid(): boolean {
    return (
      invitationEmail !== undefined &&
      invitationEntityName !== undefined &&
      invitationName !== undefined
    );
  }

  function updateFormErrors(type: keyof InvitationFormErrors, error?: InvitationFormError) {
    if (!error) return;

    let errors: InvitationFormErrors = { ...formErrors };
    errors[type] = error;
    setFormErrors(errors);
  }

  function clearForm() {
    setInvitationEmail(undefined);
    setInvitationEntityName(undefined);
    setInvitationName(undefined);
    setInvitationMessage(undefined);
  }

  return (
    <InvitationForm className={className}>
      <InvitationFormTitle variant='h6'>
        Invite Entity Officer
      </InvitationFormTitle>
      <InvitationInputContainer>
        <EmailAddressInput
          idPrefix="officer-invitation"
          emailAddress={invitationEmail}
          onChange={(updatedEmail?: EmailAddress, error?: EmailAddressInputError) => {
            if (updatedEmail) setInvitationEmail(updatedEmail);
            updateFormErrors('email', error);
            buildNewInvitation();
          }}
        />
        <NameInput
          idPrefix="officer-invitation"
          name={invitationName}
          onChange={(updatedName?: Name | undefined, error?: NameInputError) => {
            if (updatedName) setInvitationName(updatedName);
            updateFormErrors('name', error);
            buildNewInvitation();
          }}
        />
        <EntityNameInput
          idPrefix="officer-invitation"
          entityName={invitationEntityName}
          onChange={(updatedEntityName?: EntityName | undefined, error?: EntityNameInputError) => {
            if (updatedEntityName) setInvitationEntityName(updatedEntityName);
            updateFormErrors('entityName', error);
            buildNewInvitation();
          }}
        />
        <InvitationMessageInput
          idPrefix="officer-invitation"
          message={invitationMessage}
          onChange={(updatedMessage?: InvitationMessage, error?: MessageInputError) => {
            if (updatedMessage) setInvitationMessage(updatedMessage);
            updateFormErrors('message', error);
            buildNewInvitation();
          }}
        />
        <LoadingButton
          id="officer-invitation-submit-button"
          color="primary"
          disabled={sending || !formValid()}
          loading={sending}
          onClick={handleSendInvite}
          startIcon={<SendIcon />}
          variant='contained'>
          Send Invite
        </LoadingButton>
      </InvitationInputContainer>
    </InvitationForm>
  )
}
