import MaterialTable, { MaterialTableProps } from '@material-table/core';
import { Tab, Tabs, Theme, useMediaQuery } from '@mui/material';
import { ConfirmResponse, useConfirmDialog } from 'app/providers/confirm-dialog';
import { useDialog } from 'app/providers/dialog';
import { getDetailPanels } from 'app/routes/marketplace/profiles/table/details';
import TableToolbarComponent from 'app/routes/marketplace/profiles/table/toolbar';
import TableContainerComponent from 'common/components/table/container';
import { PageContainer, TableTheme } from 'common/styles/table';
import { AccountType } from 'common/values/account-type/account-type';
import Guid from 'common/values/guid/guid';
import _ from 'lodash';
import CompanyAPIService from 'marketplace/entities/company/api/company-api-service';
import Company from 'marketplace/entities/company/company';
import IndividualAPIService from 'marketplace/entities/individual/api/individual-api-service';
import Individual from 'marketplace/entities/individual/individual';
import MarketplaceTeamAPIService from 'marketplace/entities/marketplace-team/api/marketplace-team-api-service';
import CompanyProfile from 'marketplace/values/company-profile/company-profile';
import IndividualProfile from 'marketplace/values/individual-profile/individual-profile';
import TeamProfile from 'marketplace/values/team-profile/team-profile';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { Params, useLoaderData, useLocation, useNavigate } from 'react-router';
import { useSession } from 'users/session/session-context';
import { getTableActions, TableActionsComponent } from './table/actions';
import { getTableColumns, ProfileTableData } from './table/columns';
import loadData from './table/data-loader';
import { NoRowsPlaceholder } from './table/no-rows-placeholder';
import { PageTab } from './tabs';
import { AvatarMap, editComponentMap, viewComponentMap } from './types';
import MarketplaceTeam from 'marketplace/entities/marketplace-team/marketplace-team';

type ManageProfilesProps = {};

export default function ManageProfiles(props: Readonly<ManageProfilesProps>) {
  const tableRef = React.useRef<MaterialTable<ProfileTableData> & MaterialTableProps<ProfileTableData>>();
  const isDirtyRef = React.useRef<boolean>(false);

  const [individualProfiles, setIndividualProfiles] = React.useState<Individual[]>([]);
  const [companyProfiles, setCompanyProfiles] = React.useState<Company[]>([]);
  const [activeTab, setActiveTab] = React.useState(PageTab.Individual);
  const [loading, setLoading] = React.useState(false);
  const [avatars, setAvatars] = React.useState<AvatarMap>({});
  const [tableBodyHeight, setTableBodyHeight] = React.useState(0);

  const routeParams = useLoaderData() as Params<string>;
  const location = useLocation();
  const navigate = useNavigate();
  const confirm = useConfirmDialog();
  const { openDialog, closeDialog, closeAllDialogs } = useDialog();
  const isMediumDisplaySize = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const session = useSession();

  const tabs = (
    <Tabs
      value={activeTab}
      indicatorColor="primary"
      textColor="primary"
      onChange={(_event, newValue) => navigate(`/marketplace/profiles/${newValue}`)}>
      <Tab value={PageTab.Individual} label="Individual Profile" />
      <Tab value={PageTab.Company} label="Company Profile" />
      <Tab value={PageTab.Teams} label="Team Profiles" />
    </Tabs>
  );

  const activeTabSubTitle = () => readonlyTab() ? "View Profiles" : "Manage Your Profiles";
  const readonlyTab = () => activeTab === PageTab.Company && !session.isLoggedInAsAdmin;
  const rowProfileEditingDisabled = (rowData: ProfileTableData) => readonlyTab() ||
    (activeTab === PageTab.Teams && !rowData?.leader?.userId?.isEqualTo(session.user?.id));
  const disableAddProfileButton = () => {
    if (readonlyTab()) return true;
    if (!session.isLoggedInAsAdmin) return true;
    if (activeTab === PageTab.Teams && individualProfiles?.length < 1) return true;
    if (activeTab === PageTab.Individual && individualProfiles?.length > 0) return true;
    if (activeTab === PageTab.Company && companyProfiles?.length > 0) return true;
    if (activeTab === PageTab.Teams && session.accountType === AccountType.Client) return true;
    return false;
  }
  const addProfileDisabledMessage = () => {
    if (readonlyTab()) return "You don't have permission";
    if (activeTab === PageTab.Teams && individualProfiles?.length < 1)
      return 'You must create an individual profile before creating a team';
    if (activeTab === PageTab.Individual && individualProfiles?.length > 0)
      return 'You can only have one individual profile';
    if (activeTab === PageTab.Company && companyProfiles?.length > 0)
      return 'You can only have one company profile';
    return '';
  }

  // Close any open dialogs when the URL changes
  useEffect(() => {
    closeAllDialogs();
  }, [location]);

  // Handle account type change
  useEffect(() => {
    tableRef?.current?.onQueryChange({});
  }, [session.accountType]);

  // Handle URL changes
  useEffect(() => {
    if (routeParams.tab) {
      setActiveTab(routeParams.tab as PageTab);
    } else {
      navigate(`/marketplace/profiles/${PageTab.Individual}`);
    }

    if (routeParams.action === 'create') {
      createProfile();
    }

    if (routeParams.id) {
      if (routeParams.action === 'edit') {
        openEditProfileDialog(routeParams.tab as PageTab, new Guid(routeParams.id));
      }
      if (routeParams.action === 'view') {
        openViewProfileDialog(routeParams.tab as PageTab, new Guid(routeParams.id));
      }
    }
  }, [routeParams]);

  // Handle component mount
  useEffect(() => {
    function computeTableHeight() {
      const tableHead = document.querySelector('thead.MuiTableHead-root');
      const tableFoot = document.querySelector('tfoot.MuiTableFooter-root');
      const siteFooter = document.querySelector('footer#siteFooter');

      const tableOffsetTop = tableHead?.getBoundingClientRect().top;
      const tableFootHeight = tableFoot?.getBoundingClientRect().height;
      const siteFooterHeight = siteFooter?.getBoundingClientRect().height;

      if (!tableOffsetTop || !tableFootHeight || !siteFooterHeight) return;

      setTableBodyHeight(Math.floor(window.innerHeight - tableOffsetTop - tableFootHeight - siteFooterHeight));
    }

    const debouncedResize = _.debounce(() => computeTableHeight(), 250);
    debouncedResize();
    window.addEventListener('resize', debouncedResize);

    return () => {
      window.removeEventListener('resize', debouncedResize);
    }
  }, []);

  const marketplaceTeamService = new MarketplaceTeamAPIService(session);

  const deleteMethodMap = {
    [PageTab.Company]: null,
    [PageTab.Teams]: marketplaceTeamService.deleteTeam,
    [PageTab.Individual]: null
  };

  async function fetchAvatars(rows: ProfileTableData[]) {
    const abortController = new AbortController();
    try {
      for (const row of rows) {
        const avatarId = row.profile?.avatarId?.toString();

        if (!avatarId) continue;

        const cachedAvatar = avatars[avatarId];
        if (cachedAvatar || avatarId === null) return;
        let avatarImageData: string;
        if (activeTab === PageTab.Individual) {
          const service = new IndividualAPIService(session);
          avatarImageData = await service.getIndividualAvatar(row.id, abortController);
        }
        else if (activeTab === PageTab.Company) {
          const service = new CompanyAPIService(session);
          avatarImageData = await service.getCompanyAvatar(row.id, abortController);
        }
        else if (activeTab === PageTab.Teams) {
          avatarImageData = await marketplaceTeamService.getTeamAvatar(row.id, abortController);
        }
        setAvatars(prevAvatars => ({ ...prevAvatars, [avatarId]: avatarImageData }));
      }
    }
    catch (error) {
      console.error(error);
      enqueueSnackbar("Couldn't get avatars for profiles. Please try again", { variant: 'error' });
    }
  }

  function createProfile() {
    const ProfileComponent = editComponentMap[activeTab];
    const parentPath = new URL(".", window.location.origin + window.location.pathname);

    openDialog(
      {
        titleStyle: {
          position: 'absolute',
          right: 0,
          top: 0,
        },
        MuiProps: {
          maxWidth: activeTab === PageTab.Individual ? 'lg' : 'md',
          fullWidth: true,
          style: {
            overflowX: 'hidden',
            paddingBottom: 0
          }
        },
        component: (
          <ProfileComponent
            isVisible={false}
            onFormDirty={() => { isDirtyRef.current = true }}
            onSave={() => {
              closeDialog();
              navigate(parentPath.pathname);
              tableRef?.current?.onQueryChange({});
            }}
          />
        ),
      },
      () => navigate(parentPath.pathname),
      () => isDirtyRef.current
    );
  }

  function editProfile(rowData: ProfileTableData) {
    const editingDisabled = rowProfileEditingDisabled(rowData);

    if (editingDisabled) {
      enqueueSnackbar("You don't have permission to edit this profile. Opening as read-only.", { variant: 'warning' });
    }

    navigate(`/marketplace/profiles/${activeTab}/${!editingDisabled ? 'edit' : 'view'}/${rowData.id}`);
  }

  function openEditProfileDialog(profileType: PageTab, profileId: Guid, profileData?: IndividualProfile & CompanyProfile & TeamProfile) {
    const ProfileComponent = editComponentMap[profileType];
    const parentPath = new URL(".", window.location.origin + window.location.pathname);

    openDialog(
      {
        titleStyle: {
          position: 'absolute',
          right: 0,
          top: 0,
        },
        MuiProps: {
          maxWidth: profileType === PageTab.Individual ? 'lg' : 'md',
          fullWidth: true,
          style: {
            overflowX: 'hidden',
            paddingBottom: 0
          }
        },
        component: (
          <ProfileComponent
            individualId={profileType === PageTab.Individual ? profileId : undefined}
            companyId={profileType === PageTab.Company ? profileId : undefined}
            teamId={profileType === PageTab.Teams ? profileId : undefined}
            profile={profileData}
            onFormDirty={() => { isDirtyRef.current = true }}
            onSave={() => {
              closeDialog();
              navigate(parentPath.pathname);
              tableRef?.current?.onQueryChange({});
            }}
          />
        ),
      },
      () => navigate(parentPath.pathname),
      () => isDirtyRef.current
    );
  }

  function openViewProfileDialog(profileType: PageTab, profileId: Guid, profileData?: IndividualProfile & CompanyProfile & TeamProfile) {
    const ProfileComponent = viewComponentMap[profileType];
    const parentPath = new URL(".", window.location.origin + window.location.pathname);

    openDialog(
      {
        titleStyle: {
          position: 'absolute',
          right: 0,
          top: 0,
        },
        MuiProps: {
          maxWidth: profileType === PageTab.Individual ? 'lg' : 'md',
          fullWidth: true,
          style: {
            overflowX: 'hidden',
            paddingBottom: 0
          }
        },
        component: (
          <ProfileComponent
            individualId={profileType === PageTab.Individual ? profileId : undefined}
            companyId={profileType === PageTab.Company ? profileId : undefined}
            teamId={profileType === PageTab.Teams ? profileId : undefined}
            profile={profileData}
          />
        ),
      },
      () => navigate(parentPath.pathname)
    );
  }

  async function publishProfile(rowData: ProfileTableData) {
    const name = rowData.profile.name ?? `${rowData.profile.firstName} ${rowData.profile.lastName}`;

    const response = await confirm({
      title: `${rowData.isVisible ? 'Hide' : 'Show'} profile ${rowData.isVisible ? 'from' : 'on'} the Marketplace?`,
      message: rowData.isVisible ?
        `Hide ${name}'s profile? This profile will no longer be searchable on the Marketplace.` :
        `Show ${name}'s profile? This profile will appear in searches on the Marketplace.`
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      if (rowData.profile instanceof IndividualProfile) {
        const updatedIndividual = structuredClone(rowData);
        updatedIndividual.isVisible = !rowData.isVisible;
        const service = new IndividualAPIService(session);
        await service.updateIndividual(
          rowData,
          updatedIndividual
        );
      }
      if (rowData.profile instanceof CompanyProfile) {
        const service = new CompanyAPIService(session);
        await service.updateCompany(rowData.id, !rowData.isVisible)
      }
      if (rowData.profile instanceof TeamProfile) {
        const updatedTeam = structuredClone(rowData);
        updatedTeam.isVisible = !rowData.isVisible;
        const service = new MarketplaceTeamAPIService(session);
        await service.updateTeam(rowData, updatedTeam);
      }

      tableRef?.current?.onQueryChange();  // Force table data refresh
      enqueueSnackbar(rowData.isVisible ? `${name}'s Profile now hidden from the Marketplace` :
        `${name}'s Profile now shown on the Marketplace`, { variant: 'info' });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        rowData.isVisible ?
          "Couldn't hide profile from marketplace. Please try again" :
          "Couldn't show profile on marketplace. Please try again",
        { variant: 'error' }
      );
    }
  }

  async function deleteProfile(rowData: ProfileTableData) {
    const name = rowData.profile.name ?? `${rowData.profile.firstName} ${rowData.profile.lastName}`;

    const response = await confirm({
      title: 'Delete profile?',
      message: `Delete ${name} profile?`,
      okButtonText: 'Delete'
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      await deleteMethodMap[activeTab]?.(rowData.id);
      tableRef?.current?.onQueryChange();  // Force table data refresh
      enqueueSnackbar("Deleted profile", { variant: 'success' });

    } catch (error) {
      console.error(error);
      enqueueSnackbar("Couldn't delete profile. Please try again", { variant: 'error' });
    }
  }

  async function leaveTeam(rowData: ProfileTableData) {
    if (!rowData.profile) return;

    const team = rowData as MarketplaceTeam;
    if (!team.id || !team?.memberships) return;

    //search team members for current user
    const membership = team.memberships.find(m => m.userId?.isEqualTo(session.user?.id));
    if (!membership?.userId) return;

    const response = await confirm({
      title: 'Leave team?',
      message: `Leave ${team.profile.name} team?`,
      okButtonText: 'Leave'
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      const marketplaceTeamService = new MarketplaceTeamAPIService(session);
      await marketplaceTeamService.removeTeamMember(team.id, membership.userId);

      tableRef?.current?.onQueryChange();  // Force table data refresh
      enqueueSnackbar("Successfully left marketplace team", { variant: 'success' });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Couldn't leave marketplace team. Please try again", { variant: 'error' });
    }
  }

  return (
    <PageContainer>
      <MaterialTable
        key={activeTab}
        tableRef={tableRef}
        title={activeTabSubTitle()}
        columns={getTableColumns(
          activeTab,
          avatars,
          rowProfileEditingDisabled,
          publishProfile,
          editProfile,
          deleteProfile,
          leaveTeam,
          session
        )}
        data={(query) =>
          loadData(
            query,
            activeTab,
            setLoading,
            setCompanyProfiles,
            setIndividualProfiles,
            fetchAvatars,
            session
          )
        }
        actions={getTableActions({
          activeTab,
          isMediumDisplaySize,
          navigate,
          disableAddProfileButton,
          addProfileDisabledMessage
        })}
        isLoading={loading}
        options={{
          debounceInterval: 250,
          showDetailPanelIcon: activeTab === PageTab.Teams,
          emptyRowsWhenPaging: false,
          filtering: true,
          filterRowStyle: TableTheme.filterRow,
          grouping: true,
          minBodyHeight: tableBodyHeight,
          maxBodyHeight: tableBodyHeight,
          pageSize: 10,
          pageSizeOptions: [10, 25, 50],
          paginationType: isMediumDisplaySize ? 'normal' : 'stepped',
          showFirstLastPageButtons: !isMediumDisplaySize
        }}
        components={{
          Container: props => TableContainerComponent({ ...props }),
          Actions: props => TableActionsComponent({
            tableRef: tableRef,
            isMediumDisplaySize: isMediumDisplaySize,
            activeTab: activeTab,
            disableAddProfileButton: disableAddProfileButton,
            addProfileDisabledMessage: addProfileDisabledMessage,
            onCreateProfile: createProfile,
            ...props
          }),
          Toolbar: props => TableToolbarComponent({
            tableRef,
            activeTab,
            tabs,
            isMediumDisplaySize,
            ...props
          })
        }}
        detailPanel={getDetailPanels(tableRef)}
        localization={{
          body: {
            emptyDataSourceMessage: <NoRowsPlaceholder tableBodyHeight={tableBodyHeight} />
          }
        }}
      />
    </PageContainer>
  );
}
