import { Avatar } from "@mui/material";
import Guid from "common/values/guid/guid";
import IndividualAPIService, { IndividualAvatarNotFoundError } from "marketplace/entities/individual/api/individual-api-service";
import { useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Session from "users/session/session";

const StyledAvatar = styled(Avatar)<{ disabled?: boolean }>(({ disabled, theme }) => ({
  opacity: disabled ? 0.5 : 1
}));

type IndividualAvatarProps = {
  className?: string;
  avatarId?: Guid;
  individualId?: Guid;
  disabled?: boolean;
  session: Session;
}

export default function IndividualAvatar(props: Readonly<IndividualAvatarProps>) {
  const { 
    className, 
    avatarId, 
    individualId,
    disabled,
    session
  } = props;

  const [avatar, setAvatar] = useState<string>();

  const getAvatar = useCallback(async () => {
    if (!avatarId || !individualId) return;

    try {
      const service = new IndividualAPIService(session);
      const abortController = new AbortController();
      const base64Avatar = await service.getIndividualAvatar(individualId, abortController);
      setAvatar(base64Avatar);
    } catch (error: any) {
      if (error instanceof IndividualAvatarNotFoundError) {
        console.debug(`No avatar found for individual ${individualId}`);
        return;
      }
      throw error;
    }
  }, [avatarId, individualId, session]);

  useEffect(() => {
    getAvatar();
  }, [getAvatar]);

  return (
    <StyledAvatar 
      className={className} 
      src={avatar} 
      disabled={disabled} 
    />
  );
}
