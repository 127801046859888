import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import ErrorList from 'common/components/error-list';
import LoadingButton from 'common/components/loading-button';
import Guid from 'common/values/guid/guid';
import Password from 'common/values/password/password';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import AuthEmailAPIService from "users/entities/user/api/auth-email-api-service";
import { useSession } from 'users/session/session-context';

const PasswordInput = styled(TextField)(({ theme }) => ({
  "& .MuiFilledInput-root": {
    backgroundColor: "rgba(255, 255, 255, 0.09)",
    color: 'white'
  },
  "& .MuiFilledInput-root:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.13)",
    // Reset on touch devices, it doesn't add specificity
    "@media (hover: none)": {
      backgroundColor: "rgba(255, 255, 255, 0.09)"
    }
  },
  "& .MuiFilledInput-root.Mui-focused": {
    backgroundColor: "rgba(255, 255, 255, 0.09)"
  },
  "& .MuiFilledInput-input:-webkit-autofill": {
    caretColor: "#fff",
    "-webkitBoxShadow": "0 0 0 100px #266798 inset",
    "-webkitTextFillColor": "#fff",
  },
  "& .MuiFilledInput-underline:before": {
    borderColor: "white"
  },
  "& .MuiFilledInput-underline:after": {
    borderColor: "white"
  },
  "& .MuiInputLabel-root": {
    color: "rgba(255, 255, 255, 0.7)"
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "white"
  },
  "& .MuiFormHelperText-root": {
    color: "rgba(255, 255, 255, 0.7)"
  }
}));

const SubmitButton = styled(LoadingButton)(({ theme }) => ({
  backgroundColor: '#E19941',
  margin: '1.5em 0',
  '&:hover': {
    backgroundColor: '#FFB949',
  },
  "&.MuiButton-root": {
    color: 'black'
  },
  "&.Mui-disabled": {
    color: "rgba(255, 255, 255, 0.3)",
    boxShadow: "none",
    backgroundColor: "rgba(255, 255, 255, 0.12)"
  }
}));

interface Props {
  userId: string | null;
  token: string | null;
  onPasswordReset?: (password: string) => Promise<void>;
};

export default function PasswordResetForm(props: Readonly<Props>) {
  const [password, setPassword] = React.useState('');
  const [passwordConfirm, setPasswordConfirm] = React.useState('');
  const [resetting, setResetting] = React.useState(false);
  const [resetFailed, setResetFailed] = React.useState(false);
  const [errors, setErrors] = React.useState<string[]>([]);
  const [formValid, setFormValid] = React.useState(false);

  const session = useSession();

  async function handleResetClicked() {
    setResetFailed(false);
    setResetting(true);
    setErrors([]);

    try {
      const { userId, token } = props;
      if (!userId || !token) throw new Error('Invalid user ID and/or token');
      const authToken = await AuthEmailAPIService.comfirmPasswordReset(password, new Guid(userId), token);
      session.updateAuthToken(authToken);
      if (props.onPasswordReset) 
        props.onPasswordReset(password);
      enqueueSnackbar("Password update confirmed", { variant: 'success' });
    }
    catch (error: any) {
      enqueueSnackbar("Password update failed", { variant: 'error' });
    } finally {
      setResetting(false);
    }
  }

  function handlePasswordChanged(event: React.ChangeEvent<HTMLInputElement>) {
    setPassword(event.target.value);
    validateForm(event.target.value, passwordConfirm);
  }

  function handlePasswordConfirmChanged(event: React.ChangeEvent<HTMLInputElement>) {
    setPasswordConfirm(event.target.value);
    validateForm(password, event.target.value);
  }

  async function validateForm(password: string, passwordConfirm: string) {
    setErrors([]);
    try {
      Password.validate(password, passwordConfirm);
      setFormValid(true);
    }
    catch (error: any) {
      setErrors(prevErrors => ([...prevErrors, error?.message || "Unknown error"]));
      setFormValid(false);
    }
  }

  return (
    <Box>
      <PasswordInput
        variant="filled"
        size="small"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        error={!formValid || resetFailed}
        onChange={handlePasswordChanged}
        value={password}
        autoComplete="new-password"
        helperText="Min 8 characters. Requires at least (1) uppercase, lowercase, number &amp; symbol"
      />
      <PasswordInput
        variant="filled"
        size="small"
        margin="normal"
        required
        fullWidth
        name="passwordConfirm"
        label="Confirm Password"
        type="password"
        id="passwordConfirm"
        error={!formValid || resetFailed}
        onChange={handlePasswordConfirmChanged}
        onKeyDown={(event) => {
          if (event.key === 'Enter' && formValid) handleResetClicked();
        }}
        value={passwordConfirm}
        autoComplete="new-password"
      />
      <ErrorList errors={errors} />
      <SubmitButton
        fullWidth
        variant="contained"
        color="primary"
        disabled={!formValid || resetting}
        loading={resetting}
        onClick={handleResetClicked}>
        {resetting ? 'Creating Password...' : 'Set Password'}
      </SubmitButton>
    </Box>
  )
}
