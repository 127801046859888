import MaterialTable, {
  Action,
  MaterialTableProps,
  MTableActions,
} from "@material-table/core";
import {
  AddComment as AddCommentIcon,
  Archive as ArchiveIcon,
  Forum as ForumIcon,
  MarkEmailRead as MarkEmailReadIcon,
  MarkEmailUnread as MarkEmailUnreadIcon,
  Refresh as RefreshIcon,
} from "@mui/icons-material";
import { Button } from "@mui/material";
import { useDialog } from "app/providers/dialog";
import { DialogProps } from "common/components/dialog";
import { ActionsContainer } from "common/styles";
import { ChatDialog } from "messaging/components";
import { CommunicationTableData } from "./columns";
import Notification from "notifications/entities/notification/notification";
import CreateForumDialog from "messaging/entities/forum/view/components/create-forum-dialog";
import Forum from "messaging/entities/forum/forum";
import { useNavigate } from "react-router";

export type TableActionsComponentProps = {
  tableRef: React.MutableRefObject<
    | (MaterialTable<CommunicationTableData> &
      MaterialTableProps<CommunicationTableData>)
    | undefined
  >;
  isMediumDisplaySize: boolean;
  selectedRows: CommunicationTableData[];
}

export function TableActionsComponent(
  props: Readonly<TableActionsComponentProps>
): React.ReactElement {
  const {
    tableRef,
    isMediumDisplaySize,
    selectedRows
  } = props;

  const { openDialog } = useDialog();
  const navigate = useNavigate();

  const forumDialogProps: DialogProps = {
    component: <CreateForumDialog 
    onForumCreated={(forum: Forum) => tableRef?.current?.onQueryChange({})}
    />,
    title: "Create Forum",
    contentSxProps: {
      display: 'flex',
      overflowX: 'hidden',
      paddingBottom: 0
    },
    MuiProps: {
      fullWidth: true,
      maxWidth: 'md'
    }
  };

  return (
    <ActionsContainer>
      {selectedRows.length < 1 && !isMediumDisplaySize && (
        <>
          <Button
            color="primary"
            startIcon={<AddCommentIcon />}
            onClick={() => navigate("/communications/all/compose")}
          >
            Compose Message
          </Button>
          <Button
            color="primary"
            startIcon={<ForumIcon />}
            onClick={() => openDialog(forumDialogProps)}
          >
            New Forum
          </Button>
          <Button
            color="primary"
            startIcon={<RefreshIcon />}
            onClick={() => tableRef?.current?.onQueryChange({})}
          >
            Refresh
          </Button>
        </>
      )}
      <MTableActions {...props} />
    </ActionsContainer>
  );
}

export function getTableActions(
  tableRef: React.MutableRefObject<
    | (MaterialTable<CommunicationTableData> &
      MaterialTableProps<CommunicationTableData>)
    | undefined
  >,
  isMediumDisplaySize: boolean,
  onMarkSeen: (notifications: Notification[]) => void,
  onMarkUnseen: (notifications: Notification[]) => void,
  onArchive: (notifications: Notification[]) => void
): Action<object>[] {
  return [
    NewMessage(isMediumDisplaySize),
    Refresh(tableRef, isMediumDisplaySize),
    MarkUnseen(onMarkUnseen),
    MarkSeen(onMarkSeen),
    Archive(onArchive)
  ];
}

function NewMessage(isMediumDisplaySize: boolean): Action<object> {
  const { openDialog, closeDialog } = useDialog();

  const dialogProps: DialogProps = {
    component: <ChatDialog />,
    title: "Compose Message",
    contentSxProps: {
      display: 'flex',
      overflowX: 'hidden',
      paddingBottom: 0
    },
    MuiProps: {
      fullWidth: true,
      maxWidth: 'md'
    },
    onClose: closeDialog,
  };

  return {
    icon: AddCommentIcon,
    iconProps: { color: "primary", fontSize: "large" },
    tooltip: "New Message",
    position: "toolbar",
    hidden: !isMediumDisplaySize,
    isFreeAction: true,
    onClick: () => openDialog(dialogProps),
  };
}

function Refresh(
  tableRef: React.MutableRefObject<
    | (MaterialTable<CommunicationTableData> &
      MaterialTableProps<CommunicationTableData>)
    | undefined
  >,
  isMediumDisplaySize: boolean
): Action<object> {
  return {
    icon: RefreshIcon,
    iconProps: { color: "primary", fontSize: "large" },
    tooltip: "Refresh",
    position: "toolbar",
    hidden: !isMediumDisplaySize,
    isFreeAction: true,
    onClick: () => tableRef?.current?.onQueryChange({}),
  };
}

function MarkUnseen(
  onMarkUnseen: (notifications: Notification[]) => void
): Action<object> {
  return {
    icon: MarkEmailUnreadIcon,
    iconProps: { color: "primary" },
    tooltip: "Mark Unread",
    position: "toolbarOnSelect",
    onClick: (_event, data) => onMarkUnseen(data as Notification[]),
  };
};

function MarkSeen(
  onMarkSeen: (notifications: Notification[]) => void
): Action<object> {
  return {
    icon: MarkEmailReadIcon,
    iconProps: { color: "primary" },
    tooltip: "Mark Read",
    position: "toolbarOnSelect",
    onClick: (_event, data) => onMarkSeen(data as Notification[])
  };
};

function Archive(
  onArchive: (notifications: Notification[]) => void
): Action<object> {
  return {
    icon: ArchiveIcon,
    iconProps: { color: "primary" },
    tooltip: "Archive",
    position: "toolbarOnSelect",
    onClick: (_event, data) => onArchive(data as Notification[])
  };
};
