import UserCredentials from "users/values/user-credentials/user-credentials";

export default class LoginWithEmailAPIRequest {
    email: string;
    password: string;
    rememberMe: boolean;
    
    constructor(credentials: UserCredentials, rememberMe: boolean = false) {
        this.email = credentials.email;
        this.password = credentials.password;
        this.rememberMe = rememberMe;
    }
}