import MaterialTable, { MaterialTableProps } from "@material-table/core";
import ContactsIcon from "@mui/icons-material/Contacts";
import { Theme, useMediaQuery } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { ConfirmResponse, useConfirmDialog } from "app/providers/confirm-dialog";
import { useDialog } from "app/providers/dialog";
import TableToolbarComponent from "app/routes/entity-administration/table/toolbar";
import TableContainerComponent from "common/components/table/container";
import TableTitle from "common/components/table/title";
import { PageContainer, TableTheme } from "common/styles";
import Guid from "common/values/guid/guid";
import AdministratorInvitation from "legal-entities/entities/administrator-invitation/administrator-invitation";
import AdministratorInvitationAPIService from "legal-entities/entities/administrator-invitation/api/administrator-invitation-api-service";
import EntityMemberAPIService, {
  InvalidMemberUpdateException
} from "legal-entities/entities/entity-member/api/entity-member-api-service";
import EntityMember from "legal-entities/entities/entity-member/entity-member";
import EntityMemberInviteManager from "legal-entities/entities/user-entity-member-invitation/view/components/entity-member-invite-manager";
import _ from "lodash";
import ViewIndividualProfile from "marketplace/values/individual-profile/view/view-individual-profile";
import { enqueueSnackbar } from "notistack";
import * as React from "react";
import { useEffect } from "react";
import { Params, useLoaderData, useLocation, useNavigate } from "react-router-dom";
import AuthEmailAPIService from "users/entities/user/api/auth-email-api-service";
import { useSession } from "users/session/session-context";
import { TableActionsComponent, getTableActions } from "app/routes/entity-administration/table/actions";
import loadData from "app/routes/entity-administration/table/data-loader";
import { getTableColumns as getInvitationsColumns } from "app/routes/entity-administration/table/invitations/columns";
import { NoRowsPlaceholder } from "app/routes/entity-administration/table/no-rows-placeholder";
import { getTableColumns as getUsersColumns } from "app/routes/entity-administration/table/users/columns";
import { PageTab } from "app/routes/entity-administration/tabs";

export type EntityAdministrationTableData =
  | AdministratorInvitation
  | EntityMember;
type EntityAdministrationProps = {};

export default function EntityAdministration(
  props: Readonly<EntityAdministrationProps>
) {
  const tableRef = React.useRef<
    MaterialTable<EntityAdministrationTableData> &
    MaterialTableProps<EntityAdministrationTableData>
  >();

  const [loading, setLoading] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(PageTab.Users);
  const [tableBodyHeight, setTableBodyHeight] = React.useState(0);

  const routeParams = useLoaderData() as Params<string>;
  const location = useLocation();
  const navigate = useNavigate();
  const session = useSession();
  const isMedDownDisplaySize = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const isFHDUpDisplaySize = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up(1920)
  );
  const confirm = useConfirmDialog();
  const { openDialog, closeAllDialogs } = useDialog();

  const tabs = (
    <Tabs
      value={activeTab}
      indicatorColor="primary"
      textColor="primary"
      onChange={(_event, newValue) => navigate(`/administration/${newValue}`)}
    >
      <Tab value={PageTab.Users} label="Users" />
      <Tab value={PageTab.Invitations} label="Invitations" />
    </Tabs>
  );

  // Close any open dialogs when the URL changes
  useEffect(() => {
    closeAllDialogs();
  }, [location]);

  // Handle account type change
  useEffect(() => {
    tableRef?.current?.onQueryChange({});
  }, [session.accountType]);

  // Handle URL changes
  useEffect(() => {
    if (routeParams.tab) {
      setActiveTab(routeParams.tab as PageTab);
    } else {
      navigate(`/administration/${PageTab.Users}`);
    }

    if (routeParams.action === 'invite') {
      if (routeParams.tab === PageTab.Users) handleInviteUsers();
      if (routeParams.tab === PageTab.Invitations) {
        enqueueSnackbar("Cannot yet invite administrators", { variant: "info" });
      }
      return;
    }

    if (routeParams.action === 'view' && routeParams.id) {
      handleViewProfile(new Guid(routeParams.id));
    }
  }, [routeParams]);

  // Handle component mount
  useEffect(() => {
    function computeTableHeight() {
      const tableHead = document.querySelector("thead.MuiTableHead-root");
      const tableFoot = document.querySelector("tfoot.MuiTableFooter-root");
      const siteFooter = document.querySelector("footer#siteFooter");

      const tableOffsetTop = tableHead?.getBoundingClientRect().top;
      const tableFootHeight = tableFoot?.getBoundingClientRect().height;
      const siteFooterHeight = siteFooter?.getBoundingClientRect().height;

      if (!tableOffsetTop || !tableFootHeight || !siteFooterHeight) return;

      setTableBodyHeight(
        Math.floor(
          window.innerHeight -
          tableOffsetTop -
          tableFootHeight -
          siteFooterHeight
        )
      );
    }

    const debouncedResize = _.debounce(() => computeTableHeight(), 250);
    debouncedResize();
    window.addEventListener("resize", debouncedResize);

    return () => {
      window.removeEventListener("resize", debouncedResize);
    };
  }, []);

  function getColumns() {
    const usersColumns = getUsersColumns(
      session,
      isFHDUpDisplaySize,
      (id) => navigate(`/administration/${PageTab.Users}/view/${id}`),
      handleToggleAdminRole,
      handleResetPassword,
      handleRemoveUser
    );

    if (activeTab === PageTab.Users) {
      return usersColumns;
    }
    if (activeTab === PageTab.Invitations) {
      return getInvitationsColumns(handleCancelInvite);
    }

    return usersColumns;
  }

  function handleViewProfile(individualId?: Guid): void {
    if (!individualId) return;

    const parentPath = new URL(".", window.location.origin + window.location.pathname);

    openDialog(
      {
        titleStyle: {
          position: "absolute",
          right: 0,
          top: 0,
        },
        contentSxProps: {
          display: "flex",
          overflowX: "hidden",
        },
        MuiProps: {
          maxWidth: "lg",
          fullWidth: false,
        },
        component: (
          <ViewIndividualProfile
            individualId={individualId}
            confirmDialog={confirm}
          />
        ),
      },
      () => navigate(parentPath.pathname)
    );
  }

  function handleInviteUsers() {
    const parentPath = new URL(".", window.location.origin + window.location.pathname);

    openDialog(
      {
        title: "User Invitations",
        MuiProps: {
          maxWidth: "md",
        },
        component: (
          <EntityMemberInviteManager
            confirmDialog={confirm}
          />
        )
      },
      () => navigate(parentPath.pathname)
    );
  }

  async function handleCancelInvite(invitation?: AdministratorInvitation) {
    if (!invitation) return;

    const adminName = `${invitation.registrationInfo.name.firstName} ${invitation.registrationInfo.name.lastName}`;

    const response = await confirm({
      title: "Cancel Administrator Invitation?",
      message: `Cancel ${adminName}'s administrator invitation to your orginization?`,
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      const service = new AdministratorInvitationAPIService(session);
      await service.cancelAdministratorInvitation(invitation);
      enqueueSnackbar("Canceled invitation", { variant: "success" });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to cancel invitation", { variant: "error" });
    }
  }

  async function handleToggleAdminRole(entityMember: EntityMember) {
    const name = `${entityMember.name?.firstName} ${entityMember.name?.lastName}`;

    const response = await confirm({
      title: !entityMember.isAdmin
        ? "Make an Administrator?"
        : "Remove Administrative Role?",
      message: !entityMember.isAdmin
        ? `This change will grant ${name} administrative powers for your legal entity`
        : `This change will remove ${name}'s administrative powers from your legal entity`,
      okButtonText: "Change Role",
    });

    if (response === ConfirmResponse.Cancel) return;

    const updatedMember = new EntityMember({
      ...entityMember,
      isAdmin: !entityMember.isAdmin,
    });

    try {
      const service = new EntityMemberAPIService(session);
      await service.updateEntityMember(entityMember, updatedMember);
      enqueueSnackbar("Role updated", { variant: "success" });
    } catch (error) {
      console.error(error);
      if (error instanceof InvalidMemberUpdateException) {
        enqueueSnackbar(error.message, { variant: "error" });
      } else {
        enqueueSnackbar("Failed to update member administration role", {
          variant: "error",
        });
      }
    }
  }

  async function handleResetPassword(entityMember: EntityMember) {
    const response = await confirm({
      title: "Reset Password?",
      message: "Send password reset email?",
      okButtonText: "Send",
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      await AuthEmailAPIService.requestPasswordReset(entityMember.email);
      enqueueSnackbar("Password reset email sent", { variant: "success" });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to send password reset email", {
        variant: "error",
      });
    }
  }

  async function handleRemoveUser(entityMember: EntityMember) {
    const response = await confirm({
      title: "Remove User?",
      message: `Remove ${entityMember.name}'s user account from your organization?`,
      okButtonText: "Remove",
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      // TODO: Implement
      enqueueSnackbar("Removed user from organization", { variant: "success" });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to remove user from organization", {
        variant: "error",
      });
    }
  }

  return (
    <PageContainer>
      <MaterialTable
        key={activeTab}
        tableRef={tableRef}
        title={
          <TableTitle title="User Administration" icon={<ContactsIcon />} />
        }
        columns={getColumns()}
        data={(query) => loadData(query, activeTab, setLoading, session)}
        actions={getTableActions({
          activeTab,
          isMediumDisplaySize: isMedDownDisplaySize,
          navigate
        })}
        isLoading={loading}
        options={{
          debounceInterval: 250,
          defaultExpanded: true,
          emptyRowsWhenPaging: false,
          filtering: true,
          filterRowStyle: TableTheme.filterRow,
          grouping: true,
          minBodyHeight: tableBodyHeight,
          maxBodyHeight: tableBodyHeight,
          pageSize: 10,
          pageSizeOptions: [10, 25, 50],
          paginationType: isMedDownDisplaySize ? "normal" : "stepped",
          showFirstLastPageButtons: !isMedDownDisplaySize,
        }}
        components={{
          Container: (props) => TableContainerComponent({ ...props }),
          Actions: (props) =>
            TableActionsComponent({
              activeTab,
              isMediumDisplaySize: isMedDownDisplaySize,
              handleInviteUsers: handleInviteUsers,
              ...props,
            }),
          Toolbar: (props) =>
            TableToolbarComponent({
              tableRef,
              activeTab,
              tabs,
              isMedDownDisplaySize,
              ...props,
            }),
        }}
        localization={{
          body: {
            emptyDataSourceMessage: (
              <NoRowsPlaceholder tableBodyHeight={tableBodyHeight} />
            ),
          },
        }}
      />
    </PageContainer>
  );
}
