import { Column } from "@material-table/core";
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import LockResetIcon from '@mui/icons-material/LockReset';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import RemoveModeratorIcon from '@mui/icons-material/RemoveModerator';
import { Box, Button, Link, ListItemText, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { EntityAdministrationTableData } from "app/routes/entity-administration";
import { ActionsCellContainer } from "common/styles";
import Guid from "common/values/guid/guid";
import EntityMember from "legal-entities/entities/entity-member/entity-member";
import IndividualAvatar from "marketplace/view/individual-avatar";
import Session from "users/session/session";

const NameCellContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center'
}));
const NameText = styled(ListItemText)(({ theme }) => ({
  marginLeft: theme.spacing(1)
}));
const ContactLink = styled(Link)(({ theme }) => ({
  cursor: 'pointer'
}));

export function getTableColumns(
  session: Session,
  isFHDUpDisplaySize: boolean,
  handleViewProfile: (individualId?: Guid) => void,
  toggleAdminRole: (entityMember: EntityMember) => void,
  resetPassword: (entityMember: EntityMember) => void,
  removeUser: (entityMember: EntityMember) => void
): Column<EntityAdministrationTableData>[] {
  return [
    {
      field: 'name',
      title: 'Name',
      render: rowData => renderNameColumn(
        rowData as EntityMember,
        handleViewProfile,
        session
      )
    },
    {
      field: "isAdmin",
      title: "Role",
      render: rowData => renderRoleColumn(rowData as EntityMember)
    },
    {
      field: 'isActive',
      title: 'Active',
      type: 'boolean'
    },
    {
      field: 'created',
      title: 'Created',
      type: 'datetime',
    },
    {
      title: 'Actions',
      align: 'right',
      filtering: false,
      draggable: false,
      sorting: false,
      grouping: false,
      disableClick: true,
      hiddenByColumnsButton: true,
      removable: false,
      width: 'fit-content',
      render: rowData => renderActionsColumn(
        rowData as EntityMember,
        session,
        isFHDUpDisplaySize,
        toggleAdminRole,
        resetPassword,
        removeUser
      )
    }
  ];
}

function renderNameColumn(
  rowData: EntityMember,
  handleViewProfile: (individualId?: Guid) => void,
  session: Session
): React.ReactNode {
  const name = `${rowData.name?.firstName} ${rowData.name?.lastName}`;
  const emailAddress = rowData.email.toString();

  const contactLink = (
    <ContactLink onClick={() => handleViewProfile(rowData.individualId)}>
      {name}
    </ContactLink>
  );

  return (
    <NameCellContainer>
      <IndividualAvatar 
        avatarId={rowData.avatarId} 
        individualId={rowData.individualId} 
        session={session}
      />
      <NameText
        primary={contactLink}
        secondary={emailAddress}
      />
    </NameCellContainer >
  );
}

function renderRoleColumn(rowData: EntityMember) {
  if (rowData.isOfficer) return 'Officer';
  if (rowData.isAdmin) return 'Admin';
  return 'Member';
}


function renderActionsColumn(
  rowData: EntityMember | EntityMember,
  session: Readonly<Session>,
  isFHDUpDisplaySize: boolean,
  toggleAdminRole: (entityMember: EntityMember) => void,
  resetPassword: (entityMember: EntityMember) => void,
  removeUser: (entityMember: EntityMember) => void
) {
  const isOfficer = !!session.entities.find(entity => entity.isOfficer);
  return (
    <ActionsCellContainer>
      <Tooltip title={!rowData.isAdmin ? 'Make Admin' : 'Remove Admin Rights'}>
        <span>
          {isOfficer && !isFHDUpDisplaySize && (
            <IconButton
              color="primary"
              size="small"
              onClick={() => toggleAdminRole(rowData)}
            >
              {!rowData.isAdmin ? <AddModeratorIcon /> : <RemoveModeratorIcon />}
            </IconButton>
          )}
          {isOfficer && isFHDUpDisplaySize && (
            <Button
              color="primary"
              startIcon={!rowData.isAdmin ? <AddModeratorIcon /> : <RemoveModeratorIcon />}
              onClick={() => toggleAdminRole(rowData)}
            >
              {!rowData.isAdmin ? 'Make Admin' : 'Remove Admin Rights'}
            </Button>
          )}
        </span>
      </Tooltip>
      <Tooltip title="Reset Password">
        <span>
          {!isFHDUpDisplaySize && (
            <IconButton
              color="primary"
              size="small"
              onClick={() => resetPassword(rowData)}
            >
              <LockResetIcon />
            </IconButton>
          )}
          {isFHDUpDisplaySize && (
            <Button
              color="primary"
              startIcon={<LockResetIcon />}
              onClick={() => resetPassword(rowData)}
            >
              Reset Password
            </Button>
          )}
        </span>
      </Tooltip>
      <Tooltip title="Remove User">
        <span>
          {!isFHDUpDisplaySize && (
            <IconButton
              color="primary"
              size="small"
              onClick={() => removeUser(rowData)}
            >
              <PersonRemoveIcon />
            </IconButton>
          )}
          {isFHDUpDisplaySize && (
            <Button
              color="primary"
              startIcon={<PersonRemoveIcon />}
              onClick={() => removeUser(rowData)}
            >
              Remove User
            </Button>
          )}
        </span>
      </Tooltip>
    </ActionsCellContainer>
  );
}
