import MaterialTable, { MaterialTableProps } from '@material-table/core';
import AssignmentIcon from '@mui/icons-material/Assignment';
import {
  Tab,
  Tabs, Theme, useMediaQuery
} from '@mui/material';
import { ConfirmResponse, useConfirmDialog } from 'app/providers/confirm-dialog';
import { useDialog } from 'app/providers/dialog';
import { TableActionsComponent, getTableActions } from 'app/routes/proposals/table/actions';
import { getTableColumns } from 'app/routes/proposals/table/columns';
import loadData from 'app/routes/proposals/table/data-loader';
import { NoRowsPlaceholder } from 'app/routes/proposals/table/no-rows-placeholder';
import TableToolbarComponent from 'app/routes/proposals/table/toolbar';
import { PageTab } from 'app/routes/proposals/tabs';
import { CanceledError } from 'axios';
import TableContainerComponent from 'common/components/table/container';
import TableTitle from 'common/components/table/title';
import SignalRConnection, { HubName, ProposalSignalType, SignalName } from 'common/helpers/signalr-connection';
import { PageContainer, TableTheme } from 'common/styles/table';
import Guid from 'common/values/guid/guid';
import _ from 'lodash';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { Params, useLoaderData, useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useSession } from 'users/session/session-context';
import ProposalAPIService, { ProposalContextParam } from 'work/entities/proposal/api/proposal-api-service';
import ProposalDraftDialog from 'work/entities/proposal/draft/view/proposal-draft-dialog';
import ProposalRequestDraftDialog from 'work/entities/proposal/draft/view/proposal-request-draft-dialog';
import Proposal from 'work/entities/proposal/proposal';
import { default as ProposalRedline, default as ProposalRedlineDialog, ProposalTab } from 'work/entities/proposal/redlining/view/proposal-redline-dialog';
import ProposalViewDialog from 'work/entities/proposal/view/proposal-view-dialog';
import { ProposalStatus } from 'work/values/constants';
import SelectedRepresentative from 'work/values/selected-representative/selected-representative';
import SelectedTeam from 'work/values/selected-team/selected-team';

type ProposalProps = {};

export default function Proposals(_props: Readonly<ProposalProps>) {
  const tableRef = React.useRef<MaterialTable<Proposal> & MaterialTableProps<Proposal>>();
  const draftIsDirtyRef = React.useRef<boolean>(false);
  const redlineIsDirtyRef = React.useRef<boolean>(false);

  const [loading, setLoading] = React.useState(false);
  const [activeFilters, setActiveFilters] = React.useState([]);
  const [activeTab, setActiveTab] = React.useState(PageTab.Active);
  const [tableBodyHeight, setTableBodyHeight] = React.useState(0);
  const [selectedRows, setSelectedRows] = React.useState<Proposal[]>([]);

  const [inbox, setInbox] = React.useState(false);
  const [outbox, setOutbox] = React.useState(false);
  const [pendingReview, setPendingReview] = React.useState(false);

  const routeParams = useLoaderData() as Params<string>;
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const session = useSession();
  const confirm = useConfirmDialog();
  const isMediumDisplaySize = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { openDialog, closeAllDialogs } = useDialog();

  const tabs = (
    <Tabs
      value={activeTab}
      indicatorColor="primary"
      textColor="primary"
      onChange={(_event, newValue) => navigate(`/proposals/${newValue}`)}>
      <Tab value={PageTab.Active} label="Active" />
      <Tab value={PageTab.Drafts} label="Drafts" />
      <Tab value={PageTab.Archived} label="Archived" />
    </Tabs>
  );

  // Close any open dialogs when the URL changes
  useEffect(() => {
    closeAllDialogs();
  }, [location]);

  // Handle session account type change
  useEffect(() => {
    tableRef?.current?.onQueryChange({});
  }, [session.accountType]);

  // Handle URL changes
  useEffect(() => {
    if (routeParams.pageTab) {
      setActiveTab(routeParams.pageTab as PageTab);
    } else {
      navigate(`/proposals/${PageTab.Active}`);
    }

    if (routeParams.action === 'create') {
      handleCreateProposal();
      return;
    }

    if (routeParams.action === 'request') {
      handleRequestProposal();
      return;
    }

    if (routeParams.id) {
      const pageTab = routeParams.pageTab as PageTab;
      const action = routeParams.action as string;
      const tab = ProposalTab[routeParams.tab as keyof typeof ProposalTab];
      let actionMethod: (proposal: Proposal, tab?: ProposalTab) => void;
      let abortController = new AbortController();

      if (pageTab === PageTab.Drafts && action === 'edit') {
        actionMethod = handleEditProposal;
      }
      if (pageTab === PageTab.Drafts && action === 'review') {
        actionMethod = handleReviewDraftProposal;
      }
      else if (pageTab === PageTab.Active && action === 'revise') {
        actionMethod = handleReviseProposal;
      }
      else if (pageTab === PageTab.Active && action === 'review') {
        actionMethod = handleReviewProposal;
      }
      else if (pageTab === PageTab.Active && action === 'view') {
        actionMethod = handleViewProposal;
      }
      else if (pageTab === PageTab.Archived) {
        actionMethod = handleViewProposal;
      }

      getProposal(new Guid(routeParams.id), abortController)
        .then((proposal?: Proposal) => {
          if (!proposal) return;
          actionMethod(proposal, tab);
        })
        .catch((error) => {
          console.error(error);
          enqueueSnackbar('Unable to load proposal', { variant: 'error' });
        });

      return () => {
        abortController.abort();
        abortController = new AbortController();
      };
    }
  }, [routeParams]);

  useEffect(() => {
    setInbox(searchParams.get('context') === 'inbox');
    setOutbox(searchParams.get('context') === 'outbox');
    setPendingReview(searchParams.get('context') === 'pendingreview');
    tableRef?.current?.onQueryChange({});
  }, [searchParams]);

  // Handle component mount
  useEffect(() => {
    function computeTableHeight() {
      const tableHead = document.querySelector('thead.MuiTableHead-root');
      const tableFoot = document.querySelector('tfoot.MuiTableFooter-root');
      const siteFooter = document.querySelector('footer#siteFooter');

      const tableOffsetTop = tableHead?.getBoundingClientRect().top;
      const tableFootHeight = tableFoot?.getBoundingClientRect().height;
      const siteFooterHeight = siteFooter?.getBoundingClientRect().height;

      if (!tableOffsetTop || !tableFootHeight || !siteFooterHeight) return;

      setTableBodyHeight(Math.floor(window.innerHeight - tableOffsetTop - tableFootHeight - siteFooterHeight));
    }

    const debouncedResize = _.debounce(() => computeTableHeight(), 250);
    debouncedResize();
    window.addEventListener('resize', debouncedResize);

    return () => {
      window.removeEventListener('resize', debouncedResize);
    }
  }, []);

  async function getProposal(id: Guid, abortController: AbortController) {
    try {
      setLoading(true);
      const proposalService = new ProposalAPIService(session);
      return await proposalService.getProposalById(id, abortController);
    } catch (error) {
      if (error instanceof CanceledError) return;
      console.error(error);
    } finally {
      if (!abortController.signal.aborted) {
        setLoading(false);
      }
    }
  }

  function clearFilters() {
    setActiveFilters([]);
    // TODO: Update filter values on the column def. The columns will need to be stored in state
  }

  function handleChangeTab(tab: PageTab) {
    setActiveTab(tab);
    navigate(`/proposals/${tab}`);
  }

  function handleRequestProposal() {
    const preSelectedRep = SelectedRepresentative.fromURLSearchParams(searchParams);
    const preSelectedTeam = SelectedTeam.fromURLSearchParams(searchParams);
    const parentPath = new URL(".", window.location.origin + window.location.pathname);

    openDialog(
      {
        title: `Request Proposal from ${preSelectedRep?.name ?? preSelectedTeam?.targetTeamName ?? 'Vendor'}`,
        component: (
          <ProposalRequestDraftDialog
            onProposalSubmitted={(submittedProposal: Proposal) => {
              closeAllDialogs();
              navigate(`/proposals/active/revise/${submittedProposal.id}`, { replace: true });
            }}
            onCancel={() => {
              closeAllDialogs();
              navigate(parentPath.pathname);
            }}
            preSelectedRep={preSelectedRep}
            preSelectedTeam={preSelectedTeam}
          />
        ),
      },
      () => navigate(parentPath.pathname),
      () => draftIsDirtyRef.current
    );
  }

  function handleCreateProposal() {
    let url: string;
    const preSelectedRep = SelectedRepresentative.fromURLSearchParams(searchParams);
    const preSelectedTeam = SelectedTeam.fromURLSearchParams(searchParams);
    const parentPath = new URL(".", window.location.origin + window.location.pathname);

    openDialog(
      {
        title: 'Create Proposal',
        MuiProps: { fullWidth: false, maxWidth: 'lg' },
        contentSxProps: { pb: { xs: 0 } },
        titleStyle: { paddingBottom: 0 },
        component: (
          <ProposalDraftDialog
            onProposalSaved={() => { url = '/proposals/drafts'; }}
            onProposalSubmitted={(submittedProposal: Proposal) => {
              closeAllDialogs();
              navigate('/proposals/active', { replace: true });
            }}
            onDirtyChange={(isDirty) => draftIsDirtyRef.current = isDirty}
            onRefreshStaleProposalDialog={(revisedProposal: Proposal) => {
              closeAllDialogs();
              handleEditProposal(revisedProposal);
            }}
            preSelectedRep={preSelectedRep}
            preSelectedTeam={preSelectedTeam}
          />
        ),
      },
      () => navigate(url ?? parentPath.pathname),
      () => draftIsDirtyRef.current
    );
  }

  function handleEditProposal(proposal: Proposal, tab?: ProposalTab) {
    if (!proposal.id) return;

    let url: string;
    const parentPath = new URL("..", window.location.origin + window.location.pathname);

    openDialog(
      {
        title: 'Edit Proposal',
        MuiProps: { fullWidth: false, maxWidth: 'lg' },
        contentSxProps: { pb: { xs: 0 } },
        titleStyle: { paddingBottom: 0 },
        component: (
          <ProposalDraftDialog
            tab={tab}
            proposal={proposal}
            onProposalSaved={() => { url = '/proposals/drafts'; }}
            onProposalSubmitted={(submittedProposal: Proposal) => {
              closeAllDialogs();
              navigate('/proposals/active', { replace: true });
            }}
            onDirtyChange={(isDirty) => draftIsDirtyRef.current = isDirty}
            onRefreshStaleProposalDialog={(revisedProposal: Proposal) => {
              closeAllDialogs();
              handleEditProposal(revisedProposal, tab);
            }}
          />
        ),
      },
      () => navigate(url ?? parentPath.pathname),
      () => draftIsDirtyRef.current
    );
  }

  function handleReviewDraftProposal(proposal: Proposal, tab?: ProposalTab) {
    if (!proposal.id) return;

    const reviewerCanEdit =
      (proposal.clientReviewers.find(r => r.userId.isEqualTo(session.user?.id))?.canEdit ?? false) ||
      (proposal.vendorReviewers.find(r => r.userId.isEqualTo(session.user?.id))?.canEdit ?? false);
    const parentPath = new URL("..", window.location.origin + window.location.pathname);

    openDialog(
      {
        title: 'Review Proposal',
        MuiProps: { fullWidth: false, maxWidth: 'lg' },
        contentSxProps: { pb: { xs: 0 } },
        titleStyle: { paddingBottom: 0 },
        component: (
          <ProposalDraftDialog
            tab={tab}
            proposal={proposal}
            isReviewing={
              session.context?.viewingAsVendor ?
                proposal.vendorReviewers.length > 0 :
                proposal.clientReviewers.length > 0
            }
            disableEditing={!reviewerCanEdit}
            onDirtyChange={(isDirty) => draftIsDirtyRef.current = isDirty}
            onRefreshStaleProposalDialog={(revisedProposal: Proposal) => {
              closeAllDialogs();
              handleReviewDraftProposal(revisedProposal, tab);
            }}
          />
        ),
      },
      () => navigate(parentPath.pathname),
      () => draftIsDirtyRef.current
    );
  }

  function handleReviewProposal(proposal: Proposal, tab?: ProposalTab) {
    if (!proposal.id) return;

    const reviewerCanEdit =
      (proposal.clientReviewers.find(r => r.userId.isEqualTo(session.user?.id))?.canEdit ?? false) ||
      (proposal.vendorReviewers.find(r => r.userId.isEqualTo(session.user?.id))?.canEdit ?? false);
    const parentPath = new URL("..", window.location.origin + window.location.pathname);

    openDialog(
      {
        title: 'Review Proposal',
        MuiProps: { fullWidth: false, maxWidth: 'lg' },
        contentSxProps: { pb: { xs: 0 } },
        titleStyle: { paddingBottom: 0 },
        component: (
          <ProposalRedlineDialog
            tab={tab}
            proposalId={proposal.id}
            isReviewing={
              session.context?.viewingAsVendor ?
                proposal.vendorReviewers.length > 0 :
                proposal.clientReviewers.length > 0
            }
            disableEditing={!reviewerCanEdit}
            onDirtyChange={(isDirty) => redlineIsDirtyRef.current = isDirty}
            onRevisedProposalReceived={(revisedProposal: Proposal) => {
              closeAllDialogs();
              handleReviewProposal(revisedProposal, tab);
            }} />
        ),
      },
      () => navigate(parentPath.pathname),
      () => redlineIsDirtyRef.current
    );
  }

  async function handleSubmitProposal(proposal: Proposal) {
    if (!proposal.id) return Promise.reject(new Error('Proposal ID is required'));

    const response = await confirm({
      title: 'Submit Proposal',
      message: 'Are you use you want to submit this proposal?',
      okButtonText: 'Submit'
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      setLoading(true);
      await proposal.submit(session.user?.id);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleApproveProposal(proposal: Proposal) {
    if (!proposal.id) return Promise.reject(new Error('Proposal ID is required'));

    const response = await confirm({
      title: 'Approve Proposal',
      message: 'Are you use you want to approve this proposal?',
      okButtonText: 'Approve'
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      setLoading(true);
      await proposal.approve(session.user?.id);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleRejectProposal(proposal: Proposal) {
    if (!proposal.id) return Promise.reject(new Error('Proposal ID is required'));

    const response = await confirm({
      title: 'Reject Proposal',
      message: 'Are you use you want to reject this proposal?',
      okButtonText: 'Reject'
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      setLoading(true);
      await proposal.reject(session.user?.id);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleHireProposal(proposal: Proposal) {
    if (!proposal.id) return Promise.reject(new Error('Proposal ID is required'));

    const response = await confirm({
      title: 'Hire Proposal',
      message: 'Are you use you want to hire this proposal?',
      okButtonText: 'Hire'
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      setLoading(true);
      await proposal.hire(session.user?.id);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleDeleteProposal(proposal: Proposal) {
    if (!proposal.id) return Promise.reject(new Error('Proposal ID is required'));

    const response = await confirm({
      title: 'Delete Proposal',
      message: 'Are you use you want to delete this proposal?',
      okButtonText: 'Delete'
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      setLoading(true);
      await proposal.delete(session.user?.id);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleCancelProposal(proposal: Proposal) {
    if (!proposal.id) return Promise.reject(new Error('Proposal ID is required'));

    const response = await confirm({
      title: 'Cancel Proposal',
      message: 'Are you use you want to cancel this proposal?',
      okButtonText: 'Ok'
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      setLoading(true);
      await proposal.cancel(session.user?.id);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  function handleViewProposal(proposal: Proposal, tab?: ProposalTab) {
    if (!proposal.id) return;

    const parentPath = new URL("..", window.location.origin + window.location.pathname);

    openDialog(
      {
        title: proposal.name.valueOf(),
        titleStyle: { paddingBottom: 0 },
        MuiProps: { fullWidth: false, maxWidth: 'xl' },
        contentSxProps: { pb: { xs: 0 } },
        component: (
          <ProposalViewDialog tab={tab} proposalId={proposal.id} />
        ),
      },
      () => navigate(parentPath.pathname)
    );
  }

  function handleReviseProposal(proposal: Proposal, tab?: ProposalTab) {
    if (!proposal.id) return;

    const parentPath = new URL("..", window.location.origin + window.location.pathname);

    openDialog(
      {
        title: 'Review/Revise Proposal',
        titleStyle: { paddingBottom: 0 },
        MuiProps: { fullWidth: false, maxWidth: 'xl' },
        contentSxProps: { pb: { xs: 0 } },
        component: (
          <ProposalRedline
            tab={tab}
            proposalId={proposal.id}
            onDirtyChange={(isDirty) => redlineIsDirtyRef.current = isDirty}
            onRevisedProposalReceived={(revisedProposal: Proposal) => {
              closeAllDialogs();
              handleReviseProposal(revisedProposal, tab);
            }}
          />
        ),
      },
      () => navigate(parentPath.pathname),
      () => redlineIsDirtyRef.current
    );
  }

  async function handleProposalDelete(proposal: Proposal | Proposal[]) {
    let message: string = 'Are you use you want to delete this proposal?';

    if (Array.isArray(proposal)) {
      if (proposal.length === 0) return;
      message = `Are you sure you want to delete ${proposal.length} proposals?`;
    }

    const response = await confirm({
      title: 'Delete Proposals',
      message: message,
      okButtonText: 'Delete'
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      setLoading(true);

      const proposalAPIService = new ProposalAPIService(session);
      if (Array.isArray(proposal)) {
        await proposalAPIService.removeDraftProposals(
          proposal.filter(p => p._instance.id !== null).map(p => p._instance.id!)
        );
      } else {
        await proposalAPIService.deleteProposal(proposal._instance);
      }
      setSelectedRows([]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  function handleSelectionChange(selectedRowsData: Proposal[], _rowData?: Proposal | undefined) {
    setSelectedRows(selectedRowsData);
  }

  async function handleProposalNotificationCreated(proposal: Proposal) {
    // Drafting proposals generates many SignalR messages as it is updated,
    // and results in a lot of refresh calls if not ignored
    if (!proposal || proposal.status === ProposalStatus.AwaitingSubmission) return;

    // Just reload the table data for now
    tableRef?.current?.onQueryChange({});
  }

  async function handleProposalNotificationUpdated(proposal: Proposal) {
    if (!proposal) return;

    // Just reload the table data for now
    tableRef?.current?.onQueryChange({});
  }

  async function handleProposalNotificationDeleted(proposal: Proposal) {
    if (!proposal) return;

    // Just reload the table data for now
    tableRef?.current?.onQueryChange({});
  }

  return (
    <PageContainer>
      <SignalRConnection
        hubName={HubName.Proposals}
        signals={[
          { signal: SignalName.ProposalCreated, handler: handleProposalNotificationCreated },
          { signal: SignalName.ProposalUpdated, handler: handleProposalNotificationUpdated },
          { signal: SignalName.ProposalDeleted, handler: handleProposalNotificationDeleted }
        ] as ProposalSignalType[]}
      />
      <MaterialTable
        key={activeTab}
        tableRef={tableRef}
        title={
          <TableTitle title="Proposals" icon={<AssignmentIcon />} />
        }
        columns={getTableColumns(
          activeTab,
          selectedRows,
          handleChangeTab,
          (proposal) => navigate(`/proposals/drafts/edit/${proposal.id}`),
          handleSubmitProposal,
          (proposal) => navigate(`/proposals/active/revise/${proposal.id}`),
          handleApproveProposal,
          handleRejectProposal,
          handleHireProposal,
          handleDeleteProposal,
          handleCancelProposal,
          (proposal) => navigate(`/proposals/${activeTab}/view/${proposal.id}`),
          (proposal) => navigate(`/proposals/${activeTab}/review/${proposal.id}`),
          session
        )}
        data={(query) => loadData(
          query,
          activeTab,
          searchParams.get('context') as ProposalContextParam,
          setLoading,
          session
        )}
        actions={getTableActions(
          isMediumDisplaySize,
          handleProposalDelete,
          session,
          navigate
        )}
        isLoading={loading}
        options={{
          columnsButton: true,
          debounceInterval: 250,
          emptyRowsWhenPaging: false,
          filtering: true,
          filterRowStyle: TableTheme.filterRow,
          grouping: true,
          minBodyHeight: tableBodyHeight,
          maxBodyHeight: tableBodyHeight,
          pageSize: 10,
          pageSizeOptions: [10, 25, 50],
          paginationType: isMediumDisplaySize ? 'normal' : 'stepped',
          showFirstLastPageButtons: !isMediumDisplaySize,
          selection: activeTab === PageTab.Drafts,
          rowStyle: function (rowData: Proposal) {
            return {
              backgroundColor: selectedRows.some(row => row._instance.id?.isEqualTo(rowData._instance.id)) ?
                TableTheme.row.selected.backgroundColor :
                TableTheme.row.unseen.backgroundColor
            };
          }
        }}
        components={{
          Container: props => TableContainerComponent({ ...props }),
          Actions: props => TableActionsComponent({
            isMediumDisplaySize,
            selectedRows,
            session,
            navigate,
            ...props
          }),
          Toolbar: props => TableToolbarComponent({
            tableRef,
            activeTab,
            tabs,
            activeFilters,
            selectedRows,
            inbox,
            outbox,
            pendingReview,
            isMediumDisplaySize,
            clearFilters,
            setInbox,
            setOutbox,
            setPendingReview,
            ...props
          })
        }}
        localization={{
          body: {
            emptyDataSourceMessage: <NoRowsPlaceholder tableBodyHeight={tableBodyHeight} />
          }
        }}
        onSelectionChange={handleSelectionChange}
      />
    </PageContainer>
  );
}
