import MaterialTable, { Column } from '@material-table/core';
import { Icon, Typography } from '@mui/material';
import BookmarkAPIService from 'marketplace/entities/bookmark/api/bookmark-api-service';
import BookmarkedIndividual from 'marketplace/entities/bookmarked-individual/bookmarked-individual';
import React, { useEffect } from 'react';
import { useSession } from 'users/session/session-context';


export interface BookmarkedIndividualsProps {
  onSelected: (bookmarkedIndividual: BookmarkedIndividual ) => void;
}

export function BookmarkedIndividuals(props: Readonly<BookmarkedIndividualsProps>) {
  const { onSelected } = props;

  const [loading, setLoading] = React.useState<boolean>(false);
  const [bookmarks, setBookmarks] = React.useState<BookmarkedIndividual[]>([]);
  const session = useSession();


  const columns: Column<BookmarkedIndividual>[] = [
    {
      title: 'Name',
      field: 'name',
      render: (rowData) => `${rowData.vendor.profile?.firstName ?? 'Unknown'} ${rowData.vendor.profile?.lastName ?? 'User'}`
    },
    {
      title: 'Categories',
      field: 'categories',
      emptyValue: <Icon>remove</Icon>,
      render: (rowData) => rowData.vendor.profile?.categories.join(', ')
    },
    {
      title: 'Description',
      field: 'description',
      emptyValue: <Icon>remove</Icon>,
      render: (rowData) => rowData.vendor.profile?.description
    }
  ];

  useEffect(() => {
    getIndividualBookmarks();
  }, []);

  async function getIndividualBookmarks() {
    setLoading(true);
    try {
      const bookmarkService = new BookmarkAPIService(session);
      const bookmarks = await bookmarkService.getAllIndividualBookmarksForUser(new AbortController());
      setBookmarks(bookmarks);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
      <MaterialTable
        data={bookmarks}
        options={{
          showTitle: false,
          actionsColumnIndex: -1,
          pageSize: 10,
          pageSizeOptions: [10, 25, 50],
        }}
        columns={columns}
        isLoading={loading}
        onRowClick={(event, rowData) => {
          if (!rowData) return;
          onSelected(rowData);
        }}
        localization={{
          body: {
            emptyDataSourceMessage: (
              <Typography variant="h6">
                You haven't bookmarked any individuals
              </Typography>
            )
          }
        }}
      />
  )
}
