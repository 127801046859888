import { forwardRef, useState, useEffect } from "react";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

import InvitationMessage, { InvalidInvitationMessageError } from "legal-entities/values/invitation-message/invitation-message";

const MessageField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
  minWidth: theme.spacing(40)
}));

export interface MessageInputError extends String {};

type MessageInputProps = {
  idPrefix?: string;
  className?: string;
  message?: InvitationMessage;
  onChange?: (message?: InvitationMessage, error?: MessageInputError) => void;
};

const InvitationMessageInput = forwardRef((props: MessageInputProps, ref) => {
  const { idPrefix, className, message, onChange } = props;
  const [messageFieldText, setMessageFieldText] = useState<string>(message?.valueOf() ?? '');
  const [messageFieldError, setMessageFieldError] = useState<MessageInputError>('');

  useEffect(() => {
    setMessageFieldText(message?.valueOf() ?? '');
  }, [message]);

  function buildMessage(inputText?: string): InvitationMessage | undefined {
    if (!inputText) return;
    
    try {
      return new InvitationMessage(inputText);
    } catch (error: any) {
      if (error instanceof InvalidInvitationMessageError) {
        console.error(error);
        throw error;
      }
    }
  }

  function handleMessageTextChanged(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setMessageFieldText(event.target.value);

    let newMessage: InvitationMessage | undefined ;
    let newErrors: MessageInputError = '';

    try {
      newMessage = buildMessage(event.target.value);
      setMessageFieldError('');
    } catch (error: any) {
      newErrors = error.message;
      setMessageFieldError(newErrors)
    } finally {
      onChange?.(newMessage, newErrors);
    }
  }

  return (
    <MessageField
      id={idPrefix ? idPrefix + "-message-input" : "message-input"}
      className={className}
      label="Message"
      minRows={5}
      multiline
      onChange={handleMessageTextChanged}
      value={messageFieldText}
      error={!!messageFieldError}
      helperText={messageFieldError}
      variant="outlined"
    />
  );
});

export default InvitationMessageInput;
