import MaterialTable, { MaterialTableProps, MTableToolbar } from "@material-table/core";
import { styled } from "@mui/material/styles";
import { CommunicationTableData } from "app/routes/communications/table/columns";
import { TableToolbarContainer } from "common/styles";
import { BoxType } from "notifications/values/box-type/box-type";

const NavContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    marginBottom: 0
  },
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  flexDirection: 'row'
}));

export type TableToolbarComponentProps = {
  tableRef: React.MutableRefObject<(MaterialTable<CommunicationTableData> & MaterialTableProps<CommunicationTableData>) | undefined>;
  activeTab: BoxType;
  tabs: React.ReactNode;
  isMediumDisplaySize: boolean;
}

export default function TableToolbarComponent(props: Readonly<TableToolbarComponentProps>) {
  return (
    <TableToolbarContainer>
      <MTableToolbar {...props} />
      <NavContainer>
        {props.tabs}
      </NavContainer>
    </TableToolbarContainer>
  )
}
