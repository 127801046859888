import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddBoxIcon from '@mui/icons-material/AddBox';
import WorkIcon from '@mui/icons-material/Work';
import SelectedRepresentative from 'work/values/selected-representative/selected-representative';
import Session from 'users/session/session';
import Guid from 'common/values/guid/guid';
import Name from 'common/values/name/name';
import SelectedTeam from 'work/values/selected-team/selected-team';

const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  },
  marginBottom: theme.spacing(1),
  marginLeft: theme.spacing(2),
  minWidth: theme.spacing(30)
}));

export type DirectProposalButtonProps = {
  session: Readonly<Session>;
  action: "create" | "request";
  targetTeamId?: Guid;
  targetUserId?: Guid;
  targetEntityId?: Guid;
  targetName?: Name;
  targetIsClientRep?: boolean;
  targetIsVendorRep?: boolean;
  useStyledButton?: boolean;
  targetTeamName?: string;
  onClick: (targetUrl: URL) => void;
};

export default function DirectProposalButton(props: Readonly<DirectProposalButtonProps>) {
  const {
    action,
    targetTeamId,
    targetUserId,
    targetEntityId,
    targetName,
    targetIsClientRep,
    targetIsVendorRep,
    session,
    useStyledButton,
    targetTeamName,
    onClick
  } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const isOpen = Boolean(anchorEl);
  const canSelectBoth = Boolean(
    targetIsClientRep && targetIsVendorRep && session?.context?.isClient && session?.context?.isVendor
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (targetTeamId) {
      const team = new SelectedTeam(targetTeamId, targetTeamName);
      onClick(team.generateTargetUrl(action, window.location));
      setAnchorEl(null);
      return;
    }
    else if (!canSelectBoth) {
      if (targetUserId) {
        const rep = new SelectedRepresentative(targetUserId, targetEntityId!, targetIsClientRep!, targetName!);
        onClick(rep.generateTargetUrl(action, window.location));
      }
      setAnchorEl(null);
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (client: boolean | null) => {
    setAnchorEl(null);
    if (client === null) return;
    const rep = new SelectedRepresentative(targetUserId!, targetEntityId!, client, targetName!);
    onClick(rep.generateTargetUrl(action, window.location));
  };

  return (
    <>
      {useStyledButton &&
        <StyledButton
          aria-label="share"
          variant="outlined"
          startIcon={action === "create" ? <AddBoxIcon /> : <WorkIcon />}
          aria-controls={isOpen ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={isOpen ? 'true' : undefined}
          onClick={handleClick}>
          {action === "create" ? "Create Proposal" : "Request Proposal"}
        </StyledButton>
      }
      {!useStyledButton &&
        <Button
          color="primary"
          startIcon={action === "create" ? <AddBoxIcon /> : <WorkIcon />}
          aria-controls={isOpen ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={isOpen ? 'true' : undefined}
          onClick={handleClick}>
          {action === "create" ? "Create Proposal" : "Request Proposal"}
        </Button>
      }
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={() => handleClose(null)}>
        <MenuItem onClick={() => handleClose(true)}>Hire as Client Representative</MenuItem>
        <MenuItem onClick={() => handleClose(false)}>Hire as Vendor Team Lead</MenuItem>
      </Menu>
    </>
  );
}
