import UserEntityMemberInvitation from "legal-entities/entities/user-entity-member-invitation/user-entity-member-invitation";

export default class CreateUserEntityMemberInvitationAPIRequest {
    inviteeEmail: string;
    firstName?: string;
    lastName?: string;
    message?: string;

    constructor(invitation: UserEntityMemberInvitation) {
        if(!invitation.registrationInfo?.email) 
            throw new Error("Registration info email is required to create an Entity Member Invitation.");
       
        this.inviteeEmail = invitation.registrationInfo.email.valueOf();
        this.firstName = invitation.registrationInfo.name?.firstName;
        this.lastName = invitation.registrationInfo.name?.lastName;
        this.message = invitation.message?.valueOf();
    }
}
