import EmailAddress from "common/values/email-address/email-address";

export default class UserCredentials {
    email: string;
    password: string;

    constructor (email: string, password: string) {
        if(!EmailAddress.isValid(email)) throw new InvalidCredentialsError();
        if(password.length === 0) throw new InvalidCredentialsError();

        this.email = email;
        this.password = password;
    }
}

export class InvalidCredentialsError extends Error {
    constructor() {
        super("Invalid credentials");
    }
}