import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  styled,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { CanceledError } from "axios";
import { AccountType } from "common/values/account-type/account-type";
import Guid from "common/values/guid/guid";
import Forum from "messaging/entities/forum/forum";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useSession } from "users/session/session-context";
import FeeScheduleTemplateAPIService from "work/entities/fee-schedule-template/api/fee-schedule-template-api-service";
import FeeScheduleTemplate from "work/entities/fee-schedule-template/fee-schedule-template";
import { FreelyPatchableFields, ProposalField } from "work/entities/proposal/proposal";
import { FeeScheduleRedline } from "work/entities/proposal/redlining/fee-schedule-redline/fee-schedule-redline";
import FeeScheduleRedlineSelection from "work/entities/proposal/redlining/fee-schedule-redline/view/fee-schedule-redline-selection";
import ProposalRedline from "work/entities/proposal/redlining/proposal-redline";
import { ProposalFieldName } from "work/values/constants";
import FeeScheduleCategory from "work/values/fee-schedule-category/fee-schedule-category";
import FeeScheduleTag from "work/values/fee-schedule-tag/fee-schedule-tag";

const ListContainer = styled(Grid)(({ theme }) => ({
  minHeight: "20rem",
}));
const TemplateContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  flex: 1,
}));
const TemplateList = styled(List)(({ theme }) => ({
  marginRight: theme.spacing(2),
  maxHeight: "25rem",
  minWidth: "20rem",
  overflowY: "auto",
}));
const TemplateLoader = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.primary.main,
  margin: theme.spacing(2),
}));
const FeeScheduleTagChip = styled(Chip)(({ theme }) => ({
  color: "#777",
  marginRight: theme.spacing(0.25),
}));
const RedlineCategorySelection = styled(FeeScheduleRedlineSelection)(
  ({ theme }) => ({
    [theme.breakpoints.down("lg")]: {
      marginTop: theme.spacing(2),
    },
    marginLeft: theme.spacing(2),
  })
);

type FeeScheduleTabProps = {
  isOpen: boolean;
  proposalRedline: ProposalRedline;
  activeReviewField: ProposalField | undefined;
  commentForums: Forum[];
  disableEditing?: boolean;
  onProposalRedlineChange: (newRedline: ProposalRedline, traversalFieldOverride?: ProposalField) => void;
  onTraverseToNewField?: (fieldInfo: ProposalField | undefined) => void;
  onCommentsClicked: (field: ProposalFieldName, id?: Guid, title?: string) => void;

  fieldsToPatch?: FreelyPatchableFields;

  onFeeScheduleTemplatesUpdated: (templateIds: Guid[]) => void;
};

const FeeScheduleTab = (props: Readonly<FeeScheduleTabProps>) => {
  const {
    isOpen,
    proposalRedline,
    activeReviewField,
    commentForums,
    disableEditing,
    onProposalRedlineChange,
    onTraverseToNewField,
    onCommentsClicked,

    fieldsToPatch,

    onFeeScheduleTemplatesUpdated,
  } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [templateFeeSchedules, setTemplateFeeSchedules] = useState<
    FeeScheduleTemplate[]
  >([]);
  const [selectedTemplateIds, setSelectedTemplateIds] = useState<Guid[]>([]);

  const isLargeDownDisplay = useMediaQuery((theme: any) =>
    theme.breakpoints.down("lg")
  );
  const session = useSession();

  useEffect(() => {
    let abortController = new AbortController();
    getTemplateFeeSchedules(abortController);
    setSelectedTemplateIds(fieldsToPatch?.feeScheduleTemplateIds ?? []);

    return () => {
      abortController.abort();
      abortController = new AbortController();
    };
  }, []);

  async function getTemplateFeeSchedules(
    abortController: AbortController
  ): Promise<void> {
    const entityId = session?.currentEntity.id;
    if (!entityId) return;

    try {
      setIsLoading(true);

      const feeScheduleService = new FeeScheduleTemplateAPIService(session);
      const templates = await feeScheduleService.getFeeScheduleTemplates(
        entityId,
        session.context?.viewingAsVendor ? AccountType.Vendor : AccountType.Client,
        abortController
      );

      setTemplateFeeSchedules(templates);
    } catch (error) {
      if (error instanceof CanceledError) return;
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  function handleTemplateExpanded(template: FeeScheduleTemplate) {
    let templates = [...templateFeeSchedules];
    let selectedTemplate = templates.find((feeSchedule) =>
      feeSchedule.id?.isEqualTo(template.id)
    );
    template.isExpanded = !selectedTemplate?.isExpanded;

    setTemplateFeeSchedules(templates);
  }

  function handleTemplateSelectionToggled(template: FeeScheduleTemplate) {
    try {
      if (!template.id) throw new Error("No template selected");

      let updatedSelectedTemplateIds = [...selectedTemplateIds];
      const isAlreadySelected = selectedTemplateIds.some((id) =>
        id.isEqualTo(template.id)
      );

      if (isAlreadySelected) {
        updatedSelectedTemplateIds = updatedSelectedTemplateIds.filter(
          (id) => !id.isEqualTo(template.id)
        );
      } else {
        let newFeeScheduleRedline: FeeScheduleRedline =
          proposalRedline.feeSchedule;
        for (const category of template.categories) {
          newFeeScheduleRedline = newFeeScheduleRedline.addEntry(category);
        }
        const newProposalRedline = proposalRedline.updateFeeScheduleRedline(
          newFeeScheduleRedline
        );
        onProposalRedlineChange(newProposalRedline);

        updatedSelectedTemplateIds.push(template.id);
      }

      setSelectedTemplateIds(updatedSelectedTemplateIds);
      onFeeScheduleTemplatesUpdated(updatedSelectedTemplateIds);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to apply template.", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  function handleTemplateCategoryToggled(
    event: React.MouseEvent,
    templateCategory: FeeScheduleCategory
  ) {
    event.stopPropagation();
    const feeScheduleCategory =
      FeeScheduleCategory.Prototype.fromObject(templateCategory);

    if (!feeScheduleCategory) return;

    if (getIsCategoryAlreadySelected(feeScheduleCategory)) {
      const updatedRedline = proposalRedline.updateFeeScheduleRedline(
        proposalRedline.feeSchedule.removeEntryByFieldId(feeScheduleCategory.id)
      );

      onProposalRedlineChange(updatedRedline);
    } else {
      const updatedRedline = proposalRedline.updateFeeScheduleRedline(
        proposalRedline.feeSchedule.addEntry(feeScheduleCategory)
      );
      onProposalRedlineChange(updatedRedline);
    }
  }

  function getIsCategoryAlreadySelected(
    category: FeeScheduleCategory | null
  ): boolean {
    if (!category) return false;
    return proposalRedline.feeSchedule.some((categoryRedline) =>
      categoryRedline.fieldId?.isEqualTo(category.id) &&
      categoryRedline.currentEntry !== null
    );
  }

  function renderTemplates() {
    return (
      <TemplateList subheader={<ListSubheader>Templates</ListSubheader>}>
        {templateFeeSchedules.length === 0 && (
          <>
            {isLoading ? (
              <TemplateLoader size={32} thickness={4} />
            ) : (
              <ListItem key="noTemplates">
                <ListItemText>No Templates</ListItemText>
              </ListItem>
            )}
          </>
        )}
        {templateFeeSchedules.map((template: FeeScheduleTemplate) => {
          const isSelected = selectedTemplateIds.some((id) =>
            id.isEqualTo(template.id)
          );
          const feeScheduleCategories = template.categories.map(
            (templateCategory) =>
              FeeScheduleCategory.Prototype.fromObject(templateCategory)
          );
          const indeterminate =
            !isSelected &&
            feeScheduleCategories.some(
              (category) => category && getIsCategoryAlreadySelected(category)
            );

          return (
            <div key={template.id?.toString()}>
              <ListItem>
                <ListItemIcon>
                  <Tooltip
                    title={
                      !isSelected ? "Select Template" : "Deselect Template"
                    }
                  >
                    <span>
                      <Checkbox
                        edge="start"
                        color="primary"
                        checked={isSelected}
                        indeterminate={indeterminate}
                        tabIndex={-1}
                        disableRipple
                        onChange={() =>
                          handleTemplateSelectionToggled(template)
                        }
                      />
                    </span>
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  primary={template.name}
                  secondary={template.tags.map((tag: FeeScheduleTag) => (
                    <FeeScheduleTagChip
                      key={tag as string}
                      size="small"
                      label={tag}
                    />
                  ))}
                />
                <ListItemSecondaryAction>
                  <Tooltip
                    title={`${
                      template.isExpanded ? "Hide" : "Show"
                    } Categories`}
                  >
                    <span>
                      <IconButton
                        edge="end"
                        onClick={() => handleTemplateExpanded(template)}
                        size="medium"
                      >
                        {template.isExpanded ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                      </IconButton>
                    </span>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
              <Collapse
                in={template.isExpanded}
                timeout="auto"
                style={{ backgroundColor: "#FAFAFA" }}
              >
                <Divider />
                <List disablePadding dense>
                  {renderTemplateCategories(template)}
                </List>
                <Divider />
              </Collapse>
            </div>
          );
        })}
      </TemplateList>
    );
  }

  function renderTemplateCategories(template: FeeScheduleTemplate) {
    return template.categories.map((category: FeeScheduleCategory) => (
      <ListItemButton
        key={category.name.valueOf()}
        onClick={(event) => handleTemplateCategoryToggled(event, category)}
      >
        <ListItemText
          disableTypography
          primary={category.name.valueOf()}
          secondary={
            <>
              <div>{category.description?.valueOf()}</div>
              <div>{category.feeString}</div>
            </>
          }
        />
        <ListItemSecondaryAction>
          <Checkbox
            edge="end"
            color="primary"
            checked={getIsCategoryAlreadySelected(
              FeeScheduleCategory.Prototype.fromObject(category)
            )}
            tabIndex={-1}
            disableRipple
            onClick={(event) => handleTemplateCategoryToggled(event, category)}
          />
        </ListItemSecondaryAction>
      </ListItemButton>
    ));
  }

  if (!isOpen) return null;

  return (
    <ListContainer container direction="row">
      {!disableEditing && (
        <>
          <TemplateContainer item>
            {renderTemplates()}
            {isLargeDownDisplay && <Divider />}
          </TemplateContainer>
          {!isLargeDownDisplay && (
            <Grid item>
              <Divider orientation="vertical" />
            </Grid>
          )}
        </>
      )}
      <Grid item xs>
        <RedlineCategorySelection
          activeReviewField={activeReviewField}
          commentForums={commentForums}
          disableEditing={disableEditing}
          feeScheduleRedline={proposalRedline.feeSchedule}
          onCommentsClicked={(categoryId?: Guid, name?: string) => {
            onCommentsClicked(ProposalFieldName.FeeSchedule, categoryId, name);
          }}
          onFeeScheduleRedlineChange={(
            newFeeScheduleRedline: FeeScheduleRedline, 
            traversalFieldOverride?: ProposalField
          ) => {
            try {
            onProposalRedlineChange(
              proposalRedline.updateFeeScheduleRedline(newFeeScheduleRedline),
              traversalFieldOverride
              );
            } catch (error) {
              console.error(error);
              enqueueSnackbar("Failed to update fee schedule. Please try again", {
                variant: "error",
              });
            }
          }}
          onCategoryClicked={(categoryId: Guid) => {
            onTraverseToNewField?.({ 
              name: ProposalFieldName.FeeSchedule, 
              id: categoryId 
            });
          }}
        />
      </Grid>
    </ListContainer>
  );
};

export default FeeScheduleTab;
