import MaterialTable, { Action, Column } from "@material-table/core";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import CommentIcon from "@mui/icons-material/Comment";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import SnippetFolderIcon from "@mui/icons-material/Folder";
import { Badge, Box, Button, Icon, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  ConfirmResponse,
  useConfirmDialog,
} from "app/providers/confirm-dialog";
import { useDialog } from "app/providers/dialog";
import Guid from "common/values/guid/guid";
import _ from "lodash";
import Forum from "messaging/entities/forum/forum";
import { useEffect, useState } from "react";
import { useSession } from "users/session/session-context";
import FeeScheduleTemplateAPIService from "work/entities/fee-schedule-template/api/fee-schedule-template-api-service";
import FeeScheduleTemplate from "work/entities/fee-schedule-template/fee-schedule-template";
import { getForumForField } from "work/entities/proposal/utils/comment-utils";
import { ProposalFieldName } from "work/values/constants";
import FeeScheduleCategoryName from "work/values/fee-schedule-category-name/fee-schedule-category-name";
import FeeScheduleCategory from "work/values/fee-schedule-category/fee-schedule-category";
import FeeScheduleCategoryForm from "work/values/fee-schedule-category/view/fee-schedule-category-form";
import FeeScheduleExistingCategories from "work/values/fee-schedule-category/view/fee-schedule-existing-categories";
import FeeScheduleExistingTemplate from "work/values/fee-schedule-category/view/fee-schedule-existing-template";

const FeeCategoryButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));
const TableContainer = styled(Box)(({ theme }) => ({
  border: "1px solid #eee",
  marginTop: theme.spacing(2),
}));

type FeeScheduleCategoriesProps = {
  className?: string;
  categories: Array<FeeScheduleCategory>;
  viewOnly?: boolean;
  hideFromTemplateButton?: boolean;
  hideAddButton?: boolean;
  hideExistingButton?: boolean;
  disableCommenting?: boolean;
  commentForums?: Forum[];
  onCommentsClicked?: (categoryId?: Guid, name?: string) => void;
  onCategoryAdded?: (
    category: FeeScheduleCategory 
  ) => void;
  onCategoryUpdated?: (
    originalName: FeeScheduleCategoryName | null,
    updatedCategory: FeeScheduleCategory 
  ) => void;
  onCategoryRemoved?: (name: FeeScheduleCategoryName) => void;
  onExistingTemplateSelected?: (
    template: FeeScheduleTemplate,
    replace: boolean
  ) => void;
};

export default function FeeScheduleCategories(
  props: Readonly<FeeScheduleCategoriesProps>
) {
  const {
    className,
    categories,
    viewOnly,
    hideFromTemplateButton,
    hideAddButton,
    hideExistingButton,
    disableCommenting,
    commentForums,
    onCommentsClicked,
    onCategoryAdded,
    onCategoryUpdated,
    onCategoryRemoved,
    onExistingTemplateSelected,
  } = props;

  const [existingTemplates, setExistingTemplates] = useState<
    FeeScheduleTemplate[]
  >([]);
  const [existingCategories, setExistingCategories] = useState<
    FeeScheduleCategory[]
  >([]);

  const session = useSession();
  const confirm = useConfirmDialog();
  const { openDialog, closeDialog } = useDialog();

  useEffect(() => {
    getExistingTemplatesAndCategories();
  }, []);

  async function getExistingTemplatesAndCategories() {
    if (!session.user?.isCompanyManager) return;
    try {
      const existingCategories: FeeScheduleCategory[] = [];
      const entityId = session.currentEntity.entityId;
      const accountType = session.accountType;
      const feeScheduleService = new FeeScheduleTemplateAPIService(session);
      const abortController = new AbortController();
      const templates = await feeScheduleService.getFeeScheduleTemplates(
        entityId,
        accountType,
        abortController
      );

      templates.forEach((template) => {
        template.categories.forEach((category) => {
          existingCategories.push(category);
        });
      });

      setExistingTemplates(templates);
      setExistingCategories(existingCategories);
    } catch (error) {
      console.error(error);
    }
  }

  const columns: Column<FeeScheduleCategory>[] = [
    {
      field: "name",
      title: "Name",
      render: (rowData: FeeScheduleCategory) => (
        <strong>{rowData.name.valueOf()}</strong>
      ),
    },
    {
      field: "description",
      title: "Description",
      render: (rowData: FeeScheduleCategory) =>
        rowData.description?.valueOf() ?? "",
    },
    {
      field: "fixedFee",
      title: "Fixed?",
      type: "boolean",
      render: (rowData: FeeScheduleCategory) => 
        !rowData?.fee?.unit?.valueOf() ? "Yes" : "No"
    },
    {
      field: "fee.rate",
      title: "Fee",
      type: "currency",
      render: (rowData: FeeScheduleCategory) =>
        !rowData.fee
          ? `Deferred to ${
              session.context?.viewingAsVendor ? "client" : "vendor"
            }`
          : new Intl.NumberFormat(navigator.language, {
              style: "currency",
              currency: rowData.fee?.rate?.currency.toString() ?? "USD",
            }).format(rowData.fee?.rate?.amount.valueOf() ?? 0),
    },
    {
      field: "fee.unit",
      title: "Per",
      emptyValue: <Icon>remove</Icon>,
      render: (rowData: FeeScheduleCategory) =>
        rowData.fee?.unit?.valueOf() ?? <Icon>remove</Icon>
    },
    {
      field: "billingCode",
      title: "Code",
      emptyValue: <Icon>remove</Icon>,
      render: (rowData: FeeScheduleCategory) =>
        rowData.billingCode?.valueOf() && rowData.billingCode.valueOf().length > 0 ? (
          rowData.billingCode.valueOf()
        ) : (
          <Icon>remove</Icon>
        ),
    },
  ];

  const actions: (
    | Action<FeeScheduleCategory>
    | ((
        rowData: FeeScheduleCategory 
      ) => Action<FeeScheduleCategory>)
  )[] = [
    {
      icon: () => <CloseIcon color="error" />,
      tooltip: "Remove Category",
      hidden: viewOnly,
      onClick: (event, rowData) => {
        if (_.isArray(rowData)) return;
        handleRemoveButtonClicked(event, rowData.name);
      },
    },
    (
      category: FeeScheduleCategory 
    ): Action<FeeScheduleCategory> => {
      return {
        icon: () => (
          <Badge
            variant="dot"
            color="secondary"
            overlap="circular"
            invisible={
              !category.id ||
              !getForumForField(
                ProposalFieldName.FeeSchedule,
                category.id,
                commentForums
              )
            }
          >
            <CommentIcon fontSize="medium" />
          </Badge>
        ),
        disabled: disableCommenting,
        tooltip: `${category.id ? "Comments" : "Save to add comments"}`,
        isFreeAction: false,
        onClick: (event, rowData) => {
          const category = rowData as FeeScheduleCategory;
          event.stopPropagation();
          if (!category.id) return;
          if (_.isArray(rowData)) return;
          onCommentsClicked?.(
            category.id ?? undefined,
            category.name?.valueOf() ?? ""
          );
        },
      };
    },
    {
      icon: () => (
        <Badge
          variant="dot"
          color="secondary"
          overlap="circular"
          invisible={
            !getForumForField(
              ProposalFieldName.FeeSchedule,
              undefined,
              commentForums
            )
          }
        >
          <CommentIcon fontSize="medium" />
        </Badge>
      ),
      disabled: disableCommenting,
      tooltip: `${disableCommenting ? "Save to add comments" : "Comments"}`,
      isFreeAction: true,
      onClick: (event) => {
        event.stopPropagation();
        onCommentsClicked?.();
      },
    },
  ];

  /**
   * Handles the delete button click for a category
   * @param _event The event triggered by the delete button click
   * @param name The name of the category to delete
   */
  async function handleRemoveButtonClicked(
    _event: MouseEvent,
    name: FeeScheduleCategoryName | null
  ) {
    if (name == null) return;
    const feeScheduleCategory = categories.find(
      (category: FeeScheduleCategory) =>
        category.name === name
    );

    if (!feeScheduleCategory) return;

    const response = await confirm({
      title: "Delete Fee Schedule Category?",
      message: `Delete the ${feeScheduleCategory.name} fee schedule category?`,
      okButtonText: "Delete",
      cancelButtonText: "Cancel",
    });

    if (response === ConfirmResponse.Cancel) return;

    onCategoryRemoved?.(name);
  }

  /**
   * Handles when a category is added
   */
  function handleCategoryAdded(
    category: FeeScheduleCategory 
  ) {
    onCategoryAdded?.(category);
  }

  /**
   * Handles when the add button is clicked
   */
  function beginAddCategory() {
    openDialog({
      title: "Create Fee Schedule Category",
      component: (
        <FeeScheduleCategoryForm
          onCategoryAdded={handleCategoryAdded}
          closeDialog={closeDialog}
        />
      ),
    });
  }

  function beginEditCategory(
    category:
      | FeeScheduleCategory
      | undefined
      | null
  ) {
    if (!category || viewOnly) return;

    openDialog({
      title: "Edit Fee Schedule Category",
      component: (
        <FeeScheduleCategoryForm
          category={category}
          onCategoryUpdated={onCategoryUpdated}
          closeDialog={closeDialog}
        />
      ),
    });
  }

  function handleExistingCategoryClicked() {
    openDialog({
      title: "Add Existing Fee Schedule Category",
      component: (
        <FeeScheduleExistingCategories
          categories={existingCategories}
          disabledCategories={categories}
          onCategoriesSelected={handleExistingCategoriesSelected}
        />
      )
    });
  }

  function handleExistingTemplateClicked() {
    openDialog({
      title: "Select Existing Fee Schedule Template",
      component: (
        <FeeScheduleExistingTemplate
          onTemplateSelected={handleExistingTemplateSelected}
        />
      ),
    });
  }

  async function handleExistingTemplateSelected(template: FeeScheduleTemplate) {
    let replace: boolean = false;

    if (categories.length > 0) {
      const response = await confirm({
        title: "Replace Or Add Categories?",
        message: `Do you want to replace the current categories with the selected template's categories or add the selected template's categories to the current categories?`,
        okButtonText: "Replace",
        alternativeOkText: "Add",
        cancelButtonText: "Cancel",
      });

      if (response === ConfirmResponse.Cancel) return;

      replace = response === ConfirmResponse.Ok;
    }

    closeDialog();
    onExistingTemplateSelected?.(template, replace);
  }

  function handleExistingCategoriesSelected(
    categories: FeeScheduleCategory[]
  ) {
    closeDialog();
    categories.forEach((category) => {
      onCategoryAdded?.(category);
    });
  }

  function getTableContainer(props: any) {
    return <TableContainer {...props} />;
  }

  return (
    <div className={className}>
      {!viewOnly && !hideAddButton && (
        <FeeCategoryButton
          startIcon={<AddIcon />}
          onClick={beginAddCategory}
          variant="outlined"
        >
          Add New Category
        </FeeCategoryButton>
      )}
      {!viewOnly && !hideExistingButton && (
        <FeeCategoryButton
          startIcon={<SnippetFolderIcon />}
          disabled={existingCategories.length === 0}
          onClick={handleExistingCategoryClicked}
          variant="outlined"
        >
          Add Existing Category
        </FeeCategoryButton>
      )}
      {!viewOnly && !hideFromTemplateButton && (
        <Button
          startIcon={<FileCopyIcon />}
          disabled={existingTemplates.length === 0}
          onClick={handleExistingTemplateClicked}
          variant="outlined"
        >
          From Existing Template
        </Button>
      )}
      <MaterialTable
        columns={columns}
        actions={actions}
        data={categories}
        options={{
          showTitle: false,
          selection: false,
          search: false,
          actionsColumnIndex: -1,
        }}
        components={{
          Container: (props) => getTableContainer(props),
        }}
        localization={{
          header: {
            actions: "",
          },
          body: {
            emptyDataSourceMessage: (
              <Typography>There aren't any fee schedule categories</Typography>
            ),
          },
        }}
        onRowClick={
          !viewOnly
            ? (_event, rowData) => beginEditCategory(rowData)
            : undefined
        }
      />
    </div>
  );
}
