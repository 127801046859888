import Guid from "common/values/guid/guid";
import EntityClientRepresentative from "work/entities/entity-client-representative/entity-client-representative";
import Proposal, { CompleteProposalSpec } from "work/entities/proposal/proposal";
import WorkAgreement from "work/entities/work-agreement/work-agreement";
import FeeScheduleCategory from "work/values/fee-schedule-category/fee-schedule-category";
import ProjectDescription from "work/values/project-description/project-description";
import ProjectName from "work/values/project-name/project-name";
import ProposalReviewer from "work/values/proposal-reviewer";
import Team from "work/values/team/team";
import ProposalFactory from "./proposal-factory";
import _ from "lodash";
import Session from "users/session/session";
import WorkDocument from "work/values/work-document/work-document";
import Percent from "common/values/percent/percent";
import Date from "common/values/date/date";
import AHBoolean from "common/values/boolean/boolean";

export class ProposalSpec {
  client?: EntityClientRepresentative;
  name?: ProjectName;
  description?: ProjectDescription;
  workAgreement?: WorkAgreement;
  negotiable?: boolean;
  responseDueBy?: Date;
  startDate?: Date;
  endDate?: Date;
  supersededBy?: Proposal;
  supersedesId?: Guid;
  clientReviewers?: ProposalReviewer[];
  vendorReviewers?: ProposalReviewer[];

  clone(): ProposalSpec {
    const clone = new ProposalSpec();
    clone.client = this.client?.clone();
    clone.name = this.name?.clone();
    clone.description = this.description?.clone();
    clone.workAgreement = this.workAgreement?.clone();
    clone.negotiable = this.negotiable;
    clone.responseDueBy = this.responseDueBy?.clone();
    clone.startDate = this.startDate?.clone();
    clone.endDate = this.endDate?.clone();
    clone.supersededBy = this.supersededBy;
    clone.supersedesId = this.supersedesId?.clone();
    clone.clientReviewers = this.clientReviewers?.concat();
    clone.vendorReviewers = this.vendorReviewers?.concat();
    return clone;
  }
}

export default class ProposalBuilder {
  private _specStack: ProposalSpec[] = [new ProposalSpec()];
  private _currentIndex: number = 0;

  constructor(startingSpec?: CompleteProposalSpec) {
    if (startingSpec) {
      const spec = new ProposalSpec();
      spec.client = startingSpec.client;
      spec.name = startingSpec.name;
      spec.description = startingSpec.description;
      spec.workAgreement = startingSpec.workAgreement;
      spec.negotiable = startingSpec.negotiable;
      spec.responseDueBy = startingSpec.responseDueBy;
      spec.startDate = startingSpec.workAgreement?.startDate;
      spec.endDate = startingSpec.workAgreement?.endDate;
      spec.clientReviewers = startingSpec.clientReviewers;
      spec.vendorReviewers = startingSpec.vendorReviewers;
      this._specStack = [spec];
      this._currentIndex = 0;
    }
  }

  public get currentSpec(): ProposalSpec {
    if (this._currentIndex >= this._specStack.length || this._currentIndex < 0) throw new Error("Invalid index")
    return this._specStack[this._currentIndex];
  }
  public get canUndo(): boolean {
    return this._currentIndex > 0;
  }

  public get canRedo(): boolean {
    return this._currentIndex < (this._specStack.length - 1);
  }

  public get canBuild(): boolean {
    return !!this.currentSpec.client &&
      !!this.currentSpec.name?.valueOf().length &&
      !!this.currentSpec.description?.valueOf().length
  }

  public get canSubmit(): boolean {
    return this.canBuild && !!this.currentSpec.workAgreement?.team;
  }

  private resetStack() {
    if (this._currentIndex < (this._specStack.length - 1)) {
      this._specStack.push(this._specStack[this._currentIndex]);
    }
    this._currentIndex = this._specStack.length - 1;
  }

  public undo() {
    if (!this.canUndo) return;
    this._currentIndex--;
  }

  public redo() {
    if (!this.canRedo) return;
    this._currentIndex++;
  }

  public loadSpec(spec: ProposalSpec) {
    this._specStack = [spec];
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setClient(client?: EntityClientRepresentative) {
    this.resetStack();
    const newSpec = this.currentSpec.clone();
    newSpec.client = client;
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setName(name: ProjectName) {
    this.resetStack();
    const newSpec = this.currentSpec.clone();
    newSpec.name = name;
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setDescription(description: ProjectDescription) {
    this.resetStack();
    const newSpec = this.currentSpec.clone();
    newSpec.description = description;
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setNegotiable(negotiable: boolean) {
    this.resetStack();
    const newSpec = this.currentSpec.clone();
    newSpec.negotiable = negotiable;
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setResponseDueBy(responseDueBy: Date | undefined) {
    this.resetStack();
    const newSpec = this.currentSpec.clone();
    newSpec.responseDueBy = responseDueBy;
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setStartDate(startDate: Date | undefined) {
    if (!this.currentSpec.name || !this.currentSpec.client) {
      throw new Error("Name and client are required to create a work agreement");
    }

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    if (!newSpec.workAgreement) {
      newSpec.workAgreement = new WorkAgreement(
        this.currentSpec.name,
        this.currentSpec.client
      );
    }
    newSpec.workAgreement.startDate = startDate;
    newSpec.startDate = startDate;
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setEndDate(endDate: Date | undefined) {
    if (!this.currentSpec.name || !this.currentSpec.client) {
      throw new Error("Name and client are required to create a work agreement");
    }

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    if (!newSpec.workAgreement) {
      newSpec.workAgreement = new WorkAgreement(
        this.currentSpec.name,
        this.currentSpec.client
      );
    }
    newSpec.workAgreement.endDate = endDate;
    newSpec.endDate = endDate;
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setTeam(team: Team | undefined) {
    if (!this.currentSpec.name || !this.currentSpec.client) {
      throw new Error("Name and client are required to create a work agreement");
    }

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    if (!newSpec.workAgreement) {
      newSpec.workAgreement = new WorkAgreement(
        this.currentSpec.name,
        this.currentSpec.client
      );
    }
    newSpec.workAgreement.team = team;
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setFeeSchedule(categories: FeeScheduleCategory[] | undefined) {
    if (!this.currentSpec.name || !this.currentSpec.client) {
      throw new Error("Name and client are required to create a work agreement");
    }

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    if (!newSpec.workAgreement) {
      newSpec.workAgreement = new WorkAgreement(
        this.currentSpec.name,
        this.currentSpec.client
      );
    }
    newSpec.workAgreement.feeSchedule = categories;
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setDiscount(percentage: Percent) {
    if (!this.currentSpec.name || !this.currentSpec.client) {
      throw new Error("Name and client are required to create a work agreement");
    }

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    if (!newSpec.workAgreement) {
      newSpec.workAgreement = new WorkAgreement(
        this.currentSpec.name,
        this.currentSpec.client
      );
    }
    newSpec.workAgreement.discount = percentage ?? 0;
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setConflictsDocuments(documents: WorkDocument[]) {
    if (!this.currentSpec.name || !this.currentSpec.client) {
      throw new Error("Name and client are required to create a work agreement");
    }

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    if (!newSpec.workAgreement) {
      newSpec.workAgreement = new WorkAgreement(
        this.currentSpec.name,
        this.currentSpec.client
      );
    }
    newSpec.workAgreement.conflictsDocuments = _.uniqBy(documents, (id) => id.valueOf());
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setClientPolicyDocuments(documents: WorkDocument[]) {
    if (!this.currentSpec.name || !this.currentSpec.client) {
      throw new Error("Name and client are required to create a work agreement");
    }

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    if (!newSpec.workAgreement) {
      newSpec.workAgreement = new WorkAgreement(
        this.currentSpec.name,
        this.currentSpec.client
      );
    }
    newSpec.workAgreement.clientPolicyDocuments = _.uniqBy(documents, (id) => id.valueOf());
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setVendorPolicyDocuments(documents: WorkDocument[]) {
    if (!this.currentSpec.name || !this.currentSpec.client) {
      throw new Error("Name and client are required to create a work agreement");
    }

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    if (!newSpec.workAgreement) {
      newSpec.workAgreement = new WorkAgreement(
        this.currentSpec.name,
        this.currentSpec.client
      );
    }
    newSpec.workAgreement.vendorPolicyDocuments = _.uniqBy(documents, (id) => id.valueOf());
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setClientTeamTemplateIds(templateIds: Guid[]) {
    if (!this.currentSpec.name || !this.currentSpec.client) {
      throw new Error("Name and client are required to create a work agreement");
    }

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    if (!newSpec.workAgreement) {
      newSpec.workAgreement = new WorkAgreement(
        this.currentSpec.name,
        this.currentSpec.client
      );
    }
    newSpec.workAgreement.clientTeamTemplateIds = _.uniqBy(templateIds, (id) => id.valueOf());
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }
  public setVendorTeamTemplateIds(templateIds: Guid[]) {
    if (!this.currentSpec.name || !this.currentSpec.client) {
      throw new Error("Name and client are required to create a work agreement");
    }

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    if (!newSpec.workAgreement) {
      newSpec.workAgreement = new WorkAgreement(
        this.currentSpec.name,
        this.currentSpec.client
      );
    }
    newSpec.workAgreement.vendorTeamTemplateIds = _.uniqBy(templateIds, (id) => id.valueOf());
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setClientFeeScheduleTemplateIds(templateIds: Guid[]) {
    if (!this.currentSpec.name || !this.currentSpec.client) {
      throw new Error("Name and client are required to create a work agreement");
    }

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    if (!newSpec.workAgreement) {
      newSpec.workAgreement = new WorkAgreement(
        this.currentSpec.name,
        this.currentSpec.client
      );
    }
    newSpec.workAgreement.clientFeeScheduleTemplateIds = _.uniqBy(templateIds, (id) => id.valueOf());
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }
  public setVendorFeeScheduleTemplateIds(templateIds: Guid[]) {
    if (!this.currentSpec.name || !this.currentSpec.client) {
      throw new Error("Name and client are required to create a work agreement");
    }

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    if (!newSpec.workAgreement) {
      newSpec.workAgreement = new WorkAgreement(
        this.currentSpec.name,
        this.currentSpec.client
      );
    }
    newSpec.workAgreement.vendorFeeScheduleTemplateIds = _.uniqBy(templateIds, (id) => id.valueOf());
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setConflictsCheckWaived(waived: AHBoolean) {
    if (!this.currentSpec.name || !this.currentSpec.client) {
      throw new Error("Name and client are required to create a work agreement");
    }

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    if (!newSpec.workAgreement) {
      newSpec.workAgreement = new WorkAgreement(
        this.currentSpec.name,
        this.currentSpec.client
      );
    }
    newSpec.workAgreement.conflictsCheckWaived = waived;
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setClientReviewers(reviewers: ProposalReviewer[]) {
    if (!this.currentSpec.name || !this.currentSpec.client) {
      throw new Error("Name and client are required to create a work agreement");
    }

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    if (!newSpec.workAgreement) {
      newSpec.workAgreement = new WorkAgreement(
        this.currentSpec.name,
        this.currentSpec.client
      );
    }
    newSpec.clientReviewers = reviewers;
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setVendorReviewers(reviewers: ProposalReviewer[]) {
    if (!this.currentSpec.name || !this.currentSpec.client) {
      throw new Error("Name and client are required to create a work agreement");
    }

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    if (!newSpec.workAgreement) {
      newSpec.workAgreement = new WorkAgreement(
        this.currentSpec.name,
        this.currentSpec.client
      );
    }
    newSpec.vendorReviewers = reviewers;
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public buildDraft(session: Readonly<Session>): Proposal {
    return ProposalFactory.createProposal(this.completedSpec, session);
  }

  public updateProposal(oldProposal: Proposal, session: Readonly<Session>): Proposal {
    return ProposalFactory.updateProposal(oldProposal, this.completedSpec, session);
  }

  public clone(): ProposalBuilder {
    const clone = new ProposalBuilder();
    clone._specStack = this._specStack.map(spec => spec.clone());
    clone._currentIndex = this._currentIndex;
    return clone;
  }

  private get completedSpec(): CompleteProposalSpec {
    if (!this.currentSpec.client) throw new Error("Client is required to build a proposal");
    if (!this.currentSpec.name) throw new Error("Name is required to build a proposal");
    if (!this.currentSpec.description) throw new Error("Description is required to build a proposal");

    const completedSpec: CompleteProposalSpec = {
      name: this.currentSpec.name,
      client: this.currentSpec.client,
      description: this.currentSpec.description,
      workAgreement: this.currentSpec.workAgreement,
      negotiable: this.currentSpec.negotiable ?? true,
      responseDueBy: this.currentSpec.responseDueBy,
      startDate: this.currentSpec.startDate,
      endDate: this.currentSpec.endDate,
      clientReviewers: this.currentSpec.clientReviewers ?? [],
      vendorReviewers: this.currentSpec.vendorReviewers ?? []
    }

    return completedSpec;
  }
}
