import MaterialTable, { MaterialTableProps } from '@material-table/core';
import GroupsIcon from '@mui/icons-material/Groups';
import {
  Tab,
  Tabs, Theme, useMediaQuery
} from '@mui/material';
import { ConfirmResponse, useConfirmDialog } from 'app/providers/confirm-dialog';
import { useDialog } from 'app/providers/dialog';
import TableToolbarComponent from 'app/routes/teams/table/toolbar';
import TableContainerComponent from 'common/components/table/container';
import TableTitle from 'common/components/table/title';
import { PageContainer, TableTheme } from 'common/styles';
import { AccountType } from 'common/values/account-type/account-type';
import Guid from 'common/values/guid/guid';
import _ from 'lodash';
import Company from 'marketplace/entities/company/company';
import Individual from 'marketplace/entities/individual/individual';
import ViewCompanyProfile from 'marketplace/values/company-profile/view/view-company-profile';
import ViewIndividualProfile from 'marketplace/values/individual-profile/view/view-individual-profile';
import { enqueueSnackbar } from 'notistack';
import * as React from 'react';
import { useEffect } from 'react';
import { Params, useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import { useSession } from 'users/session/session-context';
import TeamTemplateAPIService from 'work/entities/team-template/api/team-template-service';
import TeamTemplate from 'work/entities/team-template/team-template';
import TeamTemplateForm from 'work/entities/team-template/view/team-template-form';
import { TeamMember } from 'work/values/team/team-member';
import { getTableActions, TableActionsComponent } from './table/actions';
import { getTableColumns } from './table/columns';
import loadData from './table/data-loader';
import { NoRowsPlaceholder } from './table/no-rows-placeholder';
import { PageTab } from './tabs';

type TeamProps = {};

export default function Teams(props: Readonly<TeamProps>) {
  const tableRef = React.useRef<MaterialTable<TeamTemplate> & MaterialTableProps<TeamTemplate>>();
  const isDirtyRef = React.useRef<boolean>(false);

  const [loading, setLoading] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(PageTab.Templates);
  const [tableBodyHeight, setTableBodyHeight] = React.useState(0);
  const [teamTemplates, setTeamTemplates] = React.useState<TeamTemplate[]>([]);

  const routeParams = useLoaderData() as Params<string>;
  const location = useLocation();
  const navigate = useNavigate();
  const session = useSession();
  const { openDialog, closeDialog, closeAllDialogs } = useDialog();
  const confirm = useConfirmDialog();
  const isMediumDisplaySize = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const tabs = (
    <Tabs
      value={activeTab}
      indicatorColor="primary"
      textColor="primary"
      onChange={(_event, newValue) => navigate(`/teams/${newValue}/`)}
    >
      <Tab value='templates' label='Templates' />
      <Tab value='teams' label={session.accountType === AccountType.Client ? "Teams Working For Me" : "Teams I'm On"} />
      <Tab value='archived' label="Archived" />
    </Tabs>
  );

  // Close any open dialogs when the URL changes
  useEffect(() => {
    closeAllDialogs();
  }, [location]);

  // Handle account type change
  useEffect(() => {
    tableRef?.current?.onQueryChange({});
  }, [session.accountType]);

  // Handle URL changes
  useEffect(() => {
    if (routeParams.tab) {
      setActiveTab(routeParams.tab as PageTab);
    } else {
      navigate(`/teams/${PageTab.Templates}`);
    }

    if (routeParams.action === 'create') {
      beginCreateTeamTemplate();
      return;
    }

    if (routeParams.tab === PageTab.Templates && routeParams.id) {
      handleEditTeamTemplate(new Guid(routeParams.id));
    }
  }, [routeParams]);

  // Handle component mount
  useEffect(() => {
    function computeTableHeight() {
      const tableHead = document.querySelector('thead.MuiTableHead-root');
      const tableFoot = document.querySelector('tfoot.MuiTableFooter-root');
      const siteFooter = document.querySelector('footer#siteFooter');

      const tableOffsetTop = tableHead?.getBoundingClientRect().top;
      const tableFootHeight = tableFoot?.getBoundingClientRect().height;
      const siteFooterHeight = siteFooter?.getBoundingClientRect().height;

      if (!tableOffsetTop || !tableFootHeight || !siteFooterHeight) return;

      setTableBodyHeight(Math.floor(window.innerHeight - tableOffsetTop - tableFootHeight - siteFooterHeight));
    }

    const debouncedResize = _.debounce(() => computeTableHeight(), 250);
    debouncedResize();
    window.addEventListener('resize', debouncedResize);

    return () => {
      window.removeEventListener('resize', debouncedResize);
    }
  }, []);

  function handleTemplatesLoaded(templates: TeamTemplate[]) {
    setTeamTemplates(templates);
  }

  function beginCreateTeamTemplate() {
    const parentPath = new URL(".", window.location.origin + window.location.pathname);

    openDialog(
      {
        title: "Create Template Team",
        MuiProps: { fullWidth: false, maxWidth: "lg" },
        contentSxProps: { display: "flex" },
        component: (
          <TeamTemplateForm
            leader={null}
            members={[]}
            navigate={navigate}
            onFormDirty={() => { isDirtyRef.current = true }}
            onSave={() => {
              closeDialog();
              navigate(parentPath.pathname);
              tableRef?.current?.onQueryChange({});
            }}
          />
        ),
      },
      () => navigate(parentPath.pathname),
      () => isDirtyRef.current
    );
  }

  function handleEditTeamTemplate(id: Guid) {
    const template = teamTemplates.find(template => template.id?.isEqualTo(id));

    if (!template) {
      enqueueSnackbar('Team template not found', { variant: 'error' });
      return;
    }

    const parentPath = new URL(".", window.location.origin + window.location.pathname);

    openDialog(
      {
        title: "Edit Template Team",
        MuiProps: { fullWidth: false, maxWidth: "lg" },
        contentSxProps: { display: "flex" },
        component: (
          <TeamTemplateForm
            template={template}
            name={template.name.valueOf()}
            leader={constructTeamMemberFromIndividual(template.leader) ?? null}
            members={constructTeamMembersFromIndividuals(template.members)}
            navigate={navigate}
            onFormDirty={() => { isDirtyRef.current = true }}
            onSave={() => {
              closeDialog();
              navigate(parentPath.pathname);
              tableRef?.current?.onQueryChange({});
            }}
          />
        ),
      },
      () => navigate(parentPath.pathname),
      () => isDirtyRef.current
    );
  }

  async function handleDeleteTeamTemplate(id: Guid) {
    try {
      const response = await confirm({
        title: 'Delete?',
        message: `Delete team template?`,
        okButtonText: 'Delete',
        cancelButtonText: 'Cancel'
      });

      if (response === ConfirmResponse.Cancel) return;

      const teamTemplateService = new TeamTemplateAPIService(session);
      await teamTemplateService.deleteTeamTemplate(id);
      enqueueSnackbar('Deleted team template', { variant: 'success' });
      tableRef?.current?.onQueryChange();  // Force table data refresh
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Failed to delete team template. Please try again', { variant: 'error' });
    }
  }

  function handleViewCompanyProfile(company: Company) {
    openDialog(
      {
        component: (
          <ViewCompanyProfile
            companyId={company?.id}
            confirmDialog={confirm}
          />
        ),
        titleStyle: {
          position: 'absolute',
          right: 0,
          top: 0,
        },
        MuiProps: {
          maxWidth: 'md',
          fullWidth: true,
          style: {
            overflowX: 'hidden',
            paddingBottom: 0
          }
        },
        onClose: closeDialog,
      }
    );
  }

  function handleViewIndividualProfile(individual: Individual) {
    openDialog(
      {
        component: <ViewIndividualProfile individualId={individual.id} />,
        contentSxProps: {
          display: "flex",
        },
        MuiProps: {
          fullWidth: true,
          maxWidth: "lg",
        },
        titleStyle: {
          position: "absolute",
          right: 0,
          top: 0,
        },
        onClose: closeDialog,
      }
    );
  }

  function constructTeamMemberFromIndividual(individual: Individual): TeamMember | undefined {
    if (!individual.profile) return;

    return {
      individualId: individual.id,
      userId: individual.userId,
      firstName: individual.profile.firstName,
      lastName: individual.profile.lastName,
      entityId: individual.company?.entityId,
    } as TeamMember;
  }

  function constructTeamMembersFromIndividuals(members: Individual[]): TeamMember[] {
    const teamMembers: TeamMember[] = [];

    for (let member of members) {
      const teamMember = constructTeamMemberFromIndividual(member);
      if (teamMember) teamMembers.push(teamMember);
    }

    return teamMembers;
  }

  return (
    <PageContainer>
      <MaterialTable
        key={activeTab}
        tableRef={tableRef}
        title={
          <TableTitle title={'Teams'} icon={<GroupsIcon />} />
        }
        columns={
          getTableColumns(
            activeTab,
            (id) => navigate(`/teams/templates/edit/${id.toString()}`),
            handleDeleteTeamTemplate,
            handleViewCompanyProfile,
            handleViewIndividualProfile
          )
        }
        data={(query) => loadData(query, activeTab, session, setLoading, handleTemplatesLoaded)}
        actions={getTableActions({ isMediumDisplaySize, navigate })}
        isLoading={loading}
        options={{
          debounceInterval: 250,
          emptyRowsWhenPaging: false,
          filtering: true,
          filterRowStyle: TableTheme.filterRow,
          grouping: true,
          minBodyHeight: tableBodyHeight,
          maxBodyHeight: tableBodyHeight,
          pageSize: 10,
          pageSizeOptions: [10, 25, 50],
          paginationType: isMediumDisplaySize ? 'normal' : 'stepped',
          showFirstLastPageButtons: !isMediumDisplaySize
        }}
        components={{
          Container: props => TableContainerComponent({ ...props }),
          Actions: props => TableActionsComponent({
            tableRef,
            isMediumDisplaySize,
            ...props
          }),
          Toolbar: props => TableToolbarComponent({
            tableRef,
            activeTab,
            tabs,
            isMediumDisplaySize,
            ...props
          })
        }}
        localization={{
          body: {
            emptyDataSourceMessage: <NoRowsPlaceholder tableBodyHeight={tableBodyHeight} />
          }
        }}
      />
    </PageContainer>
  );
}
