import MaterialTable, {
  MaterialTableProps,
  RowAction
} from "@material-table/core";
import InboxIcon from "@mui/icons-material/Inbox";
import { Tab, Tabs, Theme, useMediaQuery } from "@mui/material";
import { useDialog } from "app/providers/dialog";
import { loadData, NoRowsPlaceholder } from "app/routes";
import {
  getTableActions,
  TableActionsComponent
} from "app/routes/communications/table/actions";
import {
  CommunicationTableData,
  getTableColumns
} from "app/routes/communications/table/columns";
import TableToolbarComponent from "app/routes/communications/table/toolbar";
import TableContainerComponent from "common/components/table/container";
import TableTitle from "common/components/table/title";
import * as Constants from "common/helpers/constants";
import SignalRConnection, {
  HubName,
  NotificationSignalType,
  SignalName
} from "common/helpers/signalr-connection";
import { PageContainer, TableTheme } from "common/styles/table";
import Guid from "common/values/guid/guid";
import _ from "lodash";
import { ChatDialog } from "messaging/components";
import MessageNotification from "notifications/entities/message-notification/message-notification";
import Notification from "notifications/entities/notification/notification";
import NotificationsAPIService from "notifications/services/notifications-api-service";
import Action from "notifications/values/action/action";
import { BoxType } from "notifications/values/box-type/box-type";
import TopicCategory from "notifications/values/topic-category/topic-category";
import React, { useEffect } from "react";
import { Params, useLoaderData, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useSession } from "users/session/session-context";

type CommunicationsProps = {};

export default function Communications(_props: Readonly<CommunicationsProps>) {
  const tableRef = React.useRef<
    MaterialTable<CommunicationTableData> &
    MaterialTableProps<CommunicationTableData>
  >();

  const [loading, setLoading] = React.useState(false);
  const [activeFilters, setActiveFilters] = React.useState([]);
  const [activeTab, setActiveTab] = React.useState(BoxType.Inbox);
  const [tableBodyHeight, setTableBodyHeight] = React.useState(0);
  const [actionRequired, setActionRequired] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState<
    CommunicationTableData[]
  >([]);

  const routeParams = useLoaderData() as Params<string>;
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const session = useSession();
  const isMediumDisplaySize = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { openDialog, closeAllDialogs } = useDialog();

  const tabs = (
    <Tabs
      value={activeTab}
      indicatorColor="primary"
      textColor="primary"
      onChange={(_event, newValue) => navigate(`/communications/${newValue}`)}
    >
      <Tab value={BoxType.Inbox} label="Inbox" />
      <Tab value={BoxType.Outbox} label="Sent" />
      <Tab value={BoxType.All} label="All" />
      <Tab value={BoxType.Archived} label="Archived" />
    </Tabs>
  );

  // Close any open dialogs when the URL changes
  useEffect(() => {
    closeAllDialogs();
  }, [location]);

  // Handle account type change
  useEffect(() => {
    tableRef?.current?.onQueryChange({});
  }, [session?.accountType, tableRef]);

  // Handle URL changes
  useEffect(() => {
    if (routeParams.tab) {
      setActiveTab(routeParams.tab as BoxType);
    } else {
      navigate(`/communications/${BoxType.Inbox}`);
    }

    if (routeParams.action === 'compose') {
      openMessageDialog();
      return;
    }

    if (routeParams.tab === BoxType.All && routeParams.id) {
      openMessageDialog(new Guid(routeParams.id));
    }
  }, [routeParams]);

  useEffect(() => {
    setActionRequired(searchParams.get('actionRequired') === 'true');
    tableRef?.current?.onQueryChange({});
  }, [searchParams]);

  // Handle component mount
  useEffect(() => {
    function computeTableHeight() {
      const tableHead = document.querySelector("thead.MuiTableHead-root");
      const tableFoot = document.querySelector("tfoot.MuiTableFooter-root");
      const siteFooter = document.querySelector("footer#siteFooter");

      const tableOffsetTop = tableHead?.getBoundingClientRect().top;
      const tableFootHeight = tableFoot?.getBoundingClientRect().height;
      const siteFooterHeight = siteFooter?.getBoundingClientRect().height;

      if (!tableOffsetTop || !tableFootHeight || !siteFooterHeight) return;

      setTableBodyHeight(
        Math.floor(
          window.innerHeight -
          tableOffsetTop -
          tableFootHeight -
          siteFooterHeight
        )
      );
    }

    const debouncedResize = _.debounce(() => computeTableHeight(), 250);
    debouncedResize();
    window.addEventListener("resize", debouncedResize);

    return () => {
      window.removeEventListener("resize", debouncedResize);
    };
  }, []);

  function clearFilters() {
    setActiveFilters([]);
    // TODO: Update filter values on the column def. The columns will need to be stored in state
  }

  async function handleRowClick(
    event?: React.MouseEvent<Element, MouseEvent>,
    rowData?: CommunicationTableData,
    action?: RowAction
  ) {
    if (!rowData) return;

    const notification = rowData as Notification | MessageNotification;
    const topicCategory = notification.topic?.category;

    try {
      if (activeTab === BoxType.Inbox) {
        await new NotificationsAPIService(session).markNotificationAsSeen(notification);
        navigate(getRedirectUrl(notification));
      }

      if (activeTab === BoxType.All) {
        navigate(`/communications/all/view/${notification.id}`);
      }

      if (topicCategory === TopicCategory.Messaging.Message || topicCategory === TopicCategory.Messaging.ForumInvitation) {
        navigate(`/communications/all/view/${(notification as MessageNotification).messageInfo?.forum.id}`);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function handleSelectionChange(
    selectedRowsData: CommunicationTableData[],
    _rowData?: CommunicationTableData | undefined
  ) {
    setSelectedRows(selectedRowsData);
  }

  function getRedirectUrl(notification: Notification) {
    const id = notification.topic?.id;
    const category = notification.topic?.category?.valueOf() ?? '';
    const isProposalMessageNotification =
      (notification as MessageNotification).messageInfo?.forum.entityClass.valueOf() === 'Work.Proposal';
    const action = notification.actionRequired;
    let actionType: 'view' | 'edit' | 'revise' | 'review' = 'view';
    let pageTab = 'active';
    let tab = '';
    let url = '';

    if (!isProposalMessageNotification) {
      url = Constants.notificationLinks[category as keyof typeof Constants.notificationLinks];
    } else {
      const proposalId = (notification as MessageNotification).messageInfo?.forum.entityId;
      return `/proposals/active/revise/${proposalId}/chat`;
    }

    if (!id) return url;

    switch (action) {
      case Action.DraftProposalReview:
        pageTab = 'drafts';
        actionType = 'review';
        break;
      case Action.DraftProposalReviewApproved:
        pageTab = 'drafts';
        actionType = 'edit';
        break;
      case Action.ProposalReview:
        actionType = 'review';
        break;
      case Action.Review:
      case Action.ProposalRevisionReview:
      case Action.ProposalReviewApproved:
      case Action.HireOrCancel:
        actionType = 'revise';
        break;
    }

    url = url.replace('{pageTab}', pageTab);
    url = url.replace('{action}', actionType);
    url = url.replace('{topicId}', id.toString());
    return url.replace('{tab}', tab);
  }

  async function handleNotificationReceived(
    notification: Notification | MessageNotification
  ) {
    // Just reload the table data for now
    tableRef?.current?.onQueryChange({});
  }

  async function handleNotificationUpdated(
    notification: Notification | MessageNotification
  ) {
    // Just reload the table data for now
    tableRef?.current?.onQueryChange({});
  }

  async function handleNotificationDeleted(
    notification: Notification | MessageNotification
  ) {
    // Just reload the table data for now
    tableRef?.current?.onQueryChange({});
  }

  async function handleMarkSeen(notifications: Notification[]) {
    try {
      setLoading(true);

      for (const notification of notifications) {
        await new NotificationsAPIService(session).markNotificationAsSeen(notification);
      }
    } catch (error) {
      console.error(error);
    } finally {
      (tableRef.current as any)?.dataManager.changeAllSelected(false);
      setSelectedRows([]);
      setLoading(false);
    }
  }

  async function handleMarkUnseen(notifications: Notification[]) {
    try {
      setLoading(true);

      for (const notification of notifications) {
        await new NotificationsAPIService(session).markNotificationAsUnSeen(notification);
      }
    } catch (error) {
      console.error(error);
    } finally {
      (tableRef.current as any)?.dataManager.changeAllSelected(false);
      setSelectedRows([]);
      setLoading(false);
    }
  }

  async function handleArchive(notifications: Notification[]) {
    try {
      setLoading(true);

      for (const notification of notifications) {
        await new NotificationsAPIService(session).archiveNotification(notification);
      }
    } catch (error) {
      console.error(error);
    } finally {
      (tableRef.current as any)?.dataManager.changeAllSelected(false);
      setSelectedRows([]);
      setLoading(false);
    }
  }

  function openMessageDialog(id?: Guid) {
    const parentPath = new URL(".", window.location.origin + window.location.pathname);

    openDialog({
      title: id ? 'Message' : 'Compose Message',
      MuiProps: {
        fullWidth: true,
        maxWidth: 'md'
      },
      contentSxProps: {
        display: 'flex',
        overflowX: 'hidden',
        paddingBottom: 0
      },
      component: <ChatDialog
        forumId={id}
        onLeaveConversation={() => {
          tableRef?.current?.onQueryChange({});
        }}
      />,
    },
      () => navigate(parentPath.pathname)
    );
  }

  return (
    <PageContainer>
      <SignalRConnection
        hubName={HubName.Notifications}
        signals={
          [
            {
              signal: SignalName.NotificationReceived,
              handler: handleNotificationReceived,
            },
            {
              signal: SignalName.NotificationUpdated,
              handler: handleNotificationUpdated,
            },
            {
              signal: SignalName.MessageDeleted,
              handler: handleNotificationDeleted,
            },
          ] as NotificationSignalType[]
        }
      />
      <MaterialTable
        key={activeTab}
        tableRef={tableRef}
        title={<TableTitle title={"Communications"} icon={<InboxIcon />} />}
        columns={getTableColumns(activeTab, selectedRows, session)}
        data={(query) => loadData(query, activeTab, actionRequired, setLoading, session)}
        actions={getTableActions(
          tableRef,
          isMediumDisplaySize,
          handleMarkSeen,
          handleMarkUnseen,
          handleArchive
        )}
        isLoading={loading}
        options={{
          debounceInterval: 250,
          emptyRowsWhenPaging: false,
          filtering: true,
          filterRowStyle: TableTheme.filterRow,
          grouping: true,
          minBodyHeight: tableBodyHeight,
          maxBodyHeight: tableBodyHeight,
          pageSize: 10,
          pageSizeOptions: [10, 25, 50],
          paginationType: isMediumDisplaySize ? "normal" : "stepped",
          showFirstLastPageButtons: !isMediumDisplaySize,
          selection: activeTab !== BoxType.All,
          rowStyle: function (rowData: CommunicationTableData) {
            const notification = rowData as MessageNotification;
            const seenColor = notification.isSeen
              ? TableTheme.row.seen.backgroundColor
              : TableTheme.row.unseen.backgroundColor;
            const selectedColor = TableTheme.row.selected.backgroundColor;
            return {
              backgroundColor: selectedRows.some((row) =>
                row.id.isEqualTo(rowData.id)
              )
                ? selectedColor
                : seenColor,
            };
          },
        }}
        components={{
          Container: (props) => TableContainerComponent({ ...props }),
          Actions: (props) =>
            TableActionsComponent({
              tableRef,
              selectedRows,
              isMediumDisplaySize,
              ...props,
            }),
          Toolbar: (props) =>
            TableToolbarComponent({
              tableRef,
              activeTab,
              tabs,
              activeFilters,
              selectedRows,
              actionRequired,
              isMediumDisplaySize,
              clearFilters,
              setActionRequired,
              session,
              ...props,
            }),
        }}
        localization={{
          body: {
            emptyDataSourceMessage: (
              <NoRowsPlaceholder tableBodyHeight={tableBodyHeight} />
            ),
          },
        }}
        onRowClick={handleRowClick}
        onSelectionChange={handleSelectionChange}
      />
    </PageContainer>
  );
}
