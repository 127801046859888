import { Query, QueryResult } from "@material-table/core";
import { PageTab } from "app/routes/contracts/tabs";
import PaginationParameters from "common/contracts/pagination-parameters";
import DocumentAPIService from "documents/entities/document/api/document-api-service";
import DocumentFilterParameters from "documents/entities/document/api/request-contracts/document-filter-parameters";
import DocumentOrderParameters from "documents/entities/document/api/request-contracts/document-order-parameters";
import DocumentTopicParameters from "documents/entities/document/api/request-contracts/document-topic-parameters";
import Document from "documents/entities/document/document";
import Session from "users/session/session";

export default async function loadData(
  session: Session,
  activeTab: PageTab,
  mine: boolean,
  clientVendor: boolean,
  abortController: AbortController,
  query: Query<Document>,
  setLoading: (isLoading: boolean) => void
): Promise<QueryResult<Document>> {
  const emptyResponse = {
    data: [],
    page: 0,
    totalCount: 0
  } as QueryResult<Document>;

  try {
    setLoading(true);
    let contexts: string[] = [];
    if (activeTab === PageTab.Conflicts) {
      contexts.push('Conflicts');
    }
    if (activeTab === PageTab.Policies) {
      contexts.push('Policy');
      if (clientVendor && session.context?.viewingAsVendor) {
        contexts.push('VendorPolicies');
      } else if (clientVendor && !session.context?.viewingAsVendor) {
        contexts.push('ClientPolicies');
      }
    }
    const apiService = new DocumentAPIService(session);
    const paginationParams = new PaginationParameters(query.page + 1, query.pageSize);
    const order = query.orderByCollection.find(order => order.sortOrder !== undefined);
    const orderParams = new DocumentOrderParameters(
      order?.orderByField === 'name' ? 'name' : 'created',
      order?.orderDirection === 'asc' ? 'asc' : 'desc'
    );
    const topicParams = new DocumentTopicParameters(undefined, undefined, contexts.join(','));
    const filterParams = new DocumentFilterParameters(
      query.filters.find(filter => filter.column.field === 'name')?.value
    );
    let documentsResponse = await apiService.getDocumentsInfo(
      abortController,
      paginationParams,
      topicParams,
      orderParams,
      filterParams
    );

    return {
      data: documentsResponse.data,
      page: documentsResponse.pageNumber - 1,
      totalCount: documentsResponse.totalElements
    }
  } catch (error) {
    console.error(error);

    return emptyResponse;
  } finally {
    setLoading(false);
  }
}
