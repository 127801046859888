import { E164Number } from 'libphonenumber-js/core';
export default class PhoneNumber extends String {
    public static fromObject(object: any) {
        if (!object) return;
        return new PhoneNumber(object);
    }
    public toE164Number(): E164Number {
        return this.toString() as E164Number;
    }
    public toJSON() {
        return this.valueOf();
    }
}
