import { styled } from "@mui/material/styles";
import { Box, Chip } from "@mui/material";

export const TableTheme = {
  mainToolbar: {
    backgroundColor: 'rgb(25, 118, 210, 0.2)'
  },
  filterRow: {
    backgroundColor: 'rgba(25, 118, 210, 0.04)'
  },
  paginationToolbar: {
    backgroundColor: 'rgba(25, 118, 210, 0.04)'
  },
  row: {
    selected: {
      backgroundColor: 'rgb(25, 118, 210, 0.2)'
    },
    seen: {
      backgroundColor: '#ECECEC'
    },
    unseen: {
      backgroundColor: '#FFF'
    }
  }
};

export const PageContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%'
}));

export const TableContainer = styled(Box)(({ theme }) => ({
  borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  height: '100%',
  '& thead.MuiTableHead-root': {
    backgroundColor: '#FFF',
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.appBar
  },
  '& tbody': {
    '& .MuiTableRow-root:first-of-type': {
      '& .MuiTableCell-root': {
        padding: theme.spacing(0.5)
      },
      '& .MuiTextField-root input.MuiInputBase-input': {
        padding: theme.spacing(1, 0)
      }
    },
    '& .MuiTableCell-root': {
      padding: theme.spacing(0.5)
    }
  },
  '& tfoot': {
    '& .MuiTablePagination-root': {
      backgroundColor: TableTheme.paginationToolbar.backgroundColor,
      [theme.breakpoints.down('sm')]: {
        backgroundColor: 'white',
        justifyContent: 'center'
      }
    },
    '& td.MuiTableCell-footer': {
      border: 0
    }
  }
}));

export const TableToolbarContainer = styled(Box, { shouldForwardProp: prop => prop !== 'rowsselected'})<{ rowsselected?: boolean }>(({ theme, rowsselected }) => ({
  '& .MuiToolbar-root:first-of-type': {
    backgroundColor: rowsselected ? TableTheme.mainToolbar.backgroundColor : 'unset',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    },
    '& .MuiTypography-root': {
      color: rowsselected ? theme.palette.primary.main : 'unset'
    }
  }
}));

export const ActionsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  marginLeft: theme.spacing(2)
}));

export const ActionsCellContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end'
}));

export const PageTitle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(0.5)
  },
  '& .MuiTypography-root': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}));

export const FilterButtons = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(1)
  },
  margin: theme.spacing(1, 1, 0.5, 2),
  textAlign: 'left',
  width: 'auto'
}));

export const FilterChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0, 1, 0, 0)
}));

export const EmptyMessage = styled('div')<{ tableBodyHeight: number }>((props) => ({
  alignItems: 'center',
  display: 'flex',
  height: `calc(${props.tableBodyHeight}px - 7rem)`,
  justifyContent: 'center',
  width: `calc(100vw - 17rem - ${props.theme.spacing(2)})`,
  [props.theme.breakpoints.down('lg')]: {
    width: `calc(100vw - ${props.theme.spacing(2)})`
  }
}));

export const CenterFlexContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center'
}));

