import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PublishIcon from '@mui/icons-material/Publish';
import {
  CircularProgress,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  styled,
  Tooltip
} from '@mui/material';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import LoadingButton from 'common/components/loading-button';
import React from 'react';
import IndividualAPIService from 'marketplace/entities/individual/api/individual-api-service';
import { enqueueSnackbar } from 'notistack';
import Guid from 'common/values/guid/guid';
import { downloadFile } from 'common/helpers/utils';
import { ConfirmResponse, useConfirmDialog } from 'app/providers/confirm-dialog';
import Session from 'users/session/session';

const StyledListItemResume = styled(ListItem)(({ theme }) => ({
  paddingLeft: theme.spacing(0),
}));
const StyledLinkResume = styled(Link)(({ theme }) => ({
  cursor: 'pointer',
}));
const StyledButtonResume = styled(LoadingButton)(({ theme }) => ({
  marginLeft: theme.spacing(-1),
}));
const HiddenInput = styled('input')({
  display: 'none',
});

export interface ResumeUploadProps {
  accept: string;
  individualId?: Guid;
  userName?: string;
  resume?: Guid | File | null;
  onUpload: (updatedResume: File) => void;
  onDelete: () => void;
  session: Session;
}

export default function ResumeUpload(props: Readonly<ResumeUploadProps>) {
  const hiddenResumeFileInput = React.useRef<HTMLInputElement>(null)
  const { session } = props;

  const [uploadingResume, setUploadingResume] = React.useState(false);
  const [deletingResume, setDeletingResume] = React.useState(false);

  const confirm = useConfirmDialog();

  async function handleResumeClick() {
    if (props.resume instanceof File) {
      const blob = new Blob([props.resume], { type: props.resume.type });
      const link = document.createElement('a');
      link.setAttribute("href", window.URL.createObjectURL(blob));
      link.setAttribute("download", props.resume.name);
      link.setAttribute("target", "_blank");
      link.click();
      return;
    }

    try {
      if (!props.individualId) throw new Error('Individual ID not provided');
      const service = new IndividualAPIService(session);
      const abortController = new AbortController();
      const response = await service.downloadIndividualResume(props.individualId, abortController);
      downloadFile(response);
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Couldn't download resume. Please try again", { variant: 'error' });
    }
  }

  async function handleResumeChange(event: React.ChangeEvent<HTMLInputElement>) {
    setUploadingResume(true);

    const files = event.target.files;

    if (!files || files.length === 0) return;

    const resumeFile = files[0];

    if (!props.individualId) {
      props.onUpload(resumeFile);
      setUploadingResume(false);
      return;
    }

    try {
      const service = new IndividualAPIService(session);
      await service.createIndividualResume(props.individualId, resumeFile);
      props.onUpload(resumeFile);
      enqueueSnackbar('Uploaded resume', { variant: 'success' });
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Couldn't upload resume. Please try again", { variant: 'error' });
    }
    finally {
      setUploadingResume(false);
      hiddenResumeFileInput.current!.value = '';
    }
  }

  async function handleResumeDelete() {
    const response = await confirm({
      title: 'Delete Resume?',
      message: 'Are you sure you want to delete this resume?',
      okButtonText: 'Delete'
    });

    if (response === ConfirmResponse.Cancel) return;

    if (!props.individualId) {
      props.onDelete();
      return
    };

    try {
      setDeletingResume(true);
      const service = new IndividualAPIService(session);
      await service.deleteIndividualResume(props.individualId);
      props.onDelete();
      enqueueSnackbar('Deleted resume', { variant: 'success' });
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Couldn't delete resume. Please try again", { variant: 'error' });
    } finally {
      setDeletingResume(false);
    }
  }

  return (
    <>
      <List>
        <StyledListItemResume disabled={!props.resume} disablePadding disableGutters>
          {props.resume ? (
            <>
              <ListItemIcon><FileOpenIcon color='primary' /></ListItemIcon>
              <ListItemText>
                <StyledLinkResume onClick={handleResumeClick}>
                  {props.userName ? `${props.userName}'s Resume` : 'View Resume'}
                </StyledLinkResume>
              </ListItemText>
            </>
          ) : (
            <ListItemText primary="No resume uploaded" />
          )}
          <ListItemSecondaryAction>
            <Tooltip title="Delete Resume">
              <span>
                {(props.resume !== null && props.resume !== undefined) && (
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={handleResumeDelete}
                    size="large">
                    {deletingResume ?
                      <CircularProgress size="24px" sx={{ color: '#999' }} /> :
                      <DeleteOutlineIcon color='error' />
                    }
                  </IconButton>
                )}
              </span>
            </Tooltip>
          </ListItemSecondaryAction>
        </StyledListItemResume>
      </List>
      <HiddenInput
        type="file"
        ref={hiddenResumeFileInput}
        accept={props.accept}
        onChange={handleResumeChange}
      />
      <StyledButtonResume
        startIcon={<PublishIcon />}
        loading={uploadingResume}
        onClick={() => hiddenResumeFileInput.current?.click()}
        disabled={Boolean(props.resume) || uploadingResume}
        color="primary">
        Upload Resume
      </StyledButtonResume>
    </>
  );
}
