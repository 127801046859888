import { Action, MTableActions } from '@material-table/core';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Tooltip } from '@mui/material';
import { ActionsContainer } from 'common/styles';
import { NavigateFunction } from 'react-router';
import Session from 'users/session/session';
import Proposal from 'work/entities/proposal/proposal';

export type TableActionsComponentProps = {
  isMediumDisplaySize: boolean;
  selectedRows: Proposal[];
  session: Session;
  navigate: NavigateFunction
}

export function TableActionsComponent(props: Readonly<TableActionsComponentProps>): React.ReactElement {
  const {
    isMediumDisplaySize,
    selectedRows,
    session,
    navigate
  } = props;

  return (
    <ActionsContainer>
      {selectedRows.length < 1 && !isMediumDisplaySize && (
        <Tooltip title={
          (session.context?.viewingAsVendor && !session.networkConnections.some((conn) => conn.user.isClientRep)) ?
          'You are not networked with any client representatives' : 'New Proposal'
        }>
          <span>
            <Button
              color='primary'
              startIcon={<AddBoxIcon />}
              disabled={
                Boolean(session.context?.viewingAsVendor) &&
                !session.networkConnections.some((conn) => conn.user.isClientRep)
              }
              onClick={() => navigate('/proposals/drafts/create')}>
              New Proposal
            </Button>
          </span>
        </Tooltip>
      )}
      <MTableActions {...props} />
    </ActionsContainer>
  );
}

export function getTableActions(
  isMediumDisplaySize: boolean,
  onDeleteProposal: (proposal: Proposal | Proposal[]) => void,
  session: Session,
  navigate: NavigateFunction
): Action<object>[] {
  return [
    NewProposal(isMediumDisplaySize, session, navigate),
    DeleteProposal(onDeleteProposal)
  ];
}

function NewProposal(
  isMediumDisplaySize: boolean,
  session: Session,
  navigate: NavigateFunction
): Action<object> {
  return {
    icon: AddBoxIcon,
    iconProps: { color: 'primary', fontSize: 'large' },
    tooltip: (
      (session.context?.viewingAsVendor && !session.networkConnections.some((conn) => conn.user.isClientRep)) ?
        'You are not networked with any client representatives' : 'New Proposal'
    ),
    disabled: (
      Boolean(session.context?.viewingAsVendor) && 
      !session.networkConnections.some((conn) => conn.user.isClientRep)
    ),
    position: 'toolbar',
    hidden: !isMediumDisplaySize,
    isFreeAction: true,
    onClick: () => navigate('/proposals/drafts/create')
  };
}

function DeleteProposal(
  onDeleteProposal: (proposal: Proposal | Proposal[]) => void
): Action<object> {
  return {
    icon: DeleteIcon,
    iconProps: { color: 'primary' },
    tooltip: 'Delete',
    position: 'toolbarOnSelect',
    onClick: (_event, data) => onDeleteProposal(data as Proposal[])
  };
}
