import { Action, MTableActions } from '@material-table/core';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Button } from '@mui/material';
import { PageTab } from 'app/routes/entity-administration/tabs';
import { ActionsContainer } from 'common/styles';
import { NavigateFunction, useNavigate } from 'react-router';

export type TableActionsComponentProps = {
  activeTab: PageTab;
  isMediumDisplaySize: boolean;
  navigate: NavigateFunction;
}

export function TableActionsComponent(props: Readonly<TableActionsComponentProps>): React.ReactElement {
  const navigate = useNavigate();

  return (
    <ActionsContainer>
      {!props.isMediumDisplaySize && (
        <Button
          color='primary'
          startIcon={<PersonAddIcon />}
          onClick={() => navigate(`/administration/${props.activeTab}/invite`)}>
          {props.activeTab === PageTab.Users ? 'Invite Users' : 'Invite Administrators'}
        </Button>
      )}
      <MTableActions {...props} />
    </ActionsContainer>
  );
}

export function getTableActions(props: TableActionsComponentProps): Action<object>[] {
  return [inviteUsers(props)];
}

function inviteUsers(props: TableActionsComponentProps): Action<object> {
  return {
    icon: PersonAddIcon,
    iconProps: { color: 'primary', fontSize: 'large' },
    tooltip: props.activeTab === PageTab.Users ? 'Invite Users' : 'Invite Administrators',
    position: 'toolbar',
    hidden: !props.isMediumDisplaySize,
    isFreeAction: true,
    onClick: () => props.navigate(`/administration/${props.activeTab}/invite`)
  };
}
