import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ConfirmResponse, useConfirmDialog } from "app/providers/confirm-dialog";
import React, { useEffect } from "react";

const MAX_URL_DISPLAY_LENGTH = 85;

const MainContainer = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));
const ListContainer = styled(List)(({ theme }) => ({
  paddingRight: theme.spacing(2.5),
  paddingTop: 0,
}));
const UrlInputField = styled(TextField)(({ theme }) => ({
  width: "100%",
}));
const AddButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1),
}));

interface Props {
  label: string;
  initialLinks: string[];
  onChange: (links: string[]) => void;
}

export default function UrlList(props: Readonly<Props>) {
  const { label, initialLinks, onChange } = props;

  const urlInputFieldRef = React.useRef<HTMLInputElement>(null);

  const [links, setLinks] = React.useState<string[]>(initialLinks);
  const [websiteUrl, setWebsiteUrl] = React.useState<string>("");
  const [error, setError] = React.useState<string>("\u00A0");
  const [urlValid, setUrlValid] = React.useState<boolean>(false);

  const confirm = useConfirmDialog();

  useEffect(() => {
    if (initialLinks.length !== links.length) {
      setLinks(initialLinks);
    }
  }, [links.length, initialLinks]);

  function handleLinkAdd() {
    try {
      if (links.find((link) => link === websiteUrl)) {
        throw new Error("URL already in list");
      }
      const updatedLinks = [...links, websiteUrl];
      setLinks(updatedLinks);
      onChange(updatedLinks);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setUrlValid(false);
      setWebsiteUrl("");
      urlInputFieldRef.current?.blur();
    }
  }

  async function handleLinkRemove(link: string) {
    const response = await confirm({
      title: "Remove URL",
      message: `Are you sure you want to remove ${link} from the list?`,
      okButtonText: "Remove",
    });

    if (response === ConfirmResponse.Cancel) return;

    let updatedLinks = links.filter((url) => url !== link);
    setLinks(updatedLinks);
    onChange(updatedLinks);
  }

  function handleWebsiteUrlChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    setWebsiteUrl(event.target.value);
    setError("\u00A0");

    if (event.target.value.length < 1) return;

    try {
      const validUrlTextRegEx: RegExp = /^https?:\/\//;
      const urlValid: boolean = validUrlTextRegEx.test(event.target.value);
      if (!urlValid) {
        setError("URL must begin with http:// or https://");
        return;
      }
      setUrlValid(urlValid);
    } catch (error: any) {
      if (error instanceof TypeError) {
        setError("Invalid URL");
        return;
      }
    }
  }

  function checkForSubmission(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter" && urlValid && websiteUrl.length > 0) {
      handleLinkAdd();
    }
  }

  return (
    <MainContainer>
      <ListContainer>
        {links?.length === 0 && (
          <ListItem disabled dense disableGutters disablePadding>
            <ListItemText>No URLs</ListItemText>
          </ListItem>
        )}
        {links?.map((link: string) => (
          <ListItem
            key={link}
            dense={links.length > 3}
            disableGutters
            disablePadding
          >
            <ListItemText
              primary={
                <Link href={link} target="_blank">
                  {link.length > MAX_URL_DISPLAY_LENGTH
                    ? `${link.slice(0, MAX_URL_DISPLAY_LENGTH)}...`
                    : link}
                </Link>
              }
            />
            <ListItemSecondaryAction onClick={() => handleLinkRemove(link)}>
              <Tooltip title="Remove URL From List">
                <span>
                  <IconButton aria-label="delete" size="small">
                    <CloseIcon color="error" />
                  </IconButton>
                </span>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </ListContainer>
      <Grid container direction="row">
        <Grid item xs>
          <UrlInputField
            ref={urlInputFieldRef}
            label={label}
            variant="outlined"
            size="small"
            value={websiteUrl}
            onChange={handleWebsiteUrlChange}
            onKeyDown={checkForSubmission}
            error={error !== "\u00A0"}
            helperText={error}
          />
        </Grid>
        <Grid item>
          <Tooltip title="Add URL To List">
            <span>
              <AddButton
                edge="end"
                aria-label="add"
                color="primary"
                disabled={!urlValid || websiteUrl.length < 1}
                onClick={handleLinkAdd}
                size="large"
              >
                <AddIcon />
              </AddButton>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </MainContainer>
  );
}
