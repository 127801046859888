import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Proposal from 'work/entities/proposal/proposal';
import { ProposalTab } from 'work/entities/proposal/redlining/view/proposal-redline-dialog';

const ProposalContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
  display: 'grid',
  gap: theme.spacing(6),
  gridTemplateColumns: 'auto auto',
  width: '100%'
}));
const GeneralSection = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column'
}));
const TextContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(1)
}));
const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[700],
  fontSize: '1.05em',
  fontWeight: theme.typography.fontWeightLight,
  marginBottom: theme.spacing(0),
  textDecoration: 'underline',
  textUnderlineOffset: theme.spacing(0.5)
}));
const Text = styled(Typography)(({ theme }) => ({
  fontSize: '1.05em',
}));

type DetailsTabProps = {
  activeTab: ProposalTab;
  proposal?: Proposal;
}

export default function DetailsTab(props: Readonly<DetailsTabProps>) {
  const {
    activeTab,
    proposal
  } = props;

  if (activeTab !== ProposalTab.Details) return null;

  return (
    <ProposalContainer>
      <GeneralSection>
        <TextContainer>
          <Subtitle>Client</Subtitle>
          <Text>{proposal?.details?.client?.getFullName()}</Text>
        </TextContainer>
        <TextContainer>
          <Subtitle>Description</Subtitle>
          <Text>{proposal?.description.valueOf()}</Text>
        </TextContainer>
        <TextContainer>
          <Subtitle>Response Due Date</Subtitle>
          <Text>{proposal?.responseDueBy?.format('MM/DD/YYYY') ?? 'None Specified'}</Text>
        </TextContainer>
        <TextContainer>
          <Subtitle>Start Date</Subtitle>
          <Text>{proposal?.startDate?.format('MM/DD/YYYY') ?? 'None Specified'}</Text>
        </TextContainer>
        <TextContainer>
          <Subtitle>End Date</Subtitle>
          <Text>{proposal?.endDate?.format('MM/DD/YYYY') ?? 'None Specified'}</Text>
        </TextContainer>
        <TextContainer>
          <Subtitle>Discount</Subtitle>
          <Text>{proposal?.discount.toString()}</Text>
        </TextContainer>
      </GeneralSection>
    </ProposalContainer>
  )
}
