import { Column } from "@material-table/core";
import { styled } from "@mui/material/styles";
import Individual from "marketplace/entities/individual/individual";
import MarketplaceTeam from "marketplace/entities/marketplace-team/marketplace-team";
import { BookmarkType } from "marketplace/values/bookmark/bookmark";
import CompanyProfile from "marketplace/values/company-profile/company-profile";
import { Link } from "@mui/material"
import { MarketplaceBookmark } from "./data-loader";
import Guid from "common/values/guid/guid";

const BookmarkLink = styled(Link)(({ theme }) => ({
  cursor: 'pointer'
}));

function bookmarkLink(rowData: MarketplaceBookmark, handleViewProfile: (profileId?: Guid) => void) {
  let name = '';
  if (rowData.type === BookmarkType.Individual)
    name = `${(rowData.vendor as Individual).profile?.firstName} ${(rowData.vendor as Individual).profile?.lastName}`;
  else if (rowData.type === BookmarkType.Company)
    name = (rowData.vendor as CompanyProfile).name;
  else if (rowData.type === BookmarkType.Team)
    name = (rowData.vendor as MarketplaceTeam).profile.name;
  return (
    <BookmarkLink onClick={() => handleViewProfile(rowData.id)}>
      {name}
    </BookmarkLink>
  );
}

export function getTableColumns(
  handleViewProfile: (profileId?: Guid) => void
): Column<MarketplaceBookmark>[] {
  return [
    {
      field: 'name',
      title: 'Bookmark',
      render: (rowData: MarketplaceBookmark) => (
        bookmarkLink(rowData, handleViewProfile)
      )
    },
    {
      field: 'createdDate',
      title: 'Created',
      type: 'date',
      render: (rowData) => rowData.createdDate.format('MM/DD/YY hh:mm A'),
    }
  ];
}
