import LinkIcon from "@mui/icons-material/Link";
import {
  Button, CircularProgress, Grid, InputAdornment, Table, TableBody, TableCell, TableHead,
  TableRow, TextField, Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import * as Constants from "common/helpers/constants";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";

import LegalEntity from "legal-entities/entities/legal-entity/legal-entity";
import OpenEntityMemberInvitationAPIService from "legal-entities/entities/open-entity-member-invitation/api/open-entity-member-invitation-api-service";
import OpenEntityMemberInvitation from "legal-entities/entities/open-entity-member-invitation/open-entity-member-invitation";
import { useSession } from "users/session/session-context";
import LoadingButton from "common/components/loading-button";
import Guid from "common/values/guid/guid";
import { ConfirmDialogType } from "app/providers/confirm-dialog";

const ManagerContainer = styled("div")(( { theme }) => ({
}));
const CreateButton = styled(LoadingButton)(({ theme }) => ({
  borderBottomRightRadius: 0,
  borderTopRightRadius: 0,
  height: "56px",
}));
const InvitationText = styled(TextField)(({ theme }) => ({
  borderBottomLeftRadius: 0,
  borderTopLeftRadius: 0,
}));
const ActionButton = styled(Button)(({ theme }) => ({
  whiteSpace: "nowrap"
}));

type EntityMemberInviteManagerProps = {
  className?: string;
  confirmDialog: ConfirmDialogType;
};

export default function EntityMemberInviteManager(props: Readonly<EntityMemberInviteManagerProps>) {
  const { className, confirmDialog } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [invitations, setInvitations] = useState<OpenEntityMemberInvitation[] | null>(null);
  const [invitationUrl, setInvitationUrl] = useState<URL | null>(null);
  const [creating, setCreating] = useState<boolean>(false);
  
  const session = useSession();

  const loadInvitations = async () => {
    setLoading(true);

    try {
      const entity = session.entities[0];
      const service = new OpenEntityMemberInvitationAPIService(session);
      const abortController = new AbortController();
      const invitations =
        await service.getAllOpenEntityMemberInvitationAsync(
          new LegalEntity(entity.entityId),
          abortController
        );
      setInvitations(invitations);
    } catch (ex) {
      console.error(ex);
      enqueueSnackbar("Failed to get invitations", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadInvitations();
  }, []);

  const handleDeleteInvite = async (invitation: OpenEntityMemberInvitation) => {
    try {
      const confirmDelete = await confirmDialog({
        title: "Delete Invitation",
        message: "Are you sure you want to delete this invitation?",
        okButtonText: "Delete"
      });

      if (!confirmDelete) return;

      const service = new OpenEntityMemberInvitationAPIService(session);
      await service.deleteOpenMemberInvitation(invitation);

      const newInvitations = invitations?.filter(invite => invite.id !== invitation.id);
      setInvitations(newInvitations ?? []);

      enqueueSnackbar("Deleted invitation link", { variant: "success" });
    } catch (ex) {
      console.error(ex);
      enqueueSnackbar("Failed to delete invitation", { variant: "error" });
    }
  };

  const createInvitationLink = async () => {
    setCreating(true);
    try {
      const service = new OpenEntityMemberInvitationAPIService(session);
      const invitation = await service.createOpenMemberInvitation();
      setInvitationUrl(buildInvitationUrl(invitation.id));
      await loadInvitations();
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Couldn't get invitation link. Please try again.", {
        variant: "error",
      });
    } finally {
      setCreating(false);
    }
  };

  function buildInvitationUrl(invitationId?: Guid) : URL | null {
    if (!invitationId) return null;
    return new URL(`${Constants.entityMemberInvitationLink}/${invitationId.valueOf()}`);
  };

  const handleCopy = async (textToCopy?: string) => {
    if (!textToCopy) {
      enqueueSnackbar("No link to copy", { variant: "warning" });
      return;
    }

    await navigator.clipboard.writeText(textToCopy);
    enqueueSnackbar("Copied link to clipboard", { variant: "info" });
  };

  const getCopyButton = () => {
    return (
      <InputAdornment position="end">
        <Button
          variant="text"
          disabled={!invitationUrl}
          onClick={() => handleCopy(invitationUrl?.toString())}
        >
          Copy
        </Button>
      </InputAdornment>
    );
  };

  return (
    <ManagerContainer className={className}>
      <Grid container direction="row">
        <Grid item>
          <CreateButton
            variant="contained"
            color="primary"
            loading={creating}
            startIcon={<LinkIcon />}
            onClick={createInvitationLink}
          >
            Create Invite Link
          </CreateButton>
        </Grid>
        <Grid item xs>
          <InvitationText
            value={invitationUrl?.toString()}
            variant="outlined"
            fullWidth
            inputProps={{ disabled: true }}
            InputProps={{
              endAdornment: getCopyButton(),
            }}
            onChange={(event) => {
              setInvitationUrl(new URL(event.target.value));
            }}
          />
        </Grid>
      </Grid>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">Id</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        {invitations !== null && (
          <TableBody>
            {!loading && invitations.length < 1 && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Typography>No Active Invite Links</Typography>
                </TableCell>
              </TableRow>
            )}
            {loading && (
              <TableRow>
                <TableCell>
                  <CircularProgress size={24} thickness={5} color="primary" />
                </TableCell>
              </TableRow>
            )}
            {invitations
              .filter((invitation: OpenEntityMemberInvitation) => invitation.id)
              .map((invitation: OpenEntityMemberInvitation) => (
                <TableRow key={invitation.id?.valueOf()}>
                  <TableCell align="left">
                    <Typography>{invitation.id?.valueOf()}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <ActionButton
                      onClick={() => handleCopy(buildInvitationUrl(invitation.id)?.toString())}
                      color="primary"
                    >
                      Copy Invite Link
                    </ActionButton>
                    <ActionButton
                      onClick={() => handleDeleteInvite(invitation)}
                      color="primary"
                    >
                      Delete
                    </ActionButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        )}
      </Table>
    </ManagerContainer>
  );
}
