export default class TechnicalBackgroundInfo extends String {
    public static fromObject(object: any) {
        if (!object) return;
        return new TechnicalBackgroundInfo(object);
    }

    public toJSON() {
        return this.toString();
    }
}
