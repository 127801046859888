import SaveIcon from "@mui/icons-material/Save";
import { Grid, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import LoadingButton from "common/components/loading-button";
import TagInput from "common/components/tag-input";
import Guid from "common/values/guid/guid";
import _ from "lodash";
import { enqueueSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useSession } from "users/session/session-context";
import FeeScheduleCategory from "marketplace/entities/fee-schedule-category/fee-schedule-category";
import FeeScheduleCategories from "marketplace/entities/fee-schedule-category/view/fee-schedule-categories";
import FeeSchedule from "marketplace/entities/fee-schedule/fee-schedule";
import FeeScheduleTag from "marketplace/values/fee-schedule-tag/fee-schedule-tag";
import FeeScheduleAPIService from "marketplace/entities/fee-schedule/api/fee-schedule-api-service";
import FeeScheduleCategoryName from "marketplace/values/fee-schedule-category-name/fee-schedule-category-name";


const PageContainer = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(2),
}));
const DetailsContainer = styled(Grid)(({ theme }) => ({
  marginRight: theme.spacing(3),
  minWidth: theme.spacing(40),
}));
const NameField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  width: "100%",
}));
const Tags = styled(TagInput)(({ theme }) => ({
  maxWidth: theme.spacing(40),
  width: "100%",
}));
const TagsContainer = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  marginTop: `calc(${theme.spacing(3)} - 16px)`,
}));
const SaveButton = styled(LoadingButton)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

type MarketplaceFeeScheduleDialogProps = {
  feeSchedule?: FeeSchedule;
  feeScheduleId?: Guid;
  teamId: Guid;
  name?: string;
  categories?: FeeScheduleCategory[];
  entityId?: Guid;
  viewOnly?: boolean;
  onChanged?: (feeSchedule: FeeSchedule) => void;
  onDirty?: (dirty: boolean) => void;
}

export default function MarketplaceFeeScheduleDialog(props: Readonly<MarketplaceFeeScheduleDialogProps>) {
  const { feeSchedule, feeScheduleId, teamId, viewOnly, onChanged, onDirty } = props;

  const nameFieldRef = React.useRef<HTMLInputElement>();

  const [name, setName] = React.useState<string>(props.name ?? "");
  const [tags, setTags] = React.useState<FeeScheduleTag[]>([]);
  const [categories, setCategories] = React.useState<Array<FeeScheduleCategory>>(props.categories ?? []);
  const [originalFeeSchedule, setOriginalFeeSchedule] = React.useState<FeeSchedule | undefined>(undefined);
  const [formValid, setFormValid] = React.useState<boolean>(false);
  const [saving, setSaving] = React.useState<boolean>(false);
  const [formDirty, setFormDirty] = React.useState<boolean>(false);

  const session = useSession();

  useEffect(() => {
    loadExistingFeeSchedule();
  }, []);


  async function loadFromFeeScheduleId(
    feeScheduleId: Guid
  ): Promise<FeeSchedule | undefined> {

    try {
      const service = new FeeScheduleAPIService(session);
      const feeSchedules = await service.getFeeSchedulesForTeam(teamId, new AbortController());

      if (feeSchedules.length === 0) return;

      return feeSchedules.find(
        (schedule: FeeSchedule) => schedule.id?.isEqualTo(feeScheduleId)
      );
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        "Couldn't fetch fee schedule from schedule id. Please try again",
        { variant: "error" }
      );
      return;
    }
  }

  async function loadExistingFeeSchedule() {
    // schedule Id was provided, try to load
    if (feeScheduleId) {
      const schedule = await loadFromFeeScheduleId(feeScheduleId);

      if (!schedule) {
        setName(name ?? "");
        setTags([]);
        setCategories(categories ?? []);
        return;
      }

      setOriginalFeeSchedule(schedule);
      setName(schedule.name);
      setTags(schedule.tags);
      setCategories(schedule?.categories);
      setFormValid(true);
      return;
    }
    // The fee schedule was already provided via prop
    if (feeSchedule){
      setOriginalFeeSchedule(feeSchedule);
      setName(feeSchedule.name);
      setTags(feeSchedule?.tags ?? []);
      setCategories(feeSchedule?.categories ?? []);
      setFormValid(true);
    }
  }

  function isFormValid(): boolean {
    return !_.isEmpty(name) && !_.isEmpty(categories);
  }

  async function handleSaveClicked() {
    if(!formValid) {
      enqueueSnackbar("Fee schedule is not valid", { variant: "warning" });
      return;
    }
    
    setSaving(true);

    let newFeeSchedule = new FeeSchedule(
      undefined,
      name,
      tags,
      categories
    );

    try {
      if (!session.user?.id)
        throw new Error("User id not found");
      if (!session.entities[0].entityId)
        throw new Error("Entity id not found");
      const service = new FeeScheduleAPIService(session);
      newFeeSchedule = await service.createFeeSchedule(teamId, newFeeSchedule);
      enqueueSnackbar("Created fee schedule", { variant: "success" });
      onChanged?.(newFeeSchedule);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Couldn't save fee schedule. Please try again", {
        variant: "error",
      });
    } finally {
      setSaving(false);
    }
  }

  async function handleSaveChangesClicked() {
    if (!originalFeeSchedule?.id) {
      console.warn("Attempted to save invalid update: missing originalSchedule.id");
      return;
    }
    setSaving(true);

    let updatedFeeSchedule = new FeeSchedule(
      originalFeeSchedule.id,
      name,
      tags,
      categories
    );

    try {
      const service = new FeeScheduleAPIService(session);
      updatedFeeSchedule =
        await service.updateFeeSchedule(
          teamId,
          originalFeeSchedule,
          updatedFeeSchedule
        );
      enqueueSnackbar("Updated fee schedule", { variant: "success" });

      onChanged?.(updatedFeeSchedule);
      onDirty?.(false);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Couldn't save fee schedule changes. Please try again", {
        variant: "error",
      });
    } finally {
      setSaving(false);
    }
  }

  async function handleCategoryAdded(category: FeeScheduleCategory) {
    onDirty?.(true);
    setCategories((prevCategories) => [...prevCategories, category]);
    setFormDirty(true);
    setFormValid(isFormValid());
  }

  async function handleCategoryRemoved(name: FeeScheduleCategoryName) {
    onDirty?.(true);
    const updatedCategories = categories.filter(
      (category) => category.name?.valueOf() === name.valueOf()
    );
    setCategories(updatedCategories);
    setFormDirty(true);
    setFormValid(isFormValid());
  }

  async function handleCategoryUpdated(
    originalName: FeeScheduleCategoryName | null,
    updatedCategory: FeeScheduleCategory 
  ) {
    onDirty?.(true);

    let updatedCategories = [...categories];
    let category = updatedCategories.find((c) => c.name === originalName);

    if (!category) return;

    category.name = updatedCategory.name;
    category.description = updatedCategory.description;
    category.billingCode = updatedCategory.billingCode;
    category.fee = updatedCategory.fee;

    // HACK: There is a bug in MaterialTable that requires the list
    // to be cleared before setting the new value
    setCategories([]);
    setCategories(updatedCategories);
    setFormDirty(true);
    setFormValid(isFormValid());
  }

  return (
    <React.Fragment>
      <PageContainer container direction="row">
        {!viewOnly && (
          <DetailsContainer item>
            <Grid container direction="column">
              <Grid item>
                <NameField
                  inputRef={nameFieldRef}
                  required
                  label="Name"
                  disabled={viewOnly}
                  helperText="The template's name"
                  inputProps={{ maxLength: 50 }}
                  value={name}
                  onChange={(event) => {
                    setName(event.target.value);
                    setFormDirty(true);
                    setFormValid(isFormValid());
                    onDirty?.(true);
                  }}
                />
              </Grid>
              <TagsContainer item>
                <Tags
                  helperText="Tags to help you find this template later (ex. 'US', '2023')"
                  viewOnly={viewOnly}
                  onTagsUpdated={(tags) => {
                    setTags(tags);
                    setFormDirty(true);
                    setFormValid(isFormValid());
                    onDirty?.(true);
                  }}
                  onTagRemoved={(tag) => {
                    setTags((prevTags) =>
                      prevTags.filter((prevTag) => prevTag !== tag)
                    );
                    setFormDirty(true);
                    setFormValid(isFormValid());
                    onDirty?.(true);
                  }}
                  tags={tags as string[]}
                />
              </TagsContainer>
            </Grid>
          </DetailsContainer>
        )}
        <Grid item>
          <FeeScheduleCategories
            initialCategories={categories}
            viewOnly={viewOnly}
            onCategoryAdded={handleCategoryAdded}
            onCategoryRemoved={handleCategoryRemoved}
            onCategoryUpdated={handleCategoryUpdated}
          />
        </Grid>
      </PageContainer>
      {!viewOnly && (
        <SaveButton
          color="primary"
          startIcon={<SaveIcon />}
          loading={saving}
          variant="contained"
          disabled={!formDirty || !formValid || saving}
          onClick={
            !originalFeeSchedule ? handleSaveClicked : handleSaveChangesClicked
          }>
          {originalFeeSchedule ? "Save Changes" : "Save"}
        </SaveButton>
      )}
    </React.Fragment>
  );
}
