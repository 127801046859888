import { Query, QueryResult } from "@material-table/core";
import { PageTab } from "app/routes/proposals/tabs";
import { AccountType } from "common/values/account-type/account-type";
import Session from "users/session/session";
import ProposalAPIService, { ProposalContextParam } from "work/entities/proposal/api/proposal-api-service";
import Proposal from "work/entities/proposal/proposal";

export default async function loadData(
  query: Query<Proposal>,
  activeTab: PageTab,
  filter: ProposalContextParam | undefined,
  setLoading: (isLoading: boolean) => void,
  session: Readonly<Session>
): Promise<QueryResult<Proposal>> {
  const emptyResponse = {
    data: [],
    page: 0,
    totalCount: 0,
  } as QueryResult<Proposal>;

  // const pagination = new PaginationParameters(query.page + 1, query.pageSize);
  let context: ProposalContextParam;

  if (filter) context = filter;
  else if (activeTab === PageTab.Active) context = 'allactive';
  else if (activeTab === PageTab.Drafts) context = 'draft';
  else if (activeTab === PageTab.Archived) context = 'archive';
  else (context = 'allactive');

  try {
    if (!session.hasEntity) {
      return emptyResponse;
    }
    setLoading(true);
    const proposalService = new ProposalAPIService(session);
    const proposals = await proposalService.getProposals({
      entityId: session.entities[0].entityId,
      viewingAs: session.context?.viewingAsVendor ? AccountType.Vendor : AccountType.Client,
      context: context
    });

    return {
      data: proposals,
      page: query.page,
      totalCount: proposals.length
    }
  } catch (error) {
    console.error(error);

    return emptyResponse;
  } finally {
    setLoading(false);
  }
}
