import { Query, QueryResult } from "@material-table/core";
import PaginationParameters from "common/contracts/pagination-parameters";
import MessagingAPIService from "messaging/api/messaging-api-service";
import NotificationParameters from "notifications/entities/notification/api/request-contracts/notification-parameters";
import NotificationsAPIService from "notifications/services/notifications-api-service";
import { BoxType } from "notifications/values/box-type/box-type";
import { CommunicationTableData } from "./columns";
import Session from "users/session/session";

export default async function loadData(
  query: Query<CommunicationTableData>,
  activeTab: BoxType,
  filterByActionRequired: boolean,
  setLoading: (isLoading: boolean) => void,
  session: Readonly<Session>
): Promise<QueryResult<CommunicationTableData>> {
  setLoading(true);

  const boxType = activeTab;
  const pagination = new PaginationParameters(query.page + 1, query.pageSize);
  const notificationParams = new NotificationParameters(boxType, filterByActionRequired, false, false);

  try {
    let notifications: CommunicationTableData[] = [];
    const notificationSercice = new NotificationsAPIService(session);
    if (boxType === BoxType.Inbox || boxType === BoxType.Outbox) {
      const recievedNotifications = await notificationSercice
        .getNotificationsForUser(pagination, notificationParams);
      notifications.push(...recievedNotifications as CommunicationTableData[]);
    }
    if (boxType === BoxType.All) {
      const messageService = new MessagingAPIService(session);
      let chats = await messageService.getForums();
      chats = chats.filter(chat => chat.topic?.context?.includes("Review"));
      notifications.push(...chats as CommunicationTableData[]);
    }
    if (boxType === BoxType.Archived) {
      const archivedNotifications = await notificationSercice
        .getArchivedNotificationsForUser(pagination, notificationParams);
      notifications.push(...archivedNotifications as CommunicationTableData[]);
    }

    return {
      data: notifications,
      page: query.page,
      totalCount: notifications.length
    }
  } catch (error) {
    console.error(error);

    return {
      data: [],
      page: 0,
      totalCount: 0
    };
  } finally {
    setLoading(false);
  }
}
