import { Query, QueryResult } from "@material-table/core";

import EntityMemberAPIService from "legal-entities/entities/entity-member/api/entity-member-api-service";
import LegalEntity from "legal-entities/entities/legal-entity/legal-entity";
import UserNetworkConnectionAPIService from "users/entities/user-network-connection/api/user-network-connection-api-service";
import NetworkInvitationAPIService from "users/entities/user-network-invitation/api/network-invitation-api-service";
import Session from "users/session/session";
import { NetworkTableData } from "app/routes/network";
import { PageTab } from "app/routes/network/tabs";


export default async function loadData(
  query: Query<NetworkTableData>,
  activeTab: PageTab,
  setLoading: (isLoading: boolean) => void,
  session: Readonly<Session>
): Promise<QueryResult<NetworkTableData>> {
  const emptyResponse = {
    data: [],
    page: 0,
    totalCount: 0
  } as QueryResult<NetworkTableData>;
  try {
    setLoading(true);

    let response: QueryResult<NetworkTableData> = emptyResponse;

    switch (activeTab) {
      case PageTab.Contacts: {
        const userNetworkService = new UserNetworkConnectionAPIService(session);
        let connections = await userNetworkService.getUserConnectionsInfo();
        response.data = connections;
        response.totalCount = connections.length;
        session?.updateNetworkConnections(connections);
        break;
      }
      case PageTab.Coworkers: {
        const entityId = session?.entities[0]?.entityId;
        if (!entityId) break;
        const entity = new LegalEntity(entityId);
        const service = new EntityMemberAPIService(session);
        const abortController = new AbortController();
        const coworkers = await service.getLegalEntityMembersUserInfo(entity, abortController);
        response.data = coworkers;
        response.totalCount = coworkers.length;
        break;
      }
      case PageTab.Pending: {
        const networkInvitationService = new NetworkInvitationAPIService(session);
        let invitations = await networkInvitationService.getUserNetworkInvitations();
        response.data = invitations;
        response.totalCount = invitations.length;
        break;
      }
      default:
        break;
    }

    return response;
  } catch (error: any) {
    console.error(error);
    return emptyResponse;
  } finally {
    setLoading(false);
  }
}
