import MaterialTable, { Column } from "@material-table/core";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CommentIcon from "@mui/icons-material/Comment";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import UndoIcon from "@mui/icons-material/Undo";
import {
  Badge,
  Box,
  Button,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ConfirmResponse, useConfirmDialog } from "app/providers/confirm-dialog";
import { useDialog } from "app/providers/dialog";
import Guid from "common/values/guid/guid";
import Forum from "messaging/entities/forum/forum";
import { enqueueSnackbar } from "notistack";
import React from "react";
import FeeRedlineInput from "work/entities/proposal/redlining/fee-redline/view/fee-redline-input";
import {
  FeeScheduleCategoryRedline,
  FeeScheduleRedline,
} from "work/entities/proposal/redlining/fee-schedule-redline/fee-schedule-redline";
import FieldRedline from "work/entities/proposal/redlining/field-redline";
import TextRedlineInput from "work/entities/proposal/redlining/view/redline-field-inputs/text-redline-input";

import Proposal, { ProposalField } from "work/entities/proposal/proposal";
import { getForumForField } from "work/entities/proposal/utils/comment-utils";
import { ProposalFieldName } from "work/values/constants";
import FeeScheduleBillingCode from "work/values/fee-schedule-billing-code/fee-schedule-billing-code";
import FeeScheduleCategoryDescription from "work/values/fee-schedule-category-description/fee-schedule-category-description";
import FeeScheduleCategoryName from "work/values/fee-schedule-category-name/fee-schedule-category-name";
import FeeScheduleCategory from "work/values/fee-schedule-category/fee-schedule-category";
import FeeScheduleCategoryForm from "work/values/fee-schedule-category/view/fee-schedule-category-form";
import Fee from "work/values/fee/fee";

const FeeCategoryButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));
const TableContainer = styled(Box)(({ theme }) => ({
  border: "1px solid #eee",
  marginTop: theme.spacing(2),
  maxWidth: theme.spacing(200),
}));
const RedlineText = styled(TextRedlineInput)(({ theme }) => ({
  margin: 0,
  minWidth: "unset",
  width: "100%",
}));
const ActionButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(0.5),
}));

type FeeScheduleRedlineSelectionProps = {
  className?: string;
  feeScheduleRedline: FeeScheduleRedline;
  disableEditing?: boolean;
  commentForums: Forum[];
  activeReviewField: ProposalField | undefined;
  onCommentsClicked: (categoryId?: Guid, name?: string) => void;
  onCategoryClicked?: (categoryId: Guid) => void;
  onFeeScheduleRedlineChange: (
    newRedline: FeeScheduleRedline,
    traversalFieldOverride?: ProposalField
  ) => void;
};

const FeeScheduleRedlineSelection = (props: Readonly<FeeScheduleRedlineSelectionProps>) => {
    const {
      className,
      feeScheduleRedline,
      commentForums,
      disableEditing,
      activeReviewField,
      onCommentsClicked,
      onCategoryClicked,
      onFeeScheduleRedlineChange
    } = props;

    const confirm = useConfirmDialog();
    const tableRef =
      React.useRef<MaterialTable<FeeScheduleCategoryRedline>>(null);
    const { openDialog, closeDialog } = useDialog();

    function handleRemoveClicked(categoryId: Guid) {
      const newRedline = feeScheduleRedline.removeEntryByFieldId(categoryId);
      onFeeScheduleRedlineChange(
        newRedline,
        {
          name: ProposalFieldName.FeeSchedule,
          id: categoryId
        }
      );
    }

    const columns: Column<FeeScheduleCategoryRedline>[] = [
      {
        field: "name",
        title: "Name",
        render: (rowData: FeeScheduleCategoryRedline) => {
          const categoryRedline = FeeScheduleCategoryRedline.fromObject(rowData);
          if (!categoryRedline.nameRedline) {
            console.error("Category Redline Name is malformed", categoryRedline);
            return;
          }
          return categoryRedline.revisionAccepted && categoryRedline.isRemoved ? "Removed" : (
            <RedlineText
              variant="standard"
              size="small"
              margin="none"
              hideLabel
              hideUndoButton={categoryRedline.isNewlyAdded}
              fullWidth
              originalTextFieldRedline={categoryRedline.nameRedline}
              onTextFieldRedlineChange={(newTextRedline: FieldRedline<FeeScheduleCategoryName>) => {
                const duplicateName = feeScheduleRedline.find((redline) => {
                  if (!redline.nameRedline) {
                    console.error("Category Redline Name is malformed", redline);
                    return false;
                  }
                  return redline.nameRedline.currentEntry === newTextRedline.currentEntry;
                });
                if (duplicateName) {
                  enqueueSnackbar("A category with the same name already exists", { variant: "warning" });
                  return;
                }

                const existingCategory = categoryRedline.currentEntry ?? categoryRedline.revisedEntry;
                if (!existingCategory || !newTextRedline.currentEntry) {
                  console.error("category redline is malformed", categoryRedline);
                  return;
                }
                const updatedCategory = new FeeScheduleCategory(
                  newTextRedline.currentEntry,
                  existingCategory.description,
                  existingCategory.fee,
                  existingCategory.billingCode,
                  existingCategory.id
                );

                const newRedline = feeScheduleRedline.replaceEntryById(updatedCategory.id, updatedCategory);
                onFeeScheduleRedlineChange(
                  newRedline
                )
              }}
              readOnly={disableEditing || categoryRedline.isRemoved}
              commentForums={commentForums}
              hideCommentsButton
            />
          );
        },
      },
      {
        field: "description",
        title: "Description",
        render: (rowData: FeeScheduleCategoryRedline) => {
          const categoryRedline = FeeScheduleCategoryRedline.fromObject(rowData);
          if (!categoryRedline.descriptionRedline) {
            console.error("Category Redline description is malformed", categoryRedline);
            return;
          }
          return (
            <RedlineText
              variant="standard"
              size="small"
              margin="none"
              fullWidth
              hideLabel
              hideUndoButton={categoryRedline.isNewlyAdded}
              originalTextFieldRedline={categoryRedline.descriptionRedline}
              onTextFieldRedlineChange={async (newTextRedline: FieldRedline<FeeScheduleCategoryDescription>) => {
                const existingCategory = categoryRedline.currentEntry ?? categoryRedline.revisedEntry;
                if (!existingCategory || !newTextRedline.currentEntry) {
                  console.error("category redline is malformed", categoryRedline);
                  return;
                }
                const updatedCategory = new FeeScheduleCategory(
                  existingCategory.name,
                  newTextRedline.currentEntry,
                  existingCategory.fee,
                  existingCategory.billingCode,
                  existingCategory.id
                );

                const newRedline = feeScheduleRedline.replaceEntryById(updatedCategory.id, updatedCategory);
                onFeeScheduleRedlineChange(
                  newRedline
                )
              }}
              readOnly={disableEditing || categoryRedline.isRemoved}
              commentForums={commentForums}
              hideCommentsButton
            />
          );
        },
      },
      {
        field: "fee",
        title: "Fee",
        render: (rowData: FeeScheduleCategoryRedline) => {
          const categoryRedline = FeeScheduleCategoryRedline.fromObject(rowData);
          if (!categoryRedline.feeRedline) {
            console.error("Category Redline fee is malformed", categoryRedline);
            return;
          }
          return (
            <FeeRedlineInput
              variant="standard"
              size="small"
              margin="none"
              categoryRedline={categoryRedline}
              readOnly={disableEditing || categoryRedline.isRemoved}
              onFeeChange={(newFee: Fee) => {
                const existingCategory = categoryRedline.currentEntry ?? categoryRedline.revisedEntry;
                if (!existingCategory) {
                  console.error("category redline is malformed", categoryRedline);
                  return;
                }
                const updatedCategory = new FeeScheduleCategory(
                  existingCategory.name,
                  existingCategory.description,
                  newFee,
                  existingCategory.billingCode,
                  existingCategory.id
                );

                const newRedline = feeScheduleRedline.replaceEntryById(updatedCategory.id, updatedCategory);
                onFeeScheduleRedlineChange(newRedline)
              }}
            />
          );
        },
      },
      {
        field: "billingCode",
        title: "Billing Code",
        render: (rowData: FeeScheduleCategoryRedline) => {
          const categoryRedline = FeeScheduleCategoryRedline.fromObject(rowData);
          if (!categoryRedline.billingCodeRedline) {
            console.error("Category Redline billing code is malformed", categoryRedline);
            return;
          }
          return (
            <RedlineText
              variant="standard"
              size="small"
              margin="none"
              fullWidth
              hideLabel
              originalTextFieldRedline={categoryRedline.billingCodeRedline}
              readOnly={disableEditing || categoryRedline.isRemoved}
              commentForums={commentForums}
              hideCommentsButton
              onTextFieldRedlineChange={async (newTextRedline: FieldRedline<FeeScheduleBillingCode>) => {
                const existingCategory = categoryRedline.currentEntry ?? categoryRedline.revisedEntry;
                if (!existingCategory || !newTextRedline.currentEntry) {
                  console.error("category redline is malformed", categoryRedline);
                  return;
                }
                const updatedCategory = new FeeScheduleCategory(
                  existingCategory.name,
                  existingCategory.description,
                  existingCategory.fee,
                  newTextRedline.currentEntry,
                  existingCategory.id
                );

                const newRedline = feeScheduleRedline.replaceEntryById(updatedCategory.id, updatedCategory);
                onFeeScheduleRedlineChange(newRedline)
              }}
            />
          );
        },
      },
      {
        field: "actions",
        align: "right",
        render: (rowData: FeeScheduleCategoryRedline) => {
          const category = FeeScheduleCategoryRedline.fromObject(rowData);

          if (!category) return null;
          if (disableEditing) return renderCommentButton(category);

        return (
          <>
            {category.isResolved && !category.isRemoved && !category.isNewlyAdded && (
              <Tooltip title="Remove Fee">
                <span>
                  <ActionButton onClick={() => handleRemoveClicked(category.fieldId)}>
                    <DeleteOutlineIcon />
                  </ActionButton>
                </span>
              </Tooltip>
            )}
            {!category.isResolved && (
              <>
                <Tooltip title="Accept Changes">
                  <span>
                    <ActionButton
                      onClick={() =>
                        onFeeScheduleRedlineChange(feeScheduleRedline.updateRedline(category.accept()))}
                    >
                      <CheckIcon color="success" />
                    </ActionButton>
                  </span>
                </Tooltip>
                <Tooltip title="Reject Changes">
                  <span>
                    <ActionButton
                      onClick={() =>
                        onFeeScheduleRedlineChange(feeScheduleRedline.updateRedline(category.reject()))
                      }
                    >
                      <CloseIcon color="error" />
                    </ActionButton>
                  </span>
                </Tooltip>
              </>
            )}
            {category.canBeUndone && (
              <Tooltip title="Undo Changes">
                <span>
                  <ActionButton onClick={() => onFeeScheduleRedlineChange(
                    feeScheduleRedline.undoRedlineById(category.fieldId),
                    { name: ProposalFieldName.FeeSchedule, id: category.fieldId }
                  )}>
                    <UndoIcon />
                  </ActionButton>
                </span>
              </Tooltip>
            )}
            {renderCommentButton(category)}
          </>
        )
      }
    }];

  /**
   * Handles when the add button is clicked
   */
  function beginAddCategory(): void {
    openDialog({
      title: "Create Fee Schedule Category",
      component: (
        <FeeScheduleCategoryForm
          onCategoryAdded={async (
            newCategory: FeeScheduleCategory
          ) => {
            let newRedline: FeeScheduleRedline;
            const category = FeeScheduleCategory.Prototype.fromObject(newCategory);
            if (!category) return;

              const matchingRedline = feeScheduleRedline.getMatchingCategoryRedline(category);
              if (matchingRedline) {
                const response = await confirm({
                  title: "Overwrite Category?",
                  message: "A category with the same name already exists. Do you want to overwrite it?",
                  okButtonText: "Overwrite"
                });

              if (response === ConfirmResponse.Cancel) {
                return;
              }
              newRedline = feeScheduleRedline.replaceEntryById(category.id, category);
            } else {
              newRedline = feeScheduleRedline.addEntry(category);
            }

            onFeeScheduleRedlineChange(
              newRedline,
              {
                name: ProposalFieldName.FeeSchedule,
                id: category.id
              }
            )
          }}
          closeDialog={closeDialog}
        />
      ),
    });
  }

  function renderCommentButton(categoryRedline?: FeeScheduleCategoryRedline) {
    return (
      <Tooltip title="Comments">
        <span>
          <IconButton
            size='small'
            onClick={(event) => {
              event.stopPropagation();
              onCommentsClicked?.(
                categoryRedline?.fieldId,
                categoryRedline?.currentEntry?.name.toString()
                ?? categoryRedline?.revisedEntry?.name.toString()
                ?? categoryRedline?.originalEntry?.name.toString()
                ?? undefined
              );
            }}
          >
            <Badge
              variant="dot"
              color="secondary"
              overlap="circular"
              invisible={
                !getForumForField(
                  ProposalFieldName.FeeSchedule,
                  categoryRedline?.fieldId,
                  commentForums
                )
              }
            >
              <CommentIcon fontSize='medium' color="action" />
            </Badge>
          </IconButton>
        </span>
      </Tooltip>
    );
  }

  const getCheckIcon = () => <CheckIcon color="success" />;
  const getCloseIcon = () => <CloseIcon color="error" />;
  const getUndoIcon = () => <UndoIcon />;
  const getCommentIcon = () => (
    <Badge
      variant="dot"
      color="secondary"
      overlap="circular"
      invisible={
        !getForumForField(
          ProposalFieldName.FeeSchedule,
          undefined,
          commentForums
        )
      }
    >
      <CommentIcon fontSize="medium" color="action" />
    </Badge>
  );
  
  const getTableContainer = (props: any) => <TableContainer {...props} />;
    return (
      <div className={className}>
        <FeeCategoryButton
          startIcon={<AddIcon />}
          onClick={beginAddCategory}
          variant="outlined"
        >
          Add New Category
        </FeeCategoryButton>
        <MaterialTable
          tableRef={tableRef}
          columns={columns}
          data={feeScheduleRedline}
          options={{
            showTitle: false,
            selection: false,
            search: false,
            rowStyle: (rowData: FeeScheduleCategoryRedline) => {
              const categoryRedline = FeeScheduleCategoryRedline.fromObject(rowData);
              return {
                border: categoryRedline.fieldId.isEqualTo(activeReviewField?.id) ? 
                  "2px solid #1976d2" : "none"
              }
            }
          }}
          onRowClick={(event, rowData) => {
            const categoryRedline = FeeScheduleCategoryRedline.fromObject(rowData);
            onCategoryClicked?.(categoryRedline.fieldId);
          }}
          actions={[
            {
              icon: getCheckIcon,
              tooltip: "Accept All Changes",
              hidden: feeScheduleRedline.isResolved,
              isFreeAction: true,
              onClick: () => onFeeScheduleRedlineChange(feeScheduleRedline.acceptAll())
            },
            {
              icon: getCloseIcon,
              tooltip: "Reject All Changes",
              hidden: feeScheduleRedline.isResolved,
              isFreeAction: true,
              onClick: () => onFeeScheduleRedlineChange(feeScheduleRedline.rejectAll())
            },
            {
              icon: getUndoIcon,
              tooltip: "Undo All Changes",
              hidden: !feeScheduleRedline.canBeUndone,
              isFreeAction: true,
              onClick: () => onFeeScheduleRedlineChange(
                feeScheduleRedline.undoAll(),
                { name: ProposalFieldName.FeeSchedule }
              )
            },
            {
              icon: getCommentIcon,
              tooltip: "Comments",
              isFreeAction: true,
              onClick: (event) => {
                event.stopPropagation();
                onCommentsClicked();
              },
            }
          ]}
          components={{
            Container: getTableContainer,
          }}
          localization={{
            header: {
              actions: "",
            },
            body: {
              emptyDataSourceMessage: (
                <Typography>There aren't any fee schedule categories</Typography>
              ),
            },
          }}
        />
      </div>
    );
  };

export default FeeScheduleRedlineSelection;
