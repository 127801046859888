import Guid from "common/values/guid/guid";
import axios, { AxiosHeaders, CanceledError } from "axios";
import UserEntityMemberInvitation, {
  UserMemberInvitationNotFoundError,
} from "../user-entity-member-invitation";
import UserMemberInvitationAPIResponse from "legal-entities/entities/user-entity-member-invitation/api/response-contracts/user-member-invitation-api-response";
import AcceptEntityUserInvitationAPIRequest from "legal-entities/entities/user-entity-member-invitation/api/request-contracts/accept-entity-user-invitation-api-request";
import { InvalidRegistrationInfoError } from "legal-entities/values/registration-info";
import AttorneyHubAPIService from "common/services/api/attorney-hub-api-service";
import Session from "users/session/session";
import AuthInfoAPIResponse from "users/entities/user/api/response-contracts/auth-info-api-response";
import { AuthResponse } from "users/entities/user/api/auth-email-api-service";

export default class UserEntityMemberInvitationAPIService {
  private headers: AxiosHeaders = new AxiosHeaders();

  constructor(session: Readonly<Session>) {
    if (session.authToken) {
      this.headers.set("Authorization", `Bearer ${session.authToken.value}`);
    }
  }

  async getUserEntityMemberInvitationById(
    id: Guid,
    abortController: AbortController
  ): Promise<UserEntityMemberInvitation> {
    const url = new URL(
      `/legal-entities/user-entity-member-invitations/${id.valueOf()}`,
      AttorneyHubAPIService.apiBaseUrl
    );
    try {
      const response = await axios.get(url.toString(), {
        headers: this.headers,
        signal: abortController?.signal,
      });
      return Object.assign(
        new UserMemberInvitationAPIResponse(),
        response.data
      ).deserialize();
    } catch (error: any) {
      if (error.response.status === 404)
        throw new UserMemberInvitationNotFoundError(id);
      if (error instanceof CanceledError) throw error;
      throw new UserEntityMemberInvitationAPIServiceError(
        "getUserEntityMemberInvitationById",
        error
      );
    }
  }

  async acceptUserEntityMemberInvitation(
    invitation: UserEntityMemberInvitation,
    session: Session
  ): Promise<void> {
    if (!invitation.id)
      throw new Error("Invitation ID is required to accept an invitation.");
    if (!invitation.registrationInfo?.password)
      throw new Error("Password is required to accept an invitation.");

    const url = new URL(
      `/legal-entities/user-member-invitations/${invitation.id.valueOf()}/accept`,
      AttorneyHubAPIService.apiBaseUrl
    );
    try {
      const response = await axios.post(
        url.toString(),
        new AcceptEntityUserInvitationAPIRequest(
          invitation.registrationInfo.password,
          invitation.registrationInfo.name
        ),
        {
          headers: this.headers.concat({ "Content-Type": "application/json" }),
        }
      );

      const authInfo: AuthInfoAPIResponse = Object.assign(
        new AuthInfoAPIResponse(),
        response.data.authInfo
      );
      const authResponse: AuthResponse = authInfo.deserialize();
      session.initializeFromAuthResponse(authResponse);
    } catch (error: any) {
      if (error.response.status === 400)
        throw new InvalidRegistrationInfoError(error.response.data);
      if (error?.response?.status === 404)
        throw new UserMemberInvitationNotFoundError(invitation.id);
      throw new UserEntityMemberInvitationAPIServiceError(
        "acceptUserEntityMemberInvitation",
        error,
        invitation
      );
    }
  }
}

export class UserEntityMemberInvitationAPIServiceError extends Error {
  invitation?: UserEntityMemberInvitation;
  method: string;
  error: any;
  constructor(
    method: string,
    error: any,
    invitation?: UserEntityMemberInvitation
  ) {
    super(`UserEntityMemberInvitationAPIService: ${error.message}`);
    this.method = method;
    this.error = error;
    this.invitation = invitation;
  }
}
