import MaterialTable, { Column, MaterialTableProps } from "@material-table/core";
import { Archive as ArchiveIcon } from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";
import { Chip, Link, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import RowActionMenu from "common/components/table/row-action-menu";
import { ActionsCellContainer } from "common/styles";
import Guid from "common/values/guid/guid";
import Document from "documents/entities/document/document";
import { filesize } from "filesize";
import { defaultStyles, FileIcon } from 'react-file-icon';
import { DocumentType } from "documents/values/document-type";
import _ from "lodash";

const NameLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline'
  }
}));
const NameContainer = styled('div')(({ theme }) => ({
  alignItems: 'center',
  columnGap: theme.spacing(1),
  display: 'flex',
  'svg': {
    width: '24px'
  }
}));

export function getTableColumns(
  tableRef: React.MutableRefObject<(MaterialTable<Document> & MaterialTableProps<Document>) | undefined>,
  downloadDocument: (id: Guid) => Promise<void>,
  deleteDocument: (id: Guid) => Promise<void>
): Column<Document>[] {
  return [
    {
      field: 'name',
      title: 'Name',
      render: (rowData) => renderNameColumn(rowData, downloadDocument)
    },
    {
      field: 'topics',
      title: 'Labels',
      render: (rowData) => {
        const labels = _.uniq(rowData.topics.map((topic) => topic.context));
        return labels.map((label) => {
          if (!label) return null;
          return (
            <Chip
              key={label}
              size="small"
              style={{ marginRight: '5px' }}
              label={label}
            />
          );
        });
      }
    },
    {
      field: 'numberOfProposals',
      title: '# of Proposals',
      render: (rowData) => rowData.topics.reduce((acc, topic) => acc + (topic.entityClass === 'Work.Proposal' ? 1 : 0), 0)
    },
    {
      field: 'created',
      title: 'Created',
      type: 'date',
      render: (rowData) => rowData.created?.format('MM/DD/YY hh:mm A'),
    },
    {
      field: 'fileSize',
      title: 'Size',
      render: (rowData) => filesize(rowData.fileSize)
    },
    {
      title: 'Actions',
      align: 'right',
      filtering: false,
      draggable: false,
      sorting: false,
      grouping: false,
      disableClick: true,
      hiddenByColumnsButton: true,
      removable: false,
      width: 'fit-content',
      render: (rowData) => renderActionsColumn(rowData, downloadDocument, deleteDocument)
    }
  ];
}

function renderNameColumn(
  rowData: Document,
  downloadDocument: (id: Guid) => Promise<void>
): React.ReactNode {
  const fileType = rowData.fileType.toString() as keyof typeof DocumentType;

  return (
    <NameContainer>
      <FileIcon
        extension={fileType}
        {...defaultStyles[fileType.toLowerCase() as keyof typeof defaultStyles]}
      />
      <NameLink onClick={() => downloadDocument(rowData.id)}>
        {rowData.name}
      </NameLink>
    </NameContainer>
  );
}

function renderActionsColumn(
  rowData: Document,
  downloadDocument: (id: Guid) => Promise<void>,
  deleteDocument: (id: Guid) => Promise<void>
): React.ReactNode {
  return (
    <ActionsCellContainer>
      <RowActionMenu menuItems={
        getMenuItems(
          rowData,
          () => downloadDocument(rowData.id),
          () => deleteDocument(rowData.id)
        )
      } />
    </ActionsCellContainer>
  );
}

function getMenuItems(
  rowData: Document,
  onDownload: () => Promise<void>,
  onDelete: () => Promise<void>
): React.ReactNode[] {
  return [
    <MenuItem key="download" onClick={onDownload}>
      <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon>
      <ListItemText>Download</ListItemText>
    </MenuItem>,
    <MenuItem key="archive">
      <ListItemIcon><ArchiveIcon fontSize="small" /></ListItemIcon>
      <ListItemText>Archive</ListItemText>
    </MenuItem>
    /*<MenuItem key="delete" onClick={onDelete}>
      <ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon>
      <ListItemText>Delete</ListItemText>
  </MenuItem>*/
  ];
}
