import React, { useEffect } from 'react';
import MaterialTable, { Column } from '@material-table/core';
import { Typography, Icon } from '@mui/material';
import UserNetworkConnectionAPIService from 'users/entities/user-network-connection/api/user-network-connection-api-service';
import NetworkedUserInfo from 'users/entities/user-network-connection/networked-user-info';
import { useSession } from 'users/session/session-context';
import { CanceledError } from 'axios';

type NetworkedIndividualsProps = {
  onSelected: (
    rowData: NetworkedUserInfo
  ) => void;
}

export default function NetworkedIndividuals(props: Readonly<NetworkedIndividualsProps>) {
  const { onSelected } = props;

  const [isLoading, setIsLoading] = React.useState(false);
  const [connections, setConnections] = React.useState<NetworkedUserInfo[]>([]);

  const session = useSession();

  const columns: Column<NetworkedUserInfo>[] = [
    {
      title: 'Name',
      field: 'name',
      emptyValue: <Icon>remove</Icon>,
      render: rowData => rowData?.name?.toString()
    },
    {
      title: 'Email',
      field: 'email',
      emptyValue: <Icon>remove</Icon>,
      render: rowData => rowData?.email?.toString()
    }
  ];

  useEffect(() => {
    let abortController = new AbortController();
    getNetworkedIndividuals(abortController);
    return () => {
      abortController.abort();
      abortController = new AbortController();
    };
  }, []);

  async function getNetworkedIndividuals(abortController: AbortController) {
    try {
      setIsLoading(true);
      const userNetworkService = new UserNetworkConnectionAPIService(session);
      const connections = await userNetworkService
      .getUserConnectionsInfo(abortController);
      setConnections(connections);
      session.updateNetworkConnections(connections);
    } catch (error) {
      if(error instanceof CanceledError) 
        return;
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <MaterialTable
      data={connections}
      options={{
        showTitle: false,
        actionsColumnIndex: -1,
        pageSize: 10,
        pageSizeOptions: [10, 25, 50],
      }}
      onRowClick={(event, rowData) => {
        if (!rowData) return;
        onSelected(rowData)
      }}
      columns={columns}
      isLoading={isLoading}
      localization={{
        body: {
          emptyDataSourceMessage: (
            <Typography variant="h6">
              You aren't networked with anyone
            </Typography>
          )
        }
      }}
    />
  )
}
