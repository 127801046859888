import MaterialTable, { MaterialTableProps } from "@material-table/core";
import { FileCopy } from "@mui/icons-material";
import { Theme, useMediaQuery } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import {
  ConfirmResponse,
  useConfirmDialog,
} from "app/providers/confirm-dialog";
import TableToolbarComponent from "app/routes/contracts/table/toolbar";
import TableContainerComponent from "common/components/table/container";
import TableTitle from "common/components/table/title";
import { downloadFile } from "common/helpers/utils";
import { PageContainer, TableTheme } from "common/styles";
import Guid from "common/values/guid/guid";
import DocumentAPIService from "documents/entities/document/api/document-api-service";
import Document from "documents/entities/document/document";
import DocumentTopic from "documents/values/document-topic";
import _ from "lodash";
import { enqueueSnackbar } from "notistack";
import * as React from "react";
import { useEffect } from "react";
import { Params, useLoaderData, useNavigate, useSearchParams } from "react-router-dom";
import { useSession } from "users/session/session-context";
import { getTableActions, TableActionsComponent } from "./table/actions";
import { getTableColumns } from "./table/columns";
import loadData from "./table/data-loader";
import { NoRowsPlaceholder } from "./table/no-rows-placeholder";
import { PageTab } from "./tabs";

type ContractsProps = {};

export default function Contracts(props: Readonly<ContractsProps>) {
  const tableRef = React.useRef<
    MaterialTable<Document> & MaterialTableProps<Document>
  >();
  const documentFileUploaderRef = React.useRef<HTMLInputElement | null>(null);

  const [loading, setLoading] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(PageTab.Conflicts);
  const [tableBodyHeight, setTableBodyHeight] = React.useState(0);

  const [mine, setMine] = React.useState(true);
  const [clientVendor, setClientVendor] = React.useState(false);

  const navigate = useNavigate();
  const session = useSession();
  const routeParams = useLoaderData() as Params<string>;
  const [searchParams] = useSearchParams();
  const confirm = useConfirmDialog();
  const isMediumDisplaySize = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const tabs = (
    <Tabs
      value={activeTab}
      indicatorColor="primary"
      textColor="primary"
      onChange={(_event, newValue) => navigate(`/contracts/${newValue}?context=${mine ? 'mine' : 'clientVendor'}`)}>
      <Tab value={PageTab.Conflicts} label="Conflicts" />
      <Tab value={PageTab.Policies} label="Policies" />
    </Tabs>
  );

  // Handle session account type change
  useEffect(() => {
    tableRef?.current?.onQueryChange({});
  }, [session.accountType]);

  // Handle URL changes
  useEffect(() => {
    if (routeParams.tab) {
      setActiveTab(routeParams.tab as PageTab);
    } else {
      navigate(`/contracts/${PageTab.Conflicts}?context=mine`);
    }
  }, [routeParams]);

  useEffect(() => {
    const context = searchParams.get("context");
    setMine(context === "mine" || context === null);
    setClientVendor(context === "clientVendor");
    tableRef?.current?.onQueryChange({});
  }, [searchParams]);

  // Handle component mount
  useEffect(() => {
    function computeTableHeight() {
      const tableHead = document.querySelector("thead.MuiTableHead-root");
      const tableFoot = document.querySelector("tfoot.MuiTableFooter-root");
      const siteFooter = document.querySelector("footer#siteFooter");

      const tableOffsetTop = tableHead?.getBoundingClientRect().top;
      const tableFootHeight = tableFoot?.getBoundingClientRect().height;
      const siteFooterHeight = siteFooter?.getBoundingClientRect().height;

      if (!tableOffsetTop || !tableFootHeight || !siteFooterHeight) return;

      setTableBodyHeight(
        Math.floor(
          window.innerHeight -
          tableOffsetTop -
          tableFootHeight -
          siteFooterHeight
        )
      );
    }

    const debouncedResize = _.debounce(() => computeTableHeight(), 250);
    debouncedResize();
    window.addEventListener("resize", debouncedResize);

    return () => {
      window.removeEventListener("resize", debouncedResize);
    };
  }, []);

  /**
   * Uploads the document file
   * @param {React.ChangeEvent<HTMLInputElement>} event The file upload event
   * @returns {Promise<void>}
   */
  async function handleDocumentFileUpload(
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> {
    if (!event.currentTarget.files) return;
    
    const file: File = event.currentTarget.files[0];

    const templateResponse = await confirm({
      title: "Create Template?",
      message: "Do you want to create a template or just upload the document?",
      okButtonText: "Create Template",
      alternativeOkText: "Just Upload",
      cancelButtonText: "Cancel"
    });

    if (templateResponse === ConfirmResponse.Cancel) return;

    try {
      setUploading(true);

      let context: string | undefined;
      if (templateResponse === ConfirmResponse.Ok) {
        context = activeTab === PageTab.Conflicts ? "ConflictsTemplate" : "PolicyTemplate";
      } else {
        context = activeTab === PageTab.Conflicts ? "Conflicts" : "Policy";
      }
      const service = new DocumentAPIService(session);
      await service.createDocument(
        file,
        file.name,
        [DocumentTopic.fromObject({ context })]
      );
      enqueueSnackbar("Uploaded document file", { variant: "success" });
      tableRef?.current?.onQueryChange(); // Force table data refresh
    } catch (error: any) {
      if (error.response && error.response.status === 415) {
        enqueueSnackbar(error.response.data, { variant: "error" });
      } else if (error.response && error.response.status === 422) {
        enqueueSnackbar("Unsupported file type", { variant: "error" });
      } else {
        enqueueSnackbar("Unknown error occurred during upload", {
          variant: "error",
        });
      }
    } finally {
      event.target.value = "";
      setUploading(false);
    }
  }

  /**
   * Downloads the document file with the given id
   * @param {Guid} id The id of the document file to download
   * @returns {Promise<void>}
   */
  async function handleDownload(id: Guid): Promise<void> {
    try {
      if (!id) Promise.reject(new Error("No document id provided"));
      const service = new DocumentAPIService(session);
      const response = await service.downloadDocument(id);
      downloadFile(response);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to download file", { variant: "error" });
    }
  }

  /**
   * Deletes the document file with the given id
   * @param {Guid} id The id of the document file to delete
   * @returns {Promise<void>}
   */
  async function handleDelete(id: Guid): Promise<void> {
    if (!id) Promise.reject(new Error("No document id provided"));

    const response = await confirm({
      title: "Delete Document?",
      message: "Are you sure you want to delete this document?",
      okButtonText: "Delete",
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      const service = new DocumentAPIService(session);
      await service.deleteDocument(id);
      tableRef?.current?.onQueryChange(); // Force table data refresh
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to delete file", { variant: "error" });
    }
  }

  return (
    <PageContainer>
      <MaterialTable
        key={activeTab}
        tableRef={tableRef}
        title={<TableTitle title="Contracts" icon={<FileCopy />} />}
        columns={getTableColumns(
          tableRef,
          handleDownload,
          handleDelete
        )}
        data={(query) => loadData(
          session,
          activeTab,
          mine,
          clientVendor,
          new AbortController(),
          query,
          setLoading
        )}
        actions={getTableActions(
          activeTab,
          isMediumDisplaySize,
          uploading,
          () => documentFileUploaderRef.current?.click()
        )}
        isLoading={loading}
        options={{
          debounceInterval: 250,
          emptyRowsWhenPaging: false,
          filtering: true,
          filterRowStyle: TableTheme.filterRow,
          grouping: true,
          minBodyHeight: tableBodyHeight,
          maxBodyHeight: tableBodyHeight,
          pageSize: 10,
          pageSizeOptions: [10, 25, 50],
          paginationType: isMediumDisplaySize ? "normal" : "stepped",
          showFirstLastPageButtons: !isMediumDisplaySize,
        }}
        components={{
          Container: (props) => TableContainerComponent({ ...props }),
          Actions: (props) =>
            TableActionsComponent({
              activeTab,
              isMediumDisplaySize: isMediumDisplaySize,
              uploading: uploading,
              onUpload: () => documentFileUploaderRef.current?.click(),
              ...props,
            }),
          Toolbar: (props) =>
            TableToolbarComponent({
              session,
              activeTab,
              tabs,
              mine,
              clientVendor,
              isMediumDisplaySize,
              setMine,
              setClientVendor,
              ...props,
            }),
        }}
        localization={{
          body: {
            emptyDataSourceMessage: (
              <NoRowsPlaceholder tableBodyHeight={tableBodyHeight} />
            ),
          },
        }}
      />
      <input
        type="file"
        hidden={true}
        ref={documentFileUploaderRef}
        onChange={handleDocumentFileUpload}
      />
    </PageContainer>
  );
}
