import {
  Action, MTableActions
} from "@material-table/core";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { Button } from "@mui/material";
import { ActionsContainer } from "common/styles/table";
import { NavigateFunction, useNavigate } from "react-router";

export interface TableActionsComponentProps {
  isMediumDisplaySize: boolean;
  navigate: NavigateFunction;
}

export function TableActionsComponent(props: Readonly<TableActionsComponentProps>): React.ReactElement {
  const navigate = useNavigate();

  return (
    <ActionsContainer>
      {!props.isMediumDisplaySize && (
        <Button
          color="primary"
          startIcon={<AddBoxIcon />}
          onClick={() => navigate("/teams/templates/create")}
        >
          New Template Team
        </Button>
      )}
      <MTableActions {...props} />
    </ActionsContainer>
  );
}

export function getTableActions(props: TableActionsComponentProps): Action<object>[] {
  return [newTemplateTeam(props)];
}

function newTemplateTeam(props: TableActionsComponentProps): Action<object> {
  return {
    icon: AddBoxIcon,
    iconProps: { color: "primary", fontSize: "large" },
    tooltip: "New Template Team",
    position: "toolbar",
    hidden: !props.isMediumDisplaySize,
    isFreeAction: true,
    onClick: () => props.navigate("/teams/templates/create")
  };
}
