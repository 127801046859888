import _ from 'lodash';

import { enqueueSnackbar } from 'notistack';

import React, { useEffect } from 'react';
import { useLoaderData, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import MaterialTable, { Action, MaterialTableProps } from '@material-table/core';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Theme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import BookmarksIcon from '@mui/icons-material/Bookmarks';

import { NoRowsPlaceholder } from 'app/routes/communications/table/no-rows-placeholder';

import { PageContainer, TableTheme } from 'common/styles';

import { PageTab } from './tabs';
import { getTableColumns } from './table/columns';
import loadData, { MarketplaceBookmark } from './table/data-loader';
import BookmarkAPIService from 'marketplace/entities/bookmark/api/bookmark-api-service';
import TableContainerComponent from 'common/components/table/container';
import TableTitle from 'common/components/table/title';
import Guid from 'common/values/guid/guid';
import ViewIndividualProfile from 'marketplace/values/individual-profile/view/view-individual-profile';
import ViewTeamProfile from 'marketplace/values/team-profile/view/view-team-profile';
import ViewCompanyProfile from 'marketplace/values/company-profile/view/view-company-profile';
import TableToolbarComponent from 'app/routes/marketplace/bookmarks/table/toolbar';
import { ConfirmResponse, useConfirmDialog } from 'app/providers/confirm-dialog';
import { useDialog } from 'app/providers/dialog';
import { useSession } from 'users/session/session-context';

type BookmarksProps = {};

export default function Bookmarks(props: Readonly<BookmarksProps>) {
  const tableRef = React.useRef<MaterialTable<MarketplaceBookmark> & MaterialTableProps<MarketplaceBookmark>>();

  const [loading, setLoading] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(PageTab.Individual);
  const [tableBodyHeight, setTableBodyHeight] = React.useState(0);

  const tabFromUrl = useLoaderData() as string;
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const confirm = useConfirmDialog();
  const { openDialog, closeAllDialogs } = useDialog();
  const isMediumDisplaySize = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const session = useSession();

  const tabs = (
    <Tabs
      value={activeTab}
      indicatorColor="primary"
      textColor="primary"
      onChange={(_event, newValue) => navigate(`/marketplace/bookmarks/${newValue}`)}>
      <Tab value="individual" label="Individual" />
      <Tab value="team" label="Team" />
      <Tab value="company" label="Company" />
    </Tabs>
  );

  // Close any open dialogs when the URL changes
  useEffect(() => {
    closeAllDialogs();
  }, [location]);

  // Handle URL changes
  useEffect(() => {
    if (tabFromUrl) setActiveTab(tabFromUrl as PageTab);
    tableRef?.current?.onQueryChange({});
  }, [searchParams, tabFromUrl, tableRef]);

  // Handle component mount
  useEffect(() => {
    function computeTableHeight() {
      const tableHead = document.querySelector('thead.MuiTableHead-root');
      const tableFoot = document.querySelector('tfoot.MuiTableFooter-root');
      const siteFooter = document.querySelector('footer#siteFooter');

      const tableOffsetTop = tableHead?.getBoundingClientRect().top;
      const tableFootHeight = tableFoot?.getBoundingClientRect().height;
      const siteFooterHeight = siteFooter?.getBoundingClientRect().height;

      if (!tableOffsetTop || !tableFootHeight || !siteFooterHeight) return;

      setTableBodyHeight(Math.floor(window.innerHeight - tableOffsetTop - tableFootHeight - siteFooterHeight));
    }

    const debouncedResize = _.debounce(() => computeTableHeight(), 250);
    debouncedResize();
    window.addEventListener('resize', debouncedResize);

    return () => {
      window.removeEventListener('resize', debouncedResize);
    }
  }, []);

  const actions: Action<MarketplaceBookmark>[] = [
    {
      icon: 'delete',
      tooltip: 'Delete Bookmark',
      onClick: (_event, rowData) => {
        if (Array.isArray(rowData)) return;
        handleDelete(rowData);
      }
    }
  ];

  async function handleDelete(bookmark: MarketplaceBookmark) {
    const response = await confirm({
      title: 'Delete Bookmark?',
      message: 'Do you want to delete?',
      okButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    });

    if (response === ConfirmResponse.Cancel) return;

    const bookmarkService = new BookmarkAPIService(session);

    const removeMethodMap = {
      company: bookmarkService.deleteCompanyBookmark,
      team: bookmarkService.deleteTeamBookmark,
      individual: bookmarkService.deleteIndividualBookmark
    };

    try {
      await removeMethodMap[activeTab](bookmark.bookmarkId);
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Couldn't delete bookmark. Please try again", { variant: 'error' });
    }

    enqueueSnackbar("Deleted bookmark", { variant: 'success' });
    tableRef?.current?.onQueryChange();  // Force table data refresh
  }

  function handleViewProfile(profileId?: Guid): void {
    if (!profileId) return;

    let component: JSX.Element;

    if (activeTab === PageTab.Individual) {
      component = (
        <ViewIndividualProfile
          individualId={profileId}
          confirmDialog={confirm}
        />
      );
    } else if (activeTab === PageTab.Team) {
      component = (
        <ViewTeamProfile
          teamId={profileId}
          confirmDialog={confirm}
        />
      );
    } else {
      component = (
        <ViewCompanyProfile
          companyId={profileId}
          confirmDialog={confirm}
        />
      );
    }

    openDialog({
      component: component,
      titleStyle: {
        position: 'absolute',
        right: 0,
        top: 0,
      },
      contentSxProps: {
        display: 'flex',
        overflowX: 'hidden'
      },
      MuiProps: {
        maxWidth: 'lg',
        fullWidth: false
      }
    });
  }

  return (
    <PageContainer>
      <MaterialTable
        key={activeTab}
        tableRef={tableRef}
        title={
          <TableTitle title='Manage Bookmarks' icon={<BookmarksIcon />} />
        }
        columns={getTableColumns(handleViewProfile)}
        data={(query) => loadData(query, activeTab, setLoading, session)}
        actions={actions}
        isLoading={loading}
        options={{
          debounceInterval: 250,
          emptyRowsWhenPaging: false,
          filtering: true,
          filterRowStyle: TableTheme.filterRow,
          minBodyHeight: tableBodyHeight,
          maxBodyHeight: tableBodyHeight,
          pageSize: 10,
          pageSizeOptions: [10, 25, 50],
          paginationType: isMediumDisplaySize ? 'normal' : 'stepped',
          showFirstLastPageButtons: !isMediumDisplaySize,
          actionsColumnIndex: -1
        }}
        components={{
          Container: props => TableContainerComponent({ ...props }),
          Toolbar: props => TableToolbarComponent({
            tableRef,
            activeTab,
            tabs,
            isMediumDisplaySize,
            ...props
          })
        }}
        localization={{
          body: {
            emptyDataSourceMessage: <NoRowsPlaceholder tableBodyHeight={tableBodyHeight} />
          }
        }}
      />
    </PageContainer>
  );
}
