import { Link } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDialog } from "app/providers/dialog";
import _ from "lodash";
import { MouseEvent, useState } from "react";
import Proposal from "work/entities/proposal/proposal";
import RedlinePopover from "work/entities/proposal/redlining/view/proposal-redline-popover";
import FeeScheduleCategories from "work/values/fee-schedule-category/view/fee-schedule-categories";

const FeeLink = styled(Link, { shouldForwardProp: prop => prop !== 'revised' })<{ revised?: boolean }>(({ theme, revised }) => ({
  alignItems: 'center',
  color: '#000',
  cursor: 'pointer',
  display: 'flex',
  marginBottom: theme.spacing(1),
  overflow: 'hidden',
  textDecoration: revised ? 'underline dotted red' : 'underline',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
}));

type FeeScheduleLinkProps = {
  proposal: Proposal;
};

export default function FeeScheduleLink(props: Readonly<FeeScheduleLinkProps>) {
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLElement | null>(null);

  const { openDialog } = useDialog();

  function handleClick(_event: MouseEvent) {
    const viewFeeScheduleCompoment = (
      <FeeScheduleCategories
        categories={props.proposal.feeSchedule}
        viewOnly={true}
      />
    );

    openDialog({
      component: viewFeeScheduleCompoment
    });
  }

  return <>
    <FeeLink
      revised={
        !!(props.proposal?.supersedes && 
        !_.isEqual(props.proposal?.feeSchedule, props.proposal?.supersedes?.feeSchedule))
      }
      onMouseEnter={(event: React.MouseEvent) => setPopoverAnchor(event.currentTarget as HTMLElement)}
      onMouseLeave={(_event: React.MouseEvent) => setPopoverAnchor(null)}
      onClick={handleClick}>
      View
    </FeeLink>
    <RedlinePopover
      columnName='feeSchedule'
      proposal={props.proposal}
      open={Boolean(popoverAnchor)}
      anchorEl={popoverAnchor}
    />
  </>;
}
