import { JsonPatchOperation } from "common/services/api/attorney-hub-api-service";
import MarketplaceFeeScheduleCategoryAPIRequest from "marketplace/entities/fee-schedule-category/api/request-contracts/marketplace-fee-schedule-category-api-request";
import FeeScheduleCategory from "marketplace/entities/fee-schedule-category/fee-schedule-category";
import FeeSchedule from "marketplace/entities/fee-schedule/fee-schedule";


export default class UpdateFeeScheduleAPIRequest {
    payload: JsonPatchOperation[] = [];

    constructor(original: FeeSchedule, updated: FeeSchedule) {
        if (updated.name !== original?.name)
            this.payload.push({
                op: "replace",
                path: "/name",
                value: updated.name,
            });
        if (!UpdateFeeScheduleAPIRequest.haveSameTags(original, updated))
            this.payload.push({
                op: "replace",
                path: "/tags",
                value: updated.tags,
            });
        if (!UpdateFeeScheduleAPIRequest.haveSameCategories(original, updated)) {
            this.payload.push({
                op: "replace",
                path: "/categories",
                value: updated.categories.map((category) => new MarketplaceFeeScheduleCategoryAPIRequest(category)),
            });
        }
    }

    static haveSameTags(original: FeeSchedule, updated: FeeSchedule): boolean {
        if (original.tags.length !== updated.tags.length) {
            return false;
        }
        const originalTags = original.tags.map((tag) => tag.valueOf());
        const updatedTags = updated.tags.map((tag) => tag.valueOf());
        for (let i = 0; i < updatedTags.length; i++) {
            if (!originalTags.includes(updatedTags[i])) {
                return false;
            }
        }
        return true;
    }

    static haveSameCategories(original: FeeSchedule, updated: FeeSchedule): boolean {
        if (original.categories.length !== updated.categories.length) {
            return false;
        }
        for (let i = 0; i < updated.categories.length; i++) {
            if (!original.categories.find((category) =>
                FeeScheduleCategory.areSame(category, updated.categories[i])
            ))
                return false;
        }
        return true;
    }
}
