import { Query, QueryResult } from "@material-table/core";

import Session from "users/session/session";
import WorkAgreementAPIService, { WorkAgreementContextParam } from "work/entities/work-agreement/api/work-agreement-api-service";

import { PageTab } from "app/routes/work/tabs";
import WorkAgreement from "work/entities/work-agreement/work-agreement";

export default async function loadData(
  query: Query<WorkAgreement>,
  activeTab: PageTab,
  session: Session,
  setLoading: (isLoading: boolean) => void
): Promise<QueryResult<WorkAgreement>> {
  const emptyResponse = {
    data: [],
    page: 0,
    totalCount: 0,
  } as QueryResult<WorkAgreement>;

  try {
    setLoading(true);

    let agreements: WorkAgreement[] = [];
    const context = activeTab === PageTab.Active ? 'allactive' : 'archive';
    agreements = await getAgreements(session, context);
    return {
      data: agreements,
      page: query.page,
      totalCount: agreements.length
    }
  } catch (error) {
    console.error(error);
    return emptyResponse;
  } finally {
    setLoading(false);
  }

  async function getAgreements(session: Session, context: WorkAgreementContextParam): Promise<WorkAgreement[]> {
    const workAgreementService = new WorkAgreementAPIService(session);
    return await workAgreementService.getWorkAsync({
      entityId: session.entities[0].entityId,
      viewingAs: session.accountType,
      context: context
    });
  }
}
