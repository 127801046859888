import AHBoolean from "common/values/boolean/boolean";
import Date from "common/values/date/date";
import Percent from "common/values/percent/percent";
import Individual from "marketplace/entities/individual/individual";
import { ProposalField } from "work/entities/proposal/proposal";
import { FeeScheduleRedline } from "work/entities/proposal/redlining/fee-schedule-redline/fee-schedule-redline";
import FieldRedline, {
  FieldRedlineArray,
} from "work/entities/proposal/redlining/field-redline";
import { RedlineChange } from "work/entities/proposal/redlining/redline-change";
import { ProposalFieldName } from "work/values/constants";
import ProjectDescription from "work/values/project-description/project-description";
import ProjectName from "work/values/project-name/project-name";
import WorkDocument from "work/values/work-document/work-document";

export default class ProposalRedline {
  private _name: FieldRedline<ProjectName>;
  private _description: FieldRedline<ProjectDescription>;
  private _responseDueBy: FieldRedline<Date>;
  private _startDate: FieldRedline<Date>;
  private _endDate: FieldRedline<Date>;
  private _team: FieldRedlineArray<Individual>;
  private _feeSchedule: FeeScheduleRedline;
  private _clientPolicyDocuments: FieldRedlineArray<WorkDocument>;
  private _vendorPolicyDocuments: FieldRedlineArray<WorkDocument>;
  private _conflictsCheckWaived: FieldRedline<AHBoolean>;
  private _teamRestricted: FieldRedline<AHBoolean>;
  private _conflictsDocuments: FieldRedlineArray<WorkDocument>;
  private _discount: FieldRedline<Percent>;
  private _lastFieldUpdated: ProposalField | undefined;
  private _fieldsPendingReview: ProposalField[] = [];

  constructor(
    name: FieldRedline<ProjectName>,
    description: FieldRedline<ProjectDescription>,
    dates: RedlinedDates,
    team: FieldRedlineArray<Individual>,
    feeSchedule: FeeScheduleRedline,
    conflictsCheckWaived: FieldRedline<AHBoolean>,
    teamRestricted: FieldRedline<AHBoolean>,
    documents: RedlinedDocuments,
    discount: FieldRedline<Percent>
  ) {
    this._name = name;
    this._description = description;
    this._responseDueBy = dates?.responseDueBy;
    this._startDate = dates?.startDate;
    this._endDate = dates?.endDate;
    this._team = team;
    this._feeSchedule = feeSchedule;
    this._clientPolicyDocuments = documents.clientPolicyDocuments;
    this._vendorPolicyDocuments = documents.vendorPolicyDocuments;
    this._conflictsCheckWaived = conflictsCheckWaived;
    this._teamRestricted = teamRestricted;
    this._conflictsDocuments = documents.conflictsDocuments;
    this._discount = discount;

    this.registerFieldsPendingReview();
  }

  public get name(): FieldRedline<ProjectName> {
    return this._name.clone();
  }
  public get description(): FieldRedline<ProjectDescription> {
    return this._description.clone();
  }
  public get responseDueBy(): FieldRedline<Date> {
    return this._responseDueBy.clone();
  }
  public get startDate(): FieldRedline<Date> {
    return this._startDate.clone();
  }
  public get endDate(): FieldRedline<Date> {
    return this._endDate.clone();
  }
  public get team(): FieldRedlineArray<Individual> {
    return this._team.clone();
  }
  public get feeSchedule(): FeeScheduleRedline {
    return this._feeSchedule.clone();
  }
  public get clientPolicyDocuments(): FieldRedlineArray<WorkDocument> {
    return this._clientPolicyDocuments.clone();
  }
  public get vendorPolicyDocuments(): FieldRedlineArray<WorkDocument> {
    return this._vendorPolicyDocuments.clone();
  }
  public get conflictsCheckWaived(): FieldRedline<AHBoolean> {
    return this._conflictsCheckWaived.clone();
  }
  public get teamRestricted(): FieldRedline<AHBoolean> {
    return this._teamRestricted.clone();
  }
  public get conflictsDocuments(): FieldRedlineArray<WorkDocument> {
    return this._conflictsDocuments.clone();
  }

  public get discount(): FieldRedline<Percent> {
    return this._discount.clone();
  }

  public get lastFieldUpdated(): ProposalField | undefined {
    return this._lastFieldUpdated;
  }

  public get sessionHistory(): RedlineChange[] {
    return [
      ...this.name.sessionHistory,
      ...this.description.sessionHistory,
      ...this.responseDueBy.sessionHistory,
      ...this.startDate.sessionHistory,
      ...this.endDate.sessionHistory,
      ...this.team.sessionHistory,
      ...this.feeSchedule.sessionHistory,
      ...this.clientPolicyDocuments.sessionHistory,
      ...this.vendorPolicyDocuments.sessionHistory,
      ...this.conflictsCheckWaived.sessionHistory,
      ...this.teamRestricted.sessionHistory,
      ...this.conflictsDocuments.sessionHistory,
      ...this.discount.sessionHistory,
    ];
  }

  public get isResolved(): boolean {
    return (
      this.name.isResolved &&
      this.description.isResolved &&
      this.responseDueBy.isResolved &&
      this.startDate.isResolved &&
      this.endDate.isResolved &&
      this.team.isResolved &&
      this.feeSchedule.isResolved &&
      this.clientPolicyDocuments.isResolved &&
      this.vendorPolicyDocuments.isResolved &&
      this.conflictsCheckWaived.isResolved &&
      this.teamRestricted.isResolved &&
      this.conflictsDocuments.isResolved &&
      this.discount.isResolved
    );
  }

  public get revisionsAccepted(): boolean {
    return (
      this.name.revisionAccepted &&
      this.description.revisionAccepted &&
      this.responseDueBy.revisionAccepted &&
      this.startDate.revisionAccepted &&
      this.endDate.revisionAccepted &&
      this.team.revisionAccepted &&
      this.feeSchedule.revisionAccepted &&
      this.clientPolicyDocuments.revisionAccepted &&
      this.vendorPolicyDocuments.revisionAccepted &&
      this.conflictsCheckWaived.revisionAccepted &&
      this.teamRestricted.revisionAccepted &&
      this.conflictsDocuments.revisionAccepted &&
      this.discount.revisionAccepted
    );
  }

  public get canBeUndone(): boolean {
    return (
      this.name.canBeUndone ||
      this.description.canBeUndone ||
      this.responseDueBy.canBeUndone ||
      this.startDate.canBeUndone ||
      this.endDate.canBeUndone ||
      this.team.canBeUndone ||
      this.feeSchedule.canBeUndone ||
      this.clientPolicyDocuments.canBeUndone ||
      this.vendorPolicyDocuments.canBeUndone ||
      this.conflictsCheckWaived.canBeUndone ||
      this.teamRestricted.canBeUndone ||
      this.conflictsDocuments.canBeUndone ||
      this.discount.canBeUndone
    );
  }

  public get allReviewableFields(): ProposalField[] {
    const fields: ProposalField[] = [];
    fields.push({ name: ProposalFieldName.Name });
    fields.push({ name: ProposalFieldName.Description });
    fields.push({ name: ProposalFieldName.ResponseDueBy });
    fields.push({ name: ProposalFieldName.StartDate });
    fields.push({ name: ProposalFieldName.EndDate });
    fields.push({ name: ProposalFieldName.TeamRestriction });
    for (const teamMemberRedline of this.team.redlines) {
      fields.push({
        name: ProposalFieldName.Team,
        id: teamMemberRedline.fieldId,
      });
    }
    for (const feeCategoryRedline of this.feeSchedule) {
      fields.push({
        name: ProposalFieldName.FeeSchedule,
        id: feeCategoryRedline.fieldId,
      });
    }
    fields.push({ name: ProposalFieldName.WaiveConflictsCheck });
    for (const documentRedline of this.conflictsDocuments.redlines) {
      fields.push({
        name: ProposalFieldName.Conflicts,
        id: documentRedline.fieldId,
      });
    }
    for (const documentRedline of this.clientPolicyDocuments.redlines) {
      fields.push({
        name: ProposalFieldName.ClientPolicies,
        id: documentRedline.fieldId,
      });
    }
    for (const documentRedline of this.vendorPolicyDocuments.redlines) {
      fields.push({
        name: ProposalFieldName.VendorPolicies,
        id: documentRedline.fieldId,
      });
    }
    fields.push({ name: ProposalFieldName.Discount });
    return fields;
  }

  public get fieldsPendingReview(): ProposalField[] {
    return this._fieldsPendingReview;
  }

  public clearSessionHistory(): ProposalRedline {
    const newRedline = this.clone();
    newRedline._name = this.name.clearSessionHistory();
    newRedline._description = this.description.clearSessionHistory();
    newRedline._responseDueBy = this.responseDueBy.clearSessionHistory();
    newRedline._startDate = this.startDate.clearSessionHistory();
    newRedline._endDate = this.endDate.clearSessionHistory();
    newRedline._team = this.team.clearSessionHistory();
    newRedline._feeSchedule = this.feeSchedule.clearSessionHistory();
    newRedline._clientPolicyDocuments =
      this.clientPolicyDocuments.clearSessionHistory();
    newRedline._vendorPolicyDocuments =
      this.vendorPolicyDocuments.clearSessionHistory();
    newRedline._conflictsCheckWaived =
      this.conflictsCheckWaived.clearSessionHistory();
    newRedline._teamRestricted = this.teamRestricted.clearSessionHistory();
    newRedline._conflictsDocuments =
      this.conflictsDocuments.clearSessionHistory();
    newRedline._discount = this.discount.clearSessionHistory();
    return newRedline;
  }

  public accept() {
    this._name = this.name.accept();
    this._description = this.description.accept();
    this._responseDueBy = this.responseDueBy.accept();
    this._startDate = this.startDate.accept();
    this._endDate = this.endDate.accept();
    this._team = this.team.acceptAll();
    this._feeSchedule = this.feeSchedule.acceptAll();
    this._clientPolicyDocuments = this.clientPolicyDocuments.acceptAll();
    this._vendorPolicyDocuments = this.vendorPolicyDocuments.acceptAll();
    this._conflictsCheckWaived = this.conflictsCheckWaived.accept();
    this._teamRestricted = this.teamRestricted.accept();
    this._conflictsDocuments = this.conflictsDocuments.acceptAll();
    this._discount = this.discount.accept();
  }

  public reject() {
    this._name = this.name.reject();
    this._description = this.description.reject();
    this._responseDueBy = this.responseDueBy.reject();
    this._startDate = this.startDate.reject();
    this._endDate = this.endDate.reject();
    this._team = this.team.rejectAll();
    this._feeSchedule = this.feeSchedule.rejectAll();
    this.clientPolicyDocuments.rejectAll();
    this.vendorPolicyDocuments.rejectAll();
    this._conflictsCheckWaived = this.conflictsCheckWaived.reject();
    this._teamRestricted = this.teamRestricted.reject();
    this.conflictsDocuments.rejectAll();
    this._discount = this.discount.reject();
  }

  public undo() {
    this._name = this.name.undo();
    this._description = this.description.undo();
    this._responseDueBy = this.responseDueBy.undo();
    this._startDate = this.startDate.undo();
    this._endDate = this.endDate.undo();
    this._team = this.team.undoAll();
    this._feeSchedule = this.feeSchedule.undoAll();
    this._clientPolicyDocuments = this.clientPolicyDocuments.undoAll();
    this._vendorPolicyDocuments = this.vendorPolicyDocuments.undoAll();
    this._conflictsCheckWaived = this.conflictsCheckWaived.undo();
    this._teamRestricted = this.teamRestricted.undo();
    this._conflictsDocuments = this.conflictsDocuments.undoAll();
    this._discount = this.discount.undo();
  }

  public updateNameRedline(
    newNameRedline: FieldRedline<ProjectName>
  ): ProposalRedline {
    const newRedline = this.clone();
    newRedline._name = newNameRedline;
    newRedline.registerFieldsPendingReview();
    newRedline._lastFieldUpdated = { name: ProposalFieldName.Name };
    return newRedline;
  }

  public updateDescriptionRedline(
    newDescriptionRedline: FieldRedline<ProjectDescription>
  ): ProposalRedline {
    const newRedline = this.clone();
    newRedline._description = newDescriptionRedline;
    newRedline.registerFieldsPendingReview();
    newRedline._lastFieldUpdated = { name: ProposalFieldName.Description };
    return newRedline;
  }

  public updateResponseDueByRedline(
    newResponseDueByRedline: FieldRedline<Date>
  ): ProposalRedline {
    const newRedline = this.clone();
    newRedline._responseDueBy = newResponseDueByRedline;
    newRedline.registerFieldsPendingReview();
    newRedline._lastFieldUpdated = { name: ProposalFieldName.ResponseDueBy };
    return newRedline;
  }

  public updateStartDateRedline(
    newStartDateRedline: FieldRedline<Date>
  ): ProposalRedline {
    const newRedline = this.clone();
    newRedline._startDate = newStartDateRedline;
    newRedline.registerFieldsPendingReview();
    newRedline._lastFieldUpdated = { name: ProposalFieldName.StartDate };
    return newRedline;
  }

  public updateEndDateRedline(
    newEndDateRedline: FieldRedline<Date>
  ): ProposalRedline {
    const newRedline = this.clone();
    newRedline._endDate = newEndDateRedline;
    newRedline.registerFieldsPendingReview();
    newRedline._lastFieldUpdated = { name: ProposalFieldName.EndDate };
    return newRedline;
  }

  public updateTeamRestrictedRedline(
    newTeamRestrictedRedline: FieldRedline<AHBoolean>
  ): ProposalRedline {
    const newRedline = this.clone();
    newRedline._teamRestricted = newTeamRestrictedRedline;
    newRedline.registerFieldsPendingReview();
    newRedline._lastFieldUpdated = { name: ProposalFieldName.TeamRestriction };
    return newRedline;
  }

  public updateTeamMembersRedline(
    newTeamRedline: FieldRedlineArray<Individual>
  ): ProposalRedline {
    const newRedline = this.clone();
    newRedline._team = newTeamRedline;
    newRedline.registerFieldsPendingReview();
    newRedline._lastFieldUpdated = { name: ProposalFieldName.Team, id: newTeamRedline.lastEntryUpdated };
    return newRedline;
  }

  public updateFeeScheduleRedline(
    newFeeScheduleRedline: FeeScheduleRedline
  ): ProposalRedline {
    const newRedline = this.clone();
    newRedline._feeSchedule = newFeeScheduleRedline;
    newRedline.registerFieldsPendingReview();
    newRedline._lastFieldUpdated = { name: ProposalFieldName.FeeSchedule, id: newFeeScheduleRedline.lastEntryUpdated };
    return newRedline;
  }

  public updateConflictsCheckWaivedRedline(
    newConflictsCheckWaivedRedline: FieldRedline<AHBoolean>
  ): ProposalRedline {
    const newRedline = this.clone();
    newRedline._conflictsDocuments = newRedline._conflictsDocuments.removeAll();
    newRedline._conflictsCheckWaived = newConflictsCheckWaivedRedline;
    newRedline.registerFieldsPendingReview();
    newRedline._lastFieldUpdated = {
      name: ProposalFieldName.WaiveConflictsCheck,
    };
    return newRedline;
  }

  public updateConflictsDocumentsRedline(
    newConflictsDocumentsRedline: FieldRedlineArray<WorkDocument>
  ): ProposalRedline {
    const newRedline = this.clone();
    newRedline._conflictsDocuments = newConflictsDocumentsRedline;
    newRedline.registerFieldsPendingReview();
    newRedline._lastFieldUpdated = { name: ProposalFieldName.Conflicts, id: newConflictsDocumentsRedline.lastEntryUpdated };
    return newRedline;
  }

  public updateClientPolicyDocumentsRedline(
    newClientPolicyDocumentsRedline: FieldRedlineArray<WorkDocument>
  ): ProposalRedline {
    const newRedline = this.clone();
    newRedline._clientPolicyDocuments = newClientPolicyDocumentsRedline;
    newRedline.registerFieldsPendingReview();
    newRedline._lastFieldUpdated = { name: ProposalFieldName.ClientPolicies, id: newClientPolicyDocumentsRedline.lastEntryUpdated };
    return newRedline;
  }

  public updateVendorPolicyDocumentsRedline(
    newVendorPolicyDocumentsRedline: FieldRedlineArray<WorkDocument>
  ): ProposalRedline {
    const newRedline = this.clone();
    newRedline._vendorPolicyDocuments = newVendorPolicyDocumentsRedline;
    newRedline.registerFieldsPendingReview();
    newRedline._lastFieldUpdated = { name: ProposalFieldName.VendorPolicies, id: newVendorPolicyDocumentsRedline.lastEntryUpdated };
    return newRedline;
  }

  public updateDiscountRedline(
    newDiscountRedline: FieldRedline<Percent>
  ): ProposalRedline {
    const newRedline = this.clone();
    newRedline._discount = newDiscountRedline;
    newRedline.registerFieldsPendingReview();
    newRedline._lastFieldUpdated = { name: ProposalFieldName.Discount };
    return newRedline;
  }

  public clone() {
    const newFeeScheduleRedline = this.feeSchedule.clone();
    return new ProposalRedline(
      this.name.clone(),
      this.description.clone(),
      {
        startDate: this.startDate.clone(),
        endDate: this.endDate.clone(),
        responseDueBy: this.responseDueBy.clone(),
      },
      this.team.clone(),
      newFeeScheduleRedline,
      this.conflictsCheckWaived.clone(),
      this.teamRestricted.clone(),
      {
        clientPolicyDocuments: this.clientPolicyDocuments.clone(),
        vendorPolicyDocuments: this.vendorPolicyDocuments.clone(),
        conflictsDocuments: this.conflictsDocuments.clone(),
      },
      this.discount.clone()
    );
  }

  public get previousFieldPendingReview(): ProposalField | undefined {
    const orderedReviewableFields = this.allReviewableFields.reverse();
    let startingIndex = this.getStartingIndexFromLastFieldUpdated(orderedReviewableFields);

    return this.getFirstFieldPendingReviewFromList(
      orderedReviewableFields,
      this.fieldsPendingReview,
      startingIndex
    );
  }

  public get nextFieldPendingReview(): ProposalField | undefined {
    const orderedReviewableFields = this.allReviewableFields;
    let startingIndex = this.getStartingIndexFromLastFieldUpdated(orderedReviewableFields);

    return this.getFirstFieldPendingReviewFromList(
      orderedReviewableFields,
      this.fieldsPendingReview,
      startingIndex
    );
  }

  public getPrecedingFieldPendingReview(
    fieldToStartSearchFrom: ProposalField
  ): ProposalField | undefined {
    const fieldsPendingReview = this.fieldsPendingReview;
    if (fieldsPendingReview.length < 2) return undefined;

    const allReviewableFields = this.allReviewableFields;
    const indexOfFieldToStartSearchFrom = allReviewableFields.findIndex(
      (field) =>
        field.name === fieldToStartSearchFrom.name &&
        (field.id ? field.id?.isEqualTo(fieldToStartSearchFrom.id) : true)
    );
    const orderedReviewableFields = [
      ...allReviewableFields.slice(0, indexOfFieldToStartSearchFrom).reverse(),
      ...allReviewableFields.slice(indexOfFieldToStartSearchFrom).reverse(),
    ];

    return this.getFirstFieldPendingReviewFromList(
      orderedReviewableFields,
      fieldsPendingReview,
      0
    );
  }

  public getFollowingFieldPendingReview(
    fieldToStartSearchFrom: ProposalField
  ): ProposalField | undefined {
    const fieldsPendingReview = this.fieldsPendingReview;
    if (fieldsPendingReview.length < 2) return undefined;

    const allReviewableFields = this.allReviewableFields;
    const indexOfFieldToStartSearchFrom = allReviewableFields.findIndex(
      (field) =>
        field.name === fieldToStartSearchFrom.name &&
        (field.id ? field.id?.isEqualTo(fieldToStartSearchFrom.id) : true)
    );
    const orderedReviewableFields = [
      ...allReviewableFields.slice(indexOfFieldToStartSearchFrom),
      ...allReviewableFields.slice(0, indexOfFieldToStartSearchFrom),
    ];
    return this.getFirstFieldPendingReviewFromList(
      orderedReviewableFields,
      fieldsPendingReview,
      1
    );
  }


  toJSON(): object {
    return {
      name: this.name.toJSON(),
      description: this.description.toJSON(),
      responseDueBy: this.responseDueBy.toJSON(),
      startDate: this.startDate.toJSON(),
      endDate: this.endDate.toJSON(),
      team: this.team.toJSON(),
      feeSchedule: this.feeSchedule.toJSON(),
      clientPolicyDocuments: this.clientPolicyDocuments.toJSON(),
      vendorPolicyDocuments: this.vendorPolicyDocuments.toJSON(),
      conflictsCheckWaived: this.conflictsCheckWaived.toJSON(),
      teamRestricted: this.teamRestricted.toJSON(),
      conflictsDocuments: this.conflictsDocuments.toJSON(),
      discount: this.discount.toJSON(),
    };
  }

  static fromJSON(redliningJson: any) {
    let redline = JSON.parse(redliningJson);
    const dates = {
      startDate: FieldRedline.fromObject<Date>(new Date(), redline.startDate),
      endDate: FieldRedline.fromObject<Date>(new Date(), redline.endDate),
      responseDueBy: FieldRedline.fromObject<Date>(
        new Date(),
        redline.responseDueBy
      ),
    };
    const documents = {
      clientPolicyDocuments: FieldRedlineArray.fromArrayObject<WorkDocument>(
        WorkDocument.Prototype,
        redline.clientPolicyDocuments
      ),
      vendorPolicyDocuments: FieldRedlineArray.fromArrayObject<WorkDocument>(
        WorkDocument.Prototype,
        redline.vendorPolicyDocuments
      ),
      conflictsDocuments: FieldRedlineArray.fromArrayObject<WorkDocument>(
        WorkDocument.Prototype,
        redline.conflictsDocuments
      ),
    };

    return new ProposalRedline(
      FieldRedline.fromObject<ProjectName>(ProjectName.Prototype, redline.name),
      FieldRedline.fromObject<ProjectDescription>(
        ProjectDescription.Prototype,
        redline.description
      ),
      dates,
      FieldRedlineArray.fromArrayObject<Individual>(
        Individual.Prototype,
        redline.team
      ),
      FeeScheduleRedline.fromObjects(redline.feeSchedule),
      FieldRedline.fromObject<AHBoolean>(
        AHBoolean.Prototype,
        redline.conflictsCheckWaived
      ),
      FieldRedline.fromObject<AHBoolean>(
        AHBoolean.Prototype,
        redline.teamRestricted
      ),
      documents,
      FieldRedline.fromObject<Percent>(Percent.Prototype, redline.discount)
    );
  }

  
  private registerFieldsPendingReview() {
    const fields: ProposalField[] = [];

    if (this.name.wasRedlined && !this.name.isResolved)
      fields.push({
        name: ProposalFieldName.Name,
      });
    if (this.description.wasRedlined && !this.description.isResolved)
      fields.push({
        name: ProposalFieldName.Description,
      });
    if (this.responseDueBy.wasRedlined && !this.responseDueBy.isResolved)
      fields.push({
        name: ProposalFieldName.ResponseDueBy,
      });
    if (this.startDate.wasRedlined && !this.startDate.isResolved)
      fields.push({
        name: ProposalFieldName.StartDate,
      });
    if (this.endDate.wasRedlined && !this.endDate.isResolved)
      fields.push({
        name: ProposalFieldName.EndDate,
      });
    for (const teamMemberRedline of this.team.redlines) {
      if (teamMemberRedline.wasRedlined && !teamMemberRedline.isResolved) {
        fields.push({
          name: ProposalFieldName.Team,
          id: teamMemberRedline.fieldId,
        });
      }
    }
    for (const feeCategoryRedline of this.feeSchedule) {
      if (feeCategoryRedline.wasRedlined && !feeCategoryRedline.isResolved) {
        fields.push({
          name: ProposalFieldName.FeeSchedule,
          id: feeCategoryRedline.fieldId,
        });
      }
    }
    if (
      this.conflictsCheckWaived.wasRedlined &&
      !this.conflictsCheckWaived.isResolved
    ) {
      fields.push({
        name: ProposalFieldName.WaiveConflictsCheck,
      });
    }
    for (const documentRedline of this.conflictsDocuments.redlines) {
      if (documentRedline.wasRedlined && !documentRedline.isResolved) {
        fields.push({
          name: ProposalFieldName.Conflicts,
          id: documentRedline.fieldId,
        });
      }
    }
    for (const documentRedline of this.clientPolicyDocuments.redlines) {
      if (documentRedline.wasRedlined && !documentRedline.isResolved) {
        fields.push({
          name: ProposalFieldName.ClientPolicies,
          id: documentRedline.fieldId,
        });
      }
    }
    for (const documentRedline of this.vendorPolicyDocuments.redlines) {
      if (documentRedline.wasRedlined && !documentRedline.isResolved) {
        fields.push({
          name: ProposalFieldName.VendorPolicies,
          id: documentRedline.fieldId,
        });
      }
    }
    if (this.discount.wasRedlined && !this.discount.isResolved)
      fields.push({
        name: ProposalFieldName.Discount,
      });

    this._fieldsPendingReview = fields;
  }

  private getFirstFieldPendingReviewFromList(
    orderedReviewableFieldsList: ProposalField[],
    fieldsPendingReview: ProposalField[],
    startingIndex: number
  ) {
    let nextIndexToCheck: number = startingIndex;
    do {
      const nextField = fieldsPendingReview.find(
        (field) =>
          field.name === orderedReviewableFieldsList[nextIndexToCheck].name &&
          (field.id
            ? field.id?.isEqualTo(orderedReviewableFieldsList[nextIndexToCheck].id)
            : true)
      );

      if (nextField !== undefined) return nextField;

      nextIndexToCheck++;
      if (nextIndexToCheck > orderedReviewableFieldsList.length - 1) {
        nextIndexToCheck = 0;
      }
    } while (nextIndexToCheck !== startingIndex);
  }

  private getStartingIndexFromLastFieldUpdated(orderedReviewableFields: ProposalField[]) {
    let lastFieldUpdatedNameIndex = 0;
    if (this.lastFieldUpdated) {
      lastFieldUpdatedNameIndex = orderedReviewableFields.findIndex(
        (field) => field.name === this.lastFieldUpdated?.name &&
          (field.id ? field.id?.isEqualTo(this.lastFieldUpdated?.id) : true)
      );
      if (lastFieldUpdatedNameIndex === -1) {
        console.error(orderedReviewableFields);
        throw new Error(
          `Last updated field not found in list of all reviewable fields - 
          name: ${this.lastFieldUpdated.name}, id: ${this.lastFieldUpdated.id}`
        );
      }
    }
    let startingIndex = lastFieldUpdatedNameIndex + 1;
    if (startingIndex > orderedReviewableFields.length - 1) {
      startingIndex = 0;
    }
    return startingIndex;
  }
}

export enum ResolutionType {
  Approved,
  Rejected,
  Edited,
  Unchanged,
}

export type RedlinedDocuments = {
  clientPolicyDocuments: FieldRedlineArray<WorkDocument>;
  vendorPolicyDocuments: FieldRedlineArray<WorkDocument>;
  conflictsDocuments: FieldRedlineArray<WorkDocument>;
};

export type RedlinedDates = {
  startDate: FieldRedline<Date>;
  endDate: FieldRedline<Date>;
  responseDueBy: FieldRedline<Date>;
};
