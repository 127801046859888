import { Column } from "@material-table/core";
import { Icon } from "@mui/material";
import ActionsColumn from "app/routes/proposals/table/actions-column";
import CreatorLink from "app/routes/proposals/table/creator-link";
import FeeScheduleLink from "app/routes/proposals/table/fee-schedule-link";
import Guid from "common/values/guid/guid";
import Proposal from "work/entities/proposal/proposal";
import { HumanReadableProposalStatus, ProposalAction, ProposalStatus } from "work/values/constants";
import Description from "work/values/project-description/view/description";
import NameLink from "work/values/project-name/view/name-link";
import TeamLink from "work/values/team/view/team-link";
import DateLink from "work/view/components/date-link";
import { PageTab } from "app/routes/proposals/tabs";
import Session from "users/session/session";

export function getTableColumns(
  activeTab: PageTab,
  selectedRows: Proposal[],
  onChangeTab: (tab: PageTab) => void,
  onEditProposal: (proposal: Proposal) => void,
  onSubmitProposal: (proposal: Proposal) => Promise<void>,
  onReviseProposal: (proposal: Proposal) => void,
  onApproveProposal: (proposal: Proposal) => Promise<void>,
  onRejectProposal: (proposal: Proposal) => Promise<void>,
  onHireProposal: (proposal: Proposal) => Promise<void>,
  onDeleteProposal: (proposal: Proposal) => Promise<void>,
  onCancelProposal: (proposal: Proposal) => Promise<void>,
  onViewProposal: (proposal: Proposal) => void,
  onReviewProposal: (proposal: Proposal) => void,
  session: Readonly<Session>
): Column<Proposal>[] {
  const userId = session?.user?.id;
  return [
    {
      field: "_status",
      title: "Status",
      cellStyle: { fontSize: '0.9em' },
      render: rowData => renderStatusColumn(rowData._instance, userId),
    },
    {
      field: "_name",
      title: "Name",
      render: rowData =>
        <NameLink
          proposal={rowData._instance}
          onProposalClicked={() => {
            if (rowData._instance.availableActions.Review?.some(id => id.isEqualTo(userId))) {
              onReviewProposal(rowData._instance);
            } else if (rowData._instance.availableActions.Revise?.some(id => id.isEqualTo(userId))) {
              onReviseProposal(rowData._instance);
            } else if (rowData._instance.availableActions.Edit?.some(id => id.isEqualTo(userId))) {
              onEditProposal(rowData._instance);
            } else {
              onViewProposal(rowData._instance);
            }
          }}
        />
    },
    {
      field: "_description",
      title: "Description",
      render: rowData => <Description proposal={rowData._instance} />,
    },
    {
      field: "_creator",
      title: "Creator",
      render: rowData => (
        rowData._instance.creatorInfo &&
        <CreatorLink creator={rowData._instance.creatorInfo} />)
        ?? <Icon>remove</Icon>,
    },
    {
      field: "_negotiable",
      title: "Negotiable",
      type: "boolean",
      hidden: true,
    },
    {
      field: "_workAgreement.team",
      title: "Team",
      emptyValue: <Icon>remove</Icon>,
      render: rowData => <TeamLink
        proposal={rowData._instance}
        session={session}
      />
    },
    {
      field: "_workAgreement.feeSchedule",
      title: "Fee Schedule",
      emptyValue: <Icon>remove</Icon>,
      render: rowData => <FeeScheduleLink proposal={rowData._instance} />,
    },
    {
      field: "_responseDueBy",
      title: "Response Due",
      emptyValue: <Icon>remove</Icon>,
      type: "date",
      render: rowData => <DateLink proposal={rowData._instance} field="responseDueBy" />
    },
    {
      field: "_workAgreement.startDate",
      title: "Start Date",
      emptyValue: <Icon>remove</Icon>,
      type: "date",
      render: rowData => <DateLink proposal={rowData._instance} field="startDate" />
    },
    {
      field: "_workAgreement.endDate",
      title: "End Date",
      emptyValue: <Icon>remove</Icon>,
      type: "date",
      render: rowData => <DateLink proposal={rowData._instance} field="endDate" />
    },
    {
      title: "Actions",
      align: "right",
      filtering: false,
      draggable: false,
      sorting: false,
      grouping: false,
      disableClick: true,
      hiddenByColumnsButton: true,
      removable: false,
      width: "fit-content",
      render: rowData => selectedRows.length === 0
        ? renderActionsColumn(
          userId,
          rowData,
          activeTab,
          onChangeTab,
          onEditProposal,
          onSubmitProposal,
          onReviseProposal,
          onApproveProposal,
          onRejectProposal,
          onHireProposal,
          onDeleteProposal,
          onCancelProposal,
          onReviewProposal
        )
        : <Icon>remove</Icon>,
    },
  ];
}

function renderStatusColumn(proposal: Proposal, userId?: Guid): string {
  let proposalStatus: string = ProposalStatus[proposal.status].toString();
  let displayStatus: string = HumanReadableProposalStatus[proposalStatus as keyof typeof ProposalStatus];
  const isClient = proposal.client?.userId.isEqualTo(userId);
  const isTeamLeader = proposal.team?.leader.userId.isEqualTo(userId);
  const isVendor = proposal.vendors?.some(vendor => vendor.userId.isEqualTo(userId));

  if (
    (proposal.status === ProposalStatus.AwaitingApprovalByClient && isClient) ||
    (proposal.status === ProposalStatus.AwaitingApprovalByTeamLeader && isTeamLeader) ||
    (proposal.status === ProposalStatus.AwaitingApprovalByVendors && isVendor)
  )
    displayStatus = "Awaiting Your Approval";

  return displayStatus;
}

function renderActionsColumn(
  userId: Guid | undefined,
  rowData: Proposal,
  activeTab: PageTab,
  onChangeTab: (tab: PageTab) => void,
  onEditProposal: (proposal: Proposal) => void,
  onSubmitProposal: (proposal: Proposal) => Promise<void>,
  onReviseProposal: (proposal: Proposal) => void,
  onApproveProposal: (proposal: Proposal) => Promise<void>,
  onRejectProposal: (proposal: Proposal) => Promise<void>,
  onHireProposal: (proposal: Proposal) => Promise<void>,
  onDeleteProposal: (proposal: Proposal) => Promise<void>,
  onCancelProposal: (proposal: Proposal) => Promise<void>,
  onReviewProposal: (proposal: Proposal) => void
): React.ReactNode {
  async function handleAction(action: ProposalAction, proposal: Proposal) {
    switch (action) {
      case ProposalAction.Edit:
        onEditProposal(proposal);
        break;
      case ProposalAction.Revise:
        onReviseProposal(proposal);
        break;
      case ProposalAction.Submit:
        await onSubmitProposal(proposal);
        break;
      case ProposalAction.Approve:
        await onApproveProposal(proposal);
        break;
      case ProposalAction.Reject:
        await onRejectProposal(proposal);
        break;
      case ProposalAction.Hire:
        await onHireProposal(proposal);
        break;
      case ProposalAction.Delete:
        await onDeleteProposal(proposal);
        break;
      case ProposalAction.Cancel:
        await onCancelProposal(proposal);
        break;
      case ProposalAction.Review:
        onReviewProposal(proposal);
        break;
      default:
        break;
    }
  }

  return (
    <ActionsColumn
      userId={userId}
      proposal={rowData._instance}
      activeTab={activeTab}
      onAction={handleAction}
      onChangeTab={onChangeTab}
    />
  );
}
