export default class InvitationMessage extends String{
    constructor(value: string) {
        if(!value) throw new InvalidInvitationMessageError("Invitation message cannot be empty.");
        super(value);
    }
}

export class InvalidInvitationMessageError extends Error {
    constructor(value: string) {
        super(`Invalid invitation message: ${value}`);
    }
}