import ProposalReviewer from "work/values/proposal-reviewer";

export default class ProposalReviewerAPIRequest {
    userId?: string;
    canEdit?: boolean;
    dateApproved?: string;
    customMessage?: string;

    constructor(reviewer?: ProposalReviewer){
        this.userId = reviewer?.userId.valueOf();
        this.canEdit = reviewer?.canEdit;
        this.dateApproved = reviewer?.dateApproved?.toISOString();
        this.customMessage = reviewer?.customMessage;
    }

    public get payload(): object {
        return {
            userId: this.userId,
            canEdit: this.canEdit,
            dateApproved: this.dateApproved,
            customMessage: this.customMessage
        };
    }
}
