
import MarketplaceFeeScheduleCategoryAPIRequest from "marketplace/entities/fee-schedule-category/api/request-contracts/marketplace-fee-schedule-category-api-request";
import FeeScheduleCategory from "marketplace/entities/fee-schedule-category/fee-schedule-category";
import FeeScheduleTag from "marketplace/values/fee-schedule-tag/fee-schedule-tag";

export default class MarketplaceFeeScheduleAPIRequest {
    name: string;
    tags: FeeScheduleTag[];
    categories: MarketplaceFeeScheduleCategoryAPIRequest[];

    constructor(name: string, tags?: FeeScheduleTag[], categories?: FeeScheduleCategory[]) {
        this.name = name;
        this.tags = tags ?? [];
        this.categories = categories?.map(category => new MarketplaceFeeScheduleCategoryAPIRequest(category)) ?? [];
    }
}
