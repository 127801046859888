import MaterialTable, { DetailPanel, MaterialTableProps } from "@material-table/core";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import FeeSchedulesPanel from "marketplace/view/fee-schedules-panel";
import { ProfileTableData } from "./columns";

export function getDetailPanels(
  tableRef: React.MutableRefObject<(MaterialTable<ProfileTableData> & MaterialTableProps<ProfileTableData>) | undefined>
): DetailPanel<ProfileTableData>[] {
  return [
    {
      icon: () => <MonetizationOnOutlinedIcon color="primary" />,
      openIcon: () => <MonetizationOnIcon color="primary" />,
      tooltip: 'Show/Hide Fee Schedules',
      render({ rowData }) {
        return <FeeSchedulesPanel
          teamId={rowData.id}
          leaderUserId={rowData.leader.userId ?? undefined}
          onPanelUpdated={() => tableRef?.current?.onQueryChange()}
        />
      }
    }
  ]
}
