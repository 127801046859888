import Guid from "common/values/guid/guid";
import FeeScheduleTag from "marketplace/values/fee-schedule-tag/fee-schedule-tag";
import FeeScheduleCategory from "marketplace/entities/fee-schedule-category/fee-schedule-category";

export default class FeeSchedule {
  id?: Guid;
  name: string;
  tags: FeeScheduleTag[] = [];
  categories: FeeScheduleCategory[] = [];

  constructor(id?: Guid, name?: string, tags?: FeeScheduleTag[], categories?: FeeScheduleCategory[]) {
    this.id = id;
    this.name = name ?? "";
    this.tags = tags ?? [];
    this.categories = categories ?? [];
  }
}
