import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";

import EntityOfficerInvitation from "legal-entities/entities/entity-officer-invitation/entity-officer-invitation";
import EntityOfficerInvitationForm from "legal-entities/entities/entity-officer-invitation/view/components/entity-officer-invitation-form";
import EntityOfficerInvitationsTable from "legal-entities/entities/entity-officer-invitation/view/components/entity-officer-invitations-table";
import EntityOfficerInvitationAPIService from "../api/entity-officer-invitation-api-service";
import { useSession } from "users/session/session-context";

export default function EntityOfficerInvitationsPage() {
  const [openInvitations, setOpenInvitations] = useState<EntityOfficerInvitation[]>([]);
  const session = useSession();

  useEffect(() => {
    getOpenInvitations();
  }, []);

  async function getOpenInvitations() {
    try {
      const service = new EntityOfficerInvitationAPIService(session);
      const abortController = new AbortController();
      const returnedInvitations = await service.getAllOpenOfficerInvitations(abortController);
      setOpenInvitations(returnedInvitations);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to get open invitations", { variant: "error" });
    }
  }

  return (
    <>
      <EntityOfficerInvitationForm
        session = {session}
        onInvitationCreated={(invitation) => setOpenInvitations([...openInvitations, invitation])}
      />
      <EntityOfficerInvitationsTable
        session = {session}
        invitations={openInvitations}
        onInvitationRemoved={(invitation) => setOpenInvitations(openInvitations.filter((i) => i.id !== invitation.id))}
      />
    </>
  );
}
