import { useLoaderData } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { DateCalendar } from "@mui/x-date-pickers";

import { CtaBackground } from 'app/assets';
import CallToAction from 'common/components/call-to-action';
import { useSession } from 'users/session/session-context';
import moment from 'moment';

const Main = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0)
  },
  padding: theme.spacing(2)
}));
const Layout = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '100%'
  }
}));
const Greeting = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    margin: 'auto',
    marginTop: '1.5rem'
  },
  marginBottom: '1em',
  textAlign: 'center'
}));
const Calendar = styled(DateCalendar)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    margin: 'auto'
  },
  marginLeft: '2em'
}));

type DashboardProps = {};

export default function Dashboard(props: Readonly<DashboardProps>) {
  const partOfDay = useLoaderData();
  const session = useSession();

  return (
    <Main container direction="row">
      <Layout item md={7} xl={6}>
        <CallToAction
          title='Explore the marketplace and find what you need quickly and easily'
          buttonText={'Go To Marketplace'}
          redirectUrl='/marketplace'
          backgroundImageUrl={CtaBackground}
        />
      </Layout>
      <Layout item>
        <Greeting variant="h5">
          {`${partOfDay}, ${session.user?.name.firstName}`}
        </Greeting>
        <Calendar defaultValue={moment()} value={moment()} />
      </Layout>
    </Main>
  )
}
