import { Column } from "@material-table/core";
import { Archive, MarkEmailRead, MarkEmailUnread } from "@mui/icons-material";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AssignmentIcon from "@mui/icons-material/Assignment";
import GroupIcon from "@mui/icons-material/Group";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import MessageIcon from "@mui/icons-material/Message";
import PageviewIcon from "@mui/icons-material/Pageview";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { Icon, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { NotificationTag } from "app/routes/communications/style";
import RowActionButtons from "common/components/table/row-action-buttons";
import RowActionMenu from "common/components/table/row-action-menu";
import * as Constants from "common/helpers/constants";
import { ActionsCellContainer, CenterFlexContainer } from "common/styles";
import Guid from "common/values/guid/guid";
import Forum from "messaging/entities/forum/forum";
import moment from "moment";
import MessageNotification from "notifications/entities/message-notification/message-notification";
import Notification from "notifications/entities/notification/notification";
import NotificationsAPIService from "notifications/services/notifications-api-service";
import Action from "notifications/values/action/action";
import { BoxType } from "notifications/values/box-type/box-type";
import TopicCategory from "notifications/values/topic-category/topic-category";
import Session from "users/session/session";

export type CommunicationTableData = MessageNotification & Forum;
export type CommunicationTableColumn = Column<CommunicationTableData>;

export function getTableColumns(
  activeTab: BoxType, 
  selectedRows: CommunicationTableData[],
  session: Readonly<Session>
): CommunicationTableColumn[] {
  if (activeTab === BoxType.All)
    return [
      {
        field: 'name',
        title: 'Name',
        render: renderNameColumn
      },
      {
        field: 'createdDate',
        title: 'Created',
        render: renderCreatedDateColumn
      }
    ];

  return [
    {
      field: 'topic?.category',
      title: 'Type',
      render: (rowData: CommunicationTableData) => renderCategoryColumn(rowData, activeTab)
    },
    {
      field: 'message',
      title: 'Description',
      render: (rowData: CommunicationTableData) => renderDescriptionColumn(rowData, activeTab, session?.user?.id)
    },
    {
      field: 'createdDate',
      title: 'Created',
      render: renderCreatedDateColumn
    },
    {
      field: 'seenAt',
      type: 'boolean',
      hidden: true
    },
    {
      title: 'Actions',
      align: 'right',
      filtering: false,
      draggable: false,
      sorting: false,
      grouping: false,
      disableClick: true,
      hiddenByColumnsButton: true,
      removable: false,
      width: 'fit-content',
      render: rowData => selectedRows.length === 0
        ? renderActionsColumn(rowData, session)
        : <Icon>remove</Icon>
    }
  ];
}

function renderNameColumn(rowData: CommunicationTableData): React.ReactNode {
  const forum = rowData as Forum;

  return (
    <CenterFlexContainer>
      <ListItemIcon><MessageIcon /></ListItemIcon>
      <ListItemText
        primary={forum?.name}
      />
    </CenterFlexContainer>
  );
}

function renderCreatedDateColumn(rowData: CommunicationTableData): React.ReactNode {
  if (!rowData.createdDate) return null;
  return <time>{moment(rowData.createdDate).format('MM/DD/YY hh:mm A')}</time>
}

function renderCategoryColumn(rowData: CommunicationTableData, activeTab: BoxType) {
  const notification = rowData as Notification;
  const topicCategory: TopicCategory = notification?.topic?.category ?? '';

  return (
    <CenterFlexContainer>
      <ListItemIcon>
        {(topicCategory === TopicCategory.Messaging.Message || topicCategory === TopicCategory.Messaging.ForumInvitation) && <MessageIcon />}
        {topicCategory === TopicCategory.Work.Proposal && !notification.actionRequired && <PersonAddIcon />}
        {topicCategory === TopicCategory.Users.NetworkConnection && <AccountTreeIcon />}
        {topicCategory === TopicCategory.Users.NetworkInvitation && <AccountTreeIcon />}
        {topicCategory === TopicCategory.Work.Proposal && notification.actionRequired && notification.actionRequired !== Action.Review && <AssignmentIcon />}
        {topicCategory === TopicCategory.Work.Proposal && notification.actionRequired === Action.Review && <PageviewIcon />}
        {topicCategory === TopicCategory.Marketplace.Team && <GroupIcon />}
        {topicCategory === TopicCategory.LegalEntities.EntityMember && <PersonIcon />}
        {topicCategory === TopicCategory.Marketplace.TeamInvitation && <GroupAddIcon />}
        {topicCategory === TopicCategory.LegalEntities.LegalEntity && <SupervisorAccountIcon />}
      </ListItemIcon>
      <ListItemText
        primary={
          <strong style={{ fontWeight: !notification.isSeen ? 'bold' : 'normal' }}>
            {
              topicCategory === TopicCategory.Messaging.Message ?
                'New Message' :
                Constants.notificationTypes[topicCategory as keyof typeof Constants.notificationTypes] ?? 'Notification'
            }
          </strong>
        }
        secondary={
          activeTab === BoxType.Inbox && (
            notification.isSeen ?
              `Read at ${moment(notification.seenAt).format('MM/DD/YY hh:mm A')}` :
              'Unread notification'
          )
        }
      />
    </CenterFlexContainer>
  );
}

function renderDescriptionColumn(rowData: CommunicationTableData, activeTab: BoxType, userId?: Guid) {
  const notification = rowData as Notification;
  const messageNotification = rowData as MessageNotification;

  const tag = (notification.recipientUserId?.isEqualTo(userId)) ?
    <NotificationTag size='small' label="Received" /> :
    <NotificationTag size='small' label="Sent" />;

  const messageInfo = notification.topic?.category === TopicCategory.Messaging.Message ?
    (<>
      <strong style={{ fontWeight: 500 }}>{messageNotification.messageInfo?.forum.name}</strong><br />
      <span>{messageNotification.messageInfo?.subject && `Subject: ${messageNotification.messageInfo.subject}`}</span>
    </>) :
    (notification.message)

  return (
    <>
      {activeTab === BoxType.Archived && tag}
      {messageInfo}
    </>
  );
}

function renderActionsColumn(rowData: CommunicationTableData, session: Readonly<Session>) {
  return (
    <ActionsCellContainer>
      {rowData.actionRequired && (
        <RowActionButtons rowData={rowData} />
      )}
      <RowActionMenu menuItems={getMenuItems(rowData, session)} />
    </ActionsCellContainer>
  );
}

async function markAsRead(rowData: CommunicationTableData, session: Readonly<Session>) {
  if (!rowData || rowData?.seenAt) return;
  try{
  await new NotificationsAPIService(session).markNotificationAsSeen(rowData);
  } catch (error) {
    console.error(error);
  }
}

async function markAsUnread(rowData: CommunicationTableData, session: Readonly<Session>) {
  if (!rowData || rowData?.seenAt) return;
  try{
  await new NotificationsAPIService(session).markNotificationAsUnSeen(rowData);
  } catch (error) {
    console.error(error);
  }
}

async function archiveRowData(rowData: CommunicationTableData, session: Readonly<Session>) {
  if (!rowData || rowData instanceof Forum) return;
  try{
  await new NotificationsAPIService(session).archiveNotification(rowData);
  } catch (error) {
    console.error(error);
  }
}

function getMenuItems(rowData: CommunicationTableData, session: Readonly<Session>) {
  let menuItems: React.ReactNode[] = [];

  if (!rowData.seenAt) {
    menuItems.push(
      <MenuItem key="markRead"
        onClick={() => markAsRead(rowData, session)}
      >
        <ListItemIcon>
          <MarkEmailRead fontSize="small" />
        </ListItemIcon>
        <ListItemText>
          Mark Read
        </ListItemText>
      </MenuItem>
    );
  } else {
    menuItems.push(
      <MenuItem key="markUnread"
        onClick={() => markAsUnread(rowData, session)}
      >
        <ListItemIcon>
          <MarkEmailUnread fontSize="small" />
        </ListItemIcon>
        <ListItemText>
          Mark Unread
        </ListItemText>
      </MenuItem>
    );
  }

  menuItems.push(
    <MenuItem key="archive"
      onClick={() => archiveRowData(rowData, session)}
    >
      <ListItemIcon>
        <Archive fontSize="small" />
      </ListItemIcon>
      <ListItemText>
        Archive
      </ListItemText>
    </MenuItem>
  );

  return menuItems;
}
