export default class Text {
    private value: string;
    constructor(value: string) {
        this.value = value;
    }
    public valueOf(): string {
        return this.value;
    }
    public clone(): Text {
        return new Text(this.valueOf());
    }

    public isEqualTo(other: Text | null): boolean {
        if (!other) {
            return false;
        }
        return this.valueOf() === other.valueOf();
    }

    public fromObject(obj: any): Text | null {
        return new Text(obj);
    }
    public toJSON(): any {
        return this.valueOf();
    }
    public toString(): string {
        return this.valueOf();
    }
}