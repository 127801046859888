import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { PageTab } from 'app/routes/proposals/tabs';
import { MouseEvent, useState } from 'react';
import { useSession } from 'users/session/session-context';
import Proposal from 'work/entities/proposal/proposal';
import { ProposalAction } from 'work/values/constants';

const ActionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end'
}));
const ActionButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.black
}));

type ActionMenuProps = {
  activeTab: PageTab;
  proposal: Proposal;
  onAction: (action: ProposalAction, proposal: Proposal) => Promise<void>;
};

export default function ActionsMenu(props: Readonly<ActionMenuProps>) {
  const {
    activeTab,
    proposal,
    onAction,
  } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const session = useSession();

  const userId = session?.user?.id;

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openActionsMenu = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  }

  const isCancelAvailable = proposal.availableActions?.Cancel.some(id => id.isEqualTo(userId));
  const isDeleteAvailable = proposal.availableActions?.Delete.some(id => id.isEqualTo(userId));

  return (
    <ActionContainer>
      {(isCancelAvailable || isDeleteAvailable) && (
        <Tooltip title="Show Options">
          <span>
            <ActionButton onClick={openActionsMenu} size="large">
              <MoreVertIcon />
            </ActionButton>
          </span>
        </Tooltip>
      )}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
      >
        <MenuList onClick={handlePopoverClose}>
          {activeTab === PageTab.Active && (
            <MenuItem
              onClick={() => onAction(ProposalAction.Cancel, proposal)}>
              <ListItemIcon><CancelIcon /></ListItemIcon>
              <ListItemText primary="Cancel Proposal" />
            </MenuItem>
          )}
          {activeTab === PageTab.Drafts && (
            <MenuItem
              onClick={() => onAction(ProposalAction.Delete, proposal)}>
              <ListItemIcon><DeleteIcon color='error' /></ListItemIcon>
              <ListItemText primary="Delete" />
            </MenuItem>
          )}
        </MenuList>
      </Popover>
    </ActionContainer>
  )
}
