import EntityOfficerInvitation from "legal-entities/entities/entity-officer-invitation/entity-officer-invitation";

export default class InviteEntityOfficerAPIRequest{
    emailAddress: string;
    firstName?: string;
    lastName?: string;
    entityName: string;
    message?: string;

    constructor(invitation: EntityOfficerInvitation){
        if(!invitation.registrationInfo) throw new Error("Registration info is required");
        if(!invitation.entityName) throw new Error("Entity name is required");
        if(!invitation.registrationInfo.email) throw new Error("Email address is required");
        if(!invitation.registrationInfo.name) throw new Error("Name is required");

        this.emailAddress = invitation.registrationInfo.email.valueOf();
        this.firstName = invitation.registrationInfo.name.firstName;
        this.lastName = invitation.registrationInfo.name.lastName;
        this.entityName = invitation.entityName.valueOf();
        this.message = invitation.message?.valueOf();
    }
}