import {
  CircularProgress, List, ListItemButton, ListItemSecondaryAction, ListItemText,
  Typography
} from '@mui/material';
import Chip from '@mui/material/Chip/Chip';
import { styled } from '@mui/material/styles';
import { CanceledError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useSession } from 'users/session/session-context';
import FeeScheduleTemplateAPIService from 'work/entities/fee-schedule-template/api/fee-schedule-template-api-service';
import FeeScheduleTemplate from 'work/entities/fee-schedule-template/fee-schedule-template';

const ListContainer = styled(List)(({ theme }) => ({
  maxHeight: theme.spacing(50)
}));
const LoadingContainer = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  padding: theme.spacing(4)
}));

type FeeScheduleExistingTemplateProps = {
  templates?: FeeScheduleTemplate[];
  onTemplateSelected: (template: FeeScheduleTemplate) => void
};

export default function FeeScheduleExistingTemplate(props: Readonly<FeeScheduleExistingTemplateProps>) {
  const {
    templates,
    onTemplateSelected
  } = props;

  const [loading, setLoading] = React.useState<boolean>(false);
  const [existingTemplates, setExistingTemplates] = React.useState<FeeScheduleTemplate[]>([]);

  const session = useSession();

  useEffect(() => {
    let abortController = new AbortController();
    if (templates) {
      setExistingTemplates(templates);
    } else {
      getExistingTemplates(abortController);
      return () => {
        abortController.abort();
        abortController = new AbortController();
      };
    }
  }, []);

  async function getExistingTemplates(abortController: AbortController) {
    try {
      setLoading(true);
      const entityId = session.currentEntity.entityId;
      const accountType = session.accountType;
      const feeScheduleService = new FeeScheduleTemplateAPIService(session);
      const templates = await feeScheduleService
        .getFeeScheduleTemplates(entityId, accountType, abortController);
      setExistingTemplates(templates);
    } catch (error) {
      if(error instanceof CanceledError)
        return;
      console.error(error);
      enqueueSnackbar('Error getting existing fee schedule categories', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }

  return (
    <ListContainer>
      {loading && (
        <LoadingContainer>
          <CircularProgress size={32} thickness={4} style={{ color: '#000', margin: '1rem' }} />
          <Typography>Loading...</Typography>
        </LoadingContainer>
      )}
      {!loading && (
        <>
          {(!existingTemplates || existingTemplates.length < 1) && (
            <Typography>No existing fee schedule templates</Typography>
          )}
          {existingTemplates.map(template => {
            return (
              <ListItemButton
                key={template.name.toString()}
                onClick={() => onTemplateSelected(template)}
              >
                <ListItemText
                  primary={template.name}
                  secondary={template.tags.map(tag => <Chip key={tag.toString()} label={tag} size="small" />)}
                />
                <ListItemSecondaryAction>
                  <Typography>{template.createdDate?.format('MM/DD/YY hh:mm A')}</Typography>
                </ListItemSecondaryAction>
              </ListItemButton>
            );
          })}
        </>
      )}
    </ListContainer>
  )
}
