import { Column } from "@material-table/core";
import { Archive as ArchiveIcon, Delete as DeleteIcon, Unarchive as UnarchiveIcon } from "@mui/icons-material";
import { Chip, Icon, Link, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import { PageTab } from "app/routes/fee-schedules/tabs";
import RowActionMenu from "common/components/table/row-action-menu";
import { ActionsCellContainer } from "common/styles";
import Guid from "common/values/guid/guid";
import FeeScheduleTag from "marketplace/values/fee-schedule-tag/fee-schedule-tag";
import FeeScheduleTemplate from "work/entities/fee-schedule-template/fee-schedule-template";

const NameLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline'
  }
}));
const TagChip = styled(Chip)(({ theme }) => ({
  color: '#777',
  marginRight: theme.spacing(0.4),
}));

export function getTableColumns(
  activeTab: PageTab,
  onEditFeeScheduleTemplate: (id: Guid) => void,
  onDeleteFeeScheduleTemplate: (id: Guid) => void,
  onArchiveFeeScheduleTemplate: (id: Guid, archive: boolean) => void
): Column<FeeScheduleTemplate>[] {
  return [
    {
      field: 'name',
      title: 'Name',
      render: (rowData) => (
        <NameLink onClick={() => {
          if (!rowData.id) return;
          onEditFeeScheduleTemplate(rowData.id);
        }}>
          {rowData.name}
        </NameLink>
      )
    },
    {
      field: 'Tags',
      title: 'Tags',
      render: renderTagsColumn
    },
    {
      field: 'createdDate',
      title: 'Created',
      type: 'datetime',
      render: (rowData) => <time>{rowData.createdDate?.format('MM/DD/YYYY hh:mm A')}</time>
    },
    {
      title: 'Actions',
      align: 'right',
      filtering: false,
      draggable: false,
      sorting: false,
      grouping: false,
      disableClick: true,
      hiddenByColumnsButton: true,
      removable: false,
      width: 'fit-content',
      render: (rowData) => renderActionsColumn(
        activeTab,
        rowData,
        onDeleteFeeScheduleTemplate,
        onArchiveFeeScheduleTemplate
      )
    }
  ];
}

function renderTagsColumn(rowData: FeeScheduleTemplate) {
  if (!rowData.tags.length) return <Icon>remove</Icon>;
  return (
    <div>
      {rowData.tags.map((tag: FeeScheduleTag) => (
        <TagChip key={tag as React.Key} label={tag} size="small" />
      ))}
    </div>
  )
}

function renderActionsColumn(
  activeTab: PageTab,
  rowData: FeeScheduleTemplate,
  onDeleteFeeScheduleTemplate: (id: Guid) => void,
  onArchiveFeeScheduleTemplate: (id: Guid, archive: boolean) => void
): React.ReactNode {
  if (!rowData.id) return <Icon>remove</Icon>;

  return (
    <ActionsCellContainer>
      <RowActionMenu 
        menuItems={
          getMenuItems(
            activeTab,
            rowData,
            () => {
              if (!rowData.id) return;
              onDeleteFeeScheduleTemplate(rowData.id);
            },
            (archive: boolean) => {
              if (!rowData.id) return;
              onArchiveFeeScheduleTemplate(rowData.id, archive);
            }
          )
        }
      />
    </ActionsCellContainer>
  );
}

function getMenuItems(
  activeTab: PageTab,
  rowData: FeeScheduleTemplate,
  onDeleteFeeScheduleTemplate: () => void,
  onArchiveFeeScheduleTemplate: (archive: boolean) => void
): React.ReactNode[] {
  const menuItems: React.ReactNode[] = [];


  if (activeTab !== PageTab.Archived) {
    menuItems.push(
      <MenuItem key={"archive"} onClick={() => onArchiveFeeScheduleTemplate(true)}>
        <ListItemIcon><ArchiveIcon fontSize="small" /></ListItemIcon>
        <ListItemText>Archive</ListItemText>
      </MenuItem>
    );
  } else {
    menuItems.push(
      <MenuItem key={"unarchive"} onClick={() => onArchiveFeeScheduleTemplate(false)}>
        <ListItemIcon><UnarchiveIcon fontSize="small" /></ListItemIcon>
        <ListItemText>Unarchive</ListItemText>
      </MenuItem>
    );
  }

  menuItems.push(
    <MenuItem key="delete" onClick={onDeleteFeeScheduleTemplate}>
      <ListItemIcon>
        <DeleteIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>Delete</ListItemText>
    </MenuItem>
  );

  return menuItems;
}
