import { Column } from "@material-table/core";
import MessageIcon from '@mui/icons-material/Message';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { Box, Button, Link, ListItemText, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { NetworkTableData } from "app/routes/network/index";
import { PageTab } from "app/routes/network/tabs";
import { ActionsCellContainer } from "common/styles";
import Guid from "common/values/guid/guid";
import EntityMember from "legal-entities/entities/entity-member/entity-member";
import IndividualAvatar from "marketplace/view/individual-avatar";
import NetworkedUserInfo from "users/entities/user-network-connection/networked-user-info";
import NetworkButton from "users/entities/user-network-connection/view/components/network-button";
import Session from "users/session/session";
import DirectProposalButton from "work/view/components/direct-proposal-button";

const NameCellContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center'
}));
const NameText = styled(ListItemText)(({ theme }) => ({
  marginLeft: theme.spacing(1)
}));
const ContactLink = styled(Link)(({ theme }) => ({
  cursor: 'pointer'
}));

export function getTableColumns(
  activeTab: PageTab,
  session: Session,
  handleViewProfile: (individualId?: Guid) => void,
  sendMessageToContact: (userId?: Guid) => void,
  removeNetworkConnection: (connectionId?: Guid) => void,
  sendProposalToContact: (targetUrl: URL) => void
): Column<NetworkTableData>[] {
  return [
    {
      field: 'name',
      title: 'Name',
      render: rowData => renderNameColumn(
        rowData as NetworkedUserInfo | EntityMember,
        activeTab,
        handleViewProfile,
        session
      )
    },
    {
      field: 'createdDate',
      title: 'Connected On',
      type: 'datetime',
      hidden: activeTab === PageTab.Coworkers,
      render: rowData => (rowData as NetworkedUserInfo).createdDate?.format('L')
    },
    {
      title: 'Actions',
      align: 'right',
      filtering: false,
      draggable: false,
      sorting: false,
      grouping: false,
      disableClick: true,
      hiddenByColumnsButton: true,
      removable: false,
      width: 'fit-content',
      render: rowData => renderActionsColumn(
        rowData as NetworkedUserInfo,
        activeTab,
        session,
        sendMessageToContact,
        removeNetworkConnection,
        sendProposalToContact
      )
    }
  ];
}

function isNetworkedWithUser(
  rowData: NetworkedUserInfo | EntityMember,
  session: Readonly<Session>): boolean {
  return Boolean(rowData.userId && session.isNetworkedWith(rowData.userId));
}

function renderNameColumn(
  rowData: NetworkedUserInfo | EntityMember,
  activeTab: PageTab,
  handleViewProfile: (individualId?: Guid) => void,
  session: Session
): React.ReactNode {
  const name = `${rowData.name?.firstName} ${rowData.name?.lastName}`;
  let companyName;

  if (activeTab === PageTab.Contacts) {
    const networkedUserInfo = rowData as NetworkedUserInfo;
    companyName = networkedUserInfo.companyName;
  }
  if (activeTab === PageTab.Coworkers) {
    const entityMember = rowData as EntityMember;
    companyName = entityMember.entityName;
  }

  function contactLink() {
    if (isNetworkedWithUser(rowData, session)) {
      return (
        <ContactLink onClick={() => handleViewProfile(rowData.individualId)}>
          {name}
        </ContactLink>
      );
    } else {
      return (
        <ListItemText
          primary={name}
        />
      );
    }
  }

  return (
    <NameCellContainer>
      <IndividualAvatar 
        avatarId={rowData.avatarId} 
        individualId={rowData.individualId}
        session={session}
        />
      <NameText
        primary={contactLink()}
        secondary={companyName}
      />
    </NameCellContainer >
  );
}

function renderActionsColumn(
  rowData: NetworkedUserInfo | EntityMember,
  activeTab: PageTab,
  session: Readonly<Session>,
  sendMessageToContact: (userId?: Guid) => void,
  removeNetworkConnection: (connectionId?: Guid) => void,
  sendProposalToContact: (targetUrl: URL) => void
) {
  const networkedWithUser = isNetworkedWithUser(rowData, session);
  const networkInfo = rowData as NetworkedUserInfo;
  const canSendProposal =
    networkInfo.companyEntityId &&
    ((networkInfo.isClientRep && session.context?.isVendor && session.context?.viewingAsVendor)
      || (networkInfo.isVendorRep && session.context?.isClient && !session.context?.viewingAsVendor));

  return (
    <ActionsCellContainer>
      {networkedWithUser && (
        <span>
          {canSendProposal &&
            <Tooltip title="Create Proposal For Contact">
              <span>
                <DirectProposalButton
                  targetUserId={rowData.userId}
                  targetEntityId={networkInfo.companyEntityId}
                  targetName={networkInfo.name}
                  targetIsClientRep={networkInfo.isClientRep}
                  targetIsVendorRep={networkInfo.isVendorRep}
                  session={session}
                  onClick={(rep) => sendProposalToContact(rep)}
                  action={"create"}
                />
              </span>
            </Tooltip>
          }
          <Tooltip title="Send Message To Contact">
            <span>
              <Button
                color="primary"
                startIcon={<MessageIcon />}
                onClick={() => sendMessageToContact(rowData.userId)}
              >
                Message
              </Button>
            </span>
          </Tooltip>
        </span>
      )}
      {!networkedWithUser && activeTab === PageTab.Coworkers &&
        <NetworkButton userId={rowData.userId} variant="text" />
      }
      {activeTab === PageTab.Contacts && (
        <Tooltip title="Remove Contact From Your Network">
          <span>
            <Button
              color="primary"
              startIcon={<PersonRemoveIcon />}
              onClick={() => removeNetworkConnection(networkInfo.connectionId)}
            >
              Remove
            </Button>
          </span>
        </Tooltip>
      )}
    </ActionsCellContainer>
  );
}
