import { TextField, TextFieldVariants } from "@mui/material";
import { styled } from "@mui/material/styles";
import { forwardRef, useState, useEffect } from "react";

import { InvalidEmailAddressError } from "common/errors";
import EmailAddress from "../email-address";

const InputField = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginRight: 0,
    width: '100%'
  },
  marginBottom: theme.spacing(1.5),
  minWidth: theme.spacing(40),
  ":first-of-type": {
    marginRight: theme.spacing(1.5)
  }
}));

export interface EmailAddressInputError extends String { };

type EmailInputProps = {
  idPrefix?: string;
  className?: string;
  variant?: TextFieldVariants;
  disabled?: boolean;
  emailAddress?: EmailAddress;
  onChange?: (emailAddress?: EmailAddress, error?: EmailAddressInputError) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
};

const EmailAddressInput = forwardRef((props: EmailInputProps, ref) => {
  const { idPrefix, className, variant, disabled, emailAddress, onChange, onKeyDown } = props;

  const [emailFieldText, setEmailFieldText] = useState<string>(emailAddress?.valueOf() ?? '');
  const [emailFieldError, setEmailFieldError] = useState<EmailAddressInputError>('');

  useEffect(() => {
    setEmailFieldText(emailAddress?.valueOf() ?? '');
  }, [emailAddress]);

  function buildEmailAddress(inputText?: string): EmailAddress | undefined {
    try {
      if (!inputText) throw new InvalidEmailAddressError("Email address is required");
      return new EmailAddress(inputText);
    } catch (error: any) {
      if (error instanceof InvalidEmailAddressError) {
        throw error;
      }
    }
  }

  function handleEmailChanged(emailAddress?: string) {
    let newEmailAddress: EmailAddress | undefined;
    let newErrors: EmailAddressInputError = '';

    try {
      newEmailAddress = buildEmailAddress(emailAddress);
      setEmailFieldError('');
    } catch (error: any) {
      newErrors = error.message;
      setEmailFieldError(newErrors);
    } finally {
      onChange?.(newEmailAddress, newErrors);
    }
  }

  return (
    <InputField
      id={idPrefix ? idPrefix + "-email-address-input" : "email-address-input"}
      className={className}
      label="Email Address"
      onChange={(event) => {
        setEmailFieldText(event.target.value);
        handleEmailChanged(event.target.value);
      }}
      required
      disabled={disabled}
      value={emailFieldText}
      error={!!emailFieldError}
      helperText={emailFieldError}
      variant={variant}
      autoComplete="account-email"
      type="email"
      onKeyDown={onKeyDown}
    />
  );
});

export default EmailAddressInput;
