import _ from "lodash";
import { ControlType } from "marketplace/view/filter-list";

export type FilterChipValueType = string | string[] | boolean | number | number[] | null;

export interface FilterChip {
  readonly filterName: string;
  readonly enabled: boolean;
  readonly default: FilterChipValueType;
  readonly controlType: ControlType;
  value: FilterChipValueType;
  title: string;
  label: string;
  hidden: boolean;
  freeSolo?: boolean;
  multiple?: boolean;
  options?: string[];
  min?: number;
  max?: number;
  steps?: number;
  precision?: number;
  enable(): void;
  disable(): void;
}

export class TextChip implements FilterChip {
  readonly filterName: string;
  readonly default: string;
  readonly controlType: ControlType;
  value: string;
  title: string;
  label: string;
  enabled: boolean;
  hidden: boolean = false;

  constructor(filterName: string, title: string, enabled: boolean = false) {
    this.filterName = filterName;
    this.title = title;
    this.enabled = enabled;
    this.default = '';
    this.value = '';
    this.label = `${this.title} - Any`;
    this.controlType = ControlType.Text;
  }

  updateValue(value: string): void {
    this.value = value;
    this.label = value.length > 0 ?
      `${this.title}: ${value}` :
      `${this.title} - Any`;

    this.enable();
  }

  enable(): void {
    this.enabled = true;
  }

  disable(): void {
    this.enabled = false;
    this.label = `${this.title} - Any`;
    this.value = '';
  }
}

export class RatingChip implements FilterChip {
  readonly filterName: string;
  readonly default: number | null;
  readonly controlType: ControlType;
  value: number | null;
  title: string;
  label: string;
  enabled: boolean;
  hidden: boolean = false;
  precision?: number;

  constructor(filterName: string, title: string, enabled: boolean = false) {
    this.filterName = filterName;
    this.title = title;
    this.enabled = enabled;
    this.default = 0;
    this.value = 0;
    this.label = `${this.title} - Any`;
    this.controlType = ControlType.Rating;
  }

  updateValue(value: string): void {
    const newValue = Number(value);

    if (newValue < 0 || newValue > 5) {
      throw new RangeError('Rating value must be between 0 and 5');
    }

    this.label = newValue > 0 ?
      `${this.title}: ${value}` :
      `${this.title} - Any`;

    this.enable();
  }

  enable(): void {
    this.enabled = true;
  }

  disable(): void {
    this.enabled = false;
    this.label = `${this.title} - Any`;
  }
}

export class ArrayChip implements FilterChip {
  readonly filterName: string;
  readonly default: string[];
  readonly controlType: ControlType;
  value: string[];
  title: string;
  label: string;
  enabled: boolean;
  hidden: boolean = false;
  freeSolo?: boolean;
  multiple?: boolean;
  options?: string[];

  constructor(filterName: string, title: string, enabled: boolean = false) {
    this.filterName = filterName;
    this.title = title;
    this.enabled = enabled;
    this.default = [];
    this.value = [];
    this.label = `${this.title} - Any`;
    this.controlType = ControlType.Autocomplete;
  }

  updateValue(value: string[]) {
    this.label = !_.isEmpty(value) ?
      `${this.title}: ${value.join(', ')}` :
      `${this.title} - Any`;

    this.enable();
  }

  enable(): void {
    this.enabled = true;
  }

  disable(): void {
    this.enabled = false;
    this.label = `${this.title} - Any`;
  }
}

export class RangeChip implements FilterChip {
  readonly filterName: string;
  readonly default: number | number[];
  readonly controlType: ControlType;
  value: number | number[];
  title: string;
  label: string;
  enabled: boolean;
  hidden: boolean = false;
  min?: number;
  max?: number;
  steps?: number;

  constructor(filterName: string, title: string, enabled: boolean = false) {
    this.filterName = filterName;
    this.title = title;
    this.enabled = enabled;
    this.default = [];
    this.value = [];
    this.label = `${this.title} - Any`;    
    this.controlType = ControlType.Slider;
  }

  updateValue(value: string[]) {
    this.label = !_.isEmpty(value) ?
      `${this.title}: ${value.join(' - ')}` :
      `${this.title} - Any`;

    this.enable();
  }

  enable(): void {
    this.enabled = true;
  }

  disable(): void {
    this.enabled = false;
    this.label = `${this.title} - Any`;
  }
}

export class BoolChip implements FilterChip {
  readonly filterName: string;
  readonly default: boolean;
  readonly controlType: ControlType;
  value: boolean;
  title: string;
  label: string;
  enabled: boolean;
  hidden: boolean = false;

  constructor(filterName: string, title: string, enabled: boolean = false) {
    this.filterName = filterName;
    this.title = title;
    this.enabled = enabled;
    this.default = false;
    this.value = false;
    this.label = `${this.title} - Any`;    
    this.controlType = ControlType.Switch;
  }

  updateValue(value: string) {
    const newValue = Boolean(value);
    this.label = `${this.title}: ${newValue ? 'Yes' : 'No'}`;

    this.enable();
  }

  enable(): void {
    this.enabled = true;
  }

  disable(): void {
    this.enabled = false;
    this.label = `${this.title} - Any`;
  }
}
