import { MenuItem, TextField, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import Forum from "../../forum";

const Container = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    flexDirection: "column"
  },
  display: 'flex',
  marginBottom: theme.spacing(2),
  width: '100%'
}));
const ForumSelect = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: '100%',
    "&:first-of-type": {
      marginBottom: theme.spacing(1.5),
      marginRight: 0
    }
  },
  "&:first-of-type": {
    marginRight: theme.spacing(2)
  },
  minWidth: theme.spacing(35)
}));

enum ForumCategories {
  Individual = "Individual",
  Team = "Team",
  Proposal = "Proposal",
  Work = "Work"
}

type ForumSelectionProps = {
  individualForums: Forum[];
  teamForums: Forum[];
  proposalForums: Forum[];
  workForums: Forum[];
  onForumSelected: (forum: Forum) => void;
}

export default function ForumSelection(props: Readonly<ForumSelectionProps>) {
  const {
    individualForums,
    teamForums,
    proposalForums,
    workForums,
    onForumSelected
  } = props;

  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [selectedIndividualForum, setSelectedIndividualForum] = useState<Forum>();
  const [selectedTeamForum, setSelectedTeamForum] = useState<Forum>();
  const [selectedProposalForum, setSelectedProposalForum] = useState<Forum>();
  const [selectedWorkForum, setSelectedWorkForum] = useState<Forum>();

  const isMediumDisplay = useMediaQuery((theme: any) => theme.breakpoints.down("md"));

  return (
    <Container>
      <ForumSelect
        select
        label="Category"
        size={isMediumDisplay ? "small" : "medium"}
        value={selectedCategory}
        onChange={(event) => setSelectedCategory(event.target.value)}>
        {Object.keys(ForumCategories).map((category: string) => (
          <MenuItem key={category} value={category}>
            {category}
          </MenuItem>
        ))}
      </ForumSelect>
      {selectedCategory === ForumCategories.Individual && (
        <ForumSelect
          select
          label="Individuals"
          size={isMediumDisplay ? "small" : "medium"}
          value={selectedIndividualForum?.id?.valueOf() ?? ''}
          onChange={(event) => {
            const selection = individualForums.find((forum: Forum) => (
              forum.id?.valueOf() === event.target.value
            ));
            if (!selection) return;
            setSelectedIndividualForum(selection);
            onForumSelected(selection);
          }}>
          {individualForums.length < 1 && (
            <MenuItem key={0} value={0} disabled>
              No Individuals In Network
            </MenuItem>
          )}
          {individualForums.map((forum: Forum) => (
            <MenuItem
              key={forum.id?.valueOf()}
              value={forum.id?.valueOf()}>
              {forum.name}
            </MenuItem>
          ))}
        </ForumSelect>
      )}
      {selectedCategory === ForumCategories.Team && (
        <ForumSelect
          select
          label="Teams"
          size={isMediumDisplay ? "small" : "medium"}
          value={selectedTeamForum?.id?.valueOf() ?? ''}
          onChange={(event) => {
            const selection = teamForums.find((forum: Forum) => (
              forum.id?.valueOf() === event.target.value
            ));
            if (!selection) return;
            setSelectedTeamForum(selection);
            onForumSelected(selection);
          }}>
          {teamForums.length < 1 && (
            <MenuItem key={0} value={0} disabled>
              No Teams
            </MenuItem>
          )}
          {teamForums.map((forum: Forum) => (
            <MenuItem
              key={forum.id?.valueOf()}
              value={forum.id?.valueOf()}>
              {forum.name}
            </MenuItem>
          ))}
        </ForumSelect>
      )}
      {selectedCategory === ForumCategories.Proposal && (
        <ForumSelect
          select
          label="Proposals"
          size={isMediumDisplay ? "small" : "medium"}
          value={selectedProposalForum?.id?.valueOf() ?? ''}
          onChange={(event) => {
            const selection = proposalForums.find((forum: Forum) => (
              forum.id?.valueOf() === event.target.value
            ));
            if (!selection) return;
            setSelectedProposalForum(selection);
            onForumSelected(selection);
          }} >
          {proposalForums.length < 1 && (
            <MenuItem key={0} value={0} disabled>
              No Proposals
            </MenuItem>
          )}
          {proposalForums.map((forum) => (
            <MenuItem
              key={forum.id?.valueOf()}
              value={forum.id?.valueOf()}>
              {forum.name}
            </MenuItem>
          ))}
        </ForumSelect>
      )}
      {selectedCategory === ForumCategories.Work && (
        <ForumSelect
          select
          label="Work"
          size={isMediumDisplay ? "small" : "medium"}
          value={selectedWorkForum?.id?.valueOf() ?? ''}
          onChange={(event) => {
            const selection = workForums.find((forum: Forum) => (
              forum.id?.valueOf() === event.target.value
            ));
            if (!selection) return;
            setSelectedWorkForum(selection);
            onForumSelected(selection);
          }}>
          {workForums.length < 1 && (
            <MenuItem key={0} value={0} disabled>
              No Work
            </MenuItem>
          )}
          {workForums.map((forum) => (
            <MenuItem key={forum.id?.valueOf()} value={forum.id?.valueOf()}>
              {forum.name}
            </MenuItem>
          ))}
        </ForumSelect>
      )}
    </Container>
  )
}
