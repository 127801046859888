import Proposal from 'work/entities/proposal/proposal';
import { ProposalTab } from 'work/entities/proposal/redlining/view/proposal-redline-dialog';
import FeeScheduleCategories from 'work/values/fee-schedule-category/view/fee-schedule-categories';

type FeeScheduleTabProps = {
  activeTab: ProposalTab,
  proposal?: Proposal
};

export default function FeeScheduleTab(props: Readonly<FeeScheduleTabProps>) {
  const {
    activeTab,
    proposal
  } = props;

  if (activeTab !== ProposalTab.FeeSchedule) return null;

  return (
    <FeeScheduleCategories
      categories={proposal?.feeSchedule ?? []}
      hideFromTemplateButton={true}
      viewOnly
    />
  );
}
